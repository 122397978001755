import React, { useState, useEffect } from 'react';
import * as L from 'leaflet';
import { Marker, Popup, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import SetDivIcon from './icon';

export default function LocationMarker(props) {
  const coordinates = props.coordinates.map(x => x.position);
  const [position, setPosition] = useState(props.currentPosition.position);
  const [lastPosition, setLastPosition] = useState(props.currentPosition.position);
  const [direction, setDirection] = useState(props.currentPosition.direction);
  const [vehiclePathLayer, setVehiclePathLayer] = useState(null);
  if (position != props.currentPosition.position) {
    setPosition(props.currentPosition.position);
    setDirection(props.currentPosition.direction)
  }
  // if (position.length == 0 && coordinates && coordinates.length > 0) {
  //   let lastIndex = props.coordinates.length - 1;
  //   setPosition(props.coordinates[lastIndex].position);
  //   setLastPosition(props.coordinates[lastIndex].position);
  //   setDirection(props.coordinates[lastIndex].direction)
  // }

  const map = useMapEvents({
  });
  // map.setMaxBounds(map.getBounds());
  if (vehiclePathLayer) {
    map.removeLayer(vehiclePathLayer);
  }
  const pathPolyLine = L.polyline(coordinates);
  if (!pathPolyLine) {
    setVehiclePathLayer(pathPolyLine);
  }
  if (pathPolyLine) {
    clearMap(map);
    pathPolyLine.addTo(map);
  }
  useEffect(() => {
    map.flyTo(position);
  }, []);
  return position === null ? null :
    (
    // <div>
    //   {props.coordinates.map((x, i) => {
    //     return (
    //       <Marker
    //         key={i}
    //         position={position}
    //         icon={L.divIcon(SetDivIcon(`rotate(${direction || 0}deg)`))}
    //         style={{ background: "transperent" }}>
    //         <Popup key={i}>
    //           Latitude : {x.position[0]} <br />
    //           Longitude : {x.position[1]} <br />
    //           Speed : {x.speed} <br />
    //           Odometer : {x.odometer} <br />
    //         </Popup>
    //       </Marker>
    //     );
    //   })}
    // </div>
    <div>
    <Marker
      key={props.key}
      position={position}
      icon={L.divIcon(SetDivIcon(`rotate(${(direction || 0)}deg)`))}
      style={{ background: 'transperent' }}>
      <Popup>You are here</Popup>
    </Marker>
  </div>
    )
}

function clearMap(m) {
  for (let i in m._layers) {
    if (m._layers[i]._path != undefined) {
      try {
        m.removeLayer(m._layers[i]);
      }
      catch (e) {
        console.log("problem with " + e + m._layers[i]);
      }
    }
  }
}