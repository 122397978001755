class Tableau {
    constructor(Service, path, path2, Util) {
      this.httpService = Service;
      this.path = path;
      this.path2 = path2;
      this.Util = Util;
    }
  
    getTableauDashboard = () => {
        const url =
        process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +"/tableau/tableauDashboard"
        // const url = "http://localhost:3080/devices/tableau"
      return new Promise((resolve, reject) => {
        this.httpService
          .get(url)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    };
  
  
  }
  
  
  export default Tableau;
  