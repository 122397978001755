module.exports = Object.freeze({
    LOGIN: "owner/login",
    GET_TRIPSHEETS: "account-supervisor/get-tripsheets",
    CREATE_TRIPSHEET: "account-supervisor/create-tripsheet",
    UPDATE_TRIPSHEET: "account-supervisor/update-tripsheet",
    GET_TRIPSHEET_SUMMARY: "account-supervisor/tripsheet-summary",
    GET_TRUCK_LIST: "account-supervisor/truck-list",
    GET_DRIVER_LIST: "account-supervisor/driver-list",
    SEARCH_BOOKINGS: "account-supervisor/search-bookings",
    GET_EXPENSE_TYPES: "account-supervisor/get-exepnse-types",
    CREATE_EXPENSES: "account-supervisor/add-expense",
    CLOSE_TRIPSHEET: "account-supervisor/close-tripsheet",
    GET_BOOKING_EXPENSES: "account-supervisor/view-booking-expenses",
    UPDATE_EXPENSES: "account-supervisor/update-expense",
    EXPENSE_ATTACHMENTS: "account-supervisor/view-expense-attachments",
    ADD_ADVANCE: "account-supervisor/add-advanced"
})