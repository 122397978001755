// import * as React from 'react';
import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { MarginOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
// import { ForInventoryTypeEquipment } from './storeInventory';
import { ForInventoryTypeEquipment } from "./storeInventoryCommonComponents";
import CircularLoading from "../../components/loader/circularLoading";
import { CircularProgress } from "@mui/material";

// import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
// import { DemoItem } from '@mui/x-date-pickers/internals/demo';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import _ from "lodash";
import * as Fields from "../../sharedComponents";
import Divider from "@mui/material/Divider";
// import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';
import AntdPagination from "../../sharedComponents/antd/AntdPagination";
import * as CONFIG from "../../config/GlobalConstants";
import { makeStyles } from "@material-ui/core/styles";

import { fromAddress } from "react-geocode";
import ValidateFields from "../../validations/validateFields";
import AlertMessage from "../../components/alertmessage/AlertMessage";
import Util from "../../utils";
import { vehicleImage as vehicle } from "../../assets/index";
import { consumerImage, tireImage, equipmentImage } from "../../assets/index";
import { Card } from "@material-ui/core";
import { Avatar } from "@mui/material";
import QRCode from "qrcode.react";
import moment from "moment";
import { Pagination } from "antd";

let validateFields = new ValidateFields();
const useStyles = makeStyles((theme) => ({
  table_head: {
    padding: "10px 40px 15px 24px",
    display: "flex",
  },
  selected_text: {
    color: "#929DAF",
    fontSize: "12px",
    fontFamily: "Montserrat, SemiBold",
    letterSpacing: "0.5px",
    alignSelf: "center",
  },
  row_paper: {
    background: "#FFFFFF",
    boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
    borderRadius: "4px",
  },
  divider: {
    height: "12px",
  },
}));

export function ForTableCellCommonComponent(props) {
  return (
    <TableCell
      style={{ fontFamily: "SegoeUI, Segoe UI", fontSize: "16px" }}
      align="center"
      valign="middle"
    >
      {props.text} {props.value}{" "}
    </TableCell>
  );
}

function ForBasicDetails(props) {
  const { data } = props;
  return (
    <>
      <Grid item xs={6} md={2.5}>
        <Stack display="flex" direction="column">
          <ForTableCellCommonComponent
            text="Invoice Number :"
            value={data.invoice_number}
          />
          <ForTableCellCommonComponent text="QR Code :" value={data.qrcode} />
          <ForTableCellCommonComponent text="Name :" value={data.name} />
          <ForTableCellCommonComponent
            text="Inventory Type :"
            value={data.inventory_type}
          />
        </Stack>
      </Grid>
      <Grid item xs={6} md={2.5}>
        <Stack display="flex" direction="column">
          <ForTableCellCommonComponent
            text="Purchase Date :"
            value={data.purchase_date.slice(0, 10)}
          />
          <ForTableCellCommonComponent
            text="Serial Number :"
            value={data.serial_number}
          />
          <ForTableCellCommonComponent
            text="Product Type :"
            value={data.product_type}
          />
          <ForTableCellCommonComponent
            text={
              (data.inventory_type == "Tire" && "Tire Depth :") ||
              (data.inventory_type == "Equipment" && "Equipment Category :") ||
              (data.inventory_type == "Consumable" && "Consumable Category :")
            }
            value={
              (data.inventory_type == "Tire" && data.tire_depth) ||
              (data.inventory_type == "Equipment" &&
                data.equipment_parent_name) ||
              (data.inventory_type == "Consumable" &&
                data.consumable_parent_name)
            }
          />
        </Stack>
      </Grid>
      <Grid item xs={6} md={2.5}>
        <Stack display="flex" direction="column">
          <ForTableCellCommonComponent text="&nbsp;" />
          <ForTableCellCommonComponent
            text="Model Number :"
            value={data.model_number}
          />
          <ForTableCellCommonComponent
            text="HSN Code :"
            value={data.hsn_code}
          />
          {(data.inventory_type == "Tire" && (
            <ForTableCellCommonComponent text="&nbsp;" />
          )) ||
            (data.inventory_type == "Equipment" && (
              <ForTableCellCommonComponent
                text="Equipment Sub Category :"
                value={data.equipment_child_name}
              />
            )) ||
            (data.inventory_type == "Consumable" && (
              <ForTableCellCommonComponent
                text="Consumable Sub Category :"
                value={data.consumable_child_name}
              />
            ))}
          {/* <ForTableCellCommonComponent text="&nbsp;"/> */}
        </Stack>
      </Grid>
      <Grid
        item
        xs={6}
        md={2}
        style={{ textAlign: "center", paddingTop: "10px" }}
      >
        <div id={`print-${data.qrcode}`}>
          <QRCode value={data.qrcode} size={200} />
        </div>
      </Grid>
    </>
  );
}

function ForPricingDetails(props) {
  const { data } = props;

  return (
    <>
      <Grid item xs={6} md={3}>
        <Stack display="flex" direction="column">
          <ForTableCellCommonComponent
            text="Quantity :"
            value={data.quantity}
          />
          <TableCell
            style={{ fontFamily: "SegoeUI, Segoe UI", fontSize: "16px" }}
            align="center"
            valign="middle"
          >
            Discount :
            {(data.discount_type == "Flat" && (
              <>
                {" "}
                <span
                  style={{
                    backgroundColor: "#00BF9A",
                    padding: "3px",
                    borderRadius: "5px",
                  }}
                >
                  {data.discount_type}
                </span>
                <div>
                  ₹{" "}
                  {data.discount_value !== null
                    ? data.discount_value.toFixed(2)
                    : 0}
                </div>
              </>
            )) ||
              (data.discount_type == "Percentage" && (
                <>
                  {" "}
                  <span
                    style={{
                      backgroundColor: "#FE6593",
                      padding: "3px",
                      borderRadius: "5px",
                    }}
                  >
                    {`${data.discount_value}%`}
                  </span>
                  <div>
                    ₹ {((data.discount_value * data.subtotal) / 100).toFixed(2)}
                  </div>
                </>
              )) ||
              (data.discount_type == null && (
                <>
                  {" "}
                  <span
                    style={{
                      backgroundColor: "#FE6593",
                      padding: "3px",
                      borderRadius: "5px",
                    }}
                  >
                    {`%`}
                  </span>
                  <div>&nbsp;</div>
                </>
              ))}
          </TableCell>
          {/* <ForTableCellCommonComponent text="Discount :" value = {data.discount_type}/> */}
        </Stack>
      </Grid>
      <Grid item xs={6} md={3}>
        <Stack display="flex" direction="column">
          <ForTableCellCommonComponent
            text="Rate :"
            value={data.price !== null ? data.price.toFixed(2) : 0}
          />
          <TableCell
            style={{ fontFamily: "SegoeUI, Segoe UI", fontSize: "16px" }}
            align="center"
            valign="middle"
          >
            GST :{" "}
            <span
              style={{
                backgroundColor: "#FE6593",
                padding: "3px",
                borderRadius: "5px",
              }}
            >
              {data.gst_percentage}%{" "}
            </span>{" "}
            <div fontSize="50px">
              <span>₹</span>{" "}
              {data.gst_amount !== null ? data.gst_amount.toFixed(2) : 0}
            </div>
          </TableCell>
        </Stack>
      </Grid>
      <Grid item xs={6} md={3}>
        <Stack display="flex" direction="column">
          <ForTableCellCommonComponent
            text="Subtotal :"
            value={data.subtotal !== null ? data.subtotal.toFixed(2) : 0}
          />
          <TableCell
            style={{ fontFamily: "SegoeUI, Segoe UI", fontSize: "16px" }}
            align="center"
            valign="middle"
          >
            Purchase Cost :{" "}
            {data.purchase_total !== null ? data.purchase_total.toFixed(2) : 0}{" "}
            <div>&nbsp;</div>
          </TableCell>
          {/* <ForTableCellCommonComponent text="Purchase Cost :" value = {data.purchase_total}/> */}
        </Stack>
      </Grid>
    </>
  );
}

function ForLifetimeDetails(props) {
  const { data } = props;
  return (
    <>
      <Grid item md={1}></Grid>
      <Grid item xs={6} md={3}>
        <Stack display="flex" direction="column">
          <TableCell
            style={{ fontFamily: "SegoeUI, Segoe UI", fontSize: "18px" }}
            align="center"
          >
            Expected Lifetime : {data.expected_lifetime_no_of_years} Years,
            {data.expected_lifetime_no_of_months} Months,
            {data.expected_lifetime_no_of_days} Days
          </TableCell>
          <TableCell
            style={{ fontFamily: "SegoeUI, Segoe UI", fontSize: "18px" }}
            align="center"
          >
            Consumed Lifetime : {data.consumed_life_no_of_years} Years,
            {data.consumed_life_no_of_months} Months,
            {data.consumed_life_no_of_days} Days
          </TableCell>
        </Stack>
      </Grid>
    </>
  );
}

let Service;

function Row(props) {
  let util = new Util();
  const classes = useStyles();
  const [addInventoryDialog, setAddInventoryDialog] = React.useState(false);
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  let [pricingColor, setPricingColor] = React.useState("#EEB2AA");
  let [basicColor, setBasicColor] = React.useState("#F15F4C");
  let [outlineColor, setOutlineColor] = React.useState("#EEB2AA");
  let [basic, setBasic] = React.useState(true);
  let [pricing, setPricing] = React.useState(false);
  let [lifetime, setlifetime] = React.useState(false);
  let [forEditInventory, setforEditInventory] = React.useState(false);
  let [inventoryTypeValue, setInventoryTypeValue] = React.useState("Tire");
  let [autoFillData, setAutoFillData] = React.useState([]);
  let [updatedData, setUpdatedData] = React.useState({});
  // React.useEffect(async () => {
  //   let promise1 = Service.getEquipmentCategories();
  //   Promise.all([promise1]).then(async (response) => {
  //     let newField = formData;
  //     newField["equiCategory"]["options"] = await response[0].data.map(
  //       (each) => ({ label: each.name, value: each.id })
  //     );
  //     setFormData(newField);
  //   });
  // }, []);

  const formFields = {
    invoiceNum: {
      name: "invoiceNum",
      label: "Invoice Number",
      value: "",
      validationRequired: true,
      isValid: true,
      topLabel: true,
      errorMsg: "Please enter invoice number",
      placeholder: "Enter invoice number",
      // ref: useRef(),
      // maxLength:true
      validPattern: "ALPHA_NUMERIC_SPACE",
    },
    manfName: {
      name: "manfName",
      label: "Manufacture Name",
      topLabel: true,
      allowClear: true,
      ref: useRef(),
      value: "",
      validationRequired: true,
      isValid: true,
      options: [],
      // validPattern: "ALPHA_NUMERIC_SPACE",
    },
    tireDepth: {
      name: "tireDepth",
      label: "Tire Depth",
      value: "",
      validationRequired: true,
      isValid: true,
      topLabel: true,
      errorMsg: "Please enter tire depth",
      placeholder: "Enter tire depth",
      helperText: "Please enter tire depth",
      type: "number",
      ref: useRef(),
    },
    qrCode: {
      name: "qrCode",
      label: "QR Code",
      value: "",
      readOnly: true,
      validationRequired: true,
      isValid: true,
      topLabel: true,
      errorMsg: "Please enter qr code",
      placeholder: "Enter qr code",
      helperText: "Please enter qr coded",
      ref: useRef(),
      validPattern: "ALPHA_NUMERIC_SPACE",
    },
    serialNum: {
      name: "serialNum",
      label: "Serial Number",
      value: "",
      readOnly: true,
      validationRequired: true,
      isValid: true,
      topLabel: true,
      errorMsg: "Please enter serial number",
      placeholder: "Enter serial number",
      helperText: "Please enter serial number",
      ref: useRef(),
      validPattern: "ALPHA_NUMERIC_SPACE",
    },
    modelNum: {
      name: "modelNum",
      label: "Model Number",
      value: "",
      topLabel: true,
      placeholder: "Enter qr code",
      ref: useRef(),
      validPattern: "ALPHA_NUMERIC_SPACE",
      validationRequired: false,
      isValid: false,
    },
    name: {
      name: "name",
      label: "Name",
      value: "",
      validationRequired: true,
      isValid: true,
      topLabel: true,
      errorMsg: "Please enter name",
      placeholder: "Enter name",
      helperText: "Please enter name",
      ref: useRef(),
      validPattern: "ALPHA_NUMERIC_SPACE",
    },
    hsnCode: {
      name: "hsnCode",
      label: "HSN Code",
      value: "",
      isValid: false,
      topLabel: true,
      placeholder: "Enter hsn code",
      ref: useRef(),
      validPattern: "ALPHA_NUMERIC_SPACE",
      validationRequired: false,
    },
    quantity: {
      name: "quantity",
      label: "Quantity",
      value: "",
      validationRequired: true,
      isValid: true,
      topLabel: true,
      errorMsg: "Please enter quantity",
      placeholder: "Enter quantity",
      helperText: "Please enter quantity",
      ref: useRef(),
      type: "number",
      validPattern: "FLOAT",
    },
    rate: {
      name: "rate",
      label: "Rate",
      value: "",
      validationRequired: true,
      isValid: true,
      topLabel: true,
      errorMsg: "Please enter rate",
      placeholder: "Enter rate",
      helperText: "Please enter rate",
      ref: useRef(),
      type: "number",
      validPattern: "NUMERIC",
    },
    subtotal: {
      name: "subtotal",
      label: "Subtotal",
      value: 0,
      topLabel: true,
      // placeholder: "Enter subtotal",
      ref: useRef(),
      readOnly: true,
      type: "number",
      validationRequired: false,
      isValid: false,
    },
    gstPercentage: {
      name: "gstPercentage",
      label: "GST Percentage (+)",
      value: "",
      topLabel: true,
      placeholder: "Enter gst percentage",
      ref: useRef(),
      type: "number",
      validPattern: "NUMERIC",
      isValid: false,
      validationRequired: false,
    },
    purchaseTotal: {
      name: "purchaseTotal",
      label: "Purchase Total",
      value: "",
      topLabel: true,
      // placeholder: "Enter purchase total",
      ref: useRef(),
      readOnly: true,
      type: "number",
      isValid: false,
      validationRequired: false,
    },
    vendorName: {
      name: "vendorName",
      label: "Vendor Name",
      value: "",
      topLabel: true,
      placeholder: "Enter vendor name",
      ref: useRef(),
      validPattern: "ALPHA_NUMERIC_SPACE",
      isValid: false,
      validationRequired: false,
    },
    vendorCode: {
      name: "vendorCode",
      label: "Vendor Code",
      value: "",
      topLabel: true,
      isValid: false,
      validationRequired: false,
      placeholder: "Enter vendor code",
      ref: useRef(),
      validPattern: "ALPHA_NUMERIC_SPACE",
    },
    expLifeTimeYear: {
      name: "expLifeTimeYear",
      label: "Expected Lifetime",
      value: "",
      topLabel: true,
      placeholder: "Enter no of years",
      ref: useRef(),
      type: "number",
      validPattern: "NUMERIC",
      isValid: false,
      validationRequired: false,
    },
    expLifeTimeMonth: {
      name: "expLifeTimeMonth",
      value: "",
      topLabel: true,
      placeholder: "Enter no of months",
      ref: useRef(),
      type: "number",
      validPattern: "NUMERIC",
      isValid: false,
      validationRequired: false,
    },
    expLifeTimedays: {
      name: "expLifeTimedays",
      value: "",
      topLabel: true,
      placeholder: "Enter no of days",
      ref: useRef(),
      type: "number",
      validPattern: "NUMERIC",
      isValid: false,
      validationRequired: false,
    },
    expectedKm: {
      name: "expectedKm",
      label: "Expected KM",
      value: "",
      topLabel: true,
      placeholder: "Enter expected km",
      ref: useRef(),
      type: "number",
      validPattern: "NUMERIC",
      isValid: false,
      validationRequired: false,
    },
    conLifeTimeYear: {
      name: "conLifeTimeYear",
      label: "Consumed Life",
      value: "",
      topLabel: true,
      placeholder: "Enter no of years",
      ref: useRef(),
      type: "number",
      validPattern: "NUMERIC",
      isValid: false,
      validationRequired: false,
    },
    conLifeTimeMonth: {
      name: "conLifeTimeMonth",
      value: "",
      topLabel: true,
      placeholder: "Enter no of months",
      ref: useRef(),
      type: "number",
      validPattern: "NUMERIC",
      isValid: false,
      validationRequired: false,
    },
    conLifeTimedays: {
      name: "conLifeTimedays",
      value: "",
      topLabel: true,
      placeholder: "Enter no of days",
      ref: useRef(),
      validPattern: "NUMERIC",
      type: "number",
      isValid: false,
      validationRequired: false,
    },
    consumedKm: {
      name: "consumedKm",
      label: "Consumed KM",
      value: "",
      topLabel: true,
      placeholder: "Enter consumed km",
      ref: React.useRef(),
      validPattern: "NUMERIC",
      type: "number",
      isValid: false,
      validationRequired: false,
    },
    unitValue: {
      name: "unitValue",
      label: "Unit Value",
      value: "",
      topLabel: true,
      isValid: true,
      errorMsg: "Please enter unit value",
      placeholder: "Enter unit value",
      helperText: "Please enter unit value",
      type: "number",
      ref: useRef(),
      validationRequired: false,
    },
    inventoryType: {
      name: "inventoryType",
      label: "Inventory Type",
      value: { label: "Tire", value: "Tire" },
      options: [
        {
          label: "Tire",
          value: "Tire",
        },
        {
          label: "Equipment",
          value: "Equipment",
        },
        {
          label: "Consumable",
          value: "Consumable",
        },
      ],
      validationRequired: true,
      isValid: true,
      errorMsg: "Please select user",
      validPattern: "ALPHABET_with_label",
      topLabel: true,
    },
    purchaseDate: {
      name: "purchaseDate",
      label: "Purchase Date",
      topLabel: true,
      validationRequired: true,
      validPattern: "DATE",
      errorMsg: "Please Select Purchase Date",
      isValid: true,
      allowClear: true,
      value: "",
      //  disabledDate: (d) => !d || d.isAfter(new Date()),
    },
    discountType: {
      name: "discountType",
      label: "Discount Type",
      topLabel: true,
      value: { label: "--Discount Type--", value: "" },
      options: [
        { label: "--Discount Type--", value: "" },
        { label: "Flat", value: "Flat" },
        { label: "Percentage", value: "Percentage" },
      ],
      validPattern: "ALPHA_NUMERIC_SPACE",
      isValid: false,
      validationRequired: false,
    },
    itemType: {
      name: "itemType",
      label: "Item Type",
      topLabel: true,
      value: { label: "", value: "" },
      options: [
        { label: "New", value: "New" },
        { label: "Used", value: "Used" },
      ],
      validPattern: "ALPHA_NUMERIC_SPACE",
      isValid: false,
      validationRequired: false,
    },
    consumableCategory: {
      name: "consumableCategory",
      label: "Consumable Category",
      topLabel: true,
      value: { label: "", value: "" },
      options: [{ label: "", value: "" }],
      validPattern: "ALPHA_NUMERIC_SPACE",
      allowClear: true,
      isValid: false,
      validationRequired: false,
    },
    consumableSubCategory: {
      name: "consumableSubCategory",
      label: "Consumable Sub-Category",
      topLabel: true,
      value: { label: "", value: "" },
      options: [{ label: "", value: "" }],
      allowClear: true,
      isValid: false,
      validationRequired: false,
    },
    unitType: {
      name: "unitType",
      label: "Unit Type",
      topLabel: true,
      value: { label: "", value: "" },
      options: [{ label: "", value: "" }],
      isValid: false,
      type: "number",
      validPattern: "ALPHA_NUMERIC_SPACE",
      allowClear: true,
      validationRequired: false,
    },
    equiCategory: {
      name: "equiCategory",
      label: "Equipment Category",
      topLabel: true,
      value: { label: "", value: "" },
      options: [{ label: "", value: "" }],
      validPattern: "ALPHA_NUMERIC_SPACE",
      allowClear: true,
      isValid: false,
      validationRequired: false,
      // placeholder:"--Equipment Category--"
    },
    equiSubCategory: {
      name: "equiSubCategory",
      label: "Equipment Sub_Category",
      topLabel: true,
      value: { label: "", value: "" },
      options: [{ label: "", value: "" }],
      validPattern: "ALPHA_NUMERIC_SPACE",
      placeholder: "--Equipment Sub_Category--",
      allowClear: true,
      isValid: false,
      validationRequired: false,
    },
    discountAmount: {
      name: "discountAmount",
      label: "Discount Amount (-)",
      value: "",
      topLabel: true,
      placeholder: "Enter consumed km",
      ref: useRef(),
      type: "number",
      validPattern: "NUMERIC",
      isValid: false,
      validationRequired: false,
    },
    discountPercentage: {
      name: "discountPercentage",
      label: "Discount Percentage (-)",
      value: "",
      topLabel: true,
      placeholder: "Enter consumed km",
      ref: useRef(),
      type: "number",
      validPattern: "NUMERIC",
      isValid: false,
      validationRequired: false,
    },
  };

  let [formData, setFormData] = React.useState(_.cloneDeep(formFields));
  const forInputChangeTire = () => {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <Fields.InputField
              fieldData={formData.tireDepth}
              variant="outlined"
              inputChangeHandler={inputChangeHandler}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const forInventoryTypeEquipment = (formData) => {
    const autoCompleteChangeHandler = (value, name) => {
      formData[name]["value"] = { label: name, value: value?.value };
      setFormData(_.cloneDeep(formData));
      if (formData["equiCategory"]["value"] !== "") {
        Service.getEquipmentCategories(value.value).then((response) => {
          formData["equiSubCategory"]["options"] = response.data.map(
            (each) => ({ label: each.name, value: each.id })
          );
          setFormData(_.cloneDeep(formData));
        });
      }
    };

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <Fields.AntSelectableSearchField
              fieldData={formData.equiCategory}
              autoCompleteChangeHandler={autoCompleteChangeHandler}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Fields.AntSelectableSearchField
              fieldData={formData.equiSubCategory}
              autoCompleteChangeHandler={autoCompleteChangeHandler}
            />
          </Grid>
        </Grid>
      </>
    );
  };
  const addInventoryOpen = () => {
    setAddInventoryDialog(true);
  };
  const addInventoryClose = () => {
    setAddInventoryDialog(false);
    setFormData(formFields);
  };
  const [alertData, setAlertData] = React.useState({
    open: false,
    severity: "",
    message: "",
  });
  const [alert, setAlert] = React.useState({
    open: false,
    severity: "",
    message: "",
  });
  const contentRef = useRef(null);
  const closeAlert = () => {
    let alert = _.cloneDeep(alertData);
    alert.open = false;
    alert.severity = "";
    alert.message = "";
    alert.data = "";
    setAlertData(alert);
    // window.location.reload();
  };
  const closeAlertMsg = () => {
    let error = _.cloneDeep(alert);
    error.open = false;
    error.severity = "";
    error.message = "";
    setAlert(error);
    // window.location.reload();
  };
  const ForShowingBasicDetails = (row) => {
    setOutlineColor("#EEB2AA");
    setPricingColor("#EEB2AA");
    setBasicColor("#F15F4C");
    setlifetime(false);
    setPricing(false);
    setBasic(true);
  };

  const ForShowingPricingDetails = (row) => {
    setPricingColor("#F15F4C");
    setBasicColor("#EEB2AA");
    setOutlineColor("#EEB2AA");
    setBasic(false);
    setlifetime(false);
    setPricing(true);
  };

  const ForShowingLifetimeDetails = (row) => {
    setOutlineColor("#F15F4C");
    setBasicColor("#EEB2AA");
    setPricingColor("#EEB2AA");
    setBasic(false);
    setPricing(false);
    setlifetime(true);
  };

  const editInventoryOpen = async (id, formData) => {
    let promise1 = Service.getEquipmentCategories();
    Promise.all([promise1]).then(async (response) => {
      let newField = formData;
      newField["equiCategory"]["options"] = await response[0].data.map(
        (each) => ({ label: each.name, value: each.id })
      );
      setFormData(newField);
    });
    await Service.getAutoFillData(id).then((response) => {
      let result = response.data[0];
      // let date = response.data[0].purchaseDate.split("T")
      formData.invoiceNum.value = result.invoice_number;
      formData.inventoryType.value.value = result.inventory_type;
      formData.tireDepth.value = result.tire_depth;
      formData.equiCategory.value.value =
        result.equipment_parent_id == null ? "" : result.equipment_parent_id;
      formData.equiSubCategory.value.value =
        result.equipment_child_id == null ? "" : result.equipment_child_id;
      formData.qrCode.value = result.qrcode;
      formData.serialNum.value = result.serial_number;
      formData.modelNum.value = result.model_number;
      formData.name.value = result.name;
      formData.itemType.value.value = result.product_type;
      formData.hsnCode.value = result.hsn_code;
      formData.quantity.value = result.quantity;
      formData.rate.value = result.price;
      formData.subtotal.value = result.subtotal;
      formData.discountType.value.value = result.discount_type;
      formData.gstPercentage.value = result.gst_percentage;
      formData.purchaseTotal.value = result.purchase_total;
      formData.discountAmount.value = result.discount_value;
      formData.discountPercentage.value = result.discount_value;
      formData.vendorName.value = result.vendor_name;
      formData.vendorCode.value = result.vendor_code;
      formData.purchaseDate.value = moment(
        result?.purchase_date.slice(0, 10)
      ).clone("YYYY-MM-DD");
      formData.expLifeTimeYear.value = result.expected_lifetime_no_of_years;
      formData.expLifeTimeMonth.value = result.expected_lifetime_no_of_months;
      formData.expLifeTimedays.value = result.expected_lifetime_no_of_days;
      formData.expectedKm.value = result.expected_km;
      formData.conLifeTimeYear.value = result.consumed_life_no_of_years;
      formData.conLifeTimeMonth.value = result.expected_lifetime_no_of_months;
      formData.conLifeTimedays.value = result.consumed_life_no_of_days;
      formData.consumedKm.value = result.consumed_km;
      formData.id = id;

      setFormData(_.cloneDeep(formData));
    });
    setforEditInventory(true);
  };

  const updateInventoryData = async (updateData) => {
    updateData.id = formData.id;
    let discount;
    let gstAmount = 0;
    if (formData.discountType.value?.value == "Flat") {
      discount = formData.subtotal.value - formData.discountAmount.value;
      gstAmount = (discount * formData.gstPercentage.value) / 100;
    } else if (formData.discountType.value?.value == "Percentage") {
      discount =
        formData.subtotal.value -
        (formData.subtotal.value * formData.discountPercentage.value) / 100;
      gstAmount = (discount * formData.gstPercentage.value) / 100;
    } else {
      gstAmount =
        (formData.subtotal.value * formData.gstPercentage.value) / 100;
    }

    let dataStatus = await validateFields.validateFieldsData(formData);
    if (dataStatus.status) {
      if (formData.discountType.value?.value == "Flat") {
        updateData.discountValue = formData.discountAmount.value && formData.discountAmount.value != '' ? formData.discountAmount.value : 0;
      } else if (formData.discountType.value?.value == "Percentage") {
        updateData.discountValue = formData.discountPercentage.value;
      }
      updateData.gstAmount = gstAmount;
      updateData.availableQuantity = formData.quantity.value;
      updateData.invoiceNum = formData.invoiceNum.value;
      updateData.inventoryType = formData.inventoryType.value.value;
      updateData.name = formData.name.value;
      updateData.quantity = formData.quantity.value;
      updateData.rate = formData.rate.value;
      updateData.purchaseDate = util.formatDate(formData.purchaseDate.value);
      await Service.updateInventoryData(updateData).then((response) => {
        if (response.status == true) {
          setAlertData({
            open: true,
            message: response.message,
            severity: "success",
          });

          if (contentRef.current) {
            contentRef.current.scrollTop = 0;
          }

          setTimeout(() => {
            closeAlert();
            setforEditInventory(false);
            setFormData(formFields);
            window.location.reload()
          }, 3000);
        } else {
          setAlertData({
            open: true,
            message: response.message,
            severity: "error",
          });

          if (contentRef.current) {
            contentRef.current.scrollTop = 0;
          }
        }
      });
    } else {
      let newFields = _.cloneDeep(formData);
      let erroredFields = Object.keys(dataStatus.data).filter(
        (key, i) => dataStatus.data[key].isValid === false
      );
      newFields = dataStatus.data;
      setFormData(newFields);
    }
  };

  const editInventoryClose = () => {
    setforEditInventory(false);
  };
  const forOpeningPrintWindow = (id) => {
    // const divToPrint = document.getElementById(id);
    //   const newWin = window.open('', 'Print-Window');
    //   newWin.document.open();
    //   newWin.document.write('<html><body onload="window.print()">' + divToPrint.innerHTML + '</body></html>');
    //   newWin.document.close();
    //   setTimeout(function () {
    //     newWin.close();
    //   }, 10);

    window.print();
  };
  const inputChangeHandler = (value, name) => {
    updatedData[name] = value;
    setUpdatedData(updatedData);
    formData[name]["value"] = value;
    if (value.length > 0 && formData[name]["validationRequired"]) {
      formData[name]["isValid"] = true;
    }
    if (name == "quantity" || name == "rate") {
      formData["subtotal"]["value"] =
        formData["quantity"]["value"] * formData["rate"]["value"];
      formData["purchaseTotal"]["value"] =
        formData["quantity"]["value"] * formData["rate"]["value"];
    }
    if (formData["discountType"]["value"]["value"] == "") {
      formData["purchaseTotal"]["value"] =
        (formData["subtotal"]["value"] * formData["gstPercentage"]["value"]) /
          100 +
        formData["subtotal"]["value"];
    }
    if (name == "discountAmount") {
      let discountValue =
        formData["subtotal"]["value"] - formData["discountAmount"]["value"];
      formData["purchaseTotal"]["value"] =
        (discountValue * formData["gstPercentage"]["value"]) / 100 +
        discountValue;
    }
    if (name == "gstPercentage") {
      if (formData["discountType"]["value"]["value"] == "Flat") {
        let discountAmount =
          formData["subtotal"]["value"] - formData["discountAmount"]["value"];
        formData["purchaseTotal"]["value"] =
          discountAmount +
          (discountAmount * formData["gstPercentage"]["value"]) / 100;
      } else if (formData["discountType"]["value"]["value"] == "Percentage") {
        let discountPer =
          formData["subtotal"]["value"] -
          (formData["subtotal"]["value"] *
            formData["discountPercentage"]["value"]) /
            100;
        let gstPer = discountPer + (discountPer * value) / 100;
        formData["purchaseTotal"]["value"] = gstPer;
        if (value == "") {
          formData["purchaseTotal"]["value"] =
            formData["subtotal"]["value"] -
            (formData["subtotal"]["value"] *
              formData["discountPercentage"]["value"]) /
              100;
        }
      } else {
        formData["purchaseTotal"]["value"] =
          (formData["subtotal"]["value"] * value) / 100 +
          formData["subtotal"]["value"];
      }
    }
    if (name == "discountPercentage") {
      let discountPer =
        formData["subtotal"]["value"] -
        (formData["subtotal"]["value"] *
          formData["discountPercentage"]["value"]) /
          100;
      let gstPer =
        discountPer + (discountPer * formData["gstPercentage"]["value"]) / 100;
      formData["purchaseTotal"]["value"] = gstPer;
    }
    setFormData(_.cloneDeep(formData));
  };
  const autoCompleteChangeHandler = (value, name) => {
    updatedData[name] = value?.value;
    setUpdatedData(updatedData);
    formData[name]["value"] = { label: name, value: value?.value };
    setFormData(_.cloneDeep(formData));
    if (name == "inventoryType") {
      formData[name]["value"] = { label: value?.value, value: value?.value };
      if (value?.value == "Tire") {
        formData["equipmentCategory"]["value"]["value"] = "";
        formData["equipmentSubCategory"]["value"]["value"] = "";
      } else if (value?.value == "Equipment") {
        formData["tireDepth"]["value"] = "";
      }
    }
    if(name == 'discountType'){
      if(formData['discountType']['value']['value'] == 'Flat'){
        let discountAmount =
        formData["subtotal"]["value"] - formData["discountAmount"]["value"];
        formData["purchaseTotal"]["value"] =
        discountAmount +
        (discountAmount * formData["gstPercentage"]["value"]) / 100;
      }else{

      }
    }
    setFormData(_.cloneDeep(formData));
  };
  const timeStampChangeHandler = (value, name) => {
    updatedData[name] = value;
    setUpdatedData(updatedData);
    formData[name]["value"] = value;
    formData[name]["isValid"] = true;
    setFormData(_.cloneDeep(formData));
  };

  return (
    // <>
    //  <TableBody>
    //         {tableData.map((rec, i) => {
    //           return (
    //             <React.Fragment key={i}>
    //               <TableRow className={classes.row_paper}>
    //                 {Object.keys(rec).map((key, j) => {
    //                   return (
    //                     <TableCell key={j} align="center" onClick={()=> {
    //                       if(rec[key]?.props?.children?.props?.data?.tripId){
    //                         props.tripIdHandler(rec[key]?.props?.children?.props?.data?.tripId);
    //                       }
    //                       if(key==="Action"){
    //                         props.removeRowHandler(rec)
    //                       }
    //                     }}>
    //                       {rec[`${key}`]}
    //                     </TableCell>
    //                   );
    //                 })}
    //               </TableRow>
    //               <Divider className={classes.divider} />
    //             </React.Fragment>
    //           );
    //         })}
    //       </TableBody></>
    <React.Fragment>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        bgColor="#FFFFFF"
        key={row.invoice_number}
        style={{ fontFamily: "SegoeUI, Segoe UI" }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <RemoveCircleOutlineIcon color="error" />
            ) : (
              <ControlPointIcon color="primary" />
            )}
          </IconButton>
        </TableCell>

        <TableCell component="th" scope="row" align="center">
          {row.invoice_number}
        </TableCell>
        <TableCell align="center">{row.qrcode}</TableCell>
        <TableCell align="center">{row.serial_number}</TableCell>
        <TableCell align="center">{row.inventory_type}</TableCell>
        <TableCell align="center">{row.name}</TableCell>
        <TableCell component="th" scope="row" align="center">
          {row.available_quantity}
        </TableCell>
        {/* <TableCell align="center">{row.available_unit_value}</TableCell> */}
        <TableCell align="center">
          <span>₹</span> {row.price !== null ? row.price.toFixed(2) : 0}
        </TableCell>
        <TableCell align="center">
          <span>₹</span> {row.subtotal !== null ? row.subtotal.toFixed(2) : 0}
        </TableCell>
        <TableCell align="center">
          {(row.discount_type == "Flat" && (
            <>
              {" "}
              <span
                style={{
                  backgroundColor: "#00BF9A",
                  padding: "3px",
                  borderRadius: "5px",
                }}
              >
                {row.discount_type}
              </span>
              <div>
                ₹{" "}
                {row.discount_value !== null
                  ? row.discount_value.toFixed(2)
                  : 0}
              </div>
            </>
          )) ||
            (row.discount_type == "Percentage" && (
              <>
                {" "}
                <span
                  style={{
                    backgroundColor: "#FE6593",
                    padding: "3px",
                    borderRadius: "5px",
                  }}
                >
                  {`${row.discount_value}%`}
                </span>
                <div>
                  ₹ {((row.discount_value * row.subtotal) / 100).toFixed(2)}
                </div>
              </>
            ))}
        </TableCell>
        <TableCell align="center">
          <span
            style={{
              backgroundColor: "#FE6593",
              padding: "3px",
              borderRadius: "5px",
            }}
          >
            {row.gst_percentage}%{" "}
          </span>{" "}
          <div fontSize="50px">
            <span>₹</span>{" "}
            {row.gst_amount !== null ? row.gst_amount.toFixed(2) : 0}
          </div>
        </TableCell>
        <TableCell align="center">
          <span>₹</span>{" "}
          {row.purchase_total !== null ? row.purchase_total.toFixed(2) : 0}
        </TableCell>
        <TableCell align="center">{row.purchase_date.slice(0, 10)}</TableCell>

        <TableCell align="center">
          <React.Fragment>
            <EditIcon
              onClick={() => editInventoryOpen(row.id, formData)}
              style={{ color: "#008000", marginRight: "5px" }}
            />
            <Dialog
              open={forEditInventory}
              onClose={editInventoryClose}
              fullWidth
              maxWidth="xl"
              PaperProps={{
                style: {
                  width: "95%",
                  margin: 0,
                },
              }}
            >
              <DialogTitle>
                <Grid container justifyContent="center">
                  <Grid item xs={11.8} align="center">
                    UPDATE INVENTORY
                  </Grid>
                  <Grid item xs={0.2} container justifyContent="flex-end">
                    <CloseIcon color="error" onClick={editInventoryClose} />
                  </Grid>
                </Grid>
              </DialogTitle>
              <DialogContent ref={contentRef} dividers>
                {alertData.open ? (
                  <div style={{ padding: "10px 12px" }}>
                    <AlertMessage
                      severity={alertData.severity}
                      message={alertData.message}
                      closeAlert={closeAlert}
                    />
                  </div>
                ) : (
                  <div style={{ padding: "10px 12px" }}>
                    <AlertMessage
                      severity={alert.severity}
                      message={alert.message}
                      closeAlert={closeAlertMsg}
                    />
                  </div>
                )}
                <DialogContentText>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                      <Fields.InputField
                        fieldData={formData.invoiceNum}
                        variant="outlined"
                        inputChangeHandler={inputChangeHandler}
                      />
                    </Grid>
                    <Grid item xs={6} md={3}>
                      <Fields.AntSelectableSearchField
                        fieldData={formData.inventoryType}
                        autoCompleteChangeHandler={autoCompleteChangeHandler}
                      />
                    </Grid>
                  </Grid>

                  {formData.inventoryType.value?.value == "Equipment"
                    ? forInventoryTypeEquipment(formData)
                    : "" || formData.inventoryType.value?.value == "Tire"
                    ? forInputChangeTire()
                    : ""}
                  <Grid container spacing={2}>
                    <Grid item xs={3} md={6}>
                      <Fields.InputField
                        fieldData={formData.qrCode}
                        variant="outlined"
                        inputChangeHandler={inputChangeHandler}
                      />
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <Fields.InputField
                        fieldData={formData.serialNum}
                        variant="outlined"
                        inputChangeHandler={inputChangeHandler}
                      />
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <Fields.InputField
                        fieldData={formData.modelNum}
                        variant="outlined"
                        inputChangeHandler={inputChangeHandler}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6} md={6}>
                      <Fields.InputField
                        fieldData={formData.name}
                        variant="outlined"
                        inputChangeHandler={inputChangeHandler}
                      />
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <Fields.AntSelectableSearchField
                        fieldData={formData.itemType}
                        autoCompleteChangeHandler={autoCompleteChangeHandler}
                      />
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <Fields.InputField
                        fieldData={formData.hsnCode}
                        variant="outlined"
                        inputChangeHandler={inputChangeHandler}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={4} md={3}>
                      <Fields.InputField
                        fieldData={formData.quantity}
                        variant="outlined"
                        inputChangeHandler={inputChangeHandler}
                      />
                    </Grid>
                    <Grid item xs={4} md={3}>
                      <Fields.InputField
                        fieldData={formData.rate}
                        variant="outlined"
                        inputChangeHandler={inputChangeHandler}
                      />
                    </Grid>
                    <Grid item xs={4} md={3}>
                      <Fields.InputField
                        fieldData={formData.subtotal}
                        variant="outlined"
                        inputChangeHandler={inputChangeHandler}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={4} md={3}>
                      <Fields.AntSelectableSearchField
                        fieldData={formData.discountType}
                        autoCompleteChangeHandler={autoCompleteChangeHandler}
                      />
                    </Grid>
                    {(formData["discountType"]["value"]["value"] == "Flat" && (
                      <Grid item xs={4} md={3}>
                        {" "}
                        <Fields.InputField
                          fieldData={formData.discountAmount}
                          variant="outlined"
                          inputChangeHandler={inputChangeHandler}
                        />{" "}
                      </Grid>
                    )) ||
                      (formData["discountType"]["value"]["value"] ==
                        "Percentage" && (
                        <Grid item xs={4} md={3}>
                          {" "}
                          <Fields.InputField
                            fieldData={formData.discountPercentage}
                            variant="outlined"
                            inputChangeHandler={inputChangeHandler}
                          />{" "}
                        </Grid>
                      ))}
                    <Grid item xs={4} md={3}>
                      <Fields.InputField
                        fieldData={formData.gstPercentage}
                        variant="outlined"
                        inputChangeHandler={inputChangeHandler}
                      />
                    </Grid>
                    <Grid item xs={4} md={3}>
                      <Fields.InputField
                        fieldData={formData.purchaseTotal}
                        variant="outlined"
                        inputChangeHandler={inputChangeHandler}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={4} md={3}>
                      <Fields.InputField
                        fieldData={formData.vendorName}
                        variant="outlined"
                        inputChangeHandler={inputChangeHandler}
                      />
                    </Grid>
                    <Grid item xs={4} md={3}>
                      <Fields.InputField
                        fieldData={formData.vendorCode}
                        variant="outlined"
                        inputChangeHandler={inputChangeHandler}
                      />
                    </Grid>
                    <Grid item xs={4} md={3}>
                      <Fields.AntdDatePickerField
                        fieldData={formData.purchaseDate}
                        timeStampChangeHandler={timeStampChangeHandler}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={3} md={3}>
                      <Fields.InputField
                        fieldData={formData.expLifeTimeYear}
                        variant="outlined"
                        inputChangeHandler={inputChangeHandler}
                      />
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <Typography>&nbsp;</Typography>
                      <Fields.InputField
                        fieldData={formData.expLifeTimeMonth}
                        variant="outlined"
                        inputChangeHandler={inputChangeHandler}
                      />
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <Typography>&nbsp;</Typography>
                      <Fields.InputField
                        fieldData={formData.expLifeTimedays}
                        variant="outlined"
                        inputChangeHandler={inputChangeHandler}
                      />
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <Fields.InputField
                        fieldData={formData.expectedKm}
                        variant="outlined"
                        inputChangeHandler={inputChangeHandler}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={3} md={3}>
                      <Fields.InputField
                        fieldData={formData.conLifeTimeYear}
                        variant="outlined"
                        inputChangeHandler={inputChangeHandler}
                      />
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <Typography>&nbsp;</Typography>
                      <Fields.InputField
                        fieldData={formData.conLifeTimeMonth}
                        variant="outlined"
                        inputChangeHandler={inputChangeHandler}
                      />
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <Typography>&nbsp;</Typography>
                      <Fields.InputField
                        fieldData={formData.conLifeTimedays}
                        variant="outlined"
                        inputChangeHandler={inputChangeHandler}
                      />
                    </Grid>
                    <Grid item xs={3} md={3}>
                      <Fields.InputField
                        fieldData={formData.consumedKm}
                        variant="outlined"
                        inputChangeHandler={inputChangeHandler}
                      />
                    </Grid>
                  </Grid>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={editInventoryClose} variant="contained">
                  Close
                </Button>
                <Button
                  onClick={() => updateInventoryData(updatedData)}
                  variant="contained"
                  align="start"
                >
                  Update Inventory
                </Button>
              </DialogActions>
            </Dialog>
          </React.Fragment>

          <PrintIcon
            color="error"
            onClick={() => forOpeningPrintWindow(`print-${row.qrcode}`)}
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 3, paddingTop: 3 }} colSpan={15}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Grid container>
                <Grid item xs={6} md={2.5} alignItems="center">
                  <Stack direction="column" spacing={2} padding={3}>
                    <Button
                      variant="contained"
                      style={{
                        borderRadius: "15px",
                        backgroundColor: basicColor,
                      }}
                      onClick={() => ForShowingBasicDetails(row)}
                    >
                      Basic
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        borderRadius: "15px",
                        backgroundColor: pricingColor,
                      }}
                      onClick={() => ForShowingPricingDetails(row)}
                    >
                      Pricing
                    </Button>
                    <Button
                      variant="contained"
                      style={{
                        borderRadius: "15px",
                        backgroundColor: outlineColor,
                      }}
                      onClick={() => ForShowingLifetimeDetails(row)}
                    >
                      Lifetime
                    </Button>
                  </Stack>
                </Grid>
                {(basic && <ForBasicDetails data={row} />) ||
                  (pricing && <ForPricingDetails data={row} />) ||
                  (lifetime && <ForLifetimeDetails data={row} />)}
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function CollapsibleTable(filterData) {
  const columns = [
    { id: "#", disablePadding: true, label: "#", checked: true },
    { id: "invoice", disablePadding: true, label: "INVOICE #", checked: true },
    { id: "qrCode", label: "QR CODE", disablePadding: true, checked: true },
    {
      id: "serialNum",
      disablePadding: true,
      label: "SERIAL NUMBER",
      checked: true,
    },
    { id: "category", disablePadding: true, label: "CATEGORY", checked: true },
    { id: "name", disablePadding: true, label: "NAME", checked: true },
    {
      id: "availableQuantity",
      label: "AVAILABLE QUANTITY",
      disablePadding: true,
      checked: true,
    },
    // {
    //   id: "availableUnits",
    //   disablePadding: true,
    //   label: "AVAILABLE UNITS",
    //   checked: true,
    // },
    { id: "rate", disablePadding: true, label: "RATE", checked: true },
    { id: "subtotal", disablePadding: true, label: "SUBTOTAL", checked: true },
    {
      id: "discount",
      label: "DISCOUNT (-)",
      disablePadding: true,
      checked: true,
    },
    { id: "gst", disablePadding: true, label: "GST (+)", checked: true },
    {
      id: "purchaseCost",
      disablePadding: true,
      label: "PURCHASE COST",
      checked: true,
    },
    {
      id: "purchaseDate",
      disablePadding: true,
      label: "PURCHASE DATE",
      checked: true,
    },
    { id: "actions", label: "ACTIONS", disablePadding: true, checked: true },
  ];
  const showHeaders = columns.filter((each) => each.checked == true);
  const [headRows, setHeadRows] = React.useState(columns);
  const [cols, setCols] = React.useState(columns);
  Service = global.service;
  let [rows, setRows] = useState([]);
  const [spinner, setSpinner] = React.useState(false);
  const [total, setTotal] = React.useState(0);
  const [pagination, setPagination] = React.useState({
    current: CONFIG.PAGINATION.current,
    pageSize: CONFIG.PAGINATION.pageSize,
  });
  let [purchasesCount, setPurchasesCount] = useState({});
  const [type, setType] = useState({ type: "" });

  const spinnerStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    },
    spinner: {
      position: "absolute",
      top: "80%",
      left: "50%",
    },
  }));
  let spinnerClasses = spinnerStyles();

  const pageChangeHandler = (page, type) => {
    let dataObj = {
      skip: page * pagination.pageSize,
      limit: pagination.pageSize,
      ...type,
    };
    renderInventoryData(dataObj, cols);
    setPagination({ ...pagination, current: page, ...type });
  };
  const rowsPerPageChangeHandler = (rowsPerPage) => {
    renderInventoryData(
      {
        skip: pagination.current * rowsPerPage,
        limit: rowsPerPage,
        ...type,
      },
      cols
    );
    setPagination({ ...pagination, pageSize: rowsPerPage });
  };

  const handlePageChange = (page) => {
    pageChangeHandler(page, type);
  };
  const handleChangeRowsPerPage = (size) => {
    rowsPerPageChangeHandler(size);
  };

  React.useEffect(() => {
    Promise.all(
      Service.getPurchasesCount()
        .then((res) => {
          setPurchasesCount(res);
        })
        .catch((err) => {
          console.log("err...", err);
        })
    );
    window.scrollTo({ top: 0 });
    let dataObj = {
      skip: pagination.current * pagination.pageSize,
      limit: pagination.pageSize,
      ...type,
    };
    renderInventoryData(dataObj, cols);
  }, []);
  const renderInventoryData = (dataObj, cols) => {
    setSpinner(true);
    Service.getInventoryList(dataObj)
      .then((res) => {
        let checkedCols = cols.filter((col) => col.checked === true);
        setHeadRows(checkedCols);
        let data = res.inventoryData;
        setRows([...data]);
        setTotal(res?.total);
        setSpinner(false);
        // let data = res.inventoryData.map((each) => {
        //   return {
        //     ...(checkedCols.find((data) => data.id === "invoice")
        //       ? {
        //         invoice: each.invoice_number ? each.invoice_number : "--",
        //         }
        //       : {}),
        //     ...(checkedCols.find((data) => data.id === "qrCode")
        //       ? {
        //         qrCode:each.qrcode ? each.qrcode: "--",
        //         }
        //       : {}),
        //     ...(checkedCols.find((data) => data.id === "serialNum")
        //       ? {
        //         serialNum: each.serial_number ? each.serial_number : "--",
        //         }
        //       : {}),
        //     ...(checkedCols.find((data) => data.id === "category")
        //       ? {
        //         category: each.inventory_type ? each.inventory_type : "--",
        //         }
        //       : {}),
        //     ...(checkedCols.find((data) => data.id === "name")
        //       ? {
        //         name: each.name ? each.name : "--",
        //         }
        //       : {}),
        //     ...(checkedCols.find((data) => data.id === "availableQuantity")
        //       ? {
        //         availableQuantity: each.available_quantity ? each.available_quantity : "--",
        //         }
        //       : {}),
        //     ...(checkedCols.find((data) => data.id === "availableUnits")
        //       ? {
        //         availableUnits: each.available_unit_value? each.available_unit_value: "--",
        //         }
        //       : {}),
        //     ...(checkedCols.find((data) => data.id === "rate")
        //       ? {
        //         rate:each.price? each.price: "--",
        //         }
        //       : {}),
        //     ...(checkedCols.find((data) => data.id === "subtotal")
        //       ? {
        //         subtotal: each.subtotal? each.subtotal: "--",
        //         }
        //       : {}),
        //       ...(checkedCols.find((data) => data.id === "discount")
        //       ? {
        //         discount: each.discount_value? each.discount_value: "--",
        //         }
        //       : {}),
        //       ...(checkedCols.find((data) => data.id === "gst")
        //       ? {
        //         gst: each.gst_amount? each.gst_amount: "--",
        //         }
        //       : {}),
        //       ...(checkedCols.find((data) => data.id === "purchaseCost")
        //       ? {
        //         purchaseCost: each.purchase_total? each.purchase_total: "--",
        //         }
        //       : {}),
        //       ...(checkedCols.find((data) => data.id === "purchaseDate")
        //       ? {
        //         purchaseDate: each.purchase_date? each.purchase_date: "--",
        //         }
        //       : {}),

        //   };
        // });
      })
      .catch(() => {
        setSpinner(false);
      });
  };

  const forTypeEquipment = async (type) => {
    let typeObj = { type: type };
    setType(typeObj);
    let dataObj = {
      skip: pagination.current * pagination.pageSize,
      limit: pagination.pageSize,
      type: type,
    };
    renderInventoryData(dataObj, cols);
  };
  const forTypeConsumable = async (type) => {
    let typeObj = { type: type };
    setType(typeObj);
    let dataObj = {
      skip: pagination.current * pagination.pageSize,
      limit: pagination.pageSize,
      type: type,
    };
    renderInventoryData(dataObj, cols);
  };
  const forTypeTire = async (type) => {
    let typeObj = { type: type };
    setType(typeObj);
    let dataObj = {
      skip: pagination.current * pagination.pageSize,
      limit: pagination.pageSize,
      type: type,
    };
    renderInventoryData(dataObj, cols);
  };
  const columnCheckHandler = (columns) => {
    setCols(columns);
    renderInventoryData(
      {
        skip: pagination.current * pagination.pageSize,
        limit: pagination.pageSize,
      },
      columns
    );
  };

  return (
    <>
      <div>
        <Grid container spacing={4} padding={2} marginBottom={1}>
          <Grid item xs={6} md={4}>
            <Card
              style={{
                padding: "5px",
                marginRight: "15px",
                cursor: "pointer",
                border: type.type === "Tire" ? "2px solid #1890ff" : "",
              }}
              onClick={() => forTypeTire("Tire")}
            >
              <Grid
                container
                style={{ display: "flex", justifyContent: "space-between" }}
                padding={1.5}
              >
                <Grid item xs={3} md={2}>
                  <Typography style={{ fontWeight: "bold", fontSize: "25px" }}>
                    {purchasesCount.tyresCount}
                  </Typography>
                  <Typography
                    style={{
                      color: "#366E93",
                      fontSize: "15px",
                      fontWeight: ["550"],
                      fontFamily: "Montserrat,SemiBold",
                    }}
                  >
                    Tyres
                  </Typography>
                </Grid>
                <Grid item xs={3} md={2}>
                  <Avatar style={{ background: "#3D739619", padding: "30px" }}>
                    <img
                      src={tireImage}
                      style={{
                        width: "32px",
                        height: "32px",
                      }}
                    />
                  </Avatar>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={6} md={4}>
            <Card
              style={{
                padding: "5px",
                marginRight: "15px",
                cursor: "pointer",
                border: type.type === "Equipment" ? "2px solid #1890ff" : "",
              }}
              onClick={() => forTypeEquipment("Equipment")}
            >
              <Grid
                container
                style={{ display: "flex", justifyContent: "space-between" }}
                padding={1.5}
              >
                <Grid item xs={3} md={2}>
                  <Typography style={{ fontWeight: "bold", fontSize: "25px" }}>
                    {purchasesCount.equipmentsCount}
                  </Typography>
                  <Typography
                    style={{
                      color: "#366E93",
                      fontSize: "15px",
                      fontWeight: ["550"],
                      fontFamily: "Montserrat,SemiBold",
                    }}
                  >
                    Equipments
                  </Typography>
                </Grid>
                <Grid item xs={3} md={2}>
                  <Avatar style={{ background: "#3D739619", padding: "30px" }}>
                    <img
                      src={equipmentImage}
                      style={{
                        width: "32px",
                        height: "32px",
                      }}
                    />
                  </Avatar>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={6} md={4}>
            <Card
              style={{
                padding: "5px",
                marginRight: "15px",
                cursor: "pointer",
                border: type.type === "Consumable" ? "2px solid #1890ff" : "",
              }}
              onClick={() => forTypeConsumable("Consumable")}
            >
              <Grid
                container
                style={{ display: "flex", justifyContent: "space-between" }}
                padding={1.5}
              >
                <Grid item xs={3} md={2}>
                  <Typography style={{ fontWeight: "bold", fontSize: "25px" }}>
                    {purchasesCount.consumablesCount}
                  </Typography>
                  <Typography
                    style={{
                      color: "#366E93",
                      fontSize: "15px",
                      fontWeight: ["550"],
                      fontFamily: "Montserrat,SemiBold",
                    }}
                  >
                    Consumables
                  </Typography>
                </Grid>
                <Grid item xs={3} md={2}>
                  <Avatar style={{ background: "#3D739619", padding: "30px" }}>
                    <img
                      src={consumerImage}
                      style={{
                        width: "32px",
                        height: "32px",
                      }}
                    />
                  </Avatar>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
        {/* <div style={{ padding: "10px 40px 15px 24px", display: "flex" }}> */}
          <div className="custom_pagination" style={{ flexGrow: 1 }}>
            {pagination && total ? (
              <AntdPagination
                total={total}
                current={pagination?.current}
                pageSize={pagination?.pageSize}
                handlePageChange={handlePageChange}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            ) : //   <Pagination
            //   total={total && total ? total : 0}
            //   showTotal={(total, range) =>
            //     `${range[0]}-${range[1]} of ${total} items`
            //   }
            //   defaultPageSize={pagination?.pageSize}
            //   defaultCurrent={pagination?.current + 1}
            //   onChange={(page, pageSize) => {
            //     if(pagination?.current === page - 1){
            //       handleChangeRowsPerPage(pageSize)
            //     }else{
            //       handlePageChange(page-1);
            //     }
            //   }}
            //   pageSizeOptions={[25, 50, 75, 100]}
            //   showSizeChanger={true}
            // />
            null}

            {/* <AntdPagination
            total={total}
            current={pagination.current}
            pageSize={pagination.pageSize}
            handlePageChange={handlePageChange}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          /> */}
          </div>
          {/* <Fields.CustomColumns columns={cols} checkApplyHandler = {columnCheckHandler}/> */}
        {/* </div> */}

        <TableContainer component={Paper} style={{marginLeft:'15px',width:'97%'}}>
          <Table aria-label="collapsible table">
            <TableHead bgColor="#366E93">
              <TableRow>
                {headRows.map((row) => {
                  return (
                    <TableCell
                      key={row.id}
                      align={row.alignLeft ? "left" : "center"}
                      style={{
                        padding: row.padding ? "20px 30px" : "",
                        width: row.width,
                        fontSize: 14,
                        fontWeight: 800,
                        padingLeft: "150px",
                        color: "white",
                      }}
                    >
                      {row.label}
                    </TableCell>
                  );
                })}
                {/* <TableCell  key={row.id} align="center" style={{padding: row.padding ? "20px 30px" : "",width: row.width,fontSize:14,fontWeight:800,padingLeft: "150px",color:"white"}}>#</TableCell>
            <TableCell align="center" style={{color:'white'}}>INVOICE #</TableCell>
            <TableCell align="center" style={{color:'white'}}>QR CODE</TableCell>
            <TableCell align="center" style={{color:'white'}}>SERIAL NUMBER</TableCell>
            <TableCell align="center" style={{color:'white'}}>CATEGORY</TableCell>
            <TableCell align="center" style={{color:'white'}}>NAME</TableCell>
            <TableCell align="center" style={{color:'white'}}>AVAILABLE QUANTITY</TableCell>
            <TableCell align="center" style={{color:'white'}}>AVAILABLE UNITS</TableCell>
            <TableCell align="center" style={{color:'white'}}>RATE</TableCell>
            <TableCell align="center" style={{color:'white'}}>SUBTOTAL</TableCell>
            <TableCell align="center" style={{color:'white'}}>DISCOUNT (-)</TableCell>
            <TableCell align="center" style={{color:'white'}}>GST (+)</TableCell>
            <TableCell align="center" style={{color:'white'}}>PURCHASE COST</TableCell>
            <TableCell align="center" style={{color:'white'}}>PURCHASE DATE</TableCell>
            <TableCell align="center" style={{color:'white'}}>ACTIONS</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {spinner ? (
                <div className={spinnerClasses.container}>
                  <div className={spinnerClasses.spinner}>
                    <CircularProgress disableShrink />
                  </div>
                </div>
              ) : (
                rows.map((row) => <Row key={row.invoice_number} row={row} />)
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
