import L from 'leaflet';
import { carIcon } from "../../../assets/index";
import LocalShipping from '@material-ui/icons/LocalShipping';
const iconCar = new L.Icon({
  iconUrl: carIcon,
  iconRetinaUrl: carIcon,
  popupAnchor: [-0, -0],
  iconColor: 'blue',
  iconSize: new L.Point(30, 30)
  // className: 'leaflet-div-icon'
});
const iconMarker = new L.divIcon({
  html: <div>
    <LocalShipping style={{
      width: 40,
      height: 40,
      margin: 5,
      color: '#000'
    }} /></div>,
  // bgPos?: PointExpression | undefined;
  iconSize: new L.Point(30, 30),
  // iconAnchor?: PointExpression | undefined;
  // popupAnchor?: PointExpression | undefined;
  className: 'leaflet-div-icon'
})
function SetDivIcon(rotationAngle) {
  return {
    html: `<div style='transform: ${rotationAngle}; background: none; border: none; width: 100%; height: 100%; margin-top: -15px !important;'>
            <img src='${carIcon}' style='width: 100%; height: 100%;' />
        </div>`,
    className: '',
  }
}
export default SetDivIcon;