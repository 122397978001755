export default (state = {}, action) => {
    switch (action.type) {
        case 'SET_VEHICLE_REQUEST_LIST':
        return action.payload;
        case 'UPDATE_VEHICLE_REQUEST_LIST':
            return {...state, ...action.payload};
        default:
        return state;
    }

}