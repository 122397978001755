export default (state = {}, action) => {
    switch (action.type) {
        case 'CREATE_TRIP_ROOT_INFOS':
        return action.payload;
        case 'UPDATE_TRIP_ROOT_INFO':
            return {...state, ...action.payload};
        case 'RESET_TRIP_ROOT_INFO':
            return {};    
        default:
        return state;
    }

}