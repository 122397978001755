class TripsheetRequestBuilders {
    constructor(util){
        this.util = util;
    }

    addTripsheet = (reqObj) => {
        return {
            "driver_id": reqObj.driver_id.value,
            "truck_id": reqObj.truck_id.value,
            "start_odo": reqObj.start_odo.value,
            "trip_sheet_number": reqObj.trip_sheet_number.value,
            "freight_received": reqObj.freight_received.value,
            "diesel_shortage": reqObj.diesel_shortage.value,
            "material_damage": reqObj.material_damage.value,
            "booking_ids": reqObj.booking_ids
        }
    }

    updateTripsheet = (reqObj) => {
        return {
            "tripsheet_id": reqObj.tripsheet_id,
            "start_odometer": reqObj.start_odometer.value,
            "end_odometer": reqObj.end_odometer.value,
            "total_fuel_filled": reqObj.total_fuel_filled.value,
            "diesel_shortage": reqObj.diesel_shortage.value,
            "material_damage": reqObj.material_damage.value
        }
    };
}

export default TripsheetRequestBuilders;