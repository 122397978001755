import React from 'react';
import { Typography } from '@material-ui/core';
import { gasImage } from "../../assets/index";
import { deliveryImage } from "../../assets/index";

const imagePaths = [deliveryImage, gasImage];

export default function TabButton(props){

    return(
        <div style={{ display: 'flex'}}>
            <img src={imagePaths[props.assetNo]} style={{paddingRight: 10}} />
            <Typography style={{ color: '#000', fontWeight: 'bold', fontSize: 12 }}> {props.title}</Typography>
        </div>
    )
}