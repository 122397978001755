class Users {
  constructor(Service, path, Util) {
    this.httpService = Service;
    this.path = path;
    this.Util = Util;
  }
  getCompanyUsers = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(this.path.GROUPS+"/companyusers", data);
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getFleetUsers = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(this.path.GROUPS+"/feetusers", data);
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getAuthuserRoles = () =>{
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + '/authuser/roles'
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  addUsers = (data) => {
    const url =
    process.env.REACT_APP_SUVEGA_API_ENDPOINT_3  + '/owner/addUser'
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url,data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  updateUser = (data) => {
    const url =
    process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + '/groups/updateUserByOwner'
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url,data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  findUserById = (data) =>{
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/groups/companyusers/${data}`
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

}
export default Users;
