import React, { useState, useEffect, useRef } from "react";
import Header from "../../components/header";
import "../../common.css";
import { Typography, Button, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
// import * as Fields from "../../sharedComponents";
// import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import _ from "lodash";
import EditIcon from "@material-ui/icons/Edit";
// import CloseIcon from "@mui/icons-material/Close";
import * as Components from "../../sharedComponents";
import * as CONFIG from "../../config/GlobalConstants";
import { useHistory } from "react-router-dom";
import CircularLoading from "../../components/loader/circularLoading";
import PhoneAndroid from "@mui/icons-material/PhoneAndroid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import * as Fields from "../../sharedComponents";
import { Grid } from "@material-ui/core";
import ValidateFields from "../../validations/validateFields";
import AlertMessage from "../../components/alertmessage/AlertMessage";
import { removeImage } from "../../assets";
import { number } from "prop-types";
import moment from "moment";
import Util from "../../utils";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles(() => ({
  addUser_btn: {
    textTransform: "none",
    backgroundColor: "#649B42",
    "&:hover": {
      backgroundColor: "#649B42",
    },
    padding: "8px 15px",
    marginRight: "12px",
  },
  header_buttons: {
    padding: "0px 15px",
  },
  site_button: {
    background: "#649B42",
    textTransform: "none",
    color: "#FFFFFF",
    marginRight: "15px",
    padding: "8px 15px",
    "&:hover": {
      background: "#649B42",
    },
  },
  clear_button: {
    padding: "6px 15px",
    background: "#D3710F0D",
    border: "1px solid #D3710F",
    color: "#D3710F",
    "&:hover": {
      background: "#D3710F0D",
    },
    customWidth: {
      width: "50%" /* Adjust this value as needed */,
    },
  },
  filter_buttons: {
    textAlign: "right",
    padding: "0px 15px",
  },
  dialogPaper: {
    minWidth: "500px", // Set the minimum width to 300px
    maxWidth: "600px", // Set the maximum width to 500px
  },
  divider: {
    borderTop: "2px solid #F15F4C", // Customize the thickness and color of the divider
  },
  dialogTitle: {
    cursor: "move",
    textAlign: "center",
    color: "#F15F4C",
  },
  details_container_content: {
    padding: "20px 0px",
  },
  removePaper: {
    minWidth: "300px", // Set the minimum width to 300px
    maxWidth: "400px",
    maxHeight: "400px",
    // Set the maximum width to 500px
  },
}));
export const RemoveDevice = (props) => {
  const formFields = {
    deviceImei: {
      name: "deviceImei",
      label: "Device (IMEI) Number",
      value: { value: "", label: "" },
      options: [],
      topLabel: true,
      validationRequired: true,
      validPattern: "ALPHABET_with_numeric_label",
      isValid: true,
      errorMsg: "Please select device imei",
      ref: useRef(),
      allowClear: true,
      labelColor: "#397296",
      // image: subscriptionType,
    },

    reason: {
      name: "reason",
      label: "Device Delete Reason",
      value: "",
      isValid: true,
      topLabel: true,
      validationRequired: true,
      validPattern: "SPECIAL_CHARS_DESC",
      errorMsg: "Please enter reason",
      ref: useRef(),
      // type: "number",
      labelColor: "#397296",
    },
    removeDate: {
      name: "removeDate",
      label: "Remove Device Date",
      value: moment(new Date()),
      validationRequired: true,
      isValid: true,
      errorMsg: "Please select remove device date",
      validPattern: "DATE",
      topLabel: true,
      ref: useRef(),
      labelColor: "#397296",
      disabledDate: (d) => !d || d.isAfter(new Date()),
      // image: devicePurchaseDate,
      // disabledDate: (d) => !d || d.isBefore(new Date()),
    },
  };
  let util = new Util()
  const [fields, setFields] = useState(formFields);
  const [open, setOpen] = useState(props.open);
  const Service = global.service
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const closeAlert = () => {
    let alert = _.cloneDeep(alertData);
    alert.open = false;
    alert.severity = "";
    alert.message = "";
    setAlertData(alert);
  };
  let classes = useStyles();
  let placeRefCircle = useRef();
  const [spinner, setSpinner] = useState(false);
  const [removeOpen, setRemoveOpen] = useState(false);
  const validateFields = new ValidateFields();
  useEffect(async () => {
    if (props?.assignData) {
      let newFields = _.cloneDeep(fields);
      newFields.deviceImei.value = {
        label: props.assignData.deviceId,
        value: props.assignData.deviceImei,
      };
      if(props?.assignData.removeDeviceDate != null && props?.assignData.removeDeviceDate != undefined && props?.assignData.removeDeviceDate != ''){
        newFields.removeDate.value = moment(props?.assignData.removeDeviceDate).clone(
          "YYYY-MM-DD"
        );
      }
      // if(props.assignData.deleteReason != null && props.assignData.deleteReason != undefined && props.assignData.deleteReason != ""){
      //   newFields.reason.value = props.assignData.deleteReason
      // }
      setFields(newFields);
    }
  }, [props?.assignData]);
  const inputChangeHandler = (value, name) => {
    let newFields = _.cloneDeep(fields);
    if (newFields[name]["validationRequired"] && value.length > 0) {
      newFields[name]["isValid"] = true;
    }
    newFields[name]["value"] = value;
    setFields(newFields);
  };
  const autoCompleteChangeHandler = async (value, name) => {
    let newFields = _.cloneDeep(fields);
    if (newFields[name]["validationRequired"] && value.label.length > 0) {
      newFields[name]["isValid"] = true;
    }
    newFields[name]["value"] = value;
    setFields(newFields);
  };
  const handleClose = () => {
    setOpen(false);
    props.onClose();
    let newField = _.cloneDeep(fields);
    setFields(newField);
  };
  const timeStampChangeHandler = (value, name) => {
    let newFields = _.cloneDeep(fields);
    newFields[name]["value"] = value;
    newFields[name]["isValid"] = true;
    if (newFields[name]["validationRequired"] && value == null) {
      newFields[name]["isValid"] = false; 
    }
    setFields(newFields);
  };
  const forOpeningRemovePopup = async () => {
    let dataStatus = await validateFields.validateFieldsData(fields);
    if (dataStatus.status) {
      setRemoveOpen(true);
      setOpen(false);
    } else {
      let newFields = _.cloneDeep(fields);
      let erroredFields = Object.keys(dataStatus.data).filter(
        (key, i) => dataStatus.data[key].isValid === false
      );
      newFields = dataStatus.data;
      setFields(newFields);
    }
    // props.onClose();
  };
  const forRemovingDevice = async (fields) => {
    setSpinner(true);
    let object = {
      deviceId: props.assignData.deviceId,
      deleteReason: fields.reason.value,
      removeDeviceDate : util.formatDate(fields.removeDate.value)
    };
    await Service.deleteDevice(object)
      .then((res) => {
        setSpinner(false);
        if (res.status) {
          setAlertData({
            open: true,
            severity: "success",
            message: res.message,
          });
          setTimeout(() => {
            closeAlert();
            handleClose();
            window.location.reload();
          }, 3000);
        } else {
          setAlertData({
            open: true,
            severity: "error",
            message: res.message,
          });
        }
      })
      .catch((e) => {
        console.log("Error......", e);
      });
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        classes={{ paper: classes.dialogPaper }}
        maxWidth="md" // Set the maximum width, you can use 'xs', 'sm', 'md', 'lg', 'xl', or a specific value
        fullWidth={true}
      >
        {alertData.open ? (
          <div className={classes.alertBox}>
            <AlertMessage
              severity={alertData.severity}
              message={alertData.message}
              closeAlert={closeAlert}
            />
          </div>
        ) : null}
        <DialogTitle
          className={classes.dialogTitle}
          id="draggable-dialog-title"
        >
          Remove
        </DialogTitle>
        <DialogContent dividers className={classes.divider}>
          <DialogContentText>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12}>
                <Fields.AntSelectableSearchField
                  fieldData={fields.deviceImei}
                  autoCompleteChangeHandler={autoCompleteChangeHandler}
                  variant="outlined"
                  disabled={true}
                />
              </Grid>
              <Grid item xs={12}>
                    <Fields.AntdDatePickerField
                      fieldData={fields.removeDate}
                      timeStampChangeHandler={timeStampChangeHandler}
                    />
                  </Grid>
              <Grid item xs={12}>
                <Fields.TextAreaField
                  fieldData={fields.reason}
                  inputChangeHandler={inputChangeHandler}
                  variant="outlined"
                  fullWidth
                  className={classes.details_container_content}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            spacing={2}
            className="details_container_content"
            style={{ marginRight: 25 }}
          >
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={8}></Grid>

                <Grid item xs={2}>
                  <Button
                    variant="outlined"
                    onClick={handleClose}
                    className="cancel_button"
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item xs={2}>
                  <Button
                    className="save_button"
                    onClick={() => forOpeningRemovePopup(fields)}
                    startIcon={
                      spinner ? (
                        <CircularProgress size={20} color={"#fff"} />
                      ) : null
                    }
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      {removeOpen && (
        <Dialog
          open={removeOpen}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          classes={{ paper: classes.removePaper }}
          maxWidth="md" // Set the maximum width, you can use 'xs', 'sm', 'md', 'lg', 'xl', or a specific value
          fullWidth={true}
        >
          {alertData.open ? (
            <div className={classes.alertBox}>
              <AlertMessage
                severity={alertData.severity}
                message={alertData.message}
                closeAlert={closeAlert}
              />
            </div>
          ) : null}
          <DialogTitle
            className={classes.dialogTitle}
            id="draggable-dialog-title"
          >
            <div
              style={{
                textAlign: "center",
                padding: "10px",
                fontSize: "16px",
                lineHeight: "1.5",
                color: "#000000",
                fontWeight: 500,
              }}
            >
              <div>
                <img
                  style={{
                    height: "120px",
                    width: "120px",
                    marginBottom: "0px",
                    paddingBottom: "0px",
                  }}
                  src={removeImage}
                />
              </div>
              <div style={{ marginTop: "15px" }}>
                Are you sure you want to <br />
                remove this device ?
              </div>
            </div>
          </DialogTitle>
          <DialogActions>
            <Grid
              container
              spacing={2}
              // className="details_container_content"
              // style={{ marginRight: 25 }}
              style={{ paddingBottom: "10px" }}
            >
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={3}>
                    <Button
                      variant="outlined"
                      onClick={handleClose}
                      className="cancel_button"
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      className="delete_button"
                      onClick={() => forRemovingDevice(fields)}
                      startIcon={
                        spinner ? (
                          <CircularProgress size={20} color={"#fff"} />
                        ) : null
                      }
                    >
                      Delete
                    </Button>
                  </Grid>
                  <Grid item xs={3}></Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};
