import React from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Collapse from "@mui/material/Collapse";
import { makeStyles } from "@material-ui/core/styles";
import AntdPagination from "./antd/AntdPagination";
import CustomColumns from "./CustomColumns";
import Box from '@mui/material/Box';

const useStyles = makeStyles((theme) => ({
  table_head: {
    padding: "10px 40px 15px 24px",
    display: "flex",
  },
  selected_text: {
    color: "#929DAF",
    fontSize: "12px",
    fontFamily: "Montserrat, SemiBold",
    letterSpacing: "0.5px",
    alignSelf: "center",
  },
  row_paper: {
    background: "#FFFFFF",
    boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
    borderRadius: "4px",
  },
  divider: {
    height: "12px",
  },
}));

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, numSelected, rowCount, headRows } = props;
  return (
    <TableHead>
      <TableRow>
        {headRows.map((row) => {
          return (
            <TableCell
              key={row.id}
              align={row.alignLeft ? "left" : "center"}
              padding={row.disablePadding ? "none" : "default"}
              className={classes.whiteColor}
              style={{
                padding: row.padding ? "20px 30px" : "",
                width: row.width,
              }}
            >
              {row.label}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

function Row(props) {
  const { row } = props;

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        {Object.keys(row).map((key, j) => {
          return (
            <TableCell key={j} align="center">
              {row[`${key}`]}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}>
           <Collapse in={props.collapseIndex && props.index === props.collapseIndex ? true : false} timeout="auto" unmountOnExit>
                <Box>hello world</Box>
           </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
  headRows: PropTypes.array.isRequired,
};

const CollapsibleTable = (props) => {
  const classes = useStyles();
  const handlePageChange = (page) => {
    props.pageChangeHandler(page);
  };
  const handleChangeRowsPerPage = (size) => {
    props.rowsPerPageChangeHandler(size);
  };
  return (
    <div>
      <div className={classes.table_head}>
        <div className="custom_pagination" style={{ flexGrow: 1 }}>
          <AntdPagination
            total={props.total}
            current={props.pagination.current}
            pageSize={props.pagination.pageSize}
            handlePageChange={handlePageChange}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
        <CustomColumns
          columns={props.cols}
          checkApplyHandler={props.columnCheckHandler}
        />
      </div>
      <div className="custom_table">
        <Table>
          <EnhancedTableHead
            classes={classes}
            numSelected={0}
            rowCount={20}
            onSelectAllClick={() => {}}
            headRows={props.headRows}
          />
          <TableBody>
            {props.tableData.map((row, i) => (
              <Row
                key={i}
                row={row}
                index={i}
                collapseIndex={props.collapseIndex}
              />
            ))}
          </TableBody>
        </Table>
      </div>
      {props.tableData.length === 0 ? (
        <div
          style={{ textAlign: "center", height: "50vh", position: "relative" }}
        >
          <span
            style={{
              position: "absolute",
              top: "60%",
              left: "46%",
              fontSize: "20px",
            }}
          >
            No Data Found!
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default CollapsibleTable;
