class Tracking {
  constructor(Service, vehicleTrackingPath) {
    this.httpService = Service;
    this.vehicleTrackingPath = vehicleTrackingPath;
  }

  getMessages = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 +
      this.vehicleTrackingPath.GET_TRACKING_MESSAGES;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getOverSpeedingEvents = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 +
      this.vehicleTrackingPath.GET_OVER_SPEEDING_EVENTS;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getCrashEvents = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 +
      this.vehicleTrackingPath.GET_CRASH_EVENTS;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getHarshAccelerationEvents = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 +
      this.vehicleTrackingPath.GET_HARSH_ACCELERATION_EVENTS;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getHarshBrakingEvents = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 +
      this.vehicleTrackingPath.GET_HARSH_BRAKING_EVENTS;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getHarshCorneringEvents = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 +
      this.vehicleTrackingPath.GET_HARSH_CORNERING_EVENTS;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getRunningHours = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 +
      this.vehicleTrackingPath.GET_RUNNING_HOURS;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getTraveledDistance = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 +
      this.vehicleTrackingPath.GET_TRAVELED_DISTANCE;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getFuelHistory = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 +
      this.vehicleTrackingPath.GET_REFUEL_HISTORY;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getScheduledStops = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 +
      this.vehicleTrackingPath.GET_SCHEDULE_STOP_EVENTS;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getUnscheduledStops = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 +
      this.vehicleTrackingPath.GET_NON_SCHEDULE_STOP_EVENTS;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  
}

export default Tracking;
