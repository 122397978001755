class GenerateRequestBuilders {
  constructor(util) {
    this.util = util;
  }
  CreateInvoice = (reqObj, sameStates) => {
    return {
      clientId: reqObj.clientId.toString(),
      invoiceType: reqObj.invoiceType.toUpperCase(),
      trips:
        reqObj.trips.length > 0 ? reqObj.trips.map((trip) => Number(trip)) : [],
      contractIds: reqObj.contractIds,
      termsAndConditions: reqObj.termsAndConditions.value,
      description: reqObj.description.value,
      ...(sameStates
        ? { sgstPercentage: reqObj.sgstPercentage.value.value }
        : {}),
      ...(sameStates
        ? { cgstPercentage: reqObj.cgstPercentage.value.value }
        : {}),
      ...(!sameStates
        ? { igstPercentage: reqObj.igstPercentage.value.value }
        : {}),
      fromDate: reqObj.fromDate,
      toDate: reqObj.toDate,
      discountType: reqObj.discountType.value.value,
      discountPercentage: Number(reqObj.discountPercentage.value),
      waiveOffPercentage: Number(reqObj.waiveOffPercentage.value),
      isShipAddSameasCustomer: reqObj.isShipAddSameasCustomer,
      placeOffSupply: reqObj.placeOffSupply.value,
      trackingNumber: reqObj.trackingNumber.value,
      hsnCode: reqObj.hsnCode.toString(),
      remarks: reqObj.remarks.value,
      ...(!reqObj.isShipAddSameasCustomer
        ? {
            shippingAddress: {
              addressLine1: reqObj.addressLine1.value,
              ...(reqObj.addressLine2.value
                ? { addressLine2: reqObj.addressLine2.value }
                : {}),
              state: reqObj.state.value,
              postalCode: reqObj.postalCode.value,
            },
          }
        : { shippingAddress: {} }),
    };
  };
  getInvoiceAddress = (reqObj) => {
    return {
      clientId: reqObj.clientId.toString(),
      trips: [...reqObj.trips],
    };
  };
  getInvoiceValidation = (reqObj) => {
    return {
      clientId: reqObj.clientId.toString(),
      trips: [...reqObj.trips],
    };
  };
  updatePayment = (reqObj) => {
    return {
      paymentType: reqObj.paymentType.value,
      paymentDate: reqObj.paymentDate.value,
      transactionID: reqObj.transactionID.value,
      amount: reqObj.amount.value,
      selectedInvoices: reqObj.selectedInvoices,
      remarks: reqObj.remarks.value,
      ...(reqObj.bankName.value ? {bankName: reqObj.bankName.value} : {}),
      ...(reqObj.tdsPercentage.value ? {tdsPercentage: reqObj.tdsPercentage.value} : {}),
      ...(reqObj.tdsAmount.value? {tdsAmount: reqObj.tdsAmount.value} : {})
    };
  };
  updateBillingAmount = (reqObj) => {
    return {
      tripId: reqObj.tripId,
      amount: reqObj.additionalKmCost.value
    }
  }
}
export default GenerateRequestBuilders;
