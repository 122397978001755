import React, { useRef } from "react";
import { DatePicker } from "antd";
import { makeStyles, FormHelperText, FormLabel } from "@material-ui/core";
import moment from "moment";
import Util from "../../utils";

const useStyles = makeStyles((theme) => ({
  dateRange: {
    width: "100%",
    padding: "7px",
    border: "1px solid #91b0c4",
    borderRadius: "4px",
  },
  helperText: { color: "red", marginRight: "0px", marginLeft: "0px" },
  mtop: { marginTop: "8px" },
  headLabel: {
    fontWeight: "normal",
    color: "#333333",
    fontSize: "12px",
    fontFamily: "Montserrat, SemiBold",
  },
}));

const { RangePicker } = DatePicker;

const AntDateRange = (props) => {
  const classes = useStyles();
  let util = new Util();
  const dateFormat = "YYYY/MM/DD";
  const pickerRef = useRef();
  return (
    <div>
      {props.fieldData.topLabel ? (
        <FormLabel
          className={classes.headLabel}
          required={props.fieldData.validationRequired}
        >
          {props.fieldData.label}
        </FormLabel>
      ) : null}
      <RangePicker
        ref={pickerRef}
        className={classes.dateRange}
        value={props.fieldData.value.length > 0 ? [
          moment(util.slashFormatDate(props.fieldData.value[0]), dateFormat),
          moment(util.slashFormatDate(props.fieldData.value[1]), dateFormat),
        ]: []}
        style={{ marginTop: props.fieldData.topLabel ? "8px" : "" }}
        onChange={(value) => {
          props.dateRangeChangeHandler(value, props.fieldData.name);
        }}
        onCalendarChange={(value) => {
          if(props.calenderChangeHandler){
            props.calenderChangeHandler(value, props.fieldData.name)
          }
        }}
        onFocus={() => {
          if(props.onFocusHandler){
            props.onFocusHandler();
          }
        }}
        allowClear={props.fieldData.allowClear}
        disabledDate={props.fieldData.disabledDate}
        onOpenChange={(open) => {
          if(open){
            pickerRef.current.blur();
          }
        }}
      />
      {props.fieldData.isValid ? null : (
        <FormHelperText className={classes.helperText}>
          {props.fieldData.errorMsg}
        </FormHelperText>
      )}
    </div>
  );
};

export default AntDateRange;
