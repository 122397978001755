export default (state = {}, action) => {
    switch (action.type) {
        case "HOLDING_DELAY_STATUS":
            return { ...state, ...action.payload}
        case "HOLDING_DELAY_STATUS_ERROR":
            return action.payload;
        default:
            return state;

    }
}
