import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FilePresentIcon from "@mui/icons-material/FilePresent";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import "../css/upload.css";
import DialogContent from "@mui/material/DialogContent";
const UploadDocuments = (props, ref) => {
  let inputRef = React.createRef();
  const [file, setFile] = useState();
  const [attachments, setAttachments] = useState();
  const [open, setOpen] = useState([]);
  useEffect(() => {
    if (props.getAttachments) {
      getDocs();
    } else {
      setAttachments([...props.data.attachments]);
    }
  }, []);
  const getDocs = () => {
    props
      .getAttachments(props.item)
      .then((res) => {
        setAttachments([...res.attachments]);
      })
      .catch(() => { });
  };
  const fileUploadHandler = (e) => {
    setFile({ ...e.target.files[0], filename: e.target.files[0].name });
    if (props.removeFile) {
      let newAttachments = [...attachments];
      newAttachments.push({
        ...e.target.files[0],
        filename: e.target.files[0].name,
      });
      setAttachments(newAttachments);
    }
    props.uploadHandler(e.target.files);
  };
  const fileRemoveHandler = (i) => {
    let newAttachments = [...attachments];
    newAttachments.splice(i, 1);
    setAttachments(newAttachments);
    props.fileRemoveHandler(i);
  };
  const fileViewHandler = (file, i) => {
    const newOpen = [...open];
    newOpen[i] = true;
    setOpen(newOpen);
  };
  const handleClose = (file, i) => {
    const newOpen = [...open];
    newOpen[i] = false;
    setOpen(newOpen);
  };
  const clearAttachments = () => {
    setAttachments([]);
  };
  const addAttachment = () => {
    if (props.getAttachments) {
      props
        .getAttachments(props.item)
        .then((res) => {
          setAttachments([...res.attachments]);
        })
        .catch(() => { });
    }
  };
  useImperativeHandle(ref, () => {
    return {
      clearAttachments: clearAttachments,
      addAttachment: addAttachment,
      getDocs: getDocs,
    };
  });
  return (
    <div className="wrapper_body" ref={ref}>
      <div className="wrapper">
        {attachments && attachments.length < props.max && !props.onlyView ? (
          <div>
            <header>{props.label}</header>
            <form
              action="#"
              onClick={(e) => {
                inputRef.current.click();
              }}
            >
              <input
                type="file"
                className="file-input"
                hidden
                ref={inputRef}
                onChange={(e) => {
                  fileUploadHandler(e);
                }}
                accept="image/png, image/jpeg, image/jpg"
              />
              <CloudUploadIcon style={{ color: "#366E93", fontSize: "50px" }} />
              <p>Browse File to Upload</p>
            </form>
          </div>
        ) : null}
        <section className="progress-area">
          {props.percentCompleted > 0 && props.percentCompleted !== 100 ? (
            <li className="row">
              <FilePresentIcon className="row_icon" />
              <div className="content">
                <div className="details">
                  <span className="name">
                    {file.filename.length > 12
                      ? file.filename.split(".")[0].substring(0, 12) +
                      "... ." +
                      file.filename.split(".")[1]
                      : file.filename}{" "}
                    Uploading
                  </span>
                  <span className="percent">
                    {props.percentCompleted}
                    {props.percentCompleted > 0 ? "%" : ""}
                  </span>
                </div>
                <div className="progress-bar">
                  <div
                    className="progress"
                    style={{ width: `${props.percentCompleted}%` }}
                  ></div>
                </div>
              </div>
            </li>
          ) : (
            ""
          )}
        </section>
        <section className="uploaded-area">
          {attachments &&
            attachments.length > 0 &&
            attachments.map((file, i) => (
              <li className="upload-row" key={i}>
                <div className="content">
                  <FilePresentIcon className="row_icon" />
                  <div className="details">
                    <span className="name">
                      {file.filename.length > 12
                        ? file.filename.split(".")[0].substring(0, 12) +
                        "... ." +
                        file.filename.split(".")[1]
                        : file.filename}{" "}
                      Uploaded
                    </span>
                  </div>
                </div>
                <div>
                  <CheckIcon className="row_check_icon" />
                  {props.removeFile ? (
                    <CloseIcon
                      className="row_close_icon"
                      onClick={() => {
                        fileRemoveHandler(i);
                      }}
                    />
                  ) : null}
                  {props.viewFile ? (
                    <>
                      <VisibilityIcon
                        className="row_close_icon"
                        onClick={() => {
                          fileViewHandler(file, i);
                        }}
                      />
                      <Dialog
                        open={open[i]}
                        onClose={() => handleClose(file, i)}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <DialogTitle>{file.filename}</DialogTitle>
                          <CloseIcon
                            style={{
                              alignSelf: "center",
                              cursor: "pointer",
                              marginRight: "15px",
                            }}
                            onClick={() => {
                              handleClose(file, i);
                            }}
                          />
                        </div>
                        <DialogContent style={{ textAlign: "center" }}>
                          <img
                            src={`${file.url}`}
                            style={{ width: "100%", height: "auto" }}
                          />
                        </DialogContent>
                      </Dialog>
                    </>
                  ) : null}
                  {props.deleteFile ? (
                    <DeleteIcon
                      className="row_close_icon"
                      onClick={() => {
                        props.deleteDocument(file);
                      }}
                    />
                  ) : null}
                </div>
              </li>
            ))}
        </section>
      </div>
    </div>
  );
};
export default forwardRef(UploadDocuments);
