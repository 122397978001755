import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function AutoSelect(props) {
  const [value, setValue] = React.useState(props.value.label)
  
  return (
    <Autocomplete
        id="select-on-focus"
        disableClearable
        options={props?.items?.map((option) => option.label)}
        selectOnFocus
        style={{width: 320}}

        renderInput={(params) => (
          <TextField {...params} label={props.selectLabel} variant="standard"
          style={{ fontSize: 14, color: "#366E93", fontWeight: 400, backgroundColor: '#FFFFFF'}}/>
        )}
        renderOption={(props, option, { selected }) =>
      {
      return (
        <li {...props} value={option} key={props?.items?.map((option) => option.value)} selected={selected}
        style={{ fontSize: 14, color: "#366E93", fontWeight: 'bold', padding: 5}}>
          {option}
        </li>
      )
      }}
      onInputChange={(event, newInputValue) => {
        const filterData = props?.items?.filter((option) => {
          if(option.label === newInputValue){
            return option
          }
        }
        )
        props.onSelect(filterData);
      }}
      defaultValue={value}
      value={value}
      />

  );
}
