class ServiceArea {
  constructor(Service, path, Util) {
    this.httpService = Service;
    this.path = path;
    this.Util = Util;
  }

  getServiceAreas = () => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.SERVICE_AREA +
      "/getServiceAreas";
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export default ServiceArea;
