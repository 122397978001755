import React from "react";
import { FormLabel, FormHelperText } from "@material-ui/core";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  textAreaField: { width: "100%", border: "1px solid #707070" },
  helperText: { color: "red", marginRight: "0px", marginLeft: "0px" },
  mtop: { marginTop: "8px" },
  headLabel: {
    // textTransform: "uppercase",
    fontWeight: "normal",
    color: "#333333",
    fontSize: "12px",
    fontFamily: "Montserrat, SemiBold"
  },
}));
const TextAreaField = (props) => {
  const classes = useStyles();
  return (
    <div>
      {props?.fieldData?.topLabel ? (
        <FormLabel
          className={classes.headLabel}
          required={props.fieldData.validationRequired}
          style={{color:props.fieldData?.labelColor}}
        >
          {props.fieldData.label}
        </FormLabel>
      ) : null}
      <div className={props?.fieldData?.topLabel ? "custom_field" : ""}>
        <TextareaAutosize
          aria-label="minimum-height"
          id={props?.fieldData?.name}
          label={props?.fieldData?.topLabel ? null : props?.fieldData?.label}
          multiline
          value={props?.fieldData?.value}
          minRows={8}
          variant={props?.variant}
          className={`${classes.textAreaField} ${props?.fieldData?.topLabel ? classes.mtop : ""
            }`}
          onChange={(e) => {
            props.inputChangeHandler(e?.target?.value, props?.fieldData?.name);
          }}
          maxLength={props?.fieldData?.maxLength}
        />
      </div>
      {props?.fieldData?.isValid ? null : (
        <FormHelperText className={classes.helperText}>
          {props?.fieldData?.errorMsg}
        </FormHelperText>
      )}
    </div>
  );
};

export default TextAreaField;
