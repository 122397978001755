export default (state = {}, action) => {
    switch (action.type) {
        case 'FLEET_VENDOR_PAYMENTS':
        return action.payload;
        case 'FLEET_VENDOR_PAYMENT_TDS_AMOUNT':
            return {...state, "":action.payload}
        case 'PAYMENT_AMOUNT':
            return {...state, "PAYABLE_AMOUNT":action.payload}
        case 'SET_FLEET_VENDOR_RESPONSE':
              return {...state, "FLEET_VENDOR":action.payload};   
        case 'SET_FLEET_VENDORS_BANKS_RESPONSE':
            return{...state, "FLEET_VENDOR_BANK":action.payload}   
        case 'SEARCH_FLEET_VENDOR_PAYMENTS_DETAILS':
            return {...state, "FLEET_VENDOR_SEARCH":action.payload};
        case 'SET_SEARCH_FLEET_VENDOR_SKIP_LIMIT':
            return {...state, "skip_limit":action.payload}; 
        default:
        return state;
    }

}