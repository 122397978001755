import { combineReducers } from "redux";
import vehicleRequestListReducer from "./vehicleRequestList.reducer";
import createTripRootInfoReducer from "./createTripRootInfo.reducer";
import FleetVendorPayments from "./fleetVendorPayments.reducer";
import updateSite from "./site.reducer";
import DelayStatus from "./delayStatus.reducer";
import statusMsg from "./message.reducer"
import fuelOdoSummary from './fuelOdoSummary.reducer'
// import siteOptions from './siteOptions.reducer'
import notifications  from './notification.reducer'
import myVehicle from "./myVehicle.reducer";

const rootReducer = combineReducers({
    VEHICLE_REQUEST_LIST: vehicleRequestListReducer,
    CREATE_TRIP_ROOT_INFO: createTripRootInfoReducer,
    FLEET_VENDOR_PAYMENTS: FleetVendorPayments,
    UPDATE_SITE_INFO: updateSite,
    DELAY_STATUS: DelayStatus,
    RESPONSE_STATUS:statusMsg,
    FUELODO_SUMMARY:fuelOdoSummary,
    // SITEOPTIONS:siteOptions
    NOTIFICATIONS: notifications,
    MY_VEHICLE:myVehicle
});
export default rootReducer;