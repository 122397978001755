import React from "react";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  FormLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  selectField: { width: "100%" },
  helperText: { marginLeft: "0px", marginRight: "0px", color: "red" },
  mtop: { marginTop: "16px" },
  headLabel: {
    // textTransform: "uppercase",
    fontWeight: "normal",
    color: "#333333",
    fontSize: "12px",
    fontFamily: "Montserrat, SemiBold"
  },
}));

export default function SelectField(props) {
  const classes = useStyles();
  return (
    <div className={props.fieldData.topLabel ? "custom_field" : ""}>
      <FormControl className={classes.selectField}>
        {props.fieldData.topLabel ? (
          <FormLabel className={classes.headLabel} required={props.fieldData.validationRequired}>
            {props.fieldData.label}
          </FormLabel>
        ) : (
          <InputLabel id={props.fieldData.name}>
            {props.fieldData.label}
          </InputLabel>
        )}

        <Select
          labelId={props.fieldData.name}
          label={props.fieldData.topLabel ? null : props.fieldData.label}
          value={props.fieldData.value}
          onChange={(e) => {
            props.selectChangeHandler(e.target.value, props.fieldData.name);
          }}
          required={props.fieldData.validationRequired}
          className={`${props.fieldData.topLabel ? classes.mtop : ""}`}
          variant={props.variant}
        >
          {props.fieldData.options.length > 0 ? (
            props.fieldData.options.map((option, i) => (
              <MenuItem
                style={{ color: "#366E93", fontWeight: "bold" }}
                fullWidth={true}
                value={option.value}
                key={i}
              >
                {option.label}
              </MenuItem>
            ))
          ) : (
            <MenuItem
              style={{ color: "#366E93", fontWeight: "bold" }}
              disabled={true}
            >
              No data
            </MenuItem>
          )}
        </Select>
        {props.fieldData.isValid ? null : (
          <FormHelperText className={classes.helperText}>
            {props.fieldData.errorMsg}
          </FormHelperText>
        )}

      </FormControl>
    </div>
  );
}
