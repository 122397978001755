import React, { useState, useEffect, useRef } from "react";
import Header from "../../components/header";
import "../../common.css";
import { Typography, Button, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
// import * as Fields from "../../sharedComponents";
// import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import _ from "lodash";
import EditIcon from "@material-ui/icons/Edit";
// import CloseIcon from "@mui/icons-material/Close";
import * as Components from "../../sharedComponents";
import * as CONFIG from "../../config/GlobalConstants";
import { useHistory } from "react-router-dom";
import CircularLoading from "../../components/loader/circularLoading";
import PhoneAndroid from "@mui/icons-material/PhoneAndroid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import * as Fields from "../../sharedComponents";
import { Grid } from "@material-ui/core";
import ValidateFields from "../../validations/validateFields";
import AlertMessage from "../../components/alertmessage/AlertMessage";
import { deviceImage, removeImage } from "../../assets";
import { number } from "prop-types";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const useStyles = makeStyles(() => ({
  addUser_btn: {
    textTransform: "none",
    backgroundColor: "#649B42",
    "&:hover": {
      backgroundColor: "#649B42",
    },
    padding: "8px 15px",
    marginRight: "12px",
  },
  header_buttons: {
    padding: "0px 15px",
  },
  site_button: {
    background: "#649B42",
    textTransform: "none",
    color: "#FFFFFF",
    marginRight: "15px",
    padding: "8px 15px",
    "&:hover": {
      background: "#649B42",
    },
  },
  clear_button: {
    padding: "6px 15px",
    background: "#D3710F0D",
    border: "1px solid #D3710F",
    color: "#D3710F",
    "&:hover": {
      background: "#D3710F0D",
    },
    customWidth: {
      width: "50%" /* Adjust this value as needed */,
    },
  },
  filter_buttons: {
    textAlign: "right",
    padding: "0px 15px",
  },
  dialogPaper: {
    minWidth: "500px", // Set the minimum width to 300px
    maxWidth: "600px", // Set the maximum width to 500px
  },
  divider: {
    borderTop: "2px solid #F15F4C", // Customize the thickness and color of the divider
  },
  dialogTitle: {
    cursor: "move",
    textAlign: "center",
    color: "#F15F4C",
  },
  details_container_content: {
    padding: "20px 0px",
  },
  removePaper: {
    minWidth: "300px", // Set the minimum width to 300px
    maxWidth: "400px",
    maxHeight: "400px",
    // Set the maximum width to 500px
  },
}));
export const MoveDeviceToSubaccount = (props) => {
 
  
  const [open, setOpen] = useState(props.open);
  const Service = global.service
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const closeAlert = () => {
    let alert = _.cloneDeep(alertData);
    alert.open = false;
    alert.severity = "";
    alert.message = "";
    setAlertData(alert);
  };
  let classes = useStyles();
  let placeRefCircle = useRef();
  const [spinner, setSpinner] = useState(false);
  const [removeOpen, setRemoveOpen] = useState(props.open);
  const validateFields = new ValidateFields();
//   useEffect(async () => {
//     if (props?.assignData) {
//       let newFields = _.cloneDeep(fields);
//       newFields.deviceImei.value = {
//         label: props.assignData.deviceId,
//         value: props.assignData.deviceImei,
//       };
//       // if(props.assignData.deleteReason != null && props.assignData.deleteReason != undefined && props.assignData.deleteReason != ""){
//       //   newFields.reason.value = props.assignData.deleteReason
//       // }
//       setFields(newFields);
//     }
//   }, [props?.assignData]);
  
  
  const handleClose = () => {
    setOpen(false);
    props.onClose();
    // let newField = _.cloneDeep(fields);
    // setFields(newField);
  };
  
  const forMovingDeviceToSubAccount = async () => {
    setSpinner(true);
    let object = {
      deviceImei: props.assignData.deviceImei,
      companyId: props.assignData.companyId,
    };
    await Service.moveDeviceToSubaccount(object)
      .then((res) => {
        setSpinner(false);
        if (res.status) {
          setAlertData({
            open: true,
            severity: "success",
            message: res.message,
          });
          setTimeout(() => {
            closeAlert();
            handleClose();
            window.location.reload();
          }, 3000);
        } else {
          setAlertData({
            open: true,
            severity: "error",
            message: res.message,
          });
        }
      })
      .catch((e) => {
        console.log("Error......", e);
      });
  };
  return (
    <div>
      
        <Dialog
          open={removeOpen}
          onClose={handleClose}
          PaperComponent={PaperComponent}
          aria-labelledby="draggable-dialog-title"
          classes={{ paper: classes.removePaper }}
          maxWidth="md" // Set the maximum width, you can use 'xs', 'sm', 'md', 'lg', 'xl', or a specific value
          fullWidth={true}
        >
          {alertData.open ? (
            <div className={classes.alertBox}>
              <AlertMessage
                severity={alertData.severity}
                message={alertData.message}
                closeAlert={closeAlert}
              />
            </div>
          ) : null}
          <DialogTitle
            className={classes.dialogTitle}
            id="draggable-dialog-title"
          >
            <div
              style={{
                textAlign: "center",
                padding: "10px",
                fontSize: "16px",
                lineHeight: "1.5",
                color: "#000000",
                fontWeight: 500,
              }}
            >
              <div>
                <img
                  style={{
                    height: "170px",
                    width: "170px",
                    marginBottom: "0px",
                    paddingBottom: "0px",
                  }}
                  src={deviceImage}
                />
              </div>
              <div style={{ marginTop: "10px" }}>
                Are you sure you want to move this<br />
                device into this subaccount ({props.assignData.companyName}) ?
              </div>
            </div>
          </DialogTitle>
          <DialogActions>
            <Grid
              container
              spacing={2}
              style={{ paddingBottom: "10px" }}
            >
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={3}>
                    <Button
                      variant="outlined"
                      onClick={handleClose}
                      className="cancel_button"
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                    variant="outlined"
                      className="save_button"
                      onClick={() => forMovingDeviceToSubAccount()}
                      startIcon={
                        spinner ? (
                          <CircularProgress size={20} color={"#fff"} />
                        ) : null
                      }
                    >
                      Move
                    </Button>
                  </Grid>
                  <Grid item xs={3}></Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      
    </div>
  );
};
