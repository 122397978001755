import React, { useState } from "react";
import Header from "../../components/header";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, Grid } from "@material-ui/core";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
// import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import HistoryIcon from "@mui/icons-material/History";
import * as Fields from "../../sharedComponents";
import _ from "lodash";
import * as CONFIG from '../../config/GlobalConstants';
import * as Components from '../../sharedComponents';
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Checkbox from "@mui/material/Checkbox";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Divider from '@mui/material/Divider';
import { useHistory } from "react-router-dom";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
const headRows = [
    { id: "VENDOR ID", disablePadding: true, label: "VENDOR ID", checked: true },
    { id: "FIRST NAME", disablePadding: true, label: "FIRST NAME", checked: true },
    { id: "LASTNAME", disablePadding: true, label: "LASTNAME", checked: true },
    { id: "COMPANY NAME", disablePadding: true, label: "COMPANY NAME", checked: true },
    { id: "CONTACT#", disablePadding: true, label: "CONTACT#", checked: true },
    {
        id: "EMAID ID",
        disablePadding: true,
        label: "EMAID ID",
        checked: true
    },
    { id: "GST#", disablePadding: true, label: "GST#", checked: true },
    { id: "OPENING BAL", disablePadding: true, label: "OPENING BAL", checked: true },
    { id: "Due amount", disablePadding: true, label: "Due amount", checked: true },
    { id: "Action", disablePadding: true, label: "" },
];

const useStyles = makeStyles((theme) => ({
    main_container: {
        background: "#F5F6F7",
        height: "100vh"
    },
    header_box: {
        background: "#FFFFFF",
        padding: "20px 24px",
        display: "flex",
        justifyContent: "space-between",
    },
    header_text: {
        fontSize: "20px",
        fontWeight: "bold",
        lineHeight: "42px",
    },
    header_buttons: {
        padding: "0px 15px",
    },
    client_button: {
        background: "#366E93",
        textTransform: "none",
        color: "#FFFFFF",
        padding: "8px 15px",
        marginRight: "15px",
        "&:hover": {
            background: "#366E93",
        },
    },
    history_button: {
        background: "#649B42",
        textTransform: "none",
        color: "#FFFFFF",
        padding: "8px 15px",
        "&:hover": {
            background: "#649B42",
        },
    },

    advance_button: {
        background: "#D08B1D",
        textTransform: "none",
        color: "#FFFFFF",
        padding: "8px 15px",
        marginRight: "15px",
        "&:hover": {
            background: "#D08B1D",
        },
    },
    filter_box: {
        padding: "20px 24px",
        display: "flex",
        justifyContent: "space-between",
        border: "2px solid #DBE0E7",
    },
    filter_field: {
        background: "#FFFFFF",
    },
    filter_buttons: {
        textAlign: "right",
        padding: "0px 15px",
    },
    clear_button: {
        padding: "6px 15px",
        background: "#D3710F0D",
        border: "1px solid #D3710F",
        color: "#D3710F",
    },
    table_head: {
        padding: "10px 24px 15px 24px",
        display: "flex",
        justifyContent: "space-between",
    },
    selected_text: {
        color: "#929DAF",
        fontSize: "14px",
        letterSpacing: "0.5px",
        alignSelf: "center"
    },
    row_paper: {
        background: "#FFFFFF",
        boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
        borderRadius: "4px"
    },
    divider: {
        height: "12px"
    },
    icon: {
        cursor: "pointer"
    }
}));

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, numSelected, rowCount } = props;
    return (
        <TableHead className={classes.tableHead}>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ "aria-label": "select all desserts" }}
                        className={classes.whiteColor}
                    />
                </TableCell>
                {headRows.map((row) => (
                    <TableCell
                        key={row.id}
                        align={"center"}
                        padding={row.disablePadding ? "none" : "default"}
                        className={classes.whiteColor}
                    >
                        {row.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const Vendor = () => {
    const classes = useStyles();
    const history = useHistory();
    const filterFields = {
        vendor: { label: "all vendors", name: "vendor", value: "", options: [] },
        client: { label: "Search by Name", name: "client", value: "", options: [] },
        date: { label: "Select date", name: "date", value: "" },
    };
    const [filters, setFilters] = useState(_.cloneDeep(filterFields));
    const autoCompleteChangeHandler = () => { };
    const dateChangeHandler = () => { };
    const handlePageChange = () => { };
    const handleChangeRowsPerPage = () => { };
    return (
        <div>
            <Header />
            <div className={classes.main_container}>
                <div className={classes.header_box}>
                    <Typography className={classes.header_text}>
                        Vendors
                    </Typography>
                    <div className={classes.header_buttons}>
                        <Button
                            className={classes.client_button}
                            startIcon={<AddCircleOutlinedIcon />}
                            onClick={() => { history.push("./addVendor") }}
                        >
                            Add Vendor
                        </Button>
                        <Button
                            className={classes.advance_button}
                            startIcon={<AttachMoneyIcon />}
                        >
                            Pay Advance
                        </Button>
                        <Button
                            className={classes.history_button}
                            startIcon={<HistoryIcon />}
                        >
                            History
                        </Button>
                    </div>
                </div>
                <div className={classes.filter_box}>
                    <Grid container spacing={3}>
                        <Grid item xs={9}>
                            <Grid container spacing={2}>
                                <Grid item xs={2}>
                                    <Fields.AutoCompleteField
                                        fieldData={filters.vendor}
                                        autoCompleteChangeHandler={autoCompleteChangeHandler}
                                        className={classes.filter_field}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Fields.AutoCompleteField
                                        fieldData={filters.client}
                                        autoCompleteChangeHandler={autoCompleteChangeHandler}
                                        className={classes.filter_field}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Fields.DateField
                                        fieldData={filters.date}
                                        dateChangeHandler={dateChangeHandler}
                                        variant="outlined"
                                        className={classes.filter_field}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={3}>
                            <div className={classes.filter_buttons}>
                                <Button
                                    className={classes.clear_button}
                                    startIcon={<CloseIcon />}
                                >
                                    Clear
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <div>
                    <div className={classes.table_head}>
                        <span className={classes.selected_text}>0 SELECTED</span>
                        <div className="custom_pagination">
                            <TablePagination
                                component="div"
                                count={100}
                                page={4}
                                onPageChange={handlePageChange}
                                rowsPerPage={10}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </div>
                    </div>
                    <div className="custom_table">
                        <Table>
                            <EnhancedTableHead
                                classes={classes}
                                numSelected={0}
                                rowCount={20}
                                onSelectAllClick={() => { }}
                            />
                            <TableBody>
                                <TableRow className={classes.row_paper}>
                                    <TableCell padding="checkbox">
                                        <Checkbox />
                                    </TableCell>
                                    <TableCell align="center" className={classes.cell_content}>1234567</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>Rajesh</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>Kumar</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>SSS Group pvt Ltd</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>987654321O</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>rajesh@gmail.com</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>123455667890</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>RS 1,50,000</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>+RS 50,000</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>
                                        <Components.More options={CONFIG.VENDOR_MORE_OPTIONS} />
                                    </TableCell>
                                </TableRow>
                                <Divider className={classes.divider} />
                                <TableRow className={classes.row_paper}>
                                    <TableCell padding="checkbox">
                                        <Checkbox />
                                    </TableCell>
                                    <TableCell align="center" className={classes.cell_content}>1234567</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>Rajesh</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>Kumar</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>SSS Group pvt Ltd</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>987654321O</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>rajesh@gmail.com</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>123455667890</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>RS 1,50,000</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>+RS 50,000</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>
                                        <Components.More options={CONFIG.VENDOR_MORE_OPTIONS} />
                                    </TableCell>
                                </TableRow>
                                <Divider className={classes.divider} />
                                <TableRow className={classes.row_paper}>
                                    <TableCell padding="checkbox">
                                        <Checkbox />
                                    </TableCell>
                                    <TableCell align="center" className={classes.cell_content}>1234567</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>Rajesh</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>Kumar</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>SSS Group pvt Ltd</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>987654321O</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>rajesh@gmail.com</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>123455667890</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>RS 1,50,000</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>+RS 50,000</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>
                                        <Components.More options={CONFIG.VENDOR_MORE_OPTIONS} />
                                    </TableCell>
                                </TableRow>
                                <Divider className={classes.divider} />
                                <TableRow className={classes.row_paper}>
                                    <TableCell padding="checkbox">
                                        <Checkbox />
                                    </TableCell>
                                    <TableCell align="center" className={classes.cell_content}>1234567</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>Rajesh</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>Kumar</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>SSS Group pvt Ltd</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>987654321O</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>rajesh@gmail.com</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>123455667890</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>RS 1,50,000</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>+RS 50,000</TableCell>
                                    <TableCell align="center" className={classes.cell_content}>
                                        <Components.More options={CONFIG.VENDOR_MORE_OPTIONS} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Vendor;
