import { InMemoryCache } from "apollo-boost";
import { ApolloClient, Observable, ApolloLink } from "@apollo/client";
import { createClient } from "graphql-ws";
import { print } from "graphql";
import { split } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { getMainDefinition } from 'apollo-utilities';

class WebSocketLink extends ApolloLink {
  client;

  constructor(options) {
    super();
    this.client = createClient(options);
  }

  request(operation) {
    return new Observable((sink) => {
      return this.client.subscribe(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: sink.error.bind(sink),
        }
      );
    });
  }
}

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_SUVEGA_API_ENDPOINT_6}/graphql`
})

const wsLink = new WebSocketLink({
  url: `${process.env.REACT_APP_SUVEGA_API_ENDPOINT_7}/graphql`,
  options: {
    reconnect: true,
  },
});

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind = 'OperationDefinition' && 
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
)

export default new ApolloClient({
  link: ApolloLink.from([wsLink]),
  cache: new InMemoryCache(),
});
