import {
  Box,
  Button,
  Grid,
  Input,
  Paper,
  Stack,
  TableCell,
  Typography,
  MenuItem,
} from "@mui/material";
import React, { useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import * as Fields from "../../sharedComponents";
import RemoveIcon from "@mui/icons-material/Remove";
import "../../common.css";
import _ from "lodash";
import { CenterFocusStrong } from "@mui/icons-material";
import ValidateFields from "../../validations/validateFields";
let validateFields = new ValidateFields();

export function ForCheckoutInventoryTire() {
  let formObjectFields = [
    {
      inventory: {
        name: "inventory",
        value: "",
        isValid: true,
        placeholder: "Enter qr code or sr. number",
        ref: useRef(),
        topLabel: true,
        validationRequired: true,
        errorMsg: "Please enter qr code or sr. number",
        validPattern: "SPECIAL_CHARS_DESC",
      },
      truckNumber: {
        name: "truckNumber",
        value: "",
        isValid: true,
        placeholder: "Enter truck number",
        ref: useRef(),
        topLabel: true,
        validationRequired: true,
        errorMsg: "Please enter truck number",
        validPattern: "SPECIAL_CHARS_DESC",
        maxLength: 10,
      },
      tirePosition: {
        name: "tirePosition",
        value: "",
        isValid: true,
        placeholder: "Enter tire position",
        ref: useRef(),
        topLabel: true,
        validationRequired: true,
        errorMsg: "Please enter tire position",
        validPattern: "SPECIAL_CHARS_DESC",
      },
      odometer: {
        name: "odometer",
        value: "",
        isValid: true,
        placeholder: "Enter odometer",
        ref: useRef(),
        topLabel: true,
        validationRequired: true,
        errorMsg: "Please enter odometer",
        validPattern: "SPECIAL_CHARS_DESC",
      },
      location: {
        name: "location",
        value: "",
        isValid: true,
        placeholder: "Enter location",
        ref: useRef(),
        topLabel: true,
        validationRequired: true,
        errorMsg: "Please enter location",
        validPattern: "SPECIAL_CHARS_DESC",
      },
      tireType: {
        name: "tireType",
        value: { label: "Re-tread", value: "Re-tread" },
        isValid: true,
        options: [
          { label: "Re-tread", value: "Re-tread" },
          { label: "Seal", value: "Seal" },
        ],
        ref: useRef(),
        topLabel: true,
      },
    },
  ];

  const [formObjectData, setFormObjectData] = useState(formObjectFields);
  const inputChangeHandler = (value, name, i) => {
    if (value.length > 0 && formObjectData[i][name]["validationRequired"]) {
      formObjectData[i][name]["isValid"] = true;
    }

    formObjectData[i][name]["value"] = value;
    setFormObjectData(_.cloneDeep(formObjectData));
  };
  const autoCompleteChangeHandler = (value, name, i) => {
    formObjectData[i][name]["value"].value = value?.value;
    setFormObjectData(_.cloneDeep(formObjectData));
  };

  let addHandler = () => {
    const newFields = _.cloneDeep(formObjectData);
    newFields.push(formObjectFields[0]);
    setFormObjectData(newFields);
  };
  const removeHandler = (index) => {
    const newFields = _.cloneDeep(formObjectData);
    newFields.splice(index, 1);
    setFormObjectData(newFields);
  };
  return (
    <>
      <Grid container paddingTop={2} spacing={1}>
        <Grid item xs={2.5} md={1.8}>
          <Typography variant="h8" color="black">
            INVENTORY
          </Typography>
        </Grid>
        <Grid item xs={2.5} md={1.8}>
          <Typography variant="h8" color="black">
            TRUCK NUMBER
          </Typography>
        </Grid>
        <Grid item xs={2.5} md={1.8}>
          <Typography variant="h8" color="black">
            TIRE POSITION
          </Typography>
        </Grid>
        <Grid item xs={2.5} md={1.8}>
          <Typography variant="h8" color="black">
            ODOMETER
          </Typography>
        </Grid>
        <Grid item xs={2.5} md={1.8}>
          <Typography variant="h8" color="black">
            TIRE TYPE
          </Typography>
        </Grid>
        <Grid item xs={2.5} md={1.8}>
          <Typography variant="h8" color="black">
            LOCATION
          </Typography>
        </Grid>
        <Grid item xs={2.5} md={1}>
          <Typography variant="h8" color="black">
            ACTION
          </Typography>
        </Grid>
      </Grid>
      <DialogContent deviders style={{ padding: 0 }}>
        {formObjectData.map((field, index) => {
          return (
            <Grid container paddingTop={1.5} spacing={1} paddingBottom={1.5}>
              <Grid item xs={2.5} md={1.8}>
                <Fields.InputField
                  fieldData={field.inventory}
                  inputChangeHandler={(value, name) =>
                    inputChangeHandler(value, name, index)
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2.5} md={1.8}>
                <Fields.InputField
                  fieldData={field.truckNumber}
                  inputChangeHandler={(value, name) =>
                    inputChangeHandler(value, name, index)
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2.5} md={1.8}>
                <Fields.InputField
                  fieldData={field.tirePosition}
                  inputChangeHandler={(value, name) =>
                    inputChangeHandler(value, name, index)
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2.5} md={1.8}>
                <Fields.InputField
                  fieldData={field.odometer}
                  inputChangeHandler={(value, name) =>
                    inputChangeHandler(value, name, index)
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2.5} md={1.8}>
                <Fields.AntSelectableSearchField
                  fieldData={field.tireType}
                  autoCompleteChangeHandler={(value, name) =>
                    autoCompleteChangeHandler(value, name, index)
                  }
                />
              </Grid>
              <Grid item xs={2.5} md={1.8}>
                <Fields.InputField
                  fieldData={field.location}
                  inputChangeHandler={(value, name) =>
                    inputChangeHandler(value, name, index)
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2.5} md={1} marginTop={1.2}>
                {index === formObjectData.length - 1 ? (
                  <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    style={{ padding: "8px 0px" }}
                    onClick={() => {
                      const dataStatus =
                        validateFields.validateFieldsData(field);
                      if (dataStatus.status) {
                        addHandler();
                      } else {
                        let newFields = _.cloneDeep(formObjectData);
                        newFields.splice(index, 1, dataStatus.data);
                        setFormObjectData(newFields);
                      }
                    }}
                  >
                    <AddIcon />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    disableElevation
                    color="secondary"
                    style={{ padding: "8px 0px" }}
                    onClick={() => {
                      removeHandler(index);
                    }}
                  >
                    <RemoveIcon />
                  </Button>
                )}
              </Grid>
            </Grid>
          );
        })}
      </DialogContent>
    </>
  );
}

export function ForCheckoutInventoryEquipment() {
  let formObjectFields = [
    {
      inventory: {
        name: "inventory",
        value: "",
        isValid: true,
        placeholder: "Enter qr code or sr. number",
        ref: useRef(),
        topLabel: true,
        validationRequired: true,
        errorMsg: "Please enter qr code or sr. number",
        validPattern: "SPECIAL_CHARS_DESC",
      },
      truckNumber: {
        name: "truckNumber",
        value: "",
        isValid: true,
        placeholder: "Enter truck number",
        ref: useRef(),
        topLabel: true,
        validationRequired: true,
        errorMsg: "Please enter truck number",
        validPattern: "SPECIAL_CHARS_DESC",
      },
      equipment: {
        name: "equipment",
        value: "",
        isValid: true,
        placeholder: "Enter equipment",
        ref: useRef(),
        topLabel: true,
        validationRequired: true,
        errorMsg: "Please enter equipment",
        validPattern: "SPECIAL_CHARS_DESC",
      },
      location: {
        name: "location",
        value: "",
        isValid: true,
        placeholder: "Enter location",
        ref: useRef(),
        topLabel: true,
        validationRequired: true,
        errorMsg: "Please enter location",
        validPattern: "SPECIAL_CHARS_DESC",
      },
      quantity: {
        name: "quantity",
        value: "",
        isValid: true,
        placeholder: "Enter quantity",
        ref: useRef(),
        topLabel: true,
        validationRequired: true,
        errorMsg: "Please enter quantity",
        validPattern: "SPECIAL_CHARS_DESC",
      },
    },
  ];

  const [formObjectData, setFormObjectData] = useState(formObjectFields);
  const inputChangeHandler = (value, name, i) => {
    if (value.length > 0 && formObjectData[i][name]["validationRequired"]) {
      formObjectData[i][name]["isValid"] = true;
    }

    formObjectData[i][name]["value"] = value;
    setFormObjectData(_.cloneDeep(formObjectData));
  };
  let addHandler = () => {
    const newFields = _.cloneDeep(formObjectData);
    newFields.push(formObjectFields[0]);
    setFormObjectData(newFields);
  };
  const removeHandler = (index) => {
    const newFields = _.cloneDeep(formObjectData);
    newFields.splice(index, 1);
    setFormObjectData(newFields);
  };
  return (
    <>
      <Grid container paddingTop={2} spacing={1}>
        <Grid item xs={2.5} md={1.8}>
          <Typography variant="h8" color="black">
            INVENTORY
          </Typography>
        </Grid>
        <Grid item xs={2.5} md={1.8}>
          <Typography variant="h8" color="black">
            TRUCK NUMBER
          </Typography>
        </Grid>
        <Grid item xs={2.5} md={1.8}>
          <Typography variant="h8" color="black">
            EQUIPMENT
          </Typography>
        </Grid>
        <Grid item xs={2.5} md={1.8}>
          <Typography variant="h8" color="black">
            AVAILABLE QUANTITY
          </Typography>
        </Grid>
        <Grid item xs={2.5} md={1.8}>
          <Typography variant="h8" color="black">
            QUANTITY
          </Typography>
        </Grid>
        <Grid item xs={2.5} md={1.8}>
          <Typography variant="h8" color="black">
            LOCATION
          </Typography>
        </Grid>
        <Grid item xs={2.5} md={1}>
          <Typography variant="h8" color="black">
            ACTION
          </Typography>
        </Grid>
      </Grid>
      <DialogContent deviders style={{ padding: 0 }}>
        {formObjectData.map((field, index) => {
          return (
            <Grid container paddingTop={1.5} spacing={1} paddingBottom={1.5}>
              <Grid item xs={2.5} md={1.8}>
                <Fields.InputField
                  fieldData={field.inventory}
                  inputChangeHandler={(value, name) =>
                    inputChangeHandler(value, name, index)
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2.5} md={1.8}>
                <Fields.InputField
                  fieldData={field.truckNumber}
                  inputChangeHandler={(value, name) =>
                    inputChangeHandler(value, name, index)
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2.5} md={1.8}>
                <Fields.InputField
                  fieldData={field.equipment}
                  inputChangeHandler={(value, name) =>
                    inputChangeHandler(value, name, index)
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2.5} md={1.8}>
                <Typography
                  style={{
                    color: "white",
                    backgroundColor: "#00BF9A",
                    marginTop: "13px",
                    textAlign: "center",
                    fontWeight: "bold",
                    padding: "3px",
                    borderRadius: "5px",
                  }}
                >
                  QUANTITY :
                </Typography>
              </Grid>
              <Grid item xs={2.5} md={1.8}>
                <Fields.InputField
                  fieldData={field.quantity}
                  inputChangeHandler={(value, name) =>
                    inputChangeHandler(value, name, index)
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2.5} md={1.8}>
                <Fields.InputField
                  fieldData={field.location}
                  inputChangeHandler={(value, name) =>
                    inputChangeHandler(value, name, index)
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2.5} md={1} marginTop={1.2}>
                {index === formObjectData.length - 1 ? (
                  <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    style={{ padding: "8px 0px" }}
                    onClick={() => {
                      const dataStatus =
                        validateFields.validateFieldsData(field);
                      if (dataStatus.status) {
                        addHandler();
                      } else {
                        let newFields = _.cloneDeep(formObjectData);
                        newFields.splice(index, 1, dataStatus.data);
                        setFormObjectData(newFields);
                      }
                    }}
                  >
                    <AddIcon />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    disableElevation
                    color="secondary"
                    style={{ padding: "8px 0px" }}
                    onClick={() => {
                      removeHandler(index);
                    }}
                  >
                    <RemoveIcon />
                  </Button>
                )}
              </Grid>
            </Grid>
          );
        })}
      </DialogContent>
    </>
  );
}

export function ForCheckoutInventoryConsumable() {
  let formObjectFields = [
    {
      inventory: {
        name: "inventory",
        value: "",
        isValid: true,
        placeholder: "Enter qr code or sr. number",
        ref: useRef(),
        topLabel: true,
        validationRequired: true,
        errorMsg: "Please enter qr code or sr. number",
        validPattern: "SPECIAL_CHARS_DESC",
      },
      truckNumber: {
        name: "truckNumber",
        value: "",
        isValid: true,
        placeholder: "Enter truck number",
        ref: useRef(),
        topLabel: true,
        validationRequired: true,
        errorMsg: "Please enter truck number",
        validPattern: "SPECIAL_CHARS_DESC",
      },
      name: {
        name: "name",
        value: "",
        isValid: true,
        placeholder: "Enter name",
        ref: useRef(),
        topLabel: true,
        validationRequired: true,
        errorMsg: "Please enter name",
        validPattern: "SPECIAL_CHARS_DESC",
      },
      location: {
        name: "location",
        value: "",
        isValid: true,
        placeholder: "Enter location",
        ref: useRef(),
        topLabel: true,
        validationRequired: true,
        errorMsg: "Please enter location",
        validPattern: "SPECIAL_CHARS_DESC",
      },
      quantity: {
        name: "quantity",
        value: "",
        isValid: true,
        placeholder: "Enter quantity",
        ref: useRef(),
        topLabel: true,
        validationRequired: true,
        errorMsg: "Please enter quantity",
        validPattern: "SPECIAL_CHARS_DESC",
      },
      type: {
        name: "type",
        value: { label: "Quantity", value: "quantity" },
        isValid: true,
        options: [
          { label: "Quantity", value: "Quantity" },
          { label: "Parcial Consumption", value: "parcial_consumption" },
        ],
        ref: useRef(),
        topLabel: true,
      },
    },
  ];

  const [formObjectData, setFormObjectData] = useState(formObjectFields);
  const inputChangeHandler = (value, name, i) => {
    if (value.length > 0 && formObjectData[i][name]["validationRequired"]) {
      formObjectData[i][name]["isValid"] = true;
    }

    formObjectData[i][name]["value"] = value;
    setFormObjectData(_.cloneDeep(formObjectData));
  };
  const autoCompleteChangeHandler = (value, name, i) => {
    formObjectData[i][name]["value"].value = value?.value;
    setFormObjectData(_.cloneDeep(formObjectData));
  };
  let addHandler = () => {
    const newFields = _.cloneDeep(formObjectData);
    newFields.push(formObjectFields[0]);
    setFormObjectData(newFields);
  };
  const removeHandler = (index) => {
    const newFields = _.cloneDeep(formObjectData);
    newFields.splice(index, 1);
    setFormObjectData(newFields);
  };
  return (
    <>
      <Grid container paddingTop={2} spacing={1}>
        <Grid item xs={2.5} md={1.5}>
          <Typography variant="h8" color="black">
            INVENTORY
          </Typography>
        </Grid>
        <Grid item xs={2.5} md={1.5}>
          <Typography variant="h8" color="black">
            TRUCK NUMBER
          </Typography>
        </Grid>
        <Grid item xs={2.5} md={1.5}>
          <Typography variant="h8" color="black">
            NAME
          </Typography>
        </Grid>
        <Grid item xs={2.5} md={2} style={{ textAlign: "center" }}>
          <Typography variant="h8" color="black">
            AVAILABLE QUANTITY AVAILABLE UNITS
          </Typography>
        </Grid>
        <Grid item xs={2.5} md={1.5}>
          <Typography variant="h8" color="black">
            TYPE
          </Typography>
        </Grid>
        <Grid item xs={2.5} md={1.5}>
          <Typography variant="h8" color="black">
            QUANTITY
          </Typography>
        </Grid>
        <Grid item xs={2.5} md={1.5}>
          <Typography variant="h8" color="black">
            LOCATION
          </Typography>
        </Grid>
        <Grid item xs={2.5} md={1}>
          <Typography variant="h8" color="black">
            ACTION
          </Typography>
        </Grid>
      </Grid>
      <DialogContent deviders style={{ padding: 0 }}>
        {formObjectData.map((field, index) => {
          return (
            <Grid container paddingTop={1.5} spacing={1} paddingBottom={1.5}>
              <Grid item xs={2.5} md={1.5}>
                <Fields.InputField
                  fieldData={field.inventory}
                  inputChangeHandler={(value, name) =>
                    inputChangeHandler(value, name, index)
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2.5} md={1.5}>
                <Fields.InputField
                  fieldData={field.truckNumber}
                  inputChangeHandler={(value, name) =>
                    inputChangeHandler(value, name, index)
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2.5} md={1.5}>
                <Fields.InputField
                  fieldData={field.name}
                  inputChangeHandler={(value, name) =>
                    inputChangeHandler(value, name, index)
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2.5} md={2}>
                <Typography
                  style={{
                    color: "white",
                    backgroundColor: "#00BF9A",
                    textAlign: "center",
                    fontWeight: "bold",
                    padding: "2px",
                    borderRadius: "5px",
                  }}
                >
                  QUANTITY :
                </Typography>
                <Typography
                  style={{
                    color: "white",
                    backgroundColor: "#FD5D93",
                    marginTop: "3px",
                    textAlign: "center",
                    fontWeight: "bold",
                    padding: "2px",
                    borderRadius: "5px",
                  }}
                >
                  UNITS :
                </Typography>
              </Grid>
              <Grid item xs={2.5} md={1.5}>
                <Fields.AntSelectableSearchField
                  fieldData={field.type}
                  inputChangeHandler={(value, name) =>
                    autoCompleteChangeHandler(value, name, index)
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2.5} md={1.5}>
                <Fields.InputField
                  fieldData={field.quantity}
                  inputChangeHandler={(value, name) =>
                    inputChangeHandler(value, name, index)
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2.5} md={1.5}>
                <Fields.InputField
                  fieldData={field.location}
                  inputChangeHandler={(value, name) =>
                    inputChangeHandler(value, name, index)
                  }
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={2.5} md={1} marginTop={1.2}>
                {index === formObjectData.length - 1 ? (
                  <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    style={{ padding: "8px 0px" }}
                    onClick={() => {
                      const dataStatus =
                        validateFields.validateFieldsData(field);
                      if (dataStatus.status) {
                        addHandler();
                      } else {
                        let newFields = _.cloneDeep(formObjectData);
                        newFields.splice(index, 1, dataStatus.data);
                        setFormObjectData(newFields);
                      }
                    }}
                  >
                    <AddIcon />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    disableElevation
                    color="secondary"
                    style={{ padding: "8px 0px" }}
                    onClick={() => {
                      removeHandler(index);
                    }}
                  >
                    <RemoveIcon />
                  </Button>
                )}
              </Grid>
            </Grid>
          );
        })}
      </DialogContent>
    </>
  );
}
