import TripRequestBuilders from "../RequestBuilders/trips";
class Trips {
  constructor(Service, path, path2, Util) {
    this.httpService = Service;
    this.path = path;
    this.path2 = path2;
    this.Util = Util;
    this.tripRequestBuilder = new TripRequestBuilders(Util);
  }

  getTrips = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(this.path.TRIPS, data);
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getTrip = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.TRIPS +
      "/" +
      data.tripId;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getTripSummary = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(this.path.TRIPS + "/summary", data);
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  createTrip = (data, vehicles) => {

    let driverId = data?data.pilotName?.value:null
    if(driverId && !(Number(driverId)>0)){
      driverId = "0";
    }
    let reqData = this.tripRequestBuilder.CreateTrip(data, vehicles);
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + this.path.TRIPS;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, {...reqData,driverId})
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  updateTripLocation = (data) => {
    let reqData = this.tripRequestBuilder.UpdateTrip(data);
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.ROUTES +
      "/updateRoute";
    return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  cancelTrip = (data, tripData) => {
    let reqData = this.tripRequestBuilder.CancelTrip(data);
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.TRIPS +
      `/${tripData.id}` +
      "/cancelTrip";
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  
  uploadTripDocument = (file, tripData) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.TRIPS +
      "/" +
      tripData.id +
      "/documents";
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, file)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  updateQuantitiesInDues =(dataObj) =>{
    const url =
    process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 +"trips/updateProofOfTripDocs";
    return new Promise((resolve, reject) => {
    this.httpService
      .patch(url,dataObj)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
  }

  getTripsDocuments = (tripData) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.TRIPS +
      "/" +
      tripData.tripId +
      "/documents";
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getTripUnits = () => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + this.path.TRIPS + "/units";
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  addTripLocation = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.TRIPS +
      `/${data.tripId}/addWaypoint`;
    let reqData = this.tripRequestBuilder.AddTripLocation(data);
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  updateRouteDetails = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.TRIPS +
      `/${data.tripId.id}/updateFixedDetails`;
    let reqData = this.tripRequestBuilder.UpdateRouteDetails(data);
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getElocks = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.TRIP_SECURITY_TAGS +
      `/${data.tripId}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  
  getTripFuelData = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +"/vehicles/fuel/trip"+
      `/${data.tripId}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getDriverAdvance = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +"/driverPayments/driverAdvance?"+
      `tripId=${data.tripId}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getDriverExpense = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +"/driverPayments/driverExpense?"+
      `tripId=${data.tripId}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getTripReport = (dataObj) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + this.path.REPORTS + "/trip";
    let reqData = this.tripRequestBuilder.GetTripReport(dataObj);
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getExportTripDetails = (dataObj) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + "/reports/tripFuelDetails";
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, dataObj)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getMarketTripReport = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +`/trips/report/market?startDate=${data?.startDate}&endDate=${data?.endDate}`
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getOwnTripReport = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +`/trips/report/own?startDate=${data?.startDate}&endDate=${data?.endDate}`
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getOwnAndMarketTripReport = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +`/trips/report/ownAndMarket?startDate=${data?.startDate}&endDate=${data?.endDate}`
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getCreditVendorLedger = (dataObj) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + this.path.REPORTS + "/credit-vendor-ledger";
    let reqData = this.tripRequestBuilder.GetCreditVendorLedger(dataObj);
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getFuelVendorLedger = (dataObj) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + this.path.REPORTS + "/fuel-vendor-ledger";
    let reqData = this.tripRequestBuilder.GetFuelVendorLedger(dataObj);
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getPettyCashLedger = (dataObj) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + this.path.REPORTS + "/petty-cash-ledger";
    let reqData = this.tripRequestBuilder.GetPettyCashLedger(dataObj);
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getCustomerLedger = (dataObj) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + this.path.REPORTS + "/customer-ledger";
    let reqData = this.tripRequestBuilder.GetCustomerLedger(dataObj);
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getBankLedger = (dataObj) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + this.path.REPORTS + "/bank-ledger";
    let reqData = this.tripRequestBuilder.GetBankLedger(dataObj);
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getTripReportTypes = () => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.REPORTS +
      "/types";
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getTripReportEmails = () => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.REPORTS +
      "/get-emails";
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getTripReportDrivers = () => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.REPORTS +
      "/get-drivers";
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getRouteDeviations = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.TRIPS +
      `/${data.tripId}` +
      "/routeDeviationEvents";
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  forceStartTrip = (tripId,fieldData) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.TRIPS +
      `/${tripId}/forceStart`;  ///:tripId/forceStart
    // let reqData = this.tripRequestBuilder.AddTripLocation(data);
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, fieldData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  forceEndTrip = (tripId,fieldData) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.TRIPS +
      `/${tripId}/forceEnd`;  ///:tripId/forceEnd
      return new Promise((resolve, reject) => {
      this.httpService
        .post(url, fieldData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getTollSummary = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      `/trips/${data}` +
      "/tollSummary";
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getFuelOdoSummary = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      `/trips/${data}` +
      "/fuelSummary";
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  updateTripTollSummary = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +`/trips/updateTripTollSummary`
      return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getIndentFuelSummary = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      `/trips/${data}` +
      "/indentFuelSummary";
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getTripEstimations = (data) => {
   const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +`/trips/getTripEstimations`
      return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data.estimatedDetails)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  //trips/357540/odo
  updateTripODO = (id,data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +`/trips/${id}/odo`
      return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getFuelOdoSummarySearch = (data,searchText) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      `/trips/${data}` +
      `/fuelSummary?searchText=${searchText}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getIndentFuelSummarySearch = (data,searchText) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      `/trips/${data}` +
      `/indentFuelSummary?searchText=${searchText}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  //trips/365410/report 
  getDriverTripSheet = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      `/trips/${data}` +
      `/report`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getProofOfTripDocs = (id) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      `/trips/${id}/proofOfTripDocs`
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getTracking = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      `/trips/tracking/${data}`
      // this.Util.queryParamsFromObj(this.path.TRIPS, data);
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  addTripForMining = (data) => {
    const url =
       process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +`/trips/mining/saveTrip`
       return new Promise((resolve, reject) => {
       this.httpService
         .post(url, data)
         .then((res) => {
           resolve(res);
         })
         .catch((error) => {
           reject(error);
         });
     });
   };

   updateTripAdvanceAndExpense = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +"/driverPayments/updateAdvance"
      return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getPendingTrips = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      `/trips/pendingTrips/customer?skip=${data?.skip}&limit=${data?.limit}`
      // this.Util.queryParamsFromObj(this.path.TRIPS, data);
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getFindTrips = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      `/trips/findTrips/customer?skip=${data?.skip}&limit=${data?.limit}`
      // this.Util.queryParamsFromObj(this.path.TRIPS, data);
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  



  getIndividualFindTrips = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      `/trips/findTrips/customer?tripId=${data}`
      // this.Util.queryParamsFromObj(this.path.TRIPS, data);
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getIndividualPendingTrips = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      `/trips/pendingTrips/customer?tripId=${data}`
      // this.Util.queryParamsFromObj(this.path.TRIPS, data);
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  // trips/pendingTrips/confirmPrice
  updatePendingTrips = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +`/trips/pendingTrips/confirmPrice`
      return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  // trips/pendingTrips/cancel
  cancelPendingTrips = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +`/trips/pendingTrips/cancel`
      return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  assignTruckAndPilot = (data) => {
    const url =
       process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +`/trips/pendingTrips/assignTruckAndPilot`
       return new Promise((resolve, reject) => {
       this.httpService
         .post(url, data)
         .then((res) => {
           resolve(res);
         })
         .catch((error) => {
           reject(error);
         });
     });
   };
   uploadTripsDocuments = (data,id) => {
    const url =
       process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +`/trips/temp/${id}/documents`
       return new Promise((resolve, reject) => {
       this.httpService
         .post(url, data)
         .then((res) => {
           resolve(res);
         })
         .catch((error) => {
           reject(error);
         });
     });
   };
  //  trips/temp/385264/documents
  getCustomeTripsDocuments = (data,id) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      `/trips/temp/${id}/documents`
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url,data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  createClientTrip = (data) => {
    const url =
       process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +`/trips/clientTrip`
       return new Promise((resolve, reject) => {
       this.httpService
         .post(url, data)
         .then((res) => {
           resolve(res);
         })
         .catch((error) => {
           reject(error);
         });
     });
   };
}

export default Trips;
