module.exports = Object.freeze({
    LOGIN: "owner/login",
    LOGIN_BY_TOKEN: "owner/login-by-token",
    GET_USER_DETAILS: "owner/getDetail",
    GET_TRIPSHEETS: "owner/get-tripsheets",
    CREATE_TRIPSHEET: "owner/create-tripsheet",
    UPDATE_TRIPSHEET: "owner/update-tripsheet",
    GET_TRIPSHEET_SUMMARY: "owner/tripsheet-summary",
    GET_TRUCK_LIST: "owner/get-trucks",
    GET_DRIVER_LIST: "owner/driver-list",
    SEARCH_BOOKINGS: "owner/search-bookings",
    OWNER_PROFILE_IMAGE: "owner/image",
    OWNER_GET_PROFILE: "owner/get-profile",
    TRIPS: "trips",
})