module.exports = Object.freeze({
    CLIENTS: "/clients",
    CONTRACTS: "/contracts",
    DELIVERYORDERS: "/deliveryorders",
    ROUTES: "/routes",
    COMMODITIES: "/commodities",
    VEHICLES: "/vehicles",
    TRIPS: "/trips",
    AUTHUSER: "/authuser",
    ROLES: "/roles",
    INDENT: "/indent",
    GROUPS: "/groups",
    VENDORS: "/vendors",
    SITES: "/sites",
    TRIP_SECURITY_TAGS: "/trip-security-tags",
    REPORTS: "/reports",
    FVCONTRACTS: "/fvcontracts",
    DRIVER: "/driver",
    INVOICES: "/invoices",
    VENDORPAYMENTS: "/service-provider-payment",
    SERVICE_AREA: "/serviceArea",
    PILOTPAYMENTS: '/driverPayments',
    PETTYCASH: "/pettyCash",
    OWNERBANK: "/ownerBank"
})