import {
  Box,
  Button,
  Grid,
  Input,
  Paper,
  Stack,
  TableCell,
  Typography,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import CardContent from "@mui/material/CardContent";
import CollapsibleTable from "./forStoreInventorytable";
import * as CONFIG from "../../config/GlobalConstants";
import * as Fields from "../../sharedComponents";
import { Card, FormHelperText, FormLabel } from "@material-ui/core";
import Header from "../../components/header";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import "../../common.css";
import _ from "lodash";
import Util from "../../utils";
import { number } from "prop-types";
import ValidateFields from "../../validations/validateFields";
import * as Components from "../../sharedComponents";
import AlertMessage from "../../components/alertmessage/AlertMessage";

let Service;

export function AddInventoryDialogBox() {
  let gstAmount = 0;
  const [addInventoryDialog, setAddInventoryDialog] = React.useState(false);
  const formFields = {
    invoiceNum: {
      name: "invoiceNum",
      label: "Invoice Number",
      value: "",
      validationRequired: true,
      isValid: true,
      topLabel: true,
      errorMsg: "Please enter invoice number",
      placeholder: "Enter invoice number",
      // ref: useRef(),
      // maxLength:true
      validPattern: "ALPHA_NUMERIC_SPACE",
    },
    manfName: {
      name: "manfName",
      label: "Manufacture Name",
      topLabel: true,
      allowClear: true,
      ref: useRef(),
      value: "",
      validationRequired: true,
      isValid: true,
      options: [],
      // validPattern: "ALPHA_NUMERIC_SPACE",
    },
    tireDepth: {
      name: "tireDepth",
      label: "Tire Depth",
      value: "",
      validationRequired: true,
      isValid: true,
      topLabel: true,
      errorMsg: "Please enter tire depth",
      placeholder: "Enter tire depth",
      helperText: "Please enter tire depth",
      type: "number",
      validPattern: "NUMERIC",
      ref: useRef(),
    },
    qrCode: {
      name: "qrCode",
      label: "QR Code",
      value: "",
      validationRequired: true,
      isValid: true,
      topLabel: true,
      errorMsg: "Please enter qr code",
      placeholder: "Enter qr code",
      helperText: "Please enter qr coded",
      ref: useRef(),
      validPattern: "ALPHA_NUMERIC_SPACE",
    },
    serialNumber: {
      name: "serialNumber",
      label: "Serial Number",
      value: "",
      validationRequired: true,
      isValid: true,
      topLabel: true,
      errorMsg: "Please enter serial number",
      placeholder: "Enter serial number",
      helperText: "Please enter serial number",
      ref: useRef(),
      validPattern: "ALPHA_NUMERIC_SPACE",
    },
    modelNumber: {
      name: "modelNumber",
      label: "Model Number",
      value: "",
      topLabel: true,
      placeholder: "Enter qr code",
      ref: useRef(),
      validPattern: "ALPHA_NUMERIC_SPACE",
      validationRequired: false,
      isValid: false,
    },
    name: {
      name: "name",
      label: "Name",
      value: "",
      validationRequired: true,
      isValid: true,
      topLabel: true,
      errorMsg: "Please enter name",
      placeholder: "Enter name",
      helperText: "Please enter name",
      ref: useRef(),
      validPattern: "ALPHA_NUMERIC_SPACE",
    },
    hsnCode: {
      name: "hsnCode",
      label: "HSN Code",
      value: "",
      isValid: false,
      topLabel: true,
      placeholder: "Enter hsn code",
      ref: useRef(),
      validPattern: "ALPHA_NUMERIC_SPACE",
      validationRequired: false,
    },
    quantity: {
      name: "quantity",
      label: "Quantity",
      value: "",
      validationRequired: true,
      isValid: true,
      topLabel: true,
      errorMsg: "Please enter quantity",
      placeholder: "Enter quantity",
      helperText: "Please enter quantity",
      ref: useRef(),
      // type: "float",
      validPattern: "FLOAT",
    },
    rate: {
      name: "rate",
      label: "Rate",
      value: "",
      validationRequired: true,
      isValid: true,
      topLabel: true,
      errorMsg: "Please enter rate",
      placeholder: "Enter rate",
      helperText: "Please enter rate",
      ref: useRef(),
      type: "number",
      validPattern: "NUMERIC",
    },
    subtotal: {
      name: "subtotal",
      label: "Subtotal",
      value: 0,
      topLabel: true,
      // placeholder: "Enter subtotal",
      ref: useRef(),
      readOnly: true,
      type: "number",
      validationRequired: false,
      isValid: false,
    },
    gstPercentage: {
      name: "gstPercentage",
      label: "GST Percentage (+)",
      value: "",
      topLabel: true,
      placeholder: "Enter gst percentage",
      ref: useRef(),
      type: "number",
      validPattern: "NUMERIC",
      isValid: false,
      validationRequired: false,
    },
    purchaseTotal: {
      name: "purchaseTotal",
      label: "Purchase Total",
      value: "",
      topLabel: true,
      // placeholder: "Enter purchase total",
      ref: useRef(),
      readOnly: true,
      type: "number",
      isValid: false,
      validationRequired: false,
    },
    vendorName: {
      name: "vendorName",
      label: "Vendor Name",
      value: "",
      topLabel: true,
      placeholder: "Enter vendor name",
      ref: useRef(),
      validPattern: "ALPHA_NUMERIC_SPACE",
      isValid: false,
      validationRequired: false,
    },
    vendorCode: {
      name: "vendorCode",
      label: "Vendor Code",
      value: "",
      topLabel: true,
      isValid: false,
      validationRequired: false,
      placeholder: "Enter vendor code",
      ref: useRef(),
      validPattern: "ALPHA_NUMERIC_SPACE",
    },
    expectedYears: {
      name: "expectedYears",
      label: "Expected Lifetime",
      value: "",
      topLabel: true,
      placeholder: "Enter no of years",
      ref: useRef(),
      type: "number",
      validPattern: "NUMERIC",
      isValid: false,
      validationRequired: false,
    },
    expectedMonths: {
      name: "expectedMonths",
      value: "",
      topLabel: true,
      placeholder: "Enter no of months",
      ref: useRef(),
      type: "number",
      validPattern: "NUMERIC",
      isValid: false,
      validationRequired: false,
    },
    expectedDays: {
      name: "expectedDays",
      value: "",
      topLabel: true,
      placeholder: "Enter no of days",
      ref: useRef(),
      type: "number",
      validPattern: "NUMERIC",
      isValid: false,
      validationRequired: false,
    },
    expectedKm: {
      name: "expectedKm",
      label: "Expected KM",
      value: "",
      topLabel: true,
      placeholder: "Enter expected km",
      ref: useRef(),
      type: "number",
      validPattern: "NUMERIC",
      isValid: false,
      validationRequired: false,
    },
    consumedYears: {
      name: "consumedYears",
      label: "Consumed Life",
      value: "",
      topLabel: true,
      placeholder: "Enter no of years",
      ref: useRef(),
      type: "number",
      validPattern: "NUMERIC",
      isValid: false,
      validationRequired: false,
    },
    consumedMonths: {
      name: "consumedMonths",
      value: "",
      topLabel: true,
      placeholder: "Enter no of months",
      ref: useRef(),
      type: "number",
      validPattern: "NUMERIC",
      isValid: false,
      validationRequired: false,
    },
    consumedDays: {
      name: "consumedDays",
      value: "",
      topLabel: true,
      placeholder: "Enter no of days",
      ref: useRef(),
      validPattern: "NUMERIC",
      type: "number",
      isValid: false,
      validationRequired: false,
    },
    consumedKm: {
      name: "consumedKm",
      label: "Consumed KM",
      value: "",
      topLabel: true,
      placeholder: "Enter consumed km",
      ref: useRef(),
      validPattern: "NUMERIC",
      type: "number",
      isValid: false,
      validationRequired: false,
    },
    unitValue: {
      name: "unitValue",
      label: "Unit Value",
      value: "",
      topLabel: true,
      isValid: true,
      errorMsg: "Please enter unit value",
      placeholder: "Enter unit value",
      helperText: "Please enter unit value",
      type: "number",
      ref: useRef(),
      validationRequired: false,
    },
    inventoryType: {
      name: "inventoryType",
      label: "Inventory Type",
      value: { label: "Tire", value: "Tire" },
      options: [
        {
          label: "Tire",
          value: "Tire",
        },
        {
          label: "Equipment",
          value: "Equipment",
        },
        {
          label: "Consumable",
          value: "Consumable",
        },
      ],
      validationRequired: true,
      isValid: true,
      errorMsg: "Please select user",
      validPattern: "ALPHABET_with_label",
      topLabel: true,
    },
    purchaseDate: {
      name: "purchaseDate",
      label: "Purchase Date",
      topLabel: true,
      validationRequired: true,
      validPattern: "DATE",
      errorMsg: "Please Select Purchase Date",
      isValid: true,
      allowClear: true,
      value: "",
      //  disabledDate: (d) => !d || d.isAfter(new Date()),
    },
    discountType: {
      name: "discountType",
      label: "Discount Type",
      topLabel: true,
      value: { label: "--Discount Type--", value: "" },
      options: [
        { label: "--Discount Type--", value: "" },
        { label: "Flat", value: "Flat" },
        { label: "Percentage", value: "Percentage" },
      ],
      validPattern: "ALPHA_NUMERIC_SPACE",
      isValid: false,
      validationRequired: false,
    },
    itemType: {
      name: "itemType",
      label: "Item Type",
      topLabel: true,
      value: "",
      options: [
        { label: "New", value: "New" },
        { label: "Used", value: "Used" },
      ],
      validPattern: "ALPHA_NUMERIC_SPACE",
      isValid: false,
      validationRequired: false,
    },
    consumableCategory: {
      name: "consumableCategory",
      label: "Consumable Category",
      topLabel: true,
      value: { label: "", value: "" },
      options: [{ label: "", value: "" }],
      validPattern: "ALPHA_NUMERIC_SPACE",
      allowClear: true,
      isValid: false,
      validationRequired: false,
    },
    consumableSubCategory: {
      name: "consumableSubCategory",
      label: "Consumable Sub-Category",
      topLabel: true,
      value: { label: "", value: "" },
      options: [{ label: "", value: "" }],
      allowClear: true,
      isValid: false,
      validationRequired: false,
    },
    unitType: {
      name: "unitType",
      label: "Unit Type",
      topLabel: true,
      value: { label: "", value: "" },
      options: [{ label: "", value: "" }],
      isValid: false,
      type: number,
      validPattern: "ALPHA_NUMERIC_SPACE",
      allowClear: true,
      validationRequired: false,
    },
    equipmentCategory: {
      name: "equipmentCategory",
      label: "Equipment Category",
      topLabel: true,
      value: { label: "", value: "" },
      options: [{ label: "", value: "" }],
      validPattern: "ALPHA_NUMERIC_SPACE",
      allowClear: true,
      isValid: false,
      validationRequired: false,
      // placeholder:"--Equipment Category--"
    },
    equipmentSubCategory: {
      name: "equipmentSubCategory",
      label: "Equipment Sub_Category",
      topLabel: true,
      value: { label: "", value: "" },
      options: [{ label: "", value: "" }],
      validPattern: "ALPHA_NUMERIC_SPACE",
      placeholder: "--Equipment Sub_Category--",
      allowClear: true,
      isValid: false,
      validationRequired: false,
    },
    discountAmount: {
      name: "discountAmount",
      label: "Discount Amount (-)",
      value: "",
      topLabel: true,
      placeholder: "Enter consumed km",
      ref: useRef(),
      type: "number",
      validPattern: "NUMERIC",
      isValid: false,
      validationRequired: false,
    },
    discountPercentage: {
      name: "discountPercentage",
      label: "Discount Percentage (-)",
      value: "",
      topLabel: true,
      placeholder: "Enter consumed km",
      ref: useRef(),
      type: "number",
      validPattern: "NUMERIC",
      isValid: false,
      validationRequired: false,
    },
  };
  Service = global.service;
  let validateFields = new ValidateFields();

  let util = new Util();
  let [formData, setFormData] = React.useState(_.cloneDeep(formFields));
  const [message, setMessage] = React.useState("");
  const [color, setmessageColor] = React.useState("");
  const [status, setStatus] = React.useState(false);
  const addInventoryOpen = () => {
    let promise1 = Service.getManuatures();
    let promise2 = Service.getEquipmentCategories();
    let promise3 = Service.getConsumables();
    let promise4 = Service.getUnitTypes();
    Promise.all([promise1, promise2, promise3, promise4]).then(
      async (response) => {
        let newField = _.cloneDeep(formData);

        newField["manfName"]["options"] = await response[0].data.map(
          (each) => ({
            label: each.name,
            value: each.id,
          })
        );
        newField["equipmentCategory"]["options"] = await response[1].data.map(
          (each) => ({ label: each.name, value: each.id })
        );
        newField["consumableCategory"]["options"] = await response[2].data.map(
          (each) => ({ label: each.name, value: each.id })
        );
        newField["unitType"]["options"] = await response[3].data.map(
          (each) => ({ label: each.unit_name, value: each.id })
        );
        newField["manfName"]["value"] = newField["manfName"]["options"][0];
        setFormData(newField);
      }
    );
    setAddInventoryDialog(true);
    closeAlert();
  };
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const [alert, setAlert] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const contentRef = useRef(null);
  const closeAlert = () => {
    let alert = _.cloneDeep(alertData);
    alert.open = false;
    alert.severity = "";
    alert.message = "";
    alert.data = "";
    setAlertData(alert);
    // window.location.reload();
  };
  const closeAlertMsg = () => {
    let error = _.cloneDeep(alert);
    error.open = false;
    error.severity = "";
    error.message = "";
    setAlert(error);
    // window.location.reload();
  };

  const forAddingInventoryDetails = async (formData) => {
    let discount;
    if (formData.discountType.value?.value == "Flat") {
      discount = formData.subtotal.value - formData.discountAmount.value;
      gstAmount = (discount * formData.gstPercentage.value) / 100;
    } else if (formData.discountType.value?.value == "Percentage") {
      discount =
        formData.subtotal.value -
        (formData.subtotal.value * formData.discountPercentage.value) / 100;
      gstAmount = (discount * formData.gstPercentage.value) / 100;
    } else {
      gstAmount =
        (formData.subtotal.value * formData.gstPercentage.value) / 100;
    }
    let dataStatus = await validateFields.validateFieldsData(formData);
    if (dataStatus.status) {
      let inventoryDataDetails = {
        invoiceNum: formData.invoiceNum.value,
        inventoryType: formData.inventoryType.value?.value,
        tireDepth: formData.tireDepth.value,
        manfName: formData.manfName.value?.value,
        equiCategory: formData.equipmentCategory.value?.value,
        equiSubCategory: formData.equipmentSubCategory.value?.value,
        consumableCategory: formData.consumableCategory.value?.value,
        consumableSubCategory: formData.consumableSubCategory.value?.value,
        unitType: formData.unitType.value?.value,
        unitValue: formData.unitValue.value,
        qrCode: formData.qrCode.value,
        serialNum: formData.serialNumber.value,
        modelNum: formData.modelNumber.value,
        name: formData.name.value,
        itemType: formData.itemType.value?.value,
        hsnCode: formData.hsnCode.value,
        quantity: formData.quantity.value,
        rate: formData.rate.value,
        subtotal: formData.subtotal.value,
        discountType: formData.discountType.value?.value,
        // discountValue: formData.discountType.value?.value == "Flat" && formData.discountAmount.value || formData.discountType.value?.value == "Percentage" && formData.discountPercentage.value,
        gstPercentage: formData.gstPercentage.value,
        purchaseTotal: formData.purchaseTotal.value,
        vendorName: formData.vendorName.value,
        vendorCode: formData.vendorCode.value,
        purchaseDate: util.formatDate(formData.purchaseDate.value),
        expLifeTimeYear: formData.expectedYears.value,
        expLifeTimeMonth: formData.expectedMonths.value,
        expLifeTimedays: formData.expectedDays.value,
        expectedKm: formData.expectedKm.value,
        conLifeTimeYear: formData.consumedYears.value,
        conLifeTimeMonth: formData.consumedMonths.value,
        conLifeTimedays: formData.consumedDays.value,
        consumedKm: formData.consumedKm.value,
        gstAmount: gstAmount,
        availableQuantity: formData.quantity.value,
        availableUnitValue: formData.unitValue.value
          ? formData.quantity.value * formData.unitValue.value
          : 0,
      };
      if (formData.discountType.value?.value == "Flat") {
        inventoryDataDetails.discountValue = formData.discountAmount.value;
      } else if (formData.discountType.value?.value == "Percentage") {
        inventoryDataDetails.discountValue = formData.discountPercentage.value;
      }
      let newObject = {};
      for (const key in inventoryDataDetails) {
        if (
          inventoryDataDetails.hasOwnProperty(key) &&
          inventoryDataDetails[key] !== ""
        ) {
          newObject[key] = inventoryDataDetails[key];
        }
      }
      Service.insertInventoryDetails(newObject).then((response) => {
        if (response.status == true) {
          setAlertData({
            open: true,
            message: response.message,
            severity: "success",
          });

          if (contentRef.current) {
            contentRef.current.scrollTop = 0;
          }

          setTimeout(() => {
            closeAlert();
            setAddInventoryDialog(false);
            setFormData(formFields);
            window.location.reload()
          }, 3000);
        } else {
          setAlertData({
            open: true,
            message: response.message,
            severity: "error",
          });

          if (contentRef.current) {
            contentRef.current.scrollTop = 0;
          }

          // setTimeout(() => {
          //   setAddInventoryDialog(false);
          // }, 4000);
        }
      });
    } else {
      let newFields = _.cloneDeep(formData);
      let erroredFields = Object.keys(dataStatus.data).filter(
        (key, i) => dataStatus.data[key].isValid === false
      );
      newFields = dataStatus.data;
      setFormData(newFields);
    }

    setFormData(formData);
  };
  const addInventoryClose = () => {
    setAddInventoryDialog(false);
    setFormData(formFields);
  };
  const decimalValidation = (value) => {
    const regex = new RegExp("^[0-9]+(.[0-9]{1,3})?$");
    return regex.test(value);
  };
  const otherThenalphaNumericValidation = (value) => {
    const regex = new RegExp("^[a-zA-Z0-9@]+(.)?[a-z]*$");
    return regex.test(value);
  };
  const inputChangeHandler = (value, name) => {
    if(name == 'quantity'){
      if (
        decimalValidation(value) === true ||
        value === "" ||
        otherThenalphaNumericValidation(value) === true
      ) {
        formData[name]["value"] = value;
      }
    }else{
      formData[name]["value"] = value;
    }
    
    if (value.length > 0 && formData[name]["validationRequired"]) {
      formData[name]["isValid"] = true;
    }
    if (name == "quantity" || name == "rate") {
      formData["subtotal"]["value"] =
        formData["quantity"]["value"] * formData["rate"]["value"];
      formData["purchaseTotal"]["value"] =
        formData["quantity"]["value"] * formData["rate"]["value"];
    }
    if (formData["discountType"]["value"]["value"] == "") {
      formData["purchaseTotal"]["value"] =
        (formData["subtotal"]["value"] * formData["gstPercentage"]["value"]) /
          100 +
        formData["subtotal"]["value"];
    }
    if (name == "discountAmount") {
      let discountValue =
        formData["subtotal"]["value"] - formData["discountAmount"]["value"];
      formData["purchaseTotal"]["value"] =
        (discountValue * formData["gstPercentage"]["value"]) / 100 +
        discountValue;
    }
    if (name == "gstPercentage") {
      if (formData["discountType"]["value"]["value"] == "Flat") {
        let discountAmount =
          formData["subtotal"]["value"] - formData["discountAmount"]["value"];
        formData["purchaseTotal"]["value"] =
          discountAmount +
          (discountAmount * formData["gstPercentage"]["value"]) / 100;
      } else if (formData["discountType"]["value"]["value"] == "Percentage") {
        let discountPer =
          formData["subtotal"]["value"] -
          (formData["subtotal"]["value"] *
            formData["discountPercentage"]["value"]) /
            100;
        let gstPer = discountPer + (discountPer * value) / 100;
        formData["purchaseTotal"]["value"] = gstPer;
        if (value == "") {
          formData["purchaseTotal"]["value"] =
            formData["subtotal"]["value"] -
            (formData["subtotal"]["value"] *
              formData["discountPercentage"]["value"]) /
              100;
        }
      } else {
        formData["purchaseTotal"]["value"] =
          (formData["subtotal"]["value"] * value) / 100 +
          formData["subtotal"]["value"];
      }
    }
    if (name == "discountPercentage") {
      let discountPer =
        formData["subtotal"]["value"] -
        (formData["subtotal"]["value"] *
          formData["discountPercentage"]["value"]) /
          100;
      let gstPer =
        discountPer + (discountPer * formData["gstPercentage"]["value"]) / 100;
      formData["purchaseTotal"]["value"] = gstPer;

      // if(value == ""){
      //   formData['purchaseTotal']['value'] = formData['subtotal']['value'] * formData['gstPercentage']['value']/100 + formData['subtotal']['value']
      // }else{
      // let purchaseValue= formData['subtotal']['value'] * formData['gstPercentage']['value'] /100 + formData['subtotal']['value']
      // purchaseTotal = formData['subtotal']['value'] - formData['subtotal']['value'] /100 * value
      // formData['purchaseTotal']['value'] = purchaseTotal

      // formData['purchaseTotal']['value'] = formData['subtotal']['value'] - formData['subtotal']['value'] * value/100
      // }
    }
    setFormData(_.cloneDeep(formData));
  };
  const autoCompleteChangeHandler = (value, name) => {
    formData[name]["value"] = { label: value?.value, value: value?.value };
    setFormData(_.cloneDeep(formData));
    if (name == "inventoryType") {
      formData[name]["value"] = { label: value?.value, value: value?.value };
      if (value?.value == "Tire") {
        formData["manfName"]["value"] = formData["manfName"]["options"][0];
        formData["equipmentCategory"]["value"]["value"] = "";
        formData["equipmentSubCategory"]["value"]["value"] = "";
        formData["consumableCategory"]["value"]["value"] = "";
        formData["consumableSubCategory"]["value"]["value"] = "";
        formData["unitType"]["value"]["value"] = "";
        formData["unitValue"]["value"] = "";
      } else if (value?.value == "Equipment") {
        formData["tireDepth"]["value"] = "";
        formData["manfName"]["value"] = "";
        formData["consumableCategory"]["value"] = "";
        formData["consumableSubCategory"]["value"] = "";
        formData["unitType"]["value"]["value"] = "";
        formData["unitValue"]["value"] = "";
      } else {
        formData["tireDepth"]["value"] = "";
        formData["manfName"]["value"] = "";
        formData["equipmentCategory"]["value"] = "";
        formData["equipmentSubCategory"]["value"] = "";
        formData.unitValue.validationRequired = true;
        formData.unitValue.isValid = true;
        formData.unitValue.validPattern = "NUMERIC";
        formData.unitValue = {
          name: "unitValue",
          label: "Unit Value",
          value: "",
          validationRequired: true,
          isValid: true,
          topLabel: true,
          errorMsg: "Please enter unit value",
          placeholder: "Enter unit value",
          helperText: "Please enter unit value",
          type: "number",
          validPattern: "NUMERIC",
        };
      }
      setFormData(_.cloneDeep(formData));
    }
    if (name == "discountType") {
      formData[name]["value"] = { label: name, value: value?.value };
      formData["purchaseTotal"]["value"] =
        (formData["subtotal"]["value"] * formData["gstPercentage"]["value"]) /
          100 +
        formData["subtotal"]["value"];
      setFormData(_.cloneDeep(formData));
    }
  };
  const timeStampChangeHandler = (value, name) => {
    formData[name]["value"] = value;
    formData[name]["isValid"] = true;
    setFormData(_.cloneDeep(formData));
  };

  React.useEffect(async () => {
   
  }, []);

  const forInventoryTypeTire = (formData) => {
    const inputChangeHandler = (value, name) => {
      formData[name]["value"] = value;
      if (value.length > 0 && formData[name]["validationRequired"]) {
        formData[name]["isValid"] = true;
      }
      setFormData(_.cloneDeep(formData));
    };
    const autoCompleteChangeHandler = (value, name) => {
      formData[name]["value"] = { label: name, value: value?.value };
      setFormData(_.cloneDeep(formData));
    };
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <Fields.InputField
              fieldData={formData.tireDepth}
              variant="outlined"
              inputChangeHandler={inputChangeHandler}
              type="number"
              inputProps={{ step: "0.01", pattern: "[0-9]*[.,]?[0-9]*" }}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Fields.AntSelectableSearchField
              fieldData={formData.manfName}
              autoCompleteChangeHandler={autoCompleteChangeHandler}
            />
          </Grid>
        </Grid>
      </>
    );
  };
  const forInventoryTypeEquipment = (formData) => {
    const autoCompleteChangeHandler = (value, name) => {
      formData[name]["value"] = { label: name, value: value?.value };
      setFormData(_.cloneDeep(formData));
      if (formData["equipmentCategory"]["value"] !== "") {
        Service.getEquipmentCategories(value.value).then((response) => {
          formData["equipmentSubCategory"]["options"] = response.data.map(
            (each) => ({ label: each.name, value: each.id })
          );
          setFormData(_.cloneDeep(formData));
        });
      }
    };
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={6} md={3}>
            <Fields.AntSelectableSearchField
              fieldData={formData.equipmentCategory}
              autoCompleteChangeHandler={autoCompleteChangeHandler}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <Fields.AntSelectableSearchField
              fieldData={formData.equipmentSubCategory}
              autoCompleteChangeHandler={autoCompleteChangeHandler}
            />
          </Grid>
        </Grid>
      </>
    );
  };
  const forInventoryTypeConsumable = (formData) => {
    const inputChangeHandler = (value, name) => {
      formData[name]["value"] = value;
      setFormData(_.cloneDeep(formData));
    };
    const autoCompleteChangeHandler = (value, name) => {
      formData[name]["value"] = { label: name, value: value?.value };
      if (formData["consumableCategory"]["value"] != "") {
        Service.getConsumables(value.value).then((response) => {
          formData["consumableSubCategory"]["options"] = response.data.map(
            (each) => ({ label: each.name, value: each.id })
          );
          setFormData(formData);
        });
      }
      setFormData(_.cloneDeep(formData));
    };
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={3} md={3}>
            <Fields.AntSelectableSearchField
              fieldData={formData.consumableCategory}
              autoCompleteChangeHandler={autoCompleteChangeHandler}
            />
          </Grid>
          <Grid item xs={3} md={3}>
            <Fields.AntSelectableSearchField
              fieldData={formData.consumableSubCategory}
              autoCompleteChangeHandler={autoCompleteChangeHandler}
            />
          </Grid>
          <Grid item xs={3} md={3}>
            <Fields.AntSelectableSearchField
              fieldData={formData.unitType}
              autoCompleteChangeHandler={autoCompleteChangeHandler}
            />
          </Grid>
          <Grid item xs={3} md={3}>
            <Fields.InputField
              fieldData={formData.unitValue}
              variant="outlined"
              inputChangeHandler={inputChangeHandler}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={addInventoryOpen}
        // style={{
        //   textTransform: "none",
        //   backgroundColor: "#649B42",
        //   padding: "8px 15px",
        //   "&:hover": {
        //     backgroundColor: "#649B42",
        //   },
        //   color: "#fff",
        //   fontSize: "18px",
        // }}
        style={{
          textTransform: "none",
          backgroundColor: "#649B42",
          padding: "8px 15px",
          "&:hover": {
            backgroundColor: "#649B42",
          },
          color: "#fff",
        }}
        startIcon={<AddCircleOutlinedIcon />}
      >
        Add Inventory
      </Button>
      <Dialog
        open={addInventoryDialog}
        onClose={addInventoryClose}
        fullWidth
        maxWidth="xl"
        PaperProps={{
          style: {
            width: "95%",
            margin: 0,
          },
        }}
      >
        <DialogTitle>
          <Grid container justifyContent="center">
            <Grid item xs={11.8} align="center">
              ADD INVENTORY
            </Grid>
            <Grid item xs={0.2} container justifyContent="flex-end">
              <CloseIcon color="error" onClick={addInventoryClose} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent ref={contentRef} dividers>
          {alertData.open ? (
            <div style={{ padding: "10px 12px" }}>
              <AlertMessage
                severity={alertData.severity}
                message={alertData.message}
                closeAlert={closeAlert}
              />
            </div>
          ) : (
            <div style={{ padding: "10px 12px" }}>
              <AlertMessage
                severity={alert.severity}
                message={alert.message}
                closeAlert={closeAlertMsg}
              />
            </div>
          )}
          <DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Fields.InputField
                  fieldData={formData.invoiceNum}
                  variant="outlined"
                  inputChangeHandler={inputChangeHandler}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <Fields.AntSelectableSearchField
                  fieldData={formData.inventoryType}
                  autoCompleteChangeHandler={autoCompleteChangeHandler}
                />
              </Grid>
            </Grid>
            {formData["inventoryType"]["value"].value == "Tire"
              ? forInventoryTypeTire(formData)
              : "" || formData["inventoryType"]["value"]?.value == "Equipment"
              ? forInventoryTypeEquipment(formData)
              : "" || formData["inventoryType"]["value"]?.value == "Consumable"
              ? forInventoryTypeConsumable(formData)
              : ""}
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Fields.InputField
                  fieldData={formData.qrCode}
                  variant="outlined"
                  inputChangeHandler={inputChangeHandler}
                />
              </Grid>
              <Grid item xs={3} md={3}>
                <Fields.InputField
                  fieldData={formData.serialNumber}
                  variant="outlined"
                  inputChangeHandler={inputChangeHandler}
                />
              </Grid>
              <Grid item xs={3} md={3}>
                <Fields.InputField
                  fieldData={formData.modelNumber}
                  variant="outlined"
                  inputChangeHandler={inputChangeHandler}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Fields.InputField
                  fieldData={formData.name}
                  variant="outlined"
                  inputChangeHandler={inputChangeHandler}
                />
              </Grid>
              <Grid item xs={3} md={3}>
                <Fields.AntSelectableSearchField
                  fieldData={formData.itemType}
                  autoCompleteChangeHandler={autoCompleteChangeHandler}
                />
              </Grid>
              <Grid item xs={3} md={3}>
                <Fields.InputField
                  fieldData={formData.hsnCode}
                  variant="outlined"
                  inputChangeHandler={inputChangeHandler}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4} md={3}>
                <Fields.InputField
                  fieldData={formData.quantity}
                  variant="outlined"
                  inputChangeHandler={inputChangeHandler}
                />
              </Grid>
              <Grid item xs={4} md={3}>
                <Fields.InputField
                  fieldData={formData.rate}
                  variant="outlined"
                  inputChangeHandler={inputChangeHandler}
                />
              </Grid>
              <Grid item xs={4} md={3}>
                <Fields.InputField
                  fieldData={formData.subtotal}
                  variant="outlined"
                  inputChangeHandler={inputChangeHandler}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4} md={3}>
                <Fields.AntSelectableSearchField
                  fieldData={formData.discountType}
                  autoCompleteChangeHandler={autoCompleteChangeHandler}
                />
              </Grid>
              {(formData["discountType"]["value"]["value"] == "Flat" && (
                <Grid item xs={4} md={3}>
                  {" "}
                  <Fields.InputField
                    fieldData={formData.discountAmount}
                    variant="outlined"
                    inputChangeHandler={inputChangeHandler}
                  />{" "}
                </Grid>
              )) ||
                (formData["discountType"]["value"]["value"] == "Percentage" && (
                  <Grid item xs={4} md={3}>
                    {" "}
                    <Fields.InputField
                      fieldData={formData.discountPercentage}
                      variant="outlined"
                      inputChangeHandler={inputChangeHandler}
                    />{" "}
                  </Grid>
                ))}
              <Grid item xs={4} md={3}>
                <Fields.InputField
                  fieldData={formData.gstPercentage}
                  variant="outlined"
                  inputChangeHandler={inputChangeHandler}
                />
              </Grid>
              <Grid item xs={4} md={3}>
                <Fields.InputField
                  fieldData={formData.purchaseTotal}
                  variant="outlined"
                  inputChangeHandler={inputChangeHandler}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4} md={3}>
                <Fields.InputField
                  fieldData={formData.vendorName}
                  variant="outlined"
                  inputChangeHandler={inputChangeHandler}
                />
              </Grid>
              <Grid item xs={4} md={3}>
                <Fields.InputField
                  fieldData={formData.vendorCode}
                  variant="outlined"
                  inputChangeHandler={inputChangeHandler}
                />
              </Grid>
              <Grid item xs={4} md={3}>
                <Fields.AntdDatePickerField
                  fieldData={formData.purchaseDate}
                  timeStampChangeHandler={timeStampChangeHandler}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3} md={3}>
                <Fields.InputField
                  fieldData={formData.expectedYears}
                  variant="outlined"
                  inputChangeHandler={inputChangeHandler}
                />
              </Grid>
              <Grid item xs={3} md={3}>
                <Typography>&nbsp;</Typography>
                <Fields.InputField
                  fieldData={formData.expectedMonths}
                  variant="outlined"
                  inputChangeHandler={inputChangeHandler}
                />
              </Grid>
              <Grid item xs={3} md={3}>
                <Typography>&nbsp;</Typography>
                <Fields.InputField
                  fieldData={formData.expectedDays}
                  variant="outlined"
                  inputChangeHandler={inputChangeHandler}
                />
              </Grid>
              <Grid item xs={3} md={3}>
                <Fields.InputField
                  fieldData={formData.expectedKm}
                  variant="outlined"
                  inputChangeHandler={inputChangeHandler}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3} md={3}>
                <Fields.InputField
                  fieldData={formData.consumedYears}
                  variant="outlined"
                  inputChangeHandler={inputChangeHandler}
                />
              </Grid>
              <Grid item xs={3} md={3}>
                <Typography>&nbsp;</Typography>
                <Fields.InputField
                  fieldData={formData.consumedMonths}
                  variant="outlined"
                  inputChangeHandler={inputChangeHandler}
                />
              </Grid>
              <Grid item xs={3} md={3}>
                <Typography>&nbsp;</Typography>
                <Fields.InputField
                  fieldData={formData.consumedDays}
                  variant="outlined"
                  inputChangeHandler={inputChangeHandler}
                />
              </Grid>
              <Grid item xs={3} md={3}>
                <Fields.InputField
                  fieldData={formData.consumedKm}
                  variant="outlined"
                  inputChangeHandler={inputChangeHandler}
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions align="start">
          <Button onClick={addInventoryClose} variant="contained">
            Close
          </Button>
          <Button
            onClick={() => forAddingInventoryDetails(formData)}
            variant="contained"
          >
            Add Inventory
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
