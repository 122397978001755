import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const AlertMessage = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
        {props?.message && props?.message !== '' ? <Alert severity={props.severity} onClose={() => { props.closeAlert() }}>
          {props?.message && props?.message}
        </Alert> : null}
    </div>
  );
};

export default AlertMessage;
