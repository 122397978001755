import React, { useState, useRef, useEffect } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { makeStyles } from "@material-ui/core";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
// import Menu from '@material-ui/core/Menu';
import MenuItem from "@mui/material/MenuItem";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  more_icon: {
    cursor: "pointer",
  },
}));

const More = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const handleToggle = (e) => {
    setAnchorEl(e.currentTarget);
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleMenuItemClick = (option) => {
    if(option.value){
      history.push({ pathname: option.value, state: props.data })
    }
    if(option.type === "button") {
      props.buttonClickHandler(option);
    }
  };

  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div>
      <MoreVertIcon
        ref={anchorRef}
        className={classes.more_icon}
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      />
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                >
                  {props.options.map((option, i) => (
                    <MenuItem onClick={() => { handleMenuItemClick(option) }} key={i}>{option.label}</MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
              {/* <Menu
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onClose={handleClose}
                  open={open}
                  anchorEl={anchorEl}
                  anchorReference="anchorEl"
                >
                  {props.options.map((option, i) => (
                    <MenuItem onClick={() => {handleMenuItemClick(option)}} key={i}>{option.label}</MenuItem>
                  ))}
                </Menu> */}
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default More;
