import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import _ from "lodash";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import Typography from '@mui/material/Typography';
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import * as CONFIG from "../../config/GlobalConstants";
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import Header from "../../components/header";
import AlertMessage from "../../components/alertmessage/AlertMessage";
import { Typography, Button, Avatar, Card } from "@material-ui/core";
import * as Fields from "../../sharedComponents";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import { CSVLink } from "react-csv";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid } from "@mui/material";
const useStyles = makeStyles((theme) => ({
  search_button: {
    padding: "",
    backgroundColor: "#366e93",
    color: "#ffffff",
    textTransform: "capitalize",
    marginRight: "20px",
    "&:hover": {
      backgroundColor: "#366e93",
    },
  },
  export_button: {
    padding: "7px",
    marginTop: "13px",
    marginRight: "15px",
    backgroundColor: "#366e93",
    color: "#ffffff",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "#366e93",
    },
  },
}));

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: "2020-01-05",
        customerId: "11091700",
        amount: 3,
      },
      {
        date: "2020-01-02",
        customerId: "Anonymous",
        amount: 1,
      },
    ],
  };
}

function RowForTrasactions(props) {
  let Service = global.service;
  const { row, index,vehicles } = props;
  console.log("row.......", row);
  const [open, setOpen] = React.useState(false);
  let columns = [
    { id: "qrCode", disablePadding: true, label: "QR CODE", checked: true },
    {
      id: "serialNum",
      disablePadding: true,
      label: "SERIAL NUMBER",
      checked: true,
    },
    { id: "category", label: "CATEGORY", disablePadding: true, checked: true },
    { id: "name", disablePadding: true, label: "NAME", checked: true },
    { id: "quantity", disablePadding: true, label: "QUANTITY", checked: true },
    {
      id: "truckNum",
      disablePadding: true,
      label: "TRUCK NUMBER",
      checked: true,
    },
  ];
  if (row.inventory_type == "Tire") {
    let newColumns = [
      {
        id: "tireType",
        disablePadding: true,
        label: "TIRE TYPE",
        checked: true,
      },
      {
        id: "tirePostion",
        disablePadding: true,
        label: "TIRE POSITION",
        checked: true,
      },
      {
        id: "odometer",
        disablePadding: true,
        label: "ODOMETER",
        checked: true,
      },
      {
        id: "location",
        disablePadding: true,
        label: "LOCATION",
        checked: true,
      },
    ];
    columns.push(...newColumns);
  } else {
    columns.push({
      id: "location",
      disablePadding: true,
      label: "LOCATION",
      checked: true,
    });
  }
  let formObjectFields = {
    inventory: {
      name: "inventory",
      value: "",
      isValid: true,
      placeholder: "Enter qr code or sr. number",
      // ref: useRef(),
      topLabel: true,
      validationRequired: true,
      errorMsg: "Please enter qr code or sr. number",
      validPattern: "SPECIAL_CHARS_DESC",
      isDisabled: true,
    },
    truckNumber: {
      name: "truckNumber",
      value: { label: "", value: "" },
      // isValid: true,
      options: [],
      // ref: useRef(),
      topLabel: true,
      // validationRequired: true,
      // errorMsg: "Please select truck",
      // validPattern: "SPECIAL_CHARS_DESC",
      // maxLength: 10,
    },
    tirePosition: {
      name: "tirePosition",
      value: "",
      isValid: true,
      placeholder: "Enter tire position",
      // ref: useRef(),
      topLabel: true,
      validationRequired: true,
      errorMsg: "Please enter tire position",
      validPattern: "SPECIAL_CHARS_DESC",
    },
    odometer: {
      name: "odometer",
      value: "",
      isValid: true,
      placeholder: "Enter odometer",
      // ref: useRef(),
      topLabel: true,
      validationRequired: true,
      errorMsg: "Please enter odometer",
      validPattern: "SPECIAL_CHARS_DESC",
    },
    location: {
      name: "location",
      value: "",
      isValid: true,
      placeholder: "Enter location",
      // ref: useRef(),
      topLabel: true,
      validationRequired: true,
      errorMsg: "Please enter location",
      validPattern: "SPECIAL_CHARS_DESC",
    },
    tireType: {
      name: "tireType",
      value: { label: "Re-tread", value: "Re-tread" },
      isValid: true,
      options: [
        { label: "Re-tread", value: "Re-tread" },
        { label: "Seal", value: "Seal" },
      ],
      // ref: useRef(),
      topLabel: true,
    },
  };

  const [formObjectData, setFormObjectData] = useState(formObjectFields);
  let formFieldsForEquipment = {
    inventory: {
      name: "inventory",
      value: "",
      isValid: true,
      placeholder: "Enter qr code or sr. number",
      ref: useRef(),
      topLabel: true,
      validationRequired: true,
      errorMsg: "Please enter qr code or sr. number",
      validPattern: "SPECIAL_CHARS_DESC",
      isDisabled: true,
    },
    truckNumber: {
      name: "truckNumber",
      value: { label: "", value: "" },
      // isValid: true,
      options: [],
      // ref: useRef(),
      topLabel: true,
      // validationRequired: true,
      // errorMsg: "Please select truck",
      // validPattern: "SPECIAL_CHARS_DESC",
      // maxLength: 10,
    },
    equipment: {
      name: "equipment",
      value: "",
      isValid: true,
      placeholder: "Enter equipment",
      ref: useRef(),
      topLabel: true,
      validationRequired: true,
      errorMsg: "Please enter equipment",
      validPattern: "SPECIAL_CHARS_DESC",
      isDisabled : true
    },
    location: {
      name: "location",
      value: "",
      isValid: true,
      placeholder: "Enter location",
      ref: useRef(),
      topLabel: true,
      validationRequired: true,
      errorMsg: "Please enter location",
      validPattern: "SPECIAL_CHARS_DESC",
    },
    quantity: {
      name: "quantity",
      value: "",
      isValid: true,
      placeholder: "Enter quantity",
      ref: useRef(),
      topLabel: true,
      validationRequired: true,
      errorMsg: "Please enter quantity",
      validPattern: "SPECIAL_CHARS_DESC",
    },
  };

  const [formEquipmentData, setformEquipmentData] = useState(
    formFieldsForEquipment
  );
  let formFieldsForConsumable = {
    inventory: {
      name: "inventory",
      value: "",
      isValid: true,
      placeholder: "Enter qr code or sr. number",
      ref: useRef(),
      topLabel: true,
      validationRequired: true,
      errorMsg: "Please enter qr code or sr. number",
      validPattern: "SPECIAL_CHARS_DESC",
      isDisabled: true,
    },
    truckNumber: {
      name: "truckNumber",
      value: { label: "", value: "" },
      // isValid: true,
      options: [],
      // ref: useRef(),
      topLabel: true,
      // validationRequired: true,
      // errorMsg: "Please select truck",
      // validPattern: "SPECIAL_CHARS_DESC",
      // maxLength: 10,
    },
    name: {
      name: "name",
      value: "",
      isValid: true,
      placeholder: "Enter name",
      ref: useRef(),
      topLabel: true,
      validationRequired: true,
      errorMsg: "Please enter name",
      validPattern: "SPECIAL_CHARS_DESC",
    },
    location: {
      name: "location",
      value: "",
      isValid: true,
      placeholder: "Enter location",
      ref: useRef(),
      topLabel: true,
      validationRequired: true,
      errorMsg: "Please enter location",
      validPattern: "SPECIAL_CHARS_DESC",
    },
    quantity: {
      name: "quantity",
      value: "",
      isValid: true,
      placeholder: "Enter quantity",
      ref: useRef(),
      topLabel: true,
      validationRequired: true,
      errorMsg: "Please enter quantity",
      validPattern: "SPECIAL_CHARS_DESC",
    },
    type: {
      name: "type",
      value: { label: "Quantity", value: "quantity" },
      isValid: true,
      options: [
        { label: "Quantity", value: "Quantity" },
        { label: "Parcial Consumption", value: "parcial_consumption" },
      ],
      ref: useRef(),
      topLabel: true,
    },
  };

  const [formConsumableData, setConsumableData] = useState(
    formFieldsForConsumable
  );
  let [insideColumns, setInsideColumns] = React.useState(columns);
  const [openDialog, setOpenDialog] = useState(false);
  const handleClose = () => {
    setOpenDialog(false);
  };
  const checkinFields = {
    inventoryType: {
      name: "inventoryType",
      label: "Inventory Type",
      value: { label: "", value: "" },
      options: [],
      validationRequired: true,
      isValid: true,
      errorMsg: "Please select user",
      validPattern: "ALPHABET_with_label",
      topLabel: true,
      isDisabled: true,
    },
    name: {
      name: "name",
      label: "Name",
      value: "",
      isValid: true,
      placeholder: "Enter name",
      // ref: useRef(),
      topLabel: true,
      validationRequired: true,
      errorMsg: "Please enter name",
      validPattern: "SPECIAL_CHARS_DESC",
    },
    status: {
      name: "status",
      label: "Status",
      topLabel: true,
      value: { label: "", value: "" },
      options: [],
      isDisabled: true,
    },
    contactNumber: {
      name: "contactNumber",
      label: "Contact Number",
      validationRequired: true,
      isValid: true,
      topLabel: true,
      value: "",
      errorMsg: "Please contact number",
      placeholder: "contact number",
      helperText: "Please contact number",
      validPattern: "SPECIAL_CHARS_DESC",
    },
  };
  const [checkinFormData, setCheckinFormData] = useState(checkinFields);
  const autoCompleteChangeHandler = (value, name) => {
    let newField = _.cloneDeep(checkinFormData);
    newField[name]["value"] = value;
    setCheckinFormData(newField);
  };
  const inputChangeHandler = (value, name) => {
    if (value.length > 0 && checkinFormData[name]["validationRequired"]) {
      checkinFormData[name]["isValid"] = true;
    }

    checkinFormData[name]["value"] = value;
    setCheckinFormData(_.cloneDeep(checkinFormData));
  };

  const checkinFunction = (rowData) => {
   
    checkinFormData.status.options = [];
    checkinFormData.inventoryType.options = [];
    checkinFormData.inventoryType.value.value = rowData.inventory_type;
    checkinFormData.inventoryType.value.label = rowData.inventory_type;
    checkinFormData.inventoryType.options.push({
      label: rowData.inventory_type,
      value: rowData.inventory_type,
    });
    checkinFormData.status.value.value = rowData.action_status;
    checkinFormData.status.value.label = rowData.action_status;
    checkinFormData.status.options.push({
      label: rowData.action_status,
      value: rowData.action_status,
    });
    checkinFormData.name.value = rowData.name;
    checkinFormData.contactNumber.value = rowData?.contact_number;
    if (rowData.inventory_type == "Tire") {
      formObjectData.truckNumber.options = vehicles
      formObjectData.inventory.value = rowData?.qrcode;
      formObjectData.truckNumber.value.value = rowData?.regi_no;
      formObjectData.location.value = rowData?.location;
      formObjectData.tirePosition.value = rowData?.position;
      formObjectData.odometer.value = rowData?.odometer;
      formObjectData.tireType.value.value = rowData?.tire_type;
    } else if (rowData.inventory_type == "Equipment") {
      formEquipmentData.truckNumber.options = vehicles
      formEquipmentData.inventory.value = rowData?.qrcode;
      formEquipmentData.truckNumber.value.value = rowData?.regi_no;
      formEquipmentData.equipment.value = rowData?.name;
      formEquipmentData.quantity.value = rowData?.quantity;
      formEquipmentData.location.value = rowData?.location;
    } else {
      formConsumableData.truckNumber.options = vehicles
      formConsumableData.inventory.value = rowData?.qrcode;
      formConsumableData.truckNumber.value.value = rowData?.regi_no;
      formConsumableData.name.value = rowData?.name;
      formConsumableData.quantity.value = rowData?.quantity;
      formConsumableData.location.value = rowData?.location;
      formConsumableData.type.value.value = rowData?.quantity_type;
    }
    
      setOpenDialog(true);
    
    
  };

  const inputChangeHandlerForTire = (value, name) => {
    let newFields = _.cloneDeep(formObjectData);
    if (value.length > 0 && newFields[name]["validationRequired"]) {
      newFields[name]["isValid"] = true;
    }

    newFields[name]["value"] = value;
    setFormObjectData(newFields);
  };

  const autoCompleteChangeHandlerForTire = (value, name) => {
    formObjectData[name]["value"].value = value?.value;
    setFormObjectData(_.cloneDeep(formObjectData));
  };
  const autoCompleteChangeHandlerForEquipment = (value, name) => {
    formEquipmentData[name]["value"].value = value?.value;
    setFormObjectData(_.cloneDeep(formObjectData));
  };
  const inputChangeHandlerForEquipment = (value, name) => {
    if (value.length > 0 && formEquipmentData[name]["validationRequired"]) {
      formEquipmentData[name]["isValid"] = true;
    }

    formEquipmentData[name]["value"] = value;
    setformEquipmentData(_.cloneDeep(formEquipmentData));
  };

  const inputChangeHandlerForConsumable = (value, name) => {
    if (value.length > 0 && formConsumableData[name]["validationRequired"]) {
      formConsumableData[name]["isValid"] = true;
    }

    formConsumableData[name]["value"] = value;
    setConsumableData(_.cloneDeep(formConsumableData));
  };
  const autoCompleteChangeHandlerForConsumable = (value, name) => {
    formConsumableData[name]["value"].value = value?.value;
    setConsumableData(_.cloneDeep(formConsumableData));
  };

  const checkInVehicle = () => {
    let tireCheckin = {
      inventory_type: row.inventory_type,
      status: row.action_status,
      name: checkinFormData.name.value,
      contact_number: checkinFormData.contactNumber.value,
      transaction_id: row.transactionId
    }
    if (row.inventory_type == "Tire") {
      tireCheckin['inventory'] =  [
          {
            inventoryId: 1,
            truckId: row.truck_id,
            tirePosition: row.position,
            odometer: row.odometer,
            tireType: row.tire_type,
            location: row.location,
            // quantity: row.quantity,
          },
          {
            inventoryId: 11,
            truckId: formObjectData.truckNumber.value.value,
            tirePosition: formObjectData.tirePosition.value,
            odometer: formObjectData.odometer.value,
            tireType: formObjectData.tireType.value.value,
            location: formObjectData.location.value,
            // quantity: 50,
          },
        ]   
    }else if(row.inventory_type == "Equipment"){
      tireCheckin['inventory'] = [
          {
            inventoryId: 1,
            truckId: row.truck_id,
            location: row.location,
            quantity: row.quantity,
          },
          {
            inventoryId: 11,
            truckId: formEquipmentData.truckNumber.value.value,
            quantity: formEquipmentData.quantity.value,
            location: formEquipmentData.location.value,
          },
        ]
      }else{
        tireCheckin['inventory'] = [
          {
            inventoryId: 1,
            truckId: row.truck_id,
            location: row.location,
            quantity: row.quantity,
          },
          {
            inventoryId: 11,
            truckId: formConsumableData.truckNumber.value.value,
            quantity: formConsumableData.quantity.value,
            location: formConsumableData.location.value,
          },
        ]
      }
    console.log('tireCheckin.............',tireCheckin)
  };
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          {/* <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton> */}
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <RemoveCircleOutlineIcon color="error" align="center" />
            ) : (
              <ControlPointIcon color="primary" align="center" />
            )}
          </IconButton>
        </TableCell>
        {/* <TableCell align="center" component="th" scope="row">
          {row.name}
        </TableCell> */}
        <TableCell align="center">{index + 1}</TableCell>
        <TableCell align="center">
          {row?.status ? row.status : "--"}{" "}
          <span
            style={{
              backgroundColor:
                row.status == "Checkout"
                  ? "#FD5D93"
                  : row?.action_status && "#00BF9A",
              padding: 5,
              borderRadius: 5,
              color: "white",
              fontSize: "11px",
            }}
          >
            {row?.action_status && row?.action_status.toUpperCase()}
          </span>
        </TableCell>
        <TableCell align="center">
          {row?.inventory_type ? row?.inventory_type : "--"}
        </TableCell>
        <TableCell align="center">
          {row?.deliveryName ? row?.deliveryName : "--"}
        </TableCell>
        <TableCell align="center">
          {row?.contact_number ? row?.contact_number : "--"}
        </TableCell>

        <TableCell align="center">{row?.date ? row?.date : "--"}</TableCell>
        <TableCell align="center">
          Owner{" "}
          <span
            style={{
              backgroundColor: "#FD5D93",
              padding: 5,
              borderRadius: 5,
              color: "white",
              fontSize: "11px",
            }}
          >
            {row?.ownerName ? row?.ownerName : "--"}
          </span>
        </TableCell>
        {row.status !== "Check-in" && (
          <TableCell align="center">
            <DocumentScannerIcon style={{ cursor: "pointer" }} />
            <CheckBoxIcon
              style={{ cursor: "pointer" }}
              onClick={() => checkinFunction(row)}
            />
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {/* <Typography variant="h6" gutterBottom component="div">
                History
              </Typography> */}
              <Table size="small" aria-label="purchases">
                <TableHead bgColor="#F4F4F5">
                  <TableRow>
                    {insideColumns.map((each) => {
                      return (
                        <TableCell align="center" fontWeight="500">
                          {each.label}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableCell align="center" component="th" scope="row">
                    {row.qrcode}
                  </TableCell>
                  <TableCell align="center">{row.serial_number}</TableCell>
                  <TableCell align="center">{row.inventory_type}</TableCell>
                  <TableCell align="center">{row.name}</TableCell>
                  <TableCell align="center">{row.quantity}</TableCell>
                  <TableCell align="center">{row.regi_no}</TableCell>
                  {row.inventory_type == "Tire" ? (
                    <>
                      {" "}
                      <TableCell align="center">{row.tire_type}</TableCell>
                      <TableCell align="center">{row.position}</TableCell>
                      <TableCell align="center">{row.odometer}</TableCell>
                    </>
                  ) : null}
                  <TableCell align="center">{row.location}</TableCell>
                  {/* {row.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      <TableCell component="th" scope="row">
                        {historyRow.date}
                      </TableCell>
                      <TableCell>{historyRow.customerId}</TableCell>
                      <TableCell align="center">{historyRow.amount}</TableCell>
                      <TableCell align="center">
                        {Math.round(historyRow.amount * row.price * 100) / 100}
                      </TableCell>
                    </TableRow>
                  ))} */}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        fullWidth
        maxWidth="xl"
        PaperProps={{
          style: {
            width: "95%",
            margin: 0,
          },
        }}
      >
        <DialogTitle>
          <Grid container justifyContent="center">
            <Grid item xs={11.8} align="center">
              CHECK-IN
            </Grid>
            <Grid item xs={0.2} container justifyContent="flex-end">
              <CloseIcon color="error" onClick={handleClose} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Box bgcolor="primary">
                  <Typography variant="h5">INVENTORY DETAILS :</Typography>
                </Box>
              </Grid>
              <Grid item xs={6} md={6}>
                <Box bgcolor="primary">
                  <Typography variant="h5">DELIVERY BY :</Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Fields.AntSelectableSearchField
                  fieldData={checkinFormData.inventoryType}
                  autoCompleteChangeHandler={autoCompleteChangeHandler}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Fields.InputField
                  fieldData={checkinFormData.name}
                  inputChangeHandler={inputChangeHandler}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Fields.AntSelectableSearchField
                  fieldData={checkinFormData.status}
                  autoCompleteChangeHandler={autoCompleteChangeHandler}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Fields.InputField
                  fieldData={checkinFormData.contactNumber}
                  inputChangeHandler={inputChangeHandler}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            {/* <ForTireInformation/> */}
            {/* <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Fields.UploadInput
                  fileName={fileName}
                  lrFile={lrFile}
                  label={"Documents"}
                  uploadHandler={profileuploadHandler}
                  fileRemoveHandler={profilefileRemoveHandler}
                />
              </Grid>
            </Grid> */}
            {(row.inventory_type == "Tire" && (
              <>
                <Grid container paddingTop={2} spacing={1}>
                  <Grid item xs={2.5} md={1.9}>
                    <Typography variant="h8" color="black">
                      INVENTORY
                    </Typography>
                  </Grid>
                  <Grid item xs={2.5} md={1.9}>
                    <Typography variant="h8" color="black">
                      TRUCK NUMBER
                    </Typography>
                  </Grid>
                  <Grid item xs={2.5} md={1.9}>
                    <Typography variant="h8" color="black">
                      TIRE POSITION
                    </Typography>
                  </Grid>
                  <Grid item xs={2.5} md={1.9}>
                    <Typography variant="h8" color="black">
                      ODOMETER
                    </Typography>
                  </Grid>
                  <Grid item xs={2.5} md={1.9}>
                    <Typography variant="h8" color="black">
                      TIRE TYPE
                    </Typography>
                  </Grid>
                  <Grid item xs={2.5} md={1.9}>
                    <Typography variant="h8" color="black">
                      LOCATION
                    </Typography>
                  </Grid>
                  {/* <Grid item xs={2.5} md={1}>
                   <Typography variant="h8" color="black">
                     ACTION
                   </Typography>
                 </Grid> */}
                </Grid>
                <DialogContent deviders style={{ padding: 0 }}>
                  <Grid
                    container
                    paddingTop={1.5}
                    spacing={1}
                    paddingBottom={1.5}
                  >
                    <Grid item xs={2.5} md={1.9}>
                      <Fields.InputField
                        fieldData={formObjectData.inventory}
                        inputChangeHandler={inputChangeHandlerForTire}
                        variant="outlined"
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={2.5} md={1.9}>
                      <Fields.AntSelectableSearchField
                        fieldData={formObjectData.truckNumber}
                        autoCompleteChangeHandler={autoCompleteChangeHandlerForTire}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={2.5} md={1.9}>
                      <Fields.InputField
                        fieldData={formObjectData.tirePosition}
                        inputChangeHandler={inputChangeHandlerForTire}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={2.5} md={1.9}>
                      <Fields.InputField
                        fieldData={formObjectData.odometer}
                        inputChangeHandler={inputChangeHandlerForTire}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={2.5} md={1.9}>
                      <Fields.AntSelectableSearchField
                        fieldData={formObjectData.tireType}
                        autoCompleteChangeHandler={
                          autoCompleteChangeHandlerForTire
                        }
                      />
                    </Grid>
                    <Grid item xs={2.5} md={1.9}>
                      <Fields.InputField
                        fieldData={formObjectData.location}
                        inputChangeHandler={inputChangeHandlerForTire}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
              </>
            )) ||
              (row.inventory_type == "Equipment" && (
                <>
                  <Grid container paddingTop={2} spacing={1}>
                    <Grid item xs={2.5} md={1.9}>
                      <Typography variant="h8" color="black">
                        INVENTORY
                      </Typography>
                    </Grid>
                    <Grid item xs={2.5} md={1.9}>
                      <Typography variant="h8" color="black">
                        TRUCK NUMBER
                      </Typography>
                    </Grid>
                    <Grid item xs={2.5} md={1.9}>
                      <Typography variant="h8" color="black">
                        EQUIPMENT
                      </Typography>
                    </Grid>
                    <Grid item xs={2.5} md={1.9}>
                      <Typography variant="h8" color="black">
                        AVAILABLE QUANTITY
                      </Typography>
                    </Grid>
                    <Grid item xs={2.5} md={1.9}>
                      <Typography variant="h8" color="black">
                        QUANTITY
                      </Typography>
                    </Grid>
                    <Grid item xs={2.5} md={1.9}>
                      <Typography variant="h8" color="black">
                        LOCATION
                      </Typography>
                    </Grid>
                    {/* <Grid item xs={2.5} md={1}>
                    <Typography variant="h8" color="black">
                      ACTION
                    </Typography>
                  </Grid> */}
                  </Grid>
                  <DialogContent deviders style={{ padding: 0 }}>
                    <Grid
                      container
                      paddingTop={1.5}
                      spacing={1}
                      paddingBottom={1.5}
                    >
                      <Grid item xs={2.5} md={1.9}>
                        <Fields.InputField
                          fieldData={formEquipmentData.inventory}
                          inputChangeHandler={inputChangeHandlerForEquipment}
                          variant="outlined"
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={2.5} md={1.9}>
                      <Fields.AntSelectableSearchField
                          fieldData={formEquipmentData.truckNumber}
                          autoCompleteChangeHandler={autoCompleteChangeHandlerForEquipment}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={2.5} md={1.9}>
                        <Fields.InputField
                          fieldData={formEquipmentData.equipment}
                          inputChangeHandler={inputChangeHandlerForEquipment}
                          variant="outlined"
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={2.5} md={1.9}>
                        <Typography
                          style={{
                            color: "white",
                            backgroundColor: "#00BF9A",
                            marginTop: "13px",
                            textAlign: "center",
                            fontWeight: "bold",
                            padding: "3px",
                            borderRadius: "5px",
                          }}
                        >
                          QUANTITY : {row.available_quantity}
                        </Typography>
                      </Grid>
                      <Grid item xs={2.5} md={1.9}>
                        <Fields.InputField
                          fieldData={formEquipmentData.quantity}
                          inputChangeHandler={inputChangeHandlerForEquipment}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={2.5} md={1.9}>
                        <Fields.InputField
                          fieldData={formEquipmentData.location}
                          inputChangeHandler={inputChangeHandlerForEquipment}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                </>
              )) ||
              (row.inventory_type == "Consumable" && (
                <>
                  <Grid container paddingTop={2} spacing={1}>
                    <Grid item xs={2.5} md={1.6}>
                      <Typography variant="h8" color="black">
                        INVENTORY
                      </Typography>
                    </Grid>
                    <Grid item xs={2.5} md={1.6}>
                      <Typography variant="h8" color="black">
                        TRUCK NUMBER
                      </Typography>
                    </Grid>
                    <Grid item xs={2.5} md={1.6}>
                      <Typography variant="h8" color="black">
                        NAME
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2.5}
                      md={2.2}
                      style={{ textAlign: "center" }}
                    >
                      <Typography variant="h8" color="black">
                        AVAILABLE QUANTITY AVAILABLE UNITS
                      </Typography>
                    </Grid>
                    <Grid item xs={2.5} md={1.6}>
                      <Typography variant="h8" color="black">
                        TYPE
                      </Typography>
                    </Grid>
                    <Grid item xs={2.5} md={1.6}>
                      <Typography variant="h8" color="black">
                        QUANTITY
                      </Typography>
                    </Grid>
                    <Grid item xs={2.5} md={1.6}>
                      <Typography variant="h8" color="black">
                        LOCATION
                      </Typography>
                    </Grid>
                    {/* <Grid item xs={2.5} md={1}>
            <Typography variant="h8" color="black">
              ACTION
            </Typography>
          </Grid> */}
                  </Grid>
                  <DialogContent deviders style={{ padding: 0 }}>
                    <Grid
                      container
                      paddingTop={1.5}
                      spacing={1}
                      paddingBottom={1.5}
                    >
                      <Grid item xs={2.5} md={1.6}>
                        <Fields.InputField
                          fieldData={formConsumableData.inventory}
                          inputChangeHandler={inputChangeHandlerForConsumable}
                          variant="outlined"
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={2.5} md={1.6}>
                        <Fields.AntSelectableSearchField
                          fieldData={formConsumableData.truckNumber}
                          autoCompleteChangeHandler={autoCompleteChangeHandlerForConsumable}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={2.5} md={1.6}>
                        <Fields.InputField
                          fieldData={formConsumableData.name}
                          inputChangeHandler={inputChangeHandlerForConsumable}
                          variant="outlined"
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={2.5} md={2.2}>
                        <Typography
                          style={{
                            color: "white",
                            backgroundColor: "#00BF9A",
                            textAlign: "center",
                            fontWeight: "bold",
                            padding: "2px",
                            borderRadius: "5px",
                          }}
                        >
                          QUANTITY : {row.available_quantity}
                        </Typography>
                        <Typography
                          style={{
                            color: "white",
                            backgroundColor: "#FD5D93",
                            marginTop: "3px",
                            textAlign: "center",
                            fontWeight: "bold",
                            padding: "2px",
                            borderRadius: "5px",
                          }}
                        >
                          UNITS : {row.available_unit_value}
                        </Typography>
                      </Grid>
                      <Grid item xs={2.5} md={1.6}>
                        <Fields.AntSelectableSearchField
                          fieldData={formConsumableData.type}
                          autoCompleteChangeHandler={
                            autoCompleteChangeHandlerForConsumable
                          }
                          variant="outlined"
                          disabled={true}
                        />
                      </Grid>
                      <Grid item xs={2.5} md={1.6}>
                        <Fields.InputField
                          fieldData={formConsumableData.quantity}
                          inputChangeHandler={inputChangeHandlerForConsumable}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={2.5} md={1.6}>
                        <Fields.InputField
                          fieldData={formConsumableData.location}
                          inputChangeHandler={inputChangeHandlerForConsumable}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                </>
              ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions align="start">
          <Button onClick={handleClose} variant="contained">
            Close
          </Button>
          <Button onClick={() => checkInVehicle()} variant="contained">
            Proceed For Checkin
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default function CollapsibleTableForTransactions() {
  const spinnerStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    },
    spinner: {
      position: "absolute",
      top: "80%",
      left: "50%",
    },
    clear_button: {
      padding: "8px 15px",
      background: "#D3710F0D",
      border: "1px solid #D3710F",
      color: "#D3710F",
      fontSize: "12px",
    },
  }));
  let spinnerClasses = spinnerStyles();
  const classes = useStyles();
  const [spinner, setSpinner] = React.useState(false);
  const [csvData, setCsvData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  let csvlink = useRef();
  const filterFields = {
    status: {
      name: "status",
      label: "Status",
      value: { label: "All", value: "All" },
      options: [
        { label: "All", value: "All" },
        { label: "Check-in", value: "Check-in" },
        { label: "Checkout", value: "Checkout" },
      ],
      allowClear: true,
      // topLabel: true,
    },
  };
  const [filterData, setFilterData] = useState(filterFields);
  const [vehicles,setVehicles] = useState([])
  const columns = [
    { id: "#", disablePadding: true, label: "#", checked: true },
    { id: "no", disablePadding: true, label: "NO.", checked: true },
    { id: "STATUS", label: "STATUS", disablePadding: true, checked: true },
    {
      id: "inventoryType",
      disablePadding: true,
      label: "INVENTORY TYPE",
      checked: true,
    },
    { id: "name", disablePadding: true, label: "NAME", checked: true },
    {
      id: "contactNum",
      disablePadding: true,
      label: "CONTACT NUMBER",
      checked: true,
    },
    { id: "date", disablePadding: true, label: "DATE", checked: true },
    {
      id: "processedBy",
      disablePadding: true,
      label: "PROCESSED BY",
      checked: true,
    },
    { id: "action", disablePadding: true, label: "ACTION", checked: true },
  ];
  const [pagination, setPagination] = React.useState({
    current: CONFIG.PAGINATION.current,
    pageSize: CONFIG.PAGINATION.pageSize,
  });
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const closeAlert = () => {
    let alert = _.cloneDeep(alertData);
    alert.open = false;
    alert.severity = "";
    alert.message = "";
    setAlertData(alert);
  };
  const [headRows, setHeadRows] = React.useState(columns);
  let [rows, setRows] = React.useState([]);
  let Service = global.service;
  React.useEffect(() => {
    Service.getInventoryTransactions(filterData.status.value?.value).then(
      (response) => {
        if (response.status) {
          setRows(response.data);
        }
      }
    );
    Service.getVehicles({allVehicles: true,ownVehicles: true}).then((res)=>{
      let vehicleOptions = res?.vehicles?.map((veh) => {
        return { label: veh.registrationNumber, value: veh.id };
      });
      setVehicles(vehicleOptions)
    })

    // let dataObj = {
    //   skip: pagination.current * pagination.pageSize,
    //   limit: pagination.pageSize,
    // };

    // renderInventoryTransactionsData(dataObj,headRows)
  }, []);

  const autoCompleteChangeHandler = (value, name) => {
    let newField = _.cloneDeep(filterData);
    newField[name]["value"] = value;
    setFilterData(newField);
  };
  const clearHandler = () => {};
  const searchHandler = () => {
    setRows([]);
    Service.getInventoryTransactions(filterData.status.value?.value).then(
      (res) => {
        if (res.status) {
          setRows(res.data);
        }
      }
    );
  };
  const getDownloadData = () => {
    if (searchData.status) {
      let data = searchData?.response?.map((i, sno) => {
        return {
          "S.NO": sno + 1,
          "VEHICLE NUMBER": i?.regiNo ? i?.regiNo : "--",
          "START ODO": i?.startOdo ? i?.startOdo : "--",
          "END ODO": i?.endOdo ? i?.endOdo : "--",
          "DISTANCE TRAVELLED": i?.totalDistance ? i?.totalDistance : 0,
        };
      });
      setCsvData(data);

      setTimeout(() => {
        csvlink.current.link.click();
      }, 500);
    } else {
      setAlertData({
        open: true,
        severity: CONFIG.ALERT_SEVERITY.error,
        message: "No Data Found",
      });
      setTimeout(() => {
        setAlertData({
          open: "",
          severity: "",
          message: "",
        });
      }, 3000);
    }
  };

  // const renderInventoryTransactionsData = (dataObj, cols) => {
  //   setSpinner(true);
  //   Service.getInventoryTransactions(dataObj)
  //     .then((res) => {
  //       let checkedCols = cols.filter((col) => col.checked === true);
  //       setHeadRows(checkedCols);
  //       let data = res.data;
  //       setRows([...data]);
  //       setTotal(res?.total);
  //       setSpinner(false);
  //       // let data = res.data.map((each) => {
  //       //   return {
  //       //     ...(checkedCols.find((data) => data.id === "invoice")
  //       //       ? {
  //       //         invoice: each.invoice_number ? each.invoice_number : "--",
  //       //         }
  //       //       : {}),
  //       //     ...(checkedCols.find((data) => data.id === "qrCode")
  //       //       ? {
  //       //         qrCode:each.qrcode ? each.qrcode: "--",
  //       //         }
  //       //       : {}),
  //       //     ...(checkedCols.find((data) => data.id === "serialNum")
  //       //       ? {
  //       //         serialNum: each.serial_number ? each.serial_number : "--",
  //       //         }
  //       //       : {}),
  //       //     ...(checkedCols.find((data) => data.id === "category")
  //       //       ? {
  //       //         category: each.inventory_type ? each.inventory_type : "--",
  //       //         }
  //       //       : {}),
  //       //     ...(checkedCols.find((data) => data.id === "name")
  //       //       ? {
  //       //         name: each.name ? each.name : "--",
  //       //         }
  //       //       : {}),
  //       //     ...(checkedCols.find((data) => data.id === "availableQuantity")
  //       //       ? {
  //       //         availableQuantity: each.available_quantity ? each.available_quantity : "--",
  //       //         }
  //       //       : {}),
  //       //     ...(checkedCols.find((data) => data.id === "availableUnits")
  //       //       ? {
  //       //         availableUnits: each.available_unit_value? each.available_unit_value: "--",
  //       //         }
  //       //       : {}),
  //       //     ...(checkedCols.find((data) => data.id === "rate")
  //       //       ? {
  //       //         rate:each.price? each.price: "--",
  //       //         }
  //       //       : {}),
  //       //     ...(checkedCols.find((data) => data.id === "subtotal")
  //       //       ? {
  //       //         subtotal: each.subtotal? each.subtotal: "--",
  //       //         }
  //       //       : {}),
  //       //       ...(checkedCols.find((data) => data.id === "discount")
  //       //       ? {
  //       //         discount: each.discount_value? each.discount_value: "--",
  //       //         }
  //       //       : {}),
  //       //       ...(checkedCols.find((data) => data.id === "gst")
  //       //       ? {
  //       //         gst: each.gst_amount? each.gst_amount: "--",
  //       //         }
  //       //       : {}),
  //       //       ...(checkedCols.find((data) => data.id === "purchaseCost")
  //       //       ? {
  //       //         purchaseCost: each.purchase_total? each.purchase_total: "--",
  //       //         }
  //       //       : {}),
  //       //       ...(checkedCols.find((data) => data.id === "purchaseDate")
  //       //       ? {
  //       //         purchaseDate: each.purchase_date? each.purchase_date: "--",
  //       //         }
  //       //       : {}),

  //       //   };
  //       // });
  //     })
  //     .catch(() => {
  //       setSpinner(false);
  //     });
  // };

  return (
    <div>
      <Header />
      {alertData.open ? (
        <div>
          <AlertMessage
            severity={alertData.severity}
            message={alertData.message}
            closeAlert={closeAlert}
          />
        </div>
      ) : null}
      <div className="main_container">
        <div className="header_box">
          <Typography className="header_text" style={{ color: "#387094" }}>
            {/* <ArrowBack
              className="arrow"
              onClick={() => {
                history.push({
                  pathname: "./vehiclerequestlist",
                });
              }}
            /> */}
            Transactions
          </Typography>
          {/* <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Typography
                  // className={classes.text}
                  style={{ fontFamily: "Montserrat,SemiBold" }}
                >
                  Filter by
                </Typography>
                </Grid>
                </Grid>
                </Grid> */}
          {/* <Button
            className={classes.export_button}
            startIcon={<DownloadIcon />}
            // onClick={() => {
            //   getDownloadData();
            // }}
          >
            Export
          </Button> */}
          <div>
            <Button
              className={classes.export_button}
              startIcon={
                <DownloadIcon
                  style={{ padding: "2px", marginRight: "2px !important" }}
                />
              }
              onClick={() => {
                getDownloadData();
              }}
            >
              Export
            </Button>
            <CSVLink
              data={csvData}
              headers={Object.keys(csvData[0] || {})}
              filename={"inventory-transactions.csv"}
              ref={csvlink}
            />
          </div>
        </div>
        <div className="filter_box">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={2} className={"label_field"}>
                  <Fields.AntSelectableSearchField
                    fieldData={filterData.status}
                    autoCompleteChangeHandler={autoCompleteChangeHandler}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    className={classes.search_button}
                    style={{ borderRadius: "5px" }}
                    onClick={searchHandler}
                    startIcon={<SearchIcon />}
                  >
                    Search
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <TableContainer
          component={Paper}
          style={{ marginLeft: "15px", width: "98%" }}
        >
          <Table aria-label="collapsible table">
            <TableHead bgColor="#366E93">
              <TableRow>
                {headRows.map((row) => {
                  return (
                    <TableCell
                      key={row.id}
                      align={row.alignLeft ? "left" : "center"}
                      style={{
                        padding: row.padding ? "20px 30px" : "",
                        width: row.width,
                        fontSize: 14,
                        fontWeight: 800,
                        padingLeft: "150px",
                        color: "white",
                      }}
                    >
                      {row?.label}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {spinner ? (
                <div className={spinnerClasses.container}>
                  <div className={spinnerClasses.spinner}>
                    <CircularProgress disableShrink />
                  </div>
                </div>
              ) : (
                rows?.map((row, index) => (
                  <RowForTrasactions key={row?.name} row={row} index={index} vehicles = {vehicles} />
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
