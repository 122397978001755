import React from "react";
import "antd/dist/antd.css";
import { DatePicker, Space } from "antd";
import { FormLabel } from "react-bootstrap";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  datePickerField: {
    marginTop: "8px",
    width: "100%",
    padding: "9px",
    borderRadius: "4px",
    border: "1px solid #91b0c4",
  },
  helperText: { color: "red", marginRight: "0px", marginLeft: "0px" },
  headLabel: {
    fontWeight: "normal",
    color: "#333333",
    fontSize: "12px",
    fontFamily: "Montserrat, SemiBold"
  },
}));



const { RangePicker } = DatePicker;
export default function DateRangePicker(props) {
  const classes = useStyles();
  const onDateSelect = (values) => {
    props.onDateSelect(values);
  };
  return (
    <>
    <FormLabel
        className={classes.headLabel}
        required={props.fieldData.validationRequired}
      >
        {props.fieldData.label}
      </FormLabel>
    <Space direction="vertical" size={15} align="center">
      <RangePicker
        // renderExtraFooter={() => ''}
        showTime
        onOk={onDateSelect}
        value={props.value}
        format="DD-MM-yyyy HH:mm:ss"
        style={{ height: "42px" }}
        disabledDate={props?.fieldData?.disabledDate}
        //disabledDate for the feature to disable the dates
      />
    </Space>
    </>
  );
}