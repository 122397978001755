class Util {
	queryParamsFromObj = (url, obj) => {
		var paramsdata = url + '?';
		for (var key in obj) {
			if (obj.hasOwnProperty(key) && obj[key] !== '') {
				paramsdata += key + '=' + obj[key] + '&';
			}
		}
		return paramsdata.slice(0, -1);
	};

	formatDate = (date) => {
		var d = new Date(date);
		if (isNaN(d.getMonth())) {
			d = new Date();
		}
		var month = "" + (d.getMonth() + 1),
			day = "" + d.getDate(),
			year = d.getFullYear();
		if (month.length < 2) month = "0" + month;
		if (day.length < 2) day = "0" + day;

		return [year, month, day].join("-");
	};
}
export default Util;