class VendorsRequestBuilders {
  constructor(util) {
    this.util = util;
  }
  CreateVendor = (reqObj) => {
    return {
      firstName: reqObj.firstName.value,
      lastName: reqObj.lastName.value,
      mobileNumber: reqObj.mobileNumber.value,
      emailAddress: reqObj.emailAddress.value,
      ...(reqObj.companyName.value
        ? { companyName: reqObj.companyName.value }
        : {}),
      ...(reqObj.gstNumber.value ? { gstNumber: reqObj.gstNumber.value } : {}),
      ...(reqObj.businessId.value
        ? { businessId: reqObj.businessId.value }
        : {}),
      ...(reqObj.openingBalance.value
        ? { openingBalance: reqObj.openingBalance.value }
        : {}),
      areaServiceId: Number(reqObj.areaServiceId.value),
      status: reqObj.status.value,
      ...(reqObj.panNumber.value?{panNo: reqObj.panNumber.value}:{}),
      ...(reqObj.vendorCode.value ?{vendorCode: reqObj.vendorCode.value}:{}),

      clientAddress: {
        addressLine1: reqObj.addressLine1.value,
        ...(reqObj.addressLine2.value
          ? { addressLine2: reqObj.addressLine2.value }
          : {}),
        ...(reqObj.addressLine3.value
          ? { addressLine3: reqObj.addressLine3.value }
          : {}),
        city: reqObj.city.value,
        state: reqObj.state.value,
        country: reqObj.country.value,
        postalCode: reqObj.postalCode.value,
      },
    };
  };
  UpdateVendor = (reqObj) => {
    return {
      firstName: reqObj.firstName.value,
      lastName: reqObj.lastName.value,
      emailAddress: reqObj.emailAddress.value,
      gstNumber: reqObj.gstNumber.value,
      status: reqObj.status.value,
      ...(reqObj.panNumber.value?{panNo: reqObj.panNumber.value}:{}),
      ...(reqObj.vendorCode.value ?{vendorCode: reqObj.vendorCode.value}:{}),
      clientAddress: {
        addressLine1: reqObj.addressLine1.value,
        addressLine2: reqObj.addressLine2.value,
        addressLine3: reqObj.addressLine3.value,
        city: reqObj.city.value,
        state: reqObj.state.value,
        country: reqObj.country.value,
        postalCode: reqObj.postalCode.value,
      },
    };
  };
  CreateFleetVendor = (reqObj) => {
    return {
      name: reqObj.name.value,
      ...(reqObj.email.value ? { email: reqObj.email.value } : {}),
      address: reqObj.address.value,
      ...(reqObj.aadharNo.value ? {aadharNo: reqObj.aadharNo.value} : {}),
      contactMobile: reqObj.contactMobile.value,
      ...(reqObj.gstNumber.value ? { gstNumber: reqObj.gstNumber.value } : {}),
      ...(reqObj.openingBalance.value
        ? { openingBalance: reqObj.openingBalance.value }
        : {}),
      companyDetails: {
        companyName: reqObj.companyName.value,
        noOfTruck: reqObj.noOfTruck.value,
      },
      ...(reqObj.panNumber.value?{panNo: reqObj.panNumber.value}:{}),
      ...(reqObj.vendorCode.value ?{vendorCode: reqObj.vendorCode.value}:{}),
      ...(reqObj.bankName.value ? { bankName: reqObj.bankName.value.value } : {}),
      ...(reqObj.acName.value ? { acName: reqObj.acName.value } : {}),
      ...(reqObj.acNo.value ? { acNo: reqObj.acNo.value } : {}),
      ...(reqObj.ifsc.value ? { ifsc: reqObj.ifsc.value } : {}),
    };
  };
}

export default VendorsRequestBuilders;
