import CreateExpensesRequestBuilders from "../RequestBuilders/expense";
class Expenses {
  constructor(Service, path, path2, Util) {
    this.httpService = Service;
    this.path = path;
    this.Util = Util;
    this.CreateExpensesRequestBuilders = new CreateExpensesRequestBuilders(
      Util
    );
  }

  getExpenseTypes = () => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_2 + this.path.GET_EXPENSE_TYPES;
    return new Promise((reslove, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          reslove(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  CreateExpenses = (data) => {
    // let reqData = this.CreateExpensesRequestBuilders.CreateExpenses(data);
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_2 + this.path.CREATE_EXPENSES;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getBookingExpenses = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_2 +
      this.Util.queryParamsFromObj(this.path.GET_BOOKING_EXPENSES, data);
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  UpdateExpenses = (data) => {
    // let reqData = this.CreateExpensesRequestBuilders.UpdateExpenses(data);
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_2 + this.path.UPDATE_EXPENSES;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getExpenseAttachments = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_2 +
      this.Util.queryParamsFromObj(this.path.EXPENSE_ATTACHMENTS, data);
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export default Expenses;
