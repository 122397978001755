class DeliveryOrdersRequestBuilders {
  constructor(util) {
    this.util = util;
  }
  CreateDeliveryOrder = (reqObj) => {
    return {
        companyId: reqObj.companyId,
        clientId: reqObj.clientId,
        ...(reqObj?.fvContractId &&reqObj?.fvContractId ?{fvContractId:reqObj?.fvContractId} :{contractId: reqObj.contractId}),
        doType: reqObj.deliveryOrder.value.value,
        doNumber: reqObj.doNumber.value,
        doIssuedGodownName: reqObj.doIssueGodownName.value,
        issuedByCompany: reqObj.issuedByCompany.value,
        issuedByOffice: reqObj.issuedByOffice.value,
        issuedDate: reqObj.issuedDate.value,
        startDate:reqObj.startDate.value,
        targetDate: reqObj.targetDate.value,
        commodityId: reqObj.commodity.value.value,
        unitType : reqObj.qtyinunits.value.value,
        quantity : (reqObj.qtyinvalue.value != "") ? reqObj.qtyinvalue.value : 0,
        noOfTrips : (reqObj.noOfTrips.value != "") ? reqObj.noOfTrips.value : 0,
        remarks : reqObj.remarks.value,
        scheme : reqObj.scheme.value
    };
  };
}
export default DeliveryOrdersRequestBuilders;
