import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    container: { textAlign: "center", height: "50vh", position: "relative" },
    spinner: { position: "absolute", top: "50%" }
}));

export default function CircularLoading(){
    const classes = useStyles();
    return <div className={classes.container}>
        <CircularProgress disableShrink className={classes.spinner}/>
    </div>
}