class SiteRequestBuilders {
  constructor(util) {
    this.util = util;
  }

  CreateSite = (reqObj) => {
    return {
      siteName: reqObj.siteName.value,
      personName: reqObj.personName.value,
      geoFenceType: reqObj.geoFenceType.value,
      ...(reqObj.geoFenceType.value === "circle"
        ? { radius: Number(reqObj.radius.value) }
        : { polygon: [...reqObj.polygon,reqObj.polygon[0]]}),
      mobileNumber: reqObj.mobileNumber.value,
      emailAddress: reqObj.emailAddress.value,
      siteType: Number(reqObj.siteType.value),
      clientAddress: {
        address: reqObj.clientAddress.value,
        latitude: reqObj.clientAddress.lat,
        longitude: reqObj.clientAddress.lng,
      },
    };
  };

  UpdateSite = (reqObj) => {
    return {
      siteName: reqObj.siteName.value,
      personName: reqObj.personName.value,
      geoFenceType: reqObj.geoFenceType.value,
      ...(reqObj.geoFenceType.value === "circle"
        ? { radius: Number(reqObj.radius.value) }
        : { polygon:  reqObj.polygon&& reqObj.polygon.value ? reqObj.polygon.value : reqObj.polygon}),
      mobileNumber: reqObj.mobileNumber.value,
      emailAddress: reqObj.emailAddress.value,
        address: reqObj.clientAddress.value,
        latitude: reqObj.clientAddress.lat,
        longitude: reqObj.clientAddress.lng,
      
    };
  };
}

export default SiteRequestBuilders;
