import { Grid, Typography, Button, makeStyles, Avatar } from "@mui/material";
import React, { useState } from "react";
import CollapsibleTable, { collapsibleTable } from "./forStoreInventorytable";
import Header from "../../components/header";
import "../../common.css";
import CheckOutDialogBoxFunctionality from "./checkoutDialogBox";
import { AddInventoryDialogBox } from "./addInventoryDialoxBox";

export default function ForStoreInventory() {
  return (
    <div>
      <Header />
      <div className="main_container">
        <div>
          <div padding={3} paddingTop={0}>
            <Grid
              container
              padding={2.5}
              style={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#FFFFFF",
              }}
            >
              <Grid item xs={6} md={6}>
                <Typography className="header_text" style={{fontSize:"25px",fontWeight:"bold"}}>Store Inventories</Typography>
                {/* <Typography
                  variant="h4"
                  fontWeight="bold"
                  fontFamily="Roboto"
                  fontSize="30px"
                >
                  Store Inventories
                </Typography> */}
              </Grid>
              <Grid item xs={6} md={6} align="end">
                <CheckOutDialogBoxFunctionality />
                <AddInventoryDialogBox />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12}>
                <CollapsibleTable />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}
