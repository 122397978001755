import { Typography } from "antd"
import Header from "../../components/header"
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    MapContainer,
    TileLayer,
    Marker,
    Tooltip,
    Popup,
    Polyline,
    LayersControl,
    // useMapEvents,
    // useMap
    Circle,
    Polygon,
    useMap
} from "react-leaflet";
// import "./custom.css";
import "leaflet/dist/leaflet.css";
import LocationMarker from "../myfleet/marker";
import * as L from "leaflet";
// import { MapContainer, TileLayer, Polyline, Marker } from "react-leaflet";
// import L from "leaflet";
import "leaflet/dist/leaflet.css";
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { suvegaBlueBird } from "../../assets/index";
import { ArrowBack } from "@material-ui/icons";
import { useHistory } from "react-router";

const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: 60,
    lineHeight: '60px',
}));

const lightTheme = createTheme({ palette: { mode: 'light' } });

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        width: "100%",
        margin: "auto",
        backgroundColor: "#000",
        alignItems: "center",
        height: "100%",
    },
    leafletContainer: {
        margin: "auto",
        width: "100%",
        height: (props) => props.height,
    },
    leafletMarkerIcon: {
        width: "20px !important",
        height: "35px !important",
    },
    icon: {
        width: 40,
        height: 40,
        margin: 5,
        color: "#FF0000",
    },
}));
const DeviceLiveData = (props) => {
    
    // console.log('props.........',props.location.state.truckId)
    let Service = global.service;
    // const classes = useStyles(props);
    // Hitech City
    // const jntuCoords = [17.4933, 78.3926]; // JNTU (intermediate point)
    // const endCoords = [17.4059, 78.5595]; // Uppal

    // Path from Hitech City -> JNTU -> Uppal
    // let startCoords = [17.4474, 78.3762];
    // let path = [startCoords];
    let [startCoords,setStartCoords] = useState([])
    let [daviceData, setDeviceData] = useState([])
    let history = useHistory()

    const startIcon = L.icon({
        iconUrl: suvegaBlueBird, // Replace with your start icon URL
        iconSize: [50, 50],
        iconAnchor: [15, 30],
    });

    // const stopIcon = L.icon({
    //     iconUrl: "stop-icon-url", // Replace with your stop icon URL
    //     iconSize: [30, 30],
    //     iconAnchor: [15, 30],
    // });

    useEffect(async () => {
        await Service.getTruckMessages(props.location.state.truckId)
            .then((res) => {
                if (res.status && res.data["position.latitude"] && res.data["position.longitude"]) {
                    let coords = [res.data["position.latitude"], res.data["position.longitude"]];
                    setStartCoords(coords);
                                        console.log('coords.....', coords); // Log coordinates
                    // setStartCoords(coords);
                    setDeviceData([res.data]);
                } else {
                    console.log("Latitude or Longitude is missing.");
                }
            })
            .catch((e) => {
                console.log("Error.......", e);
            });
    }, []);
    const CenterMap = ({ coords }) => {
        const map = useMap();
        useEffect(() => {
            if (coords.length > 0) {
                map.setView(coords, 12); // Set the map center and zoom level
            }
        }, [coords, map]);
        return null;
    };
    
    // console.log('daviceData..........', daviceData)
    return (
        <>
            <div>
                <Header />
                <div className="main_container">
                <div className="header_box">
                    <Typography className="header_text">
                        <ArrowBack className="arrow" onClick={() => history.goBack()} />
                        Device Live Data
                    </Typography>
                    </div>
                    <div style={{ backgroundColor: "#546E7A", marginBottom: '20px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <MapContainer
                                center={startCoords.length > 0 ? startCoords : null} // Default location
                                zoom={12}
                                style={{ width: "100%", height: "50vh" }}
                                >
                                <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <CenterMap coords={startCoords} />
                                {startCoords.length > 0  ? (
                                 <Marker position={startCoords} icon={startIcon}>
                                 {/* <Popup>
                                     {daviceData[0]["osm.address"]}
                                 </Popup> */}
                             </Marker>
                                ) : null}
                            </MapContainer>

                        </div>
                        <div style={{ backgroundColor: "#546E7A", marginTop: '20px', marginLeft: '10px', marginBottom: '20px' }}>
                            <Grid container xs={12} spacing={2} style={{ marginBottom: '20px' }}>
                                {daviceData.length > 0 && Object.entries(daviceData[0]).map(([key, value], index) => (
                                    <Grid item xs={3} key={index}>
                                        <Box
                                            sx={{
                                                p: 2,
                                                borderRadius: 2,
                                                bgcolor: 'background.default',
                                                // display: 'grid',
                                                gridTemplateColumns: { md: '1fr 1fr' },
                                                // maxHeight:'100px'
                                            }}
                                        >
                                            <div >
                                                <Typography style={{ fontWeight: "bold", fontSize: "auto" }}>
                                                    {key}
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        color: "#366E93",
                                                        fontSize: "auto",
                                                        fontWeight: "550",
                                                        textAlign: 'end',
                                                        width: '100%',
                                                    }}
                                                >
                                                    {`${value}`}
                                                </Typography>
                                            </div>
                                        </Box>
                                        <div style={{marginBottom:'20px'}}></div>
                                    </Grid>
                                ))}


                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DeviceLiveData