import * as React from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDateTimePicker from '@mui/lab/MobileDateTimePicker';
import Stack from '@mui/material/Stack';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles({
  root: {
    width: 190,
  },
  underline: {
    "&&&:before": {
      borderBottom: "none"
    },
    "&&&:after": {
      borderBottom: "none"
    },
    "&&&:hover": {
      borderBottom: "none"
    }
  },
  input: {
    padding: '2px !important',
    backgroundColor: '#FFFFFF !important',
    textAlign: 'center !important'
  }
});
export default function MobileDateAndTimePicker(props) {
  // const [value, setValue] = React.useState(props.value?props.value:new Date());
  const classes = useStyles();
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        <MobileDateTimePicker
          value={props.value ? props.value : new Date()}
          onChange={props.onChange}
          renderInput={(params) => <TextField {...params}
            variant='filled'
            style={{ border: 'none', margin: 0 }} />}
          InputProps={{ classes, disableUnderline: true }}
          toolbarTitle={props.toolbarTitle}
          // inputFormat='dd/mm/yyyy hh:mm:ss a'
        />
        {/* <DesktopDateTimePicker
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
        <DateTimePicker
          renderInput={(params) => <TextField {...params} />}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
        /> */}
      </Stack>
    </LocalizationProvider>
  );
}
