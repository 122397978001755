class TripRequestBuilders {
  constructor(util) {
    this.util = util;
  }
  CreateTrip = (reqObj, vehicles) => {
    return {
      bookingType: reqObj.bookingType.value,
      ...(reqObj.clientId && reqObj.clientId.value
        ? { clientId: reqObj.clientId.value }
        : {}),
      ...(reqObj.routeId && reqObj.routeId.value
        ? { contractId: reqObj.contractId.toString() }
        : {}),
      ...(reqObj.routeId && reqObj.routeId.value
        ? { routeId: reqObj.routeId.value }
        : {}),
      ...(reqObj.deliveryOrder && reqObj.deliveryOrder.value
        ? { doNumber: reqObj.deliveryOrder.value }
        : {}),
        truckId: reqObj.truckId.value,
        remarks:reqObj.remarks,
      ...(vehicles === "fleetVendorvehicles"
        ? { fvCompanyId: reqObj.fvCompanyId.value }
        : {}),
      fvglCode: reqObj.fvglCode.value,
      freightAmount: Number(reqObj.freightAmount.value) ,
      commodityMetricType: reqObj.commodityMetricType.value ? Number(reqObj.commodityMetricType.value.value) : '',
      invoiceNo: reqObj.invoiceNo.value,
      ...(reqObj.noOfBags.value ? {noOfBags:reqObj.noOfBags.value}: {}),
      ...(reqObj.commodityTypeId.value
        ? { commodityTypeId: reqObj.commodityTypeId.value }
        : {}),
      ...(reqObj.commodityMetricValue.value
        ? { commodityMetricValue: reqObj.commodityMetricValue.value }
        : {}),
      ...(reqObj.commodityMetricType.value
        ? { commodityMetricType: reqObj.commodityMetricType.value }
        : {}),
      ...(reqObj.advance.value ? { advance: Number(reqObj.advance.value) } : {}),
      ...(reqObj.amountToDriver.value
        ? { amountToDriver: Number(reqObj.amountToDriver.value) }
        : {}),
      ...(reqObj.scheduledPickupDatetime
        ? { scheduledPickupDatetime: reqObj.scheduledPickupDatetime }
        : {}),
      ...(reqObj.scheduledDropoffDatetime
        ? { scheduledDropoffDatetime: reqObj.scheduledDropoffDatetime }
        : {}),
      ...(reqObj.tripSheetId ? { tripSheetId: reqObj.tripSheetId } : {}),
      ...(reqObj.pickupLocation && reqObj.pickupLocation.value
        ? {
            pickupLocation: {
              latitude: reqObj.pickupLocation.lat,
              longitude: reqObj.pickupLocation.lng,
              address: reqObj.pickupLocation.value,
            },
          }
        : {}),
      ...(reqObj.dropoffLocation && reqObj.dropoffLocation.value
        ? {
            dropoffLocation: {
              latitude: reqObj.dropoffLocation.lat,
              longitude: reqObj.dropoffLocation.lng,
              address: reqObj.dropoffLocation.value,
            },
          }
        : {}),
      ...(reqObj.bookingType.value === "TBB"
        ? {
            routeDetails: {
              routePayload: {
                routeName: reqObj.routeFields.routeName.value,
                scheduleTime: reqObj?.routeFields?.scheduleTime?.value ? reqObj?.routeFields?.scheduleTime?.value : "",
                pickupName: reqObj.routeLocFields.pickupName.value,
                pickupLocation: {
                  latitude: reqObj.routeLocFields.pickupLocation.lat,
                  longitude: reqObj.routeLocFields.pickupLocation.lng,
                  address: reqObj.routeLocFields.pickupLocation.value,
                },
                ...(reqObj.routeLocFields.pickupSiteId?.value?.value ? {pickupSiteId: reqObj.routeLocFields.pickupSiteId.value.value} : {}),
                pickupLocationGroups:
                  reqObj.routeLocFields.pickupLocationGroups.value.length > 0
                    ? reqObj.routeLocFields.pickupLocationGroups.value
                    : "",
                dropoffName: reqObj.routeLocFields.dropoffName.value,
                dropoffLocation: {
                  latitude: reqObj.routeLocFields.dropoffLocation.lat,
                  longitude: reqObj.routeLocFields.dropoffLocation.lng,
                  address: reqObj.routeLocFields.dropoffLocation.value,
                },
                ...(reqObj.routeLocFields.dropoffSiteId?.value?.value ? {dropoffSiteId: reqObj.routeLocFields.dropoffSiteId.value.value} : {}),
                dropoffLocationGroups:
                  reqObj.routeLocFields.dropoffLocationGroups.value.length > 0
                    ? reqObj.routeLocFields.dropoffLocationGroups.value
                    : "",
                ...(reqObj.routeLocFields.pickHoldingTimeHours?.value ||
                reqObj.routeLocFields.pickHoldingTimeMinutes?.value
                  ? {
                      pickupHoldingTime:
                        reqObj.routeLocFields.pickHoldingTimeHours.value &&
                        reqObj.routeLocFields.pickHoldingTimeMinutes.value
                          ? parseInt(
                              reqObj.routeLocFields.pickHoldingTimeHours.value
                            ) *
                              60 +
                            parseInt(
                              reqObj.routeLocFields.pickHoldingTimeMinutes.value
                            )
                          : reqObj.routeLocFields.pickHoldingTimeHours.value
                          ? parseInt(
                              reqObj.routeLocFields.pickHoldingTimeHours.value
                            ) *
                              60 +
                            0
                          : reqObj.routeLocFields.pickHoldingTimeMinutes.value
                          ? 0 +
                            parseInt(
                              reqObj.routeLocFields.pickHoldingTimeMinutes.value
                            )
                          : 0,
                    }
                  : {}),
                ...(reqObj.routeLocFields.dropHoldingTimeHours.value ||
                reqObj.routeLocFields.dropHoldingTimeMinutes.value
                  ? {
                      dropoffHoldingTime:
                        reqObj.routeLocFields.dropHoldingTimeHours.value &&
                        reqObj.routeLocFields.dropHoldingTimeMinutes.value
                          ? parseInt(
                              reqObj.routeLocFields.dropHoldingTimeHours.value
                            ) *
                              60 +
                            parseInt(
                              reqObj.routeLocFields.dropHoldingTimeMinutes.value
                            )
                          : reqObj.routeLocFields.dropHoldingTimeHours.value
                          ? parseInt(
                              reqObj.routeLocFields.dropHoldingTimeHours.value
                            ) *
                              60 +
                            0
                          : reqObj.routeLocFields.dropHoldingTimeMinutes.value
                          ? 0 +
                            parseInt(
                              reqObj.routeLocFields.dropHoldingTimeMinutes.value
                            )
                          : 0,
                    }
                  : {}),
                ...(reqObj.routeFields.totalTATHours.value ||
                reqObj.routeFields.totalTATMin.value
                  ? {
                      totalTAT:
                        reqObj.routeFields.totalTATHours.value &&
                        reqObj.routeFields.totalTATMin.value
                          ? parseInt(reqObj.routeFields.totalTATHours.value) *
                              60 +
                            parseInt(reqObj.routeFields.totalTATMin.value)
                          : reqObj.routeFields.totalTATHours.value
                          ? parseInt(reqObj.routeFields.totalTATHours.value) *
                              60 +
                            0
                          : reqObj.routeFields.totalTATMin.value
                          ? 0 + parseInt(reqObj.routeFields.totalTATMin.value)
                          : 0,
                    }
                  : {}),
                ...(reqObj.polyLine ? { polyLine: reqObj.polyLine } : {}),
                onlyWaypointAlert: reqObj.routeFields.onlyWaypointAlert.value,
                maximumExpenses: Number(
                  reqObj.routeFields.maximumExpenses.value
                ),
                maximumFuel: Number(reqObj.routeFields.maximumFuel.value),
                maximumAdBlue: Number(reqObj.routeFields.maximumAdBlue.value),
                driverIncentive: Number(
                  reqObj.routeFields.driverIncentive.value
                ),
                maximumDistance: Number(
                  reqObj.routeFields.maximumDistance.value
                ),
                commodity: reqObj.routeFields.commodity.value.value
                  ? reqObj.routeFields.commodity.value.value
                  : "",
                freightType: "None",
                // freightAmount: Number(reqObj.routeFields.freightAmount.value),
                  freightAmount: Number(reqObj.freightAmount.value) ,
                maximumMarketVehicleFreightAmt: Number(
                  reqObj.routeFields.maximumMarketVehicleFreightAmt.value
                ),
                notes: reqObj.routeFields.notes.value,
              },
              wayPoints: reqObj.routeWaypointFields.map((wp) => {
                return {
                  waypointName: wp.waypointName.value,
                  transitTime:
                    wp.transitHours.value && wp.transitMinutes.value
                      ? parseInt(wp.transitHours.value) * 60 +
                        parseInt(wp.transitMinutes.value)
                      : wp.transitHours.value
                      ? parseInt(wp.transitHours.value) * 60 + 0
                      : wp.transitMinutes.value
                      ? 0 + parseInt(wp.transitMinutes.value)
                      : 0,
                  holdingTime:
                    wp.holdingHours.value && wp.holdingMinutes.value
                      ? parseInt(wp.holdingHours.value) * 60 +
                        parseInt(wp.holdingMinutes.value)
                      : wp.holdingHours.value
                      ? parseInt(wp.holdingHours.value) * 60 + 0
                      : wp.holdingMinutes.value
                      ? 0 + parseInt(wp.holdingMinutes.value)
                      : 0,
                  openingTime: wp?.openingTime?.value,
                  location: {
                    latitude: wp.location.lat,
                    longitude: wp.location.lng,
                    address: wp.location.value,
                  },
                  ...(wp.siteId.value?.value ? {siteId: wp.siteId.value?.value} : {}),
                  ...(wp.groups.value.length > 0
                    ? { groups: wp.groups.value }
                    : {}),
                  mobileNumbers: [
                    wp.mobileNumber1.value
                      ? `+91${wp.mobileNumber1.value}`
                      : "",
                    wp.mobileNumber2.value
                      ? `+91${wp.mobileNumber2.value}`
                      : "",
                    wp.mobileNumber3.value
                      ? `+91${wp.mobileNumber3.value}`
                      : "",
                  ]
                    .filter((item) => item !== "")
                    .toString(),
                };
              }),
            },
          }
        : {}),
    };
  };
  UpdateTrip = (reqObj) => {
    return reqObj.updateType === "WAYPOINT"
      ? {
          tripId: reqObj.tripId,
          updateType: reqObj.updateType,
          waypointId: reqObj.waypointId,
          wayPoint: {
            location: {
              latitude: reqObj.location.lat,
              longitude: reqObj.location.lng,
              address: reqObj.location.value,
            },
            transitTime:
              reqObj.transitHours.value && reqObj.transitMinutes.value
                ? parseInt(reqObj.transitHours.value) * 60 +
                  parseInt(reqObj.transitMinutes.value)
                : reqObj.transitHours.value
                ? parseInt(reqObj.transitHours.value) * 60 + 0
                : reqObj.transitMinutes.value
                ? 0 + parseInt(reqObj.transitMinutes.value)
                : 0,
            holdingTime:
              reqObj.holdingHours.value && reqObj.holdingMinutes.value
                ? parseInt(reqObj.holdingHours.value) * 60 +
                  parseInt(reqObj.holdingMinutes.value)
                : reqObj.holdingHours.value
                ? parseInt(reqObj.holdingHours.value) * 60 + 0
                : reqObj.holdingMinutes.value
                ? 0 + parseInt(reqObj.holdingMinutes.value)
                : 0,
            mobileNumbers: [
              reqObj.mobileNumber1.value
                ? `+91${reqObj.mobileNumber1.value}`
                : "",
              reqObj.mobileNumber2.value
                ? `+91${reqObj.mobileNumber2.value}`
                : "",
              reqObj.mobileNumber3.value
                ? `+91${reqObj.mobileNumber3.value}`
                : "",
            ]
              .filter((item) => item !== "")
              .toString(),
              ...(reqObj.siteId? { siteId: reqObj.siteId.value.value } : null),
            waypointName: reqObj.waypointName.value,
            groups: reqObj.groups.value.length > 0 ? reqObj.groups.value : "",
          },
        }
      : {
          tripId: reqObj.tripId,
          updateType: reqObj.updateType,
          routePayload: {
            ...(reqObj.pickupLocation
              ? { pickupLocationLatitude: reqObj.pickupLocation.lat }
              : {}),
            ...(reqObj.pickupLocation
              ? { pickupLocationLongitude: reqObj.pickupLocation.lng }
              : {}),
            ...(reqObj.pickupLocation
              ? { pickupLocationAddress: reqObj.pickupLocation.value }
              : {}),
            ...(reqObj.pickupName
              ? { pickupName: reqObj.pickupName.value }
              : {}),
            ...(reqObj.pickupLocationGroups
              ? {
                  pickupLocationGroups:
                    reqObj.pickupLocationGroups.value.length > 0
                      ? reqObj.pickupLocationGroups.value
                      : "",
                }
              : {}),
            ...(reqObj.pickHoldingTimeHours || reqObj.pickHoldingTimeMinutes
              ? {
                  pickupHoldingTime:
                    reqObj.pickHoldingTimeHours.value &&
                    reqObj.pickHoldingTimeMinutes.value
                      ? parseInt(reqObj.pickHoldingTimeHours.value) * 60 +
                        parseInt(reqObj.pickHoldingTimeMinutes.value)
                      : reqObj.pickHoldingTimeHours.value
                      ? parseInt(reqObj.pickHoldingTimeHours.value) * 60 + 0
                      : reqObj.pickHoldingTimeMinutes.value
                      ? 0 + parseInt(reqObj.pickHoldingTimeMinutes.value)
                      : 0,
                }
              : {}),
            ...(reqObj.dropoffLocation
              ? { dropoffLocationLatitude: reqObj.dropoffLocation.lat }
              : {}),
            ...(reqObj.dropoffLocation
              ? { dropoffLocationLongitude: reqObj.dropoffLocation.lng }
              : {}),
            ...(reqObj.dropoffLocation
              ? { dropoffLocationAddress: reqObj.dropoffLocation.value }
              : {}),
            ...(reqObj.dropoffName
              ? { dropoffName: reqObj.dropoffName.value }
              : {}),
            ...(reqObj.dropoffLocationGroups
              ? {
                  dropoffLocationGroups:
                    reqObj.dropoffLocationGroups.value.length > 0
                      ? reqObj.dropoffLocationGroups.value
                      : "",
                }
              : {}),
            ...(reqObj.pickupSiteId && reqObj.pickupSiteId.value.value !=='' ? { siteId: reqObj.pickupSiteId.value.value } : { siteId: reqObj.dropoffSiteId.value.value }),
            ...(reqObj.dropoffTimeHours || reqObj.dropoffTimeMinutes
              ? {
                  dropoffHoldingTime:
                    reqObj?.dropoffTimeHours?.value &&
                    reqObj?.dropoffTimeMinutes?.value
                      ? parseInt(reqObj?.dropoffTimeHours?.value) * 60 +
                        parseInt(reqObj?.dropoffTimeMinutes?.value)
                      : reqObj?.dropoffTimeHours?.value
                      ? parseInt(reqObj?.pickHoldingTimeHours?.value) * 60 + 0
                      : reqObj?.dropoffTimeMinutes?.value
                      ? 0 + parseInt(reqObj?.dropoffTimeMinutes?.value)
                      : 0,
                }
              : {}),
          },
        };
  };
  AddTripLocation = (reqObj) => {
    return reqObj.updateType === "WAYPOINT" || reqObj.updateType === "PICKUP"
      ? {
          tripId: reqObj.tripId,
          updateType: reqObj.updateType,
          ...(reqObj.updateType === "WAYPOINT"
            ? { waypointId: reqObj.waypointId }
            : {}),
          wayPoint: {
            location: {
              latitude: reqObj.location.lat,
              longitude: reqObj.location.lng,
              address: reqObj.location.value,
            },
            transitTime:
              reqObj.transitHours.value && reqObj.transitMinutes.value
                ? parseInt(reqObj.transitHours.value) * 60 +
                  parseInt(reqObj.transitMinutes.value)
                : reqObj.transitHours.value
                ? parseInt(reqObj.transitHours.value) * 60 + 0
                : reqObj.transitMinutes.value
                ? 0 + parseInt(reqObj.transitMinutes.value)
                : 0,
            holdingTime:
              reqObj.holdingHours.value && reqObj.holdingMinutes.value
                ? parseInt(reqObj.holdingHours.value) * 60 +
                  parseInt(reqObj.holdingMinutes.value)
                : reqObj.holdingHours.value
                ? parseInt(reqObj.holdingHours.value) * 60 + 0
                : reqObj.holdingMinutes.value
                ? 0 + parseInt(reqObj.holdingMinutes.value)
                : 0,
            mobileNumbers: [
              reqObj.mobileNumber1.value
                ? `+91${reqObj.mobileNumber1.value}`
                : "",
              reqObj.mobileNumber2.value
                ? `+91${reqObj.mobileNumber2.value}`
                : "",
              reqObj.mobileNumber3.value
                ? `+91${reqObj.mobileNumber3.value}`
                : "",
            ]
              .filter((item) => item !== "")
              .toString(),
            waypointName: reqObj.waypointName.value,
            groups: reqObj.groups.value.length > 0 ? reqObj.groups.value : "",
            siteId: reqObj.siteId ? reqObj.siteId.value.value : null,
          },
        }
      : {
          tripId: reqObj.tripId,
          updateType: reqObj.updateType,
          routePayload: {
            ...(reqObj.pickupLocation
              ? { pickupLocationLatitude: reqObj.pickupLocation.lat }
              : {}),
            ...(reqObj.pickupLocation
              ? { pickupLocationLongitude: reqObj.pickupLocation.lng }
              : {}),
            ...(reqObj.pickupLocation
              ? { pickupLocationAddress: reqObj.pickupLocation.value }
              : {}),
            ...(reqObj.pickupName
              ? { pickupName: reqObj.pickupName.value }
              : {}),
            ...(reqObj.pickupLocationGroups
              ? {
                  pickupLocationGroups:
                    reqObj.pickupLocationGroups.value.length > 0
                      ? reqObj.pickupLocationGroups.value
                      : "",
                }
              : {}),
            ...(reqObj.pickHoldingTimeHours || reqObj.pickHoldingTimeMinutes
              ? {
                  pickupHoldingTime:
                    reqObj.pickHoldingTimeHours.value &&
                    reqObj.pickHoldingTimeMinutes.value
                      ? parseInt(reqObj.pickHoldingTimeHours.value) * 60 +
                        parseInt(reqObj.pickHoldingTimeMinutes.value)
                      : reqObj.pickHoldingTimeHours.value
                      ? parseInt(reqObj.pickHoldingTimeHours.value) * 60 + 0
                      : reqObj.pickHoldingTimeMinutes.value
                      ? 0 + parseInt(reqObj.pickHoldingTimeMinutes.value)
                      : 0,
                }
              : {}),
            ...(reqObj.dropoffLocation
              ? { dropoffLocationLatitude: reqObj.dropoffLocation.lat }
              : {}),
            ...(reqObj.dropoffLocation
              ? { dropoffLocationLongitude: reqObj.dropoffLocation.lng }
              : {}),
            ...(reqObj.dropoffLocation
              ? { dropoffLocationAddress: reqObj.dropoffLocation.value }
              : {}),
            ...(reqObj.dropoffName
              ? { dropoffName: reqObj.dropoffName.value }
              : {}),
            ...(reqObj.dropoffSiteId ? { siteId: reqObj.dropoffSiteId.value.value } : null),
            ...(reqObj.dropoffLocationGroups
              ? {
                  dropoffLocationGroups:
                    reqObj.dropoffLocationGroups.value.length > 0
                      ? reqObj.dropoffLocationGroups.value
                      : "",
                }
              : {}),
            ...(reqObj.dropoffTimeHours || reqObj.dropoffTimeMinutes
              ? {
                  dropoffHoldingTime:
                    reqObj.dropoffTimeHours.value &&
                    reqObj.dropoffTimeMinutes.value
                      ? parseInt(reqObj.dropoffTimeHours.value) * 60 +
                        parseInt(reqObj.dropoffTimeMinutes.value)
                      : reqObj.dropoffTimeHours.value
                      ? parseInt(reqObj.pickHoldingTimeHours.value) * 60 + 0
                      : reqObj.dropoffTimeMinutes.value
                      ? 0 + parseInt(reqObj.dropoffTimeMinutes.value)
                      : 0,
                }
              : {}),
          },
        };
  };
  CancelTrip = (reqObj) => {
    return {
      remarks: reqObj.remarks.value,
    };
  };
  UpdateRouteDetails = (reqObj) => {
    return {
      ...(reqObj.totalTATHours
        ? {
            totalTAT:
              parseInt(reqObj.totalTATHours.value) * 60 +
              parseInt(reqObj.totalTATMin.value),
          }
        : {}),
      ...(reqObj.maximumExpenses
        ? { maximumExpenses: Number(reqObj.maximumExpenses.value) }
        : {}),
      ...(reqObj.maximumFuel
        ? { maximumFuel: Number(reqObj.maximumFuel.value) }
        : {}),
      ...(reqObj.maximumAdBlue
        ? { maximumAdBlue: Number(reqObj.maximumAdBlue.value) }
        : {}),
      ...(reqObj.maximumDistance
        ? { maximumDistance: Number(reqObj.maximumDistance.value) }
        : {}),
      ...(reqObj.driverIncentive
        ? { driverIncentive: Number(reqObj.driverIncentive.value) }
        : {}),
      ...(reqObj.freightAmount && reqObj?.type === "MARKET"
        ? { marketFreightAmount: Number(reqObj.freightAmount.value) }
        :reqObj?.type === "OWN"?{ freightAmount: Number(reqObj.freightAmount.value) }:{}),
      ...(reqObj.lr ? { lr: reqObj.lr.value } : {})
    };
  };
  GetTripReport = (reqObj) => {
    return {
      reportType: reqObj.reportType.value,
      fromTimestamp: new Date(reqObj.dateRange.value[0]).getTime(),
      toTimestamp: new Date(reqObj.dateRange.value[1]).getTime(),
      ...(reqObj.driverName.value.toString().length > 0
        ? { driverId: reqObj.driverName.value.toString() }
        : { driverId: null }),
      emails: reqObj.emails.actualValue,
    };
  };

  GetCreditVendorLedger = (reqObj) => {
    return {
      companyId : reqObj.vendor.value.companyId,
      vendor: reqObj.vendor.value.value,
      fromTimestamp: new Date(reqObj.dateRange.value[0]).getTime(),
      toTimestamp: new Date(reqObj.dateRange.value[1]).getTime()
    };
  };

  GetFuelVendorLedger = (reqObj) => {
    return {
      companyId : reqObj.vendor.value.companyId,
      vendor: reqObj.vendor.value.value,
      fromTimestamp: new Date(reqObj.dateRange.value[0]).getTime(),
      toTimestamp: new Date(reqObj.dateRange.value[1]).getTime()
    };
  };

  GetCustomerLedger = (reqObj) => {
    return {
      companyId : reqObj.clientId.value.companyId,
      clientId: reqObj.clientId.value.value,
      fromTimestamp: new Date(reqObj.dateRange.value[0]).getTime(),
      toTimestamp: new Date(reqObj.dateRange.value[1]).getTime()
    };
  };
  GetPettyCashLedger = (reqObj) => {
    return {
      companyId : 249, // Bharti Cement
      fromTimestamp: new Date(reqObj.dateRange.value[0]).getTime(),
      toTimestamp: new Date(reqObj.dateRange.value[1]).getTime()
    };
  };
  GetBankLedger = (reqObj) => {
    return {
      companyId : 249, // Bharti Cement
      fromTimestamp: new Date(reqObj.dateRange.value[0]).getTime(),
      toTimestamp: new Date(reqObj.dateRange.value[1]).getTime()
    };
  };
}
export default TripRequestBuilders;
