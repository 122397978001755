import React from "react";
import { Pagination } from "antd";

const AntdPagination = (props) => {
  return (
    <div>
      <Pagination
        total={props.total}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        defaultPageSize={props.pageSize}
        defaultCurrent={props.current + 1}
        onChange={(page, pageSize) => {
          if(props.current === page - 1){
            props.handleChangeRowsPerPage(pageSize)
          }else{
            props.handlePageChange(page-1);
          }
        }}
        pageSizeOptions={[25, 50, 75, 100]}
        showSizeChanger={true}
      />
    </div>
  );
};

export default AntdPagination;
