import CreateExpensesRequestBuilders from "../RequestBuilders/expense";

class Booking {
  constructor(Service, path, path2, Util) {
    this.httpService = Service;
    this.path = path;
    this.util = Util;
    this.createExpenseRequestBuilder = new CreateExpensesRequestBuilders();
  }

  getSearchedBookings = (data) => {
    const url = this.util.queryParamsFromObj(
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_2 + this.path.SEARCH_BOOKINGS,
      data
    );
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  addAdvance = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_2 + this.path.ADD_ADVANCE;
    let dataObj = this.createExpenseRequestBuilder.addAdvance(data);
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, dataObj)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

}

export default Booking;
