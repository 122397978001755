import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

import { scheduledStopSvg } from "../../../assets/index";
// import { scheduledStopImage2 } from "../../../assets/index";
import {
  unscheduledStopSvg,
} from "../../../assets/index";
// import { unscheduledStopImage2 } from "../../../assets/index";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const imagePaths = {
  unScheduledEvents: unscheduledStopSvg,
  scheduledEvents: scheduledStopSvg,
};

export default function VehicleStops(props) {
  const handleChange = (array) => {
    props.setStopEventsType(array.map((x) => x.value));
  };
  return (
    <Autocomplete
      onChange={(event, value) => handleChange(value)}
      multiple
      id="checkboxes-tags-demo"
      options={props.items}
      disableCloseOnSelect
      getOptionLabel={(option) => {
        let input = option.text
          .split(" ")
          .map((s) => s[0])
          .toString()
          .replace(",", "")
          .toUpperCase();
        return input;
      }}
      renderOption={(props, option, { selected }) => {
        return (
          <li
            {...props}
            style={{ fontSize: 14, color: "#366E93", fontWeight: 400 }}
          >
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8, fontSize: 10, color: "#366E93" }}
              checked={selected}
            />
            <img
              src={imagePaths[option.value]}
              style={{ width: 28, height: 20, paddingRight: 10 }}
            />
            {option.text}
          </li>
        );
      }}
      style={{ width: 320, margin: 0, fontSize: 12 }}
      renderInput={(params) => {
        return (
          <TextField
            variant="standard"
            {...params}
            label="Stop Events"
            placeholder=""
            style={{
              padding: 0,
              margin: 0,
              backgroundColor: "#FFFFFF",
              marginLeft: 0,
              fontSize: 12,
              color: "#366E93",
            }}
          />
        );
      }}
      defaultValue={props.items}
    />
  );
}
