export default (state = {}, action) => {
    switch (action.type) {
        case "NOTIFICATION_DATA":
            return { ...state, ...action.payload}
        case "ALL_CATEGORY":
            return { ...state, ...action.payload}  
        case "ALERT_RESPONSE":
            return { ...state, ...action.payload}  
        default:
            return state;

    }
}
