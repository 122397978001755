import React from "react";
import { TextField, FormHelperText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  textField: { width: "100%" },
  helperText: { color: "red", marginRight: "0px", marginLeft: "0px" },
  mtop: { marginTop: "8px" }
}));

export default function DateField(props) {
  const classes = useStyles();
  return (
    <div className={props.fieldData.topLabel ? "custom_field" : ""}>
      <TextField
        type="date"
        variant={props.variant}
        id={props.fieldData.name}
        defaultValue={props.fieldData.value}
        className={`${classes.textField} ${props.className ? props.className : ""}`}
        onChange={(e) => {
          props.dateChangeHandler(e.target.value, props.fieldData.name);
        }}
        required={props.fieldData.validationRequired}
      />
      {props.fieldData.isValid ? null : (
        <FormHelperText className={classes.helperText}>
          {props.fieldData.errorMsg}
        </FormHelperText>
      )}
    </div>
  );
}
