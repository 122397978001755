import BankRequestBuilders from "../RequestBuilders/banks";

class Banks {
  constructor(Service, path, Util) {
    this.httpService = Service;
    this.path = path;
    this.Util = Util;
    this.bankRequestBuilder = new BankRequestBuilders();
  }

  getOwnerBanks = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(this.path.OWNERBANK, data);
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getBank = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.OWNERBANK +
      `/${data.bankId}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  createBank = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + this.path.OWNERBANK;
    let reqData = this.bankRequestBuilder.CreateBank(data);
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  updateBank = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.OWNERBANK +
      `/${data.bankId}`;
    let reqData = this.bankRequestBuilder.UpdateBank(data);
    return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export default Banks;
