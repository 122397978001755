import GroupRequestBuilders from "../RequestBuilders/groups";

class Groups {
  constructor(Service, path, Util) {
    this.httpService = Service;
    this.path = path;
    this.Util = Util;
    this.groupRequestBuilders = new GroupRequestBuilders(Util);
  }

  getGroups = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(this.path.GROUPS + "/getgroups", data);
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getMembers = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(this.path.GROUPS + "/getusers", data);
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  createGroup = (data) => {
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + this.path.GROUPS;
    let dataObj = this.groupRequestBuilders.createGroup(data);
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, dataObj)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getGroupMembers = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.GROUPS +
      `/getgroupusers/${data.groupId}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getGroup = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.GROUPS +
      `/getgroup/${data.groupId}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  updateGroup = (data, id) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.GROUPS +
      `/updategroup/${id}`;
    let dataObj = this.groupRequestBuilders.createGroup(data);
    return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, dataObj)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  deleteGroup = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.GROUPS +
      `/deletegroup/${data.groupId}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .delete(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}
export default Groups;
