// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging,getToken } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// const firebaseConfig = {
//   apiKey: "AIzaSyDkZO3lF4UwO0SW-M-PS6eOwOL_iEy3FXA",
//   authDomain: "suvegafleet-a8c79.firebaseapp.com",
//   projectId: "suvegafleet-a8c79",
//   storageBucket: "suvegafleet-a8c79.appspot.com",
//   messagingSenderId: "181293729584",
//   appId: "1:181293729584:web:35e060c6381e2cd0eeaaf5"
// };
const firebaseConfig = {
    apiKey: "AIzaSyBYaz98gs-pQncZQE_C8ep4g36NZ6yEzxo",
    authDomain: "suvegafleet.firebaseapp.com",
    databaseURL: "https://suvegafleet.firebaseio.com",
    projectId: "suvegafleet",
    storageBucket: "suvegafleet.appspot.com",
    messagingSenderId: "585688589225",
    appId: "1:585688589225:web:a5f83c827699ecb929dab8",
    measurementId: "G-38XDWCE98D"
  };
  
  

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

// export const generateToken = async()=>{
//     debugger
//     const permission = await Notification.requestPermission()
//     console.log(permission)
//     if(permission === `granted`){
//         const token = await getToken(messaging,{
//             // vapidKey:"BCyO-XpwBWSfKWlUODjWin_FnS3xEW7yi4LKqjGgquJKsWvVXvDHTMnvUsANhKlNC-hSH9-cZDysVJ7YCX-e_d4"
//             vapidKey:"BOy1I2CjIuWjKVAy__7PMnOEKs8UXlwzbkYN9mDu2U8eLOEQxENmAVxiHBptigR7Rg8cWe4mAttNzPRnVWYCctw"
//         })
//         console.log(token)
//         sessionStorage.setItem(`Gcm_token`,token)
//     }

// }
export const generateToken = async () => {
    try {
        const permission = await Notification.requestPermission();
        console.log(permission);
        
        if (permission === 'granted') {
            const token = await getToken(messaging, {
                vapidKey: "BOy1I2CjIuWjKVAy__7PMnOEKs8UXlwzbkYN9mDu2U8eLOEQxENmAVxiHBptigR7Rg8cWe4mAttNzPRnVWYCctw"
            });
            console.log(token);
            sessionStorage.setItem('Gcm_token', token);
        } else {
            console.error('Notification permission denied.');
        }
    } catch (error) {
        console.error('Error generating token:', error);
    }
}
