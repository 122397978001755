class Truck {
    constructor(Service, path, path2, Util){
        this.httpService = Service;
        this.path = path;
        this.path2 = path2;
        this.Util = Util;
    }

    getDashboardTrucks = (data) => {
        const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1+this.path2.GET_TRUCK_LIST;
        return new Promise((resolve, reject) => {
            this.httpService.post(url, data).then((res) => {
                resolve(res)
            }).catch((error) => {
                reject(error)
            })
        })
    }

    getTruckList = (data) => {
        const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_2 + this.Util.queryParamsFromObj(this.path.GET_TRUCK_LIST, data);
        return new Promise((resolve, reject) => {
            this.httpService.get(url).then((res) => {
                resolve(res)
            }).catch((error) => {
                reject(error)
            })
        })
    }
}

export default Truck;