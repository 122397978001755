import {
  Box,
  Button,
  Grid,
  Input,
  Paper,
  Stack,
  TableCell,
  Typography,
  MenuItem,
} from "@mui/material";
import React, { useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import "../../common.css";
import * as Fields from "../../sharedComponents";
import {
  ForCheckoutInventoryConsumable,
  ForCheckoutInventoryEquipment,
  ForCheckoutInventoryTire,
} from "./storeInventoryCommonComponents";
import _ from "lodash";
import ValidateFields from "../../validations/validateFields";

const CheckOutDialogBoxFunctionality = (props) => {
  let validateFields = new ValidateFields();
  const formFields = {
    inventoryType: {
      name: "inventoryType",
      label: "Inventory Type",
      topLabel: true,
      value: { label: "Tire", value: "Tire" },
      options: [
        {
          label: "Tire",
          value: "Tire",
        },
        {
          label: "Equipment",
          value: "Equipment",
        },
        {
          label: "Consumable",
          value: "Consumable",
        },
      ],
      validationRequired: true,
      isValid: true,
      errorMsg: "Please select user",
      validPattern: "ALPHABET_with_label",
    },
    name: {
      name: "name",
      label: "Name",
      validationRequired: true,
      isValid: true,
      topLabel: true,
      value: "",
      errorMsg: "Please enter name",
      placeholder: "Enter name",
      helperText: "Please enter name",
      validPattern: "SPECIAL_CHARS_DESC",
    },
    contactNumber: {
      name: "contactNumber",
      label: "Contact Number",
      validationRequired: true,
      isValid: true,
      topLabel: true,
      value: "",
      errorMsg: "Please contact number",
      placeholder: "contact number",
      helperText: "Please contact number",
      validPattern: "SPECIAL_CHARS_DESC",
    },
    status: {
      name: "status",
      label: "Status",
      topLabel: true,
      value: { label: "Transfer", value: "Transfer" },
      options: [
        {
          label: "Transfer",
          value: "Transfer",
        },
        {
          label: "Consumed",
          value: "Consumed",
        },
        {
          label: "Discard",
          value: "Discard",
        },
      ],
    },
    documents: {
      name: "documents",
      label: "Documents",
      topLabel: true,
    },
  };

  let [formData, setFormData] = React.useState(formFields);
  const [forHandling, setHandling] = useState(0);
  const [open, setOpen] = useState(false);
  const [fileName, setFileName] = useState("");
  const [lrFile, setLrFile] = useState([]);

  const checkoutVehicle = async (formData) => {
    let dataStatus = await validateFields.validateFieldsData(formData);

    if (dataStatus.status) {
      setOpen(false);
    } else {
      let newFields = _.cloneDeep(formData);
      let erroredFields = Object.keys(dataStatus.data).filter(
        (key, i) => dataStatus.data[key].isValid === false
      );
      newFields = dataStatus.data;
      setFormData(newFields);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  const inputChangeHandler = (value, name) => {
    if (value.length > 0 && formData[name]["validationRequired"]) {
      formData[name]["isValid"] = true;
    }

    formData[name]["value"] = value;
    setFormData(_.cloneDeep(formData));
  };

  const autoCompleteChangeHandler = (value, name) => {
    formData[name]["value"] = { label: value?.value, value: value?.value };
    setFormData(_.cloneDeep(formData));
  };
  const profileuploadHandler = (e) => {
    if (e.target.files.length > 0) {
      setFileName(e.target.files[0].name);
      setLrFile(e.target.files);
    }
  };
  const profilefileRemoveHandler = () => {
    setFileName("");
    setLrFile([]);
  };

  const handleClickOpen = () => {
    setFormData(formFields);
    setOpen(true);
  };
  return (
    <>
      <Button
        variant="contained"
        onClick={handleClickOpen}
        // style={{
        //   textTransform: "none",
        //   marginRight: "10px",
        //   backgroundColor: "#366E93",
        //   padding: "8px 15px",
        //   "&:hover": {
        //     backgroundColor: "error",
        //   },
        //   color: "#fff",
        //   fontSize: "18px",
        // }}
        style={{
          padding: "9px 15px",
          textTransform: "none",
          backgroundColor: "#366E93",
          color: "#FFF",
          "&:hover": {
            backgroundColor: "#366E93",
            color: "#FFF",
          },
          marginRight: 10,
        }}
      >
        Checkout
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="xl"
        PaperProps={{
          style: {
            width: "95%",
            margin: 0,
          },
        }}
      >
        <DialogTitle>
          <Grid container justifyContent="center">
            <Grid item xs={11.8} align="center">
              CHECKOUT
            </Grid>
            <Grid item xs={0.2} container justifyContent="flex-end">
              <CloseIcon color="error" onClick={handleClose} />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Box bgcolor="primary">
                  <Typography variant="h5">INVENTORY DETAILS :</Typography>
                </Box>
              </Grid>
              <Grid item xs={6} md={6}>
                <Box bgcolor="primary">
                  <Typography variant="h5">RECEIVED BY :</Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Fields.AntSelectableSearchField
                  fieldData={formData.inventoryType}
                  autoCompleteChangeHandler={autoCompleteChangeHandler}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Fields.InputField
                  fieldData={formData.name}
                  inputChangeHandler={inputChangeHandler}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Fields.AntSelectableSearchField
                  fieldData={formData.status}
                  autoCompleteChangeHandler={autoCompleteChangeHandler}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <Fields.InputField
                  fieldData={formData.contactNumber}
                  inputChangeHandler={inputChangeHandler}
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <Fields.UploadInput
                  fileName={fileName}
                  lrFile={lrFile}
                  label={"Documents"}
                  uploadHandler={profileuploadHandler}
                  fileRemoveHandler={profilefileRemoveHandler}
                />
              </Grid>
            </Grid>
            {(formData["inventoryType"]["value"]?.value == "Tire" && (
              <ForCheckoutInventoryTire />
            )) ||
              (formData.inventoryType.value?.value == "Equipment" && (
                <ForCheckoutInventoryEquipment />
              )) ||
              (formData.inventoryType.value?.value == "Consumable" && (
                <ForCheckoutInventoryConsumable />
              ))}
          </DialogContentText>
        </DialogContent>
        <DialogActions align="start">
          <Button onClick={handleClose} variant="contained">
            Close
          </Button>
          <Button onClick={() => checkoutVehicle(formData)} variant="contained">
            Proceed For Checkout
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default CheckOutDialogBoxFunctionality;
