import React from "react";
import { DatePicker } from "antd";
import { FormLabel, FormHelperText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  datePickerField: {
    marginTop: "8px",
    width: "100%",
    padding: "9px",
    borderRadius: "4px",
    border: "1px solid #91b0c4",
  },
  helperText: { color: "red", marginRight: "0px", marginLeft: "0px" },
  headLabel: {
    fontWeight: "normal",
    color: "#333333",
    fontSize: "12px",
    fontFamily: "Montserrat, SemiBold",
  },
}));

const AntdDatePicker = (props) => {
  const classes = useStyles();
  return (
    <div>
      <FormLabel
        className={classes.headLabel}
        required={props.fieldData.validationRequired}
        style={{color:props.fieldData?.labelColor}}
      >
         {props.fieldData?.image ? <img src = {props.fieldData.image} style={{height:'17px',width:'17px',marginRight:'5px'}}/> : null}
        {props.fieldData.label}
      </FormLabel>
      <DatePicker 
        className={classes.datePickerField}
        format={"DD/MM/YYYY"} 
        value={props.fieldData.value}
        disabledDate={props.fieldData.disabledDate}
        disabled={props.fieldData.isDisabled}
        showToday={props.fieldData.showToday}
        onChange={(value) => {
            props.timeStampChangeHandler(value, props.fieldData.name);
        }}
      />
      {props.fieldData.isValid ? null : (
        <FormHelperText className={classes.helperText}>
          {props.fieldData.errorMsg}
        </FormHelperText>
      )}
    </div>
  );
};

export default AntdDatePicker;
