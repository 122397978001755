import { reject } from "lodash";
import DeliveryOrdersRequestBuilders from "../RequestBuilders/deliveryorder";

class DeliveryOrders {
  constructor(Service, path, Util) {
    this.httpService = Service;
    this.path = path;
    this.Util = Util;
    this.deliveryOrderRequestBuilder = new DeliveryOrdersRequestBuilders(Util);
  }

  getDeliveryOrders = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(this.path.DELIVERYORDERS + "/list", data);
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  createDeliveryOrder = (data) => {
    let reqData = this.deliveryOrderRequestBuilder.CreateDeliveryOrder(data);
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.DELIVERYORDERS +
      "/create";
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  updateDeliveryOrder = (id,data) => {
    // let reqData = this.deliveryOrderRequestBuilder.CreateDeliveryOrder(data);
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.DELIVERYORDERS +
      `/${id}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getDeliveryOrder = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(
        this.path.DELIVERYORDERS + "/view/" + data.id
      );
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  addDoDocuments = (file, data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      "/deliveryorders/" +
      `${data.doId}` +
      "/documents";
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, file)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  updateDoDocuments = (file, data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +`/deliveryorders/documents/${data?.doId}`
    return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, file)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getDoDashboard = () => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +"/deliveryorders/dashboard"
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export default DeliveryOrders;
