import PettycashRequestBuilder from "../RequestBuilders/pettycash";
class Pettycash {
  constructor(Service, path, Util) {
    this.httpService = Service;
    this.path = path;
    this.Util = Util;
    this.PettycashRequestBuilder = new PettycashRequestBuilder(Util);
  }

  getPayments = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(this.path.PETTYCASH + "/payments", data);
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  addPayment = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.PETTYCASH +
      "/addPayment";
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => resolve(res))
        .catch((error) => reject(error));
    });
  };
}
export default Pettycash;
