import IndentRequestBuilders from "../RequestBuilders/indents";

class Indents {
  constructor(Service, path, Util) {
    this.httpService = Service;
    this.path = path;
    this.Util = Util;
    this.indentRequestBuilder = new IndentRequestBuilders(Util);
  }
  getIndentTypes = () => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + this.path.INDENT + "/types";
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  createIndent = (data, indents) => {
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + this.path.INDENT;
    let reqData = this.indentRequestBuilder.CreateIndent(data, indents);
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getIndents = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(this.path.INDENT, data);
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getIndent = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.INDENT +
      "/" +
      data.indentId;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  updateIndent = (data, indents) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.INDENT +
      "/update";
    let reqData = this.indentRequestBuilder.UpdateIndent(data, indents);
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}
export default Indents;
