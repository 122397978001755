import L from "leaflet";
import { createControlComponent } from "@react-leaflet/core";
import "leaflet-routing-machine";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";

const RoutingMachine = (props) => {
  let wp = props.waypoints.map((wp) => {
    return {
      location: {
        lat: wp.location.lat,
        lng: wp.location.lng,
        val: wp.location.value,
      },
    };
  });

  let wps = [
    {
      location: {
        lat: props.pickupLocation.lat,
        lng: props.pickupLocation.lng,
        val: props.pickupLocation.value,
      },
    },
    ...wp,
    {
      location: {
        lat: props.dropoffLocation.lat,
        lng: props.dropoffLocation.lng,
        val: props.dropoffLocation.value,
      },
    },
  ];

  const instance = L.Routing.control({
    waypoints: wps.map((wp) => L.latLng(wp.location.lat, wp.location.lng)),
    lineOptions: {
      styles: [{ color: "#6FA1EC", weight: 4 }],
    },
    show: false,
    addWaypoints: false,
    routeWhileDragging: true,
    draggableWaypoints: true,
    fitSelectedRoutes: true,
    showAlternatives: false,
    collapsible: true,
    createMarker: function (i, wp, nWps) {
      if (i === 0) {
        return null;
      } else if (i > 0 && i < nWps - 1) {
        return null;
      } else {
        return null;
      }
    },
  });

  return instance;
};

const RouteMachine = createControlComponent(RoutingMachine);

export default RouteMachine;
