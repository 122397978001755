import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, Grid } from "@material-ui/core";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import CloseIcon from "@mui/icons-material/Close";
import * as Fields from "../../sharedComponents";
import * as Components from "../../sharedComponents";
import * as CONFIG from "../../config/GlobalConstants";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import "../../common.css";
import CircularLoading from "../../components/loader/circularLoading";
import AlertMessage from "../../components/alertmessage/AlertMessage";
import Chip from "@mui/material/Chip";
import { CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import Divider from "@mui/material/Divider";
import { InputAdornment } from "@mui/material";
import Header from "../../components/header";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
var Service;
const useStyles = makeStyles((theme) => ({
  header_buttons: {
    padding: "0px 15px",
  },
  alertBox: {
    padding: "10px 20px",
  },
  client_button: {
    background: "#649B42",
    textTransform: "none",
    color: "#FFFFFF",
    marginRight: "15px",
    padding: "8px 15px",
    "&:hover": {
      background: "#649B42",
    },
  },
  history_button: {
    background: "#649B42",
    textTransform: "none",
    color: "#FFFFFF",
    padding: "8px 15px",
    "&:hover": {
      background: "#649B42",
    },
  },
  filter_buttons: {
    textAlign: "right",
    padding: "0px 15px",
  },
  search_button: {
    padding: "6px 15px",
    marginRight: "12px",
  },
  clear_button: {
    padding: "6px 15px",
    background: "#D3710F0D",
    border: "1px solid #D3710F",
    color: "#D3710F",
    "&:hover": {
      background: "#D3710F0D",
    },
  },
  export_button: {
    padding: "8px 15px",
    background: "#366E93",
    textTransform: "none",
    color: "#FFF",
    "&:hover": {
      background: "#366E93",
    },
  },
  contract_button: {
    background: "#D08B1D",
    textTransform: "none",
    color: "#FFFFFF",
    padding: "8px 15px",
    marginRight: theme.spacing(2),
    "&:hover": {
      background: "#D08B1D",
    },
  },
  route_btn: {
    background: "#F23838",
    textTransform: "none",
    color: "#FFFFFF",
    padding: "8px 15px",
    marginRight: theme.spacing(2),
    "&:hover": {
      background: "#F23838",
    },
  },
}));
function BenchmarksList(props) {
  const columns = [
    {
      id: "id",
      disablePadding: true,
      label: "S.NO",
      checked: true,
    },
    {
      id: "category",
      disablePadding: true,
      label: "CATEGORY",
      checked: true,
    },
    {
      id: "alertName",
      disablePadding: true,
      label: "ALERT NAME",
      checked: true,
    },
    {
      id: "thresholdName",
      disablePadding: true,
      label: "THRESHOLD NAME",
      checked: true,
    },

    {
      id: "thresholdDefaultValue",
      disablePadding: true,
      label: "THRESHOLD DEFAULT VALUE",
      checked: true,
    },
    {
      id: "currentOverrideValue",
      disablePadding: true,
      label: "CURRENT OVERRIDE VALUE",
      checked: true,
    },
    { id: "actions", disablePadding: true, label: "ACTIONS", checked: true },
  ];

  let formFields = {
    benchmarkType: {
      name: "benchmarkType",
      label: "Benchmark Type",
      value: { label: "", value: "" },
      options: [],
      topLabel: true,
      validationRequired: true,
      // maxLength: 30,
      // validPattern: "SPECIAL_CHARS_DESC",
      isValid: true,
      // errorMsg: "Please enter your name",
      // ref: useRef(),
    },
    alertName: {
      name: "alertName",
      label: "AlertName",
      value: "",
      topLabel: true,
      // validationRequired: true,
      // validPattern: "SPECIAL_CHARS_DESC",
      isValid: true,
      // errorMsg: "Please select your address",
      lat: "",
      lng: "",
      // ref: useRef(),
    },
    whenYouGetThisAlert: {
      name: "whenYouGetThisAlert",
      label: "When you get this alert",
      value: "",
      topLabel: true,
      // validationRequired: true,
      // validPattern: "SPECIAL_CHARS_DESC",
      isValid: true,
      // errorMsg: "Please select your address",
    },
    getTheAlert: {
      name: "getTheAlert",
      label: "When will you get this alert",
      value: "",
      topLabel: true,
      validationRequired: false,
      validPattern: "EMAIL",
      isValid: true,
      // errorMsg: "Please enter your email",
      // ref: useRef(),
    },
    thresholdName: {
      name: "thresoldName",
      label: "Threshold Name",
      value: "",
      topLabel: true,
      validationRequired: true,
      maxLength: 10,
      validPattern: "NUMERIC",
      isValid: true,
    },
    thresholdDefaultValue: {
      name: "thresholdDefaultValue",
      label: "Threshold Default Value",
      value: "",
      //   validationRequired: true,
      isValid: true,
      errorMsg: "Please select role",
      validPattern: "ALPHABET_with_label",
      allowClear: true,
      topLabel: true,
    },
    currentOverrideValue: {
      name: "currentOverrideValue",
      label: "Current Override Value",
      value: "",
      topLabel: true,
      // validationRequired: true,
      maxLength: 10,
      validPattern: "NUMERIC",
      isValid: true,
      type: `Number`,
    },
  };
  const [selectObject, setSelectObject] = useState({});
  let [formData, setFormData] = useState(formFields);
  const forUpdateUserDetails = async (benchmark) => {
    setSelectObject(benchmark);
    setEditOpen(true);

    formData.benchmarkType.value = {
      label: benchmark.entity,
      value: benchmark.entity,
    };
    formData.alertName.value = benchmark.alert_name;
    formData.thresholdName.value = benchmark.threshold_name;
    formData.thresholdDefaultValue.value =
      benchmark.threshold_default_value.split(" ")[0];
    formData.currentOverrideValue.value = parseFloat(
      benchmark.current_override_value.split(" ")[0]
    ).toFixed(3);
    setFormData(formData);
    // let promise1 = Service.getEquipmentCategories();
    // Promise.all([promise1]).then(async (response) => {
    //   let newField = formData;
    //   newField["equiCategory"]["options"] = await response[0].data.map(
    //     (each) => ({ label: each.name, value: each.id })
    //   );
    //   setFormData(newField);
    // });
  };
  const classes = useStyles();
  const history = useHistory();
  Service = global.service;
  const filterFields = {
    client: {
      label: "",
      name: "client",
      value: "",
      placeholder: "Search by Company Name",
    },
    // date: { label: "Select date", name: "date", value: "" },
  };
  const [headRows, setHeadRows] = useState(columns);
  const [cols, setCols] = useState(columns);
  const [filters, setFilters] = useState(_.cloneDeep(formFields));
  const [spinner, setSpinner] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [benchmarkData, setBenchmarkdata] = useState();
  const [editOpen, setEditOpen] = useState(false);
  const [pagination, setPagination] = useState({
    current: CONFIG.PAGINATION.current,
    pageSize: CONFIG.PAGINATION.pageSize,
  });
  const [total, setTotal] = useState(0);
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const [alertUpdateData, setAlertUpdateData] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const updatehandleClose = () => {
    setEditOpen(false);
  };
  useEffect(() => {
    window.scrollTo({ top: 0 });
    let dataObj = {
      skip: pagination.current * pagination.pageSize,
      limit: pagination.pageSize,
    };

    renderClients(dataObj, cols);
  }, []);
  let csvlink = useRef();
  const renderClients = (dataObj, cols) => {
    setSpinner(true);
    Service.benchmarkDetails(dataObj)
      .then((res) => {
        if (res.status) {
          setBenchmarkdata(res.data);
          let checkedCols = cols.filter((col) => col.checked === true);
          setHeadRows(checkedCols);
          let data = res.data.map((eachData, i) => {
            switch (eachData.alert_name) {
              case "Low fuel":
                eachData.threshold_default_value = `${eachData.threshold_default_value} %`;
                eachData.current_override_value = `${parseFloat(
                  eachData.current_override_value
                ).toFixed(3)} %`;
                break;
              case "Battery voltage low":
                eachData.threshold_default_value = `${eachData.threshold_default_value} volts`;
                eachData.current_override_value = `${parseFloat(
                  eachData.current_override_value
                ).toFixed(3)} volts`;
                break;
              case "Battery voltage very low":
                eachData.threshold_default_value = `${eachData.threshold_default_value} volts`;
                eachData.current_override_value = `${parseFloat(
                  eachData.current_override_value
                ).toFixed(3)} volts`;
                break;
              case "Tire life":
                eachData.threshold_default_value = `${eachData.threshold_default_value} mm`;
                eachData.current_override_value = `${parseFloat(
                  eachData.current_override_value
                ).toFixed(3)} mm`;
                break;
              case "Speed limit":
                eachData.threshold_default_value = `${eachData.threshold_default_value} Km/hr`;
                eachData.current_override_value = `${parseFloat(
                  eachData.current_override_value
                ).toFixed(3)} Km/hr`;
                break;
              case "Average fuel consumption":
                eachData.threshold_default_value = `${eachData.threshold_default_value} Km/l`;
                eachData.current_override_value = `${parseFloat(
                  eachData.current_override_value
                ).toFixed(3)} Km/l`;
                break;

              case "Unknown Stop":
                eachData.threshold_default_value = `${eachData.threshold_default_value} Minutes`;
                eachData.current_override_value = `${parseFloat(
                  eachData.current_override_value
                ).toFixed(3)} Minutes`;
                break;
            }
            return {
              ...(checkedCols.find((data) => data.id === "id")
                ? {
                    id: <div style={{ textAlign: "center" }}>{i + 1}</div>,
                  }
                : {}),
              ...(checkedCols.find((data) => data.id === "category")
                ? {
                    category: (
                      <div style={{ textAlign: "center" }}>
                        {eachData.entity}
                      </div>
                    ),
                  }
                : {}),
              ...(checkedCols.find((data) => data.id === "alertName")
                ? {
                    alertName: (
                      <div style={{ textAlign: "center" }}>
                        {eachData.alert_name ? eachData.alert_name : "--"}
                      </div>
                    ),
                  }
                : {}),
              ...(checkedCols.find((data) => data.id === "thresholdName")
                ? {
                    thresholdName: (
                      <div style={{ textAlign: "center" }}>
                        {eachData.threshold_name
                          ? eachData.threshold_name
                          : "--"}
                      </div>
                    ),
                  }
                : {}),

              ...(checkedCols.find(
                (data) => data.id === "thresholdDefaultValue"
              )
                ? {
                    thresholdDefaultValue: (
                      <div style={{ textAlign: "center" }}>
                        {eachData.threshold_default_value
                          ? eachData.threshold_default_value
                          : "--"}
                      </div>
                    ),
                  }
                : {}),
              ...(checkedCols.find((data) => data.id === "currentOverrideValue")
                ? {
                    currentOverrideValue: (
                      <div style={{ textAlign: "center" }}>
                        {eachData.current_override_value
                          ? eachData.current_override_value
                          : "--"}
                      </div>
                    ),
                  }
                : {}),
              ...(checkedCols.find((data) => data.id === "actions")
                ? {
                    actions: (
                      <div>
                        <EditIcon
                          style={{
                            //   marginLeft: "5px",
                            color: "#1269A8",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            forUpdateUserDetails(eachData);
                          }}
                        />
                      </div>
                    ),
                  }
                : {}),
            };
          });
          setTotal(res.data.length);
          setTableData(data);
          setSpinner(false);
        } else {
          setAlertData({
            open: true,
            severity: CONFIG.ALERT_SEVERITY.error,
            message: res.message,
          });
        }
      })
      .catch((error) => {
        setSpinner(false);
        setAlertData({
          open: true,
          severity: CONFIG.ALERT_SEVERITY.error,
          message:
            error.response && error.response.data
              ? error.response.data.message
              : "Something went wrong!",
        });
      });
  };
  const decimalValidation = (value) => {
    const regex = new RegExp("^[0-9]+(.[0-9]{1,3})?$");
    return regex.test(value);
  };
  const otherThenalphaNumericValidation = (value) => {
    const regex = new RegExp("^[a-zA-Z0-9 ]*$");
    return regex.test(value);
  };
  const inputChangeHandler = (value, name) => {
    let newFields = _.cloneDeep(formData);
    if (
      decimalValidation(value) === true ||
      value === "" ||
      otherThenalphaNumericValidation(value) === true
    ) {
      // newField[name]["value"] = value;
      // setField(newField);
      newFields[name]["value"] = value;
      setFormData(newFields);
      // setPagination({ ...pagination, current: 0 });
      // let dataObj = value
      //   ? {
      //       searchText: value,
      //     }
      //   : {
      //       skip: pagination.current * pagination.pageSize,
      //       limit: pagination.pageSize,
      //     };
    }
    // else {
    //   newField[name]["value"] = value;
    //   setField(newField);
    // }
  };
  // const inputChangeHandler = (value, name) => {
  //   let newFields = _.cloneDeep(formData);
  //   newFields[name]["value"] = value;
  //   setFormData(newFields);
  //   setPagination({ ...pagination, current: 0 });
  //   let dataObj = value
  //     ? {
  //         searchText: value,
  //       }
  //     : {
  //         skip: pagination.current * pagination.pageSize,
  //         limit: pagination.pageSize,
  //       };
  //   // renderClients(dataObj, cols);
  // };
  // const dateChangeHandler = () => {};
  const pageChangeHandler = (page) => {
    let fil = Object.values(filters).filter((fil) => fil.value);
    let dataObj;
    if (fil.length > 0) {
      let filters = fil.map((fil) => {
        switch (fil.name) {
          case "client":
            return { searchText: fil.value };
          default:
            return;
        }
      });
      filters = Object.assign({}, ...filters);
      dataObj = {
        skip: page * pagination.pageSize,
        limit: pagination.pageSize,
        ...filters,
      };
    } else {
      dataObj = {
        skip: page * pagination.pageSize,
        limit: pagination.pageSize,
      };
    }
    renderClients(dataObj, cols);
    setPagination({ ...pagination, current: page });
  };
  const autoCompleteChangeHandler = (value, name) => {
    let newFields = _.cloneDeep(formData);
    newFields[name]["value"] = { label: value?.label, value: value?.value };
    newFields[name]["isValid"] = true;
    setFormData(newFields);
  };
  const rowsPerPageChangeHandler = (rowsPerPage) => {
    renderClients(
      {
        skip: pagination.current * rowsPerPage,
        limit: rowsPerPage,
      },
      cols
    );
    setPagination({ ...pagination, pageSize: rowsPerPage });
  };
  //   const clearHandler = () => {
  //     let newFilters = _.cloneDeep(filterFields);
  //     setFilters(newFilters);
  //     renderClients(
  //       {
  //         skip: pagination.current * pagination.pageSize,
  //         limit: pagination.pageSize,
  //       },
  //       cols
  //     );
  //   };
  const closeAlert = () => {
    let alert = _.cloneDeep(alertData);
    alert.open = false;
    alert.severity = "";
    alert.message = "";
    setAlertData(alert);
  };

  const closeUpdateAlert = () => {
    let alert = _.cloneDeep(alertUpdateData);
    alert.open = false;
    alert.severity = "";
    alert.message = "";
    setAlertUpdateData(alert);
  };
  const forUpdatingBenchmarkDetails = (formData, benchmarkList) => {
    let newObject = {
      id: benchmarkList.id,
      current_override_value: formData.currentOverrideValue.value,
    };
    Service.updateBenchmarkDetails(newObject).then((res) => {
      if (res.status) {
        setAlertUpdateData({
          open: true,
          severity: "success",
          message: res.message,
        });
        setTimeout(() => {
          closeUpdateAlert();
          updatehandleClose();
          window.location.reload();
        }, 2000);
      } else {
        setAlertUpdateData({
          open: true,
          severity: "error",
          message: res.message,
        });
      }
    });
  };
  const getDownloadData = () => {
    let data = benchmarkData.map((item, i) => {
      return {
        Sno: `${i + 1}`,
        Category: item.entity,
        "Alert Name": item.alert_name,
        "Threshold Name": item.threshold_name,
        "Threshold Default Value": item.threshold_default_value,
        "Current Override Value": item.current_override_value,
      };
    });
    setCsvData(data);
    setTimeout(() => {
      csvlink.current.link.click();
    }, 500);
  };
  const columnCheckHandler = (columns) => {
    setCols(columns);
    renderClients(
      {
        skip: pagination.current * pagination.pageSize,
        limit: pagination.pageSize,
        ...(filters.client.value ? { searchText: filters.client.value } : {}),
      },
      columns
    );
  };
  return (
    <div>
      <Header />
      <div className="main_container">
        <div className="header_box">
          <Typography className="header_text">Benchmarks List</Typography>
          <div className={classes.header_buttons}>
            <>
              <Button
                className={classes.export_button}
                startIcon={<DownloadIcon />}
                onClick={() => {
                  getDownloadData();
                }}
              >
                Export
              </Button>
              <CSVLink
                filename={"Benchmarks Report.csv"}
                data={csvData}
                style={{ display: "none" }}
                ref={csvlink}
              />
            </>
          </div>
        </div>
        {/* <div className="filter_box">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={2} className={"label_field"}>
                  <Fields.InputField
                    fieldData={filters.client}
                    inputChangeHandler={inputChangeHandler}
                    className="filter_field"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={2}>
                  <Button
                    className={classes.clear_button}
                    startIcon={<CloseIcon />}
                    onClick={() => {
                      clearHandler();
                    }}
                  >
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div> */}
        <div>
          {alertData.open ? (
            <div className={classes.alertBox}>
              <AlertMessage
                severity={alertData.severity}
                message={alertData.message}
                closeAlert={closeAlert}
              />
            </div>
          ) : null}
          {spinner ? (
            <CircularLoading />
          ) : (
            <Components.DataTable
              headRows={headRows}
              cols={cols}
              tableData={tableData}
              pagination={pagination}
              pageChangeHandler={pageChangeHandler}
              rowsPerPageChangeHandler={rowsPerPageChangeHandler}
              total={total}
              columnCheckHandler={columnCheckHandler}
            />
          )}
        </div>
      </div>
      <Dialog
        open={editOpen}
        onClose={updatehandleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        //   maxWidth="md" // Set the maximum width, you can use 'xs', 'sm', 'md', 'lg', 'xl', or a specific value
        //   fullWidth={true}
      >
        {alertUpdateData.open ? (
          <div className={classes.alertBox}>
            <AlertMessage
              severity={alertUpdateData.severity}
              message={alertUpdateData.message}
              closeAlert={closeUpdateAlert}
            />
          </div>
        ) : null}
        <DialogTitle
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
          textAlign="center"
        >
          Update Benchmark
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12}>
                <Grid item xs={6} spacing={2}>
                  <Fields.AntSelectableSearchField
                    fieldData={formData.benchmarkType}
                    autoCompleteChangeHandler={autoCompleteChangeHandler}
                    variant="outlined"
                    disabled={true}
                    InputProps={{
                      style: {
                        color: "gray",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Fields.InputField
                    fieldData={formData.alertName}
                    inputChangeHandler={inputChangeHandler}
                    variant="outlined"
                    disabled={true}
                    InputProps={{
                      style: {
                        color: "gray",
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container xs={12} spacing={2}></Grid>
            </Grid>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12}>
                <Typography style={{ marginTop: "5px" }}>
                  When you get this alert
                </Typography>
                <p
                  style={{
                    marginTop: "5px",
                    color: "black",
                    marginLeft: "50px",
                  }}
                >
                  {selectObject.when_you_get_alert}
                </p>
                <Divider
                  style={{
                    backgroundColor: "pink",
                    height: "2px",
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Fields.InputField
                  fieldData={formData.thresholdName}
                  inputChangeHandler={inputChangeHandler}
                  variant="outlined"
                  disabled={true}
                  InputProps={{
                    style: {
                      color: "gray",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Fields.InputField
                  fieldData={formData.thresholdDefaultValue}
                  inputChangeHandler={inputChangeHandler}
                  variant="outlined"
                  disabled={true}
                  InputProps={{
                    style: {
                      color: "gray",
                    },
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      {selectObject.threshold_default_value &&
                        selectObject.threshold_default_value.split(" ")[1]}
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <Fields.InputField
                  fieldData={formData.currentOverrideValue}
                  inputChangeHandler={inputChangeHandler}
                  variant="outlined"
                  endAdornment={
                    <InputAdornment position="end">
                      {selectObject.current_override_value &&
                        selectObject.current_override_value.split(" ")[1]}
                    </InputAdornment>
                  }
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={updatehandleClose} color="primary" style={{ 
              textTransform: "none", 
              backgroundColor: "#9d9d9d", // custom background color
              color: "white" // custom text color
            }}>
            Cancel
          </Button>
          <Button
            onClick={() => forUpdatingBenchmarkDetails(formData, selectObject)}
            color="primary"
            style={{ 
              textTransform: "none", 
              backgroundColor: "#366e93", // custom background color
              color: "white" // custom text color
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default BenchmarksList;
