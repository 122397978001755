import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import Divider from "@mui/material/Divider";
import AntdPagination from "./antd/AntdPagination";
import CustomColumns from "./CustomColumns";
const useStyles = makeStyles((theme) => ({
  table_head: {
    padding: "10px 40px 15px 24px",
    display: "flex",
  },
  selected_text: {
    color: "#929DAF",
    fontSize: "12px",
    fontFamily: "Montserrat, SemiBold",
    letterSpacing: "0.5px",
    alignSelf: "center",
  },
  row_paper: {
    background: "#FFFFFF",
    boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
    borderRadius: "4px",
  },
  divider: {
    height: "12px",
  },
}));

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, numSelected, rowCount, headRows } = props;
  return (
    <TableHead>
      <TableRow>
        {headRows.map((row) => {
          return (
            <TableCell
              key={row.id}
              align={row.alignLeft ? "left" : "center"}
              // padding={row.disablePadding ? "none" : "default"}
              className={classes.whiteColor}
              style={{
                padding: row.padding ? "20px 20px" : "",
                width: row.width,
                fontSize:14,
                fontWeight:800,
                padingLeft: "150px",
              }}
            >
              {row.label}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
  headRows: PropTypes.array.isRequired,
};

const CustomDataTable = (props) => {
  const classes = useStyles();
  const handlePageChange = (page) => {
    props.pageChangeHandler(page);
  };
  const handleChangeRowsPerPage = (size) => {
    props.rowsPerPageChangeHandler(size);
  };
  console.log(props)
  return (
    <>
      <div className={classes.table_head}>
        <div className="custom_pagination" style={{ flexGrow: 1 }}>
          {props?.pagination ? (
            <AntdPagination
              total={props?.total}
              current={props?.pagination?.current}
              pageSize={props?.pagination?.pageSize}
              handlePageChange={handlePageChange}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          ) : null}
        </div>
        {props?.cols && props?.disableColumnFilter!=true ?  <CustomColumns
          columns={props?.cols}
          checkApplyHandler={props?.columnCheckHandler}
        /> : null}
      </div>
      <div className="custom_table">
        <Table>
          <EnhancedTableHead
            classes={classes}
            numSelected={0}
            rowCount={20}
            onSelectAllClick={() => {}}
            headRows={props?.headRows}
          />
          <TableBody>
            {props.tableData.map((rec, i) => {
              return (
                <Fragment key={i}>
                  <TableRow className={classes.row_paper}>
                    {Object.keys(rec).map((key, j) => {
                      return (
                        <TableCell key={j} align="center" onClick={()=> {
                          if(rec[key]?.props?.children?.props?.data?.tripId){
                            props.tripIdHandler(rec[key]?.props?.children?.props?.data?.tripId);
                          }
                          if(key==="Action"){
                            props.removeRowHandler(rec)
                          }
                        }}>
                          {rec[`${key}`]}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                  <Divider className={classes.divider} />
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      </div>
      {props.tableData.length === 0 ? (
        <div
          style={{ textAlign: "center", height: "50vh", position: "relative" }}
        >
          <span
            style={{
              position: "absolute",
              top: "60%",
              left: "46%",
              fontSize: "20px",
            }}
          >
            No Data Found!
          </span>
        </div>
      ) : null}
    </>
  );
};

export default CustomDataTable;
