import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import moment from "moment";
import {
  AutoSelect,
  DateRangePicker,
  AntdSelect,
} from "../../components/index";
import { Typography, Paper } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import "chartjs-adapter-moment";
import { format } from "date-fns";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";

import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

export default function FuelHistoryContent(props) {
  const [ErrorMessage, setErrorMessage] = useState(null);
  const [maxYValue, setMaxYValue] = useState(200);
  const [stepValue, setStepValue] = useState(50);
  const [loading, setLoading] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = React.useState(null);
  const [timestamps, setTimestamps] = useState(null);
  const [fuelReadings, setFuelReadings] = useState(null);
  const [fuelRefilled, setFuelRefilled] = useState(null);
  const [distanceTravelledByFuel, setDistanceTravelledByFuel] = useState(null);
  const [vehicleNum, setDefaultVehicle] = useState(undefined);
  const [fromDateToHist, setFromDateToHist] = React.useState(
    new Date(new Date().setHours(0, 0, 0, 0))
  );
  const [toDateToHist, setToDateToHist] = React.useState(
    new Date(new Date().setHours(23, 59, 59, 0))
  );
  // const [toDateToHist, setToDateToHist] = React.useState(new Date(new Date()));
  const [dateRangeValue, setDateRangeValue] = React.useState([
    moment().startOf("day"),
    moment().endOf("day"),
  ]);
  //useState(new Date())
  //const maxDate = moment().toDate();
  const [vehicleSelectIndex, setVehicleSelectIndex] = React.useState(0);
  const [fuelReportTable, setFuelReportTable] = useState([]);

  const filterFields = {
    date: {
      name: "date",
      label: "Date",
      value: null,
      topLabel: true,
      isValid: true,
      validationRequired: true,
      validPattern: "DATE",
      errorMsg: "Please select date",
      maxDate: new Date(),
      disabledDate: (d) => !d || d.isAfter(new Date()),
    },
  };
  const [fields, setFields] = useState(filterFields);
  const getFuelData = (selectedTruck) => {
    let vehicleFlespiId = null;
    let truckId1 = null;
    if (selectedVehicle && selectedVehicle.flespi_id && selectedVehicle.id) {
      vehicleFlespiId = selectedVehicle.flespi_id;
      truckId1 = selectedVehicle.id;
    } else if(props?.trucksInfo && props?.trucksInfo.length > 0){
      let getSletedVehicleData = props?.trucksInfo.filter((i)=>i.regi_no === selectedVehicle)
      vehicleFlespiId = getSletedVehicleData[0]?.flespi_id;
      truckId1 = getSletedVehicleData[0]?.id;
    }
    // else if (selectedTruck && selectedTruck.flespi_id && selectedTruck.id) {
    //   vehicleFlespiId = selectedTruck.flespi_id;
    //   truckId1 = selectedTruck.id;
    // }

    // if (selectedVehicle && selectedVehicle.id) {
    //   truckId1 = selectedVehicle.id;
    // } else if (selectedTruck && selectedTruck.id) {
    //   truckId1 = selectedTruck.id;
    // }
     if (vehicleFlespiId || truckId1) {
      setLoading(true);
      // setDistanceTravelledByFuel(null);
      setFuelRefilled(null);
      setErrorMessage(null);

      // props.getRunningHours(null);
      // const promise1 = global.service.getTraveledDistance({
      //   flespiId: vehicleFlespiId,
      //   fromUnixTimestamp: parseInt(new Date(fromDateToHist).getTime() / 1000),
      //   toUnixTimestamp: parseInt(new Date(toDateToHist).getTime() / 1000),
      // });
      const promise2 = global.service.getFuelHistory({
        flespiId: vehicleFlespiId,
        from: Math.floor(new Date(fromDateToHist).getTime() / 1000),
        to: Math.floor(new Date(toDateToHist).getTime() / 1000),
      });
      const promise3 = global.service.getFuelTableApi({
        truckId: truckId1,
        // from: parseInt((new Date(fromDateToHist).getTime()) / 1000),
        // to: parseInt((new Date(toDateToHist).getTime()) / 1000)
        from: Math.floor(new Date(fromDateToHist).getTime() / 1000),
        to: Math.floor(new Date(toDateToHist).getTime() / 1000),
      });
      Promise.all([promise2, promise3])
        .then((response) => {
          // if (response[0].data) {
          //   let mileageData = response[0].data;
          //   if (mileageData.length > 1) {
          //     let distance =
          //       mileageData[mileageData.length - 1]["vehicle.mileage"] -
          //       mileageData[0]["vehicle.mileage"];
          //     setDistanceTravelledByFuel(
          //       distance ? distance.toFixed(2) + " KM" : ""
          //     );
          //   }
          // }
          if (response[0]) {
            // setLoading(false);
            if(response[0]?.status){
              if (response[0]?.data?.length == 0) {
                setErrorMessage("No Data Available");
                setFuelReadings(null)
              } else {
                setFuelRefilled(
                  response[0]?.fuelRefilled ? response[0]?.fuelRefilled + " L" : 0
                );
  
                let graphData = [];
                let prevTimeValue;
                let maxValue = 0;
                for (let record of response[0].data) {
                  const timeValue = moment(record.timestamp * 1000).format(
                    "yyyy-MM-DD HH:mm"
                  );
                  if (maxValue < parseInt(record.fuel)) {
                    maxValue = parseInt(record.fuel);
                  }
                  if (prevTimeValue) {
                    if (prevTimeValue != timeValue) {
                      graphData.push(record);
                      prevTimeValue = timeValue;
                    }
                  } else {
                    graphData.push(record);
                    prevTimeValue = timeValue;
                  }
                }
                // setting value near to hundreds
                maxValue = maxValue < 200 ? 200 : maxValue < 300 ? 300 : 400;
                let stepValue = 50;
                setTimestamps(
                  graphData.map((m) =>
                    format(m.timestamp * 1000, "dd-MM-yyyy HH:mm:ss")
                  )
                );
                // setTimestamps(graphData.map(m => m.timestamp));
                setFuelReadings(graphData.map((m) => m.fuel));
                setMaxYValue(maxValue);
                setStepValue(stepValue);
              }
            }else{
              setFuelReadings(null)
            }

          }if (response[1]) {
            if(response[1]?.status){
              setFuelReportTable(response[1]);

            }else{
              setFuelReportTable([])
            }
          }
          //  else {
          //   // setLoading(false);
          //   setErrorMessage("No Data Available");
          // }
          setLoading(false);
        })
        .catch((err) => {
          // this.setState({ successMsg: err.toString() });
          setLoading(false);
          setErrorMessage("Unable to fetch data from API");
        });
    }
  };

  useEffect(() => {
    if (props.fuelVehicles) {
      setDefaultVehicle(props.fuelVehicles[0]);
      setSelectedVehicle(props.fuelVehicles[0]);
      onSelectFuelVehicle(props.fuelVehicles[0]);
      setDateRangeValue([
        moment().startOf("day"),
        // moment().startOf('minute')]);
        moment().endOf("day"),
      ]);
      //moment().endOf('day')]);
    }
  }, [props.fuelVehicles]);

  useEffect(() => {
    getFuelData();
  }, [selectedVehicle, fromDateToHist, toDateToHist,props?.trucksInfo]);

  const onSelectFuelVehicle = (value) => {
    if (value && value.length > 0) {
      let vehicleNo = value;
      let vehiclesInfo = props.trucksInfo.filter(
        (x) => x.regi_no.toLowerCase().trim() == vehicleNo.toLowerCase().trim()
      );
      if (vehiclesInfo && vehiclesInfo.length > 0) {
        setSelectedVehicle(vehiclesInfo[0]);
        setDefaultVehicle(value);
      }
    }
  };

  const handleDateRangeControl = (dateArray) => {
    if (moment(dateArray[1]).diff(dateArray[0], "m") > 0) {
      setFromDateToHist(dateArray[0]._d);
      if (moment(dateArray[1]).diff(dateArray[0], "d") > 6) {
        dateArray[1] = moment(dateArray[0]).endOf("day").add(6, "d");
      }
      setToDateToHist(dateArray[1]._d);
      setDateRangeValue(dateArray);
    }
  };

  const state = {
    labels: timestamps ? timestamps : [],
    datasets: [
      {
        label: "Fuel quantity(L)",
        fill: false,
        lineTension: 0,
        backgroundColor: "#FFFFFF",
        borderColor: "#068ae4",
        borderWidth: 1,
        data: fuelReadings ? fuelReadings : [],
        pointStyle: "circle",
        radius: 0.5,
        pointBackgroundColor: "#068ae4",
        hoverBackgroundColor: "#FF0000",
      },
    ],
  };
  return (
    <>
      <div style={{ padding: 10, height: window.innerHeight - 150 }}>
        <div style={{ display: "flex", padding: 5 }}>
          <AntdSelect
            key="fuel-vehicle-dropdown"
            placeholder="Select Vehicle"
            items={props.fuelVehicles}
            onChange={onSelectFuelVehicle}
            value={vehicleNum}
          />

          <div style={{ padding: 5 }}>
            <DateRangePicker
              fieldData={fields.date}
              onDateSelect={handleDateRangeControl}
              value={dateRangeValue}
            />
          </div>
          {/* <div style={{ padding: 5, paddingLeft: 10, alignItems: 'center', float: 'left', breakInside: 'avoid', width: 250 }}>
                        <Typography style={{ color: '#366E93', fontSize: 12, fontWeight: 600 }}> Fuel Refilled: {fuelRefilled}</Typography>
                        <Typography style={{ color: '#366E93', fontSize: 12, fontWeight: 600 }}> Distance Travelled: {distanceTravelledByFuel}</Typography>
                    </div> */}
        </div>
        {loading ? (
          <CircularProgress
            disableShrink
            style={{ position: "fixed", top: "50%", left: "50%" }}
          />
        ) : ErrorMessage ? (
          <div
            style={{
              position: "fixed",
              top: "50%",
              width: "100%",
              textAlign: "center",
              fontSize: 25,
              fontFamily: "sans-serif",
              color: "#366E93",
            }}
          >
            <Typography>{ErrorMessage} </Typography>
          </div>
        ) : (
          <>
            <div
              key={
                selectedVehicle +
                "-" +
                new Date(fromDateToHist).getTime() +
                "-" +
                new Date(toDateToHist).getTime()
              }
              style={{ padding: 10, paddingBottom: 20, marginBottom: 20 }}
            >
              <Line
                style={{
                  backgroundColor: "#FFFFFF",
                  height: window.innerHeight - 250,
                }}
                data={state}
                options={{
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    tooltip: {
                      displayColors: false,
                      backgroundColor: "#000",
                      bodyColor: "#FFF",
                      titleColor: "#FFF",
                    },
                  },
                  scales: {
                    y: {
                      display: true,
                      min: 0,
                      max: maxYValue,
                      title: {
                        display: true,
                        text: "Fuel Quantity(L)",
                        font: {
                          family: "sans-serif",
                          size: 12,
                          weight: 500,
                        },
                      },
                      // gridLines: {
                      //     color: '#03204F',
                      //     drawBorder: true,
                      //     tickMarkLength: 1,
                      //     display: true,
                      //     lineWidth: 0.10
                      // },
                      ticks: {
                        // beginAtZero: true,
                        stepSize: stepValue,
                        // display: true,
                        // fontStyle: 'normal',
                        // fontSize: 12,
                        // fontColor: '#222222',
                        // fontFamily: 'Arial',
                        // padding: 10
                      },
                    },
                    x: {
                      type: "time",
                      time: {
                        unit: "hour",
                        parser: "DD-MM-yyyy HH:mm",
                        displayFormats: {
                          hour: "DD-MM-yyyy HH:mm",
                        },
                        tooltipFormat: "DD-MM-yyyy HH:mm",
                      },
                      ticks: {
                        autoSkip: false,
                        maxRotation: 90,
                        minRotation: 90,
                        padding: 0,
                      },
                    },
                  },
                }}
              />
            </div>

            <div
              style={{
                backgroundColor: "#f4f4f4",
                marginTop: "30px",
                border: "1px solid black",
                marginLeft: "-25px",
                marginRight: "-25px",
              }}
            >
              <Typography
                style={{
                  padding: "10px",
                  color: "black",
                  fontSize: 18,
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                Fuel Refill
              </Typography>
              <Paper>
                <TableContainer>
                  <Table>
                    <TableHead sx={{ backgroundColor: "#366E93" }}>
                      <TableRow style={{ fontFamily: "sans-serif" }}>
                        <TableCell
                          sx={{
                            color: "white",
                            padding: "15px 40px",
                            fontSize: "18px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          DATE & TIME
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            padding: "15px 40px",
                            fontSize: "18px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          DURATION
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            padding: "15px 40px",
                            fontSize: "18px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          LATITUDE
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            padding: "15px 40px",
                            fontSize: "18px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          LONGITUDE
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            padding: "15px 40px",
                            fontSize: "18px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          ODO
                          <br />
                          (KM)
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            padding: "15px 40px",
                            fontSize: "18px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          BEGIN FUEL
                          <br />
                          (L)
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            padding: "15px 40px",
                            fontSize: "18px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          END FUEL
                          <br />
                          (L)
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            padding: "15px 40px",
                            fontSize: "18px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          REFILL
                          <br />
                          (L)
                        </TableCell>
                        {/* <TableCell sx={{color:'white',padding:'15px 40px',fontSize:'18px',textAlign:'center'}}>Consumption</TableCell>
                                        <TableCell sx={{color:'white',padding:'15px 40px',fontSize:'18px',textAlign:'center'}}>Mileage</TableCell> */}
                      </TableRow>
                    </TableHead>

                    {fuelReportTable?.fuelRefills !== undefined &&
                    fuelReportTable?.fuelRefills?.length > 0 ? ( 
                      <TableBody>
                        {fuelReportTable?.fuelRefills?.map((refill) => {
                          return (
                            <TableRow style={{ fontFamily: "sans-serif" }}>
                              <TableCell
                                sx={{
                                  padding: "15px 30px",
                                  fontSize: "16px",
                                  textAlign: "center",
                                }}
                              >
                                {refill?.dateTime}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "15px 30px",
                                  fontSize: "16px",
                                  textAlign: "center",
                                }}
                              >
                                {/* {`${Math.round(
                                parseInt(refill?.duration)
                              )} min`} */}
                              {refill?.duration}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "15px 30px",
                                  fontSize: "16px",
                                  textAlign: "center",
                                }}
                              >
                                {refill?.latitude}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "15px 30px",
                                  fontSize: "16px",
                                  textAlign: "center",
                                }}
                              >
                                {refill?.longitude}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "15px 30px",
                                  fontSize: "16px",
                                  textAlign: "center",
                                }}
                              >
                                {refill?.odo}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "15px 30px",
                                  fontSize: "16px",
                                  textAlign: "center",
                                }}
                              >
                                {refill?.beginFuel}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "15px 30px",
                                  fontSize: "16px",
                                  textAlign: "center",
                                }}
                              >
                                {refill?.endFuel}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "15px 30px",
                                  fontSize: "16px",
                                  textAlign: "center",
                                }}
                              >
                                {refill?.refill}
                              </TableCell>
                              {/* <TableCell sx={{padding:'15px 30px',fontSize:'16px',textAlign:'center'}}>{refill.consumption}</TableCell>
                                                <TableCell sx={{padding:'15px 30px',fontSize:'16px',textAlign:'center'}}>{refill.mileage}</TableCell>  */}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    ) : (
                      <div
                        style={{
                          position: "absolute",
                          marginLeft: "800px",
                          paddingTop: "5px",
                          fontSize: 18,
                          fontWeight: "bold",
                          fontFamily: "sans-serif",
                          color: "#252540",
                        }}
                      >
                        No Refills Found
                      </div>
                    )}
                  </Table>
                </TableContainer>
              </Paper>
            </div>
            <div
              style={{
                backgroundColor: "#f4f4f4",
                marginTop: "45px",
                marginBottom: "20px",
                border: "1px solid black",
                marginLeft: "-25px",
                marginRight: "-25px",
                marginBottom: "",
              }}
            >
              <Typography
                style={{
                  padding: "10px",
                  color: "black",
                  fontSize: 18,
                  fontWeight: "bold",
                }}
              >
                Fuel Consumption
              </Typography>
              <Paper>
                <TableContainer>
                  <Table>
                    <TableHead sx={{ backgroundColor: "#366E93" }}>
                      <TableRow style={{ fontFamily: "sans-serif" }}>
                        <TableCell
                          sx={{
                            color: "white",
                            padding: "15px 30px",
                            fontSize: "18px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          VEHICLE NUMBER
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            padding: "15px 30px",
                            fontSize: "18px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          FROM DATE
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            padding: "15px 30px",
                            fontSize: "18px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          TO DATE
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            padding: "15px 30px",
                            fontSize: "18px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          BEGIN FUEL
                          <br />
                          (L)
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            padding: "15px 30px",
                            fontSize: "18px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          END FUEL
                          <br />
                          (L)
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            padding: "15px 30px",
                            fontSize: "18px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          TOTAL REFILL
                          <br />
                          (L)
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            padding: "15px 30px",
                            fontSize: "18px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          BEGIN ODO
                          <br />
                          (KM)
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            padding: "15px 30px",
                            fontSize: "18px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          END ODO
                          <br />
                          (KM)
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            padding: "15px 30px",
                            fontSize: "18px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          TOTAL KMS
                          <br />
                          (KM)
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            padding: "15px 30px",
                            fontSize: "18px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          TOTAL CONSUMPTION
                          <br />
                          (L)
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "white",
                            padding: "15px 30px",
                            fontSize: "18px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          TOTAL MILEAGE
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {fuelReportTable?.fuelConsumption && fuelReportTable?.fuelConsumption !== undefined ? (
                      <TableBody>
                        <TableRow style={{ fontFamily: "sans-serif" }}>
                          <TableCell
                            sx={{
                              padding: "15px 30px",
                              fontSize: "16px",
                              textAlign: "center",
                            }}
                          >
                            {fuelReportTable?.fuelConsumption?.vehcile}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "15px 30px",
                              fontSize: "16px",
                              textAlign: "center",
                            }}
                          >
                            {fuelReportTable?.fuelConsumption?.fromDateTime}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "15px 30px",
                              fontSize: "16px",
                              textAlign: "center",
                            }}
                          >
                            {fuelReportTable?.fuelConsumption?.toDateTime}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "15px 30px",
                              fontSize: "16px",
                              textAlign: "center",
                            }}
                          >
                            {fuelReportTable?.fuelConsumption?.beginFuel}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "15px 30px",
                              fontSize: "16px",
                              textAlign: "center",
                            }}
                          >
                            {fuelReportTable?.fuelConsumption?.endFuel}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "15px 30px",
                              fontSize: "16px",
                              textAlign: "center",
                            }}
                          >
                            {fuelReportTable?.fuelConsumption?.totalRefill}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "15px 30px",
                              fontSize: "16px",
                              textAlign: "center",
                            }}
                          >
                            {fuelReportTable?.fuelConsumption?.beginOdo}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "15px 30px",
                              fontSize: "16px",
                              textAlign: "center",
                            }}
                          >
                            {fuelReportTable?.fuelConsumption?.endOdo}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "15px 30px",
                              fontSize: "16px",
                              textAlign: "center",
                            }}
                          >
                            {fuelReportTable?.fuelConsumption?.totalKM}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "15px 30px",
                              fontSize: "16px",
                              textAlign: "center",
                            }}
                          >
                            {fuelReportTable?.fuelConsumption?.totalConsumption}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "15px 30px",
                              fontSize: "16px",
                              textAlign: "center",
                            }}
                          >
                            {fuelReportTable?.fuelConsumption?.totalMileage}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    ) : (
                      <div
                        style={{
                          position: "absolute",
                          marginLeft: "800px",
                          paddingTop: "5px",
                          fontSize: 18,
                          fontWeight: "bold",
                          fontFamily: "sans-serif",
                          color: "#252540",
                        }}
                      >
                        No Consumption Found
                      </div>
                    )}
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </>
        )}
      </div>
    </>
  );
}
