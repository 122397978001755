import React, { useState, useEffect, useRef } from "react";
import Header from "../../components/header";
import "../../common.css";
import { CircularProgress, InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { Button } from "@mui/material";
// import * as Fields from "../../sharedComponents";
// import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
// import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import _ from "lodash";
// import EditIcon from "@material-ui/icons/Edit";
// import CloseIcon from "@mui/icons-material/Close";
// import * as Components from "../../sharedComponents";
// import * as CONFIG from "../../config/G/lobalConstants";
// import { useHistory } from "react-router-dom";
// import CircularLoading from "../../components/loader/circularLoading";
// import PhoneAndroid from "@mui/icons-material/PhoneAndroid";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import * as Fields from "../../sharedComponents";
import { Grid } from "@material-ui/core";
import ValidateFields from "../../validations/validateFields";
import AlertMessage from "../../components/alertmessage/AlertMessage";
import moment from "moment";
import Util from "../../utils";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Typography } from "antd";

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />
        </Draggable>
    );
}

const useStyles = makeStyles(() => ({
    addUser_btn: {
        textTransform: "none",
        backgroundColor: "#649B42",
        "&:hover": {
            backgroundColor: "#649B42",
        },
        padding: "8px 15px",
        marginRight: "12px",
    },
    header_buttons: {
        padding: "0px 15px",
    },
    site_button: {
        background: "#649B42",
        textTransform: "none",
        color: "#FFFFFF",
        marginRight: "15px",
        padding: "8px 15px",
        "&:hover": {
            background: "#649B42",
        },
    },
    clear_button: {
        padding: "6px 15px",
        background: "#D3710F0D",
        border: "1px solid #D3710F",
        color: "#D3710F",
        "&:hover": {
            background: "#D3710F0D",
        },
    },
    filter_buttons: {
        textAlign: "right",
        padding: "0px 15px",
    },
    dialogPaper: {
        minWidth: '500px', // Set the minimum width to 300px
        maxWidth: '600px', // Set the maximum width to 500px
    },
    divider: {
        borderTop: '2px solid #F15F4C', // Customize the thickness and color of the divider
    },
    dialogTitle: {
        cursor: 'move',
        textAlign: 'center',
        color: '#F15F4C'
    }
}));
export const CommandsAndSettings = (props) => {

    const formFields = {

        deviceImei: {
            name: "deviceImei",
            label: "Device (IMEI) Number",
            value: { value: "", label: "" },
            options: [],
            topLabel: true,
            validationRequired: true,
            validPattern: "ALPHABET_with_numeric_label",
            isValid: true,
            errorMsg: "Please select device type",
            ref: useRef(),
            allowClear: true,
            labelColor: "#397296",
            // image: subscriptionType,
        },
        stopMinPeriod: {
            name: "stopMinPeriod",
            label: "Data Recording Timeout",
            value: 0,
            options: [],
            topLabel: true,
            validationRequired: false,
            //   ref: useRef(),
            // allowClear: true,
            labelColor: "#397296",
            // image: subscriptionType,
            type : 'number'
        },
        stopMinRecords: {
            name: "stopMinRecords",
            label: "Saved Records",
            value: 1,
            topLabel: true,
            validationRequired: false,
            labelColor: "#397296",
            type : 'number'
        },
        stopSendPeriod: {
            name: "stopSendPeriod",
            label: "Send Period",
            value: 0,
            topLabel: true,
            validationRequired: false,
            labelColor: "#397296",
            type : 'number'
        },
        moveMinAngle: {
            name: "moveMinAngle",
            label: "Timeout",
            value: 0,
            topLabel: true,
            validationRequired: false,
            labelColor: "#397296",
            type : 'number'
        },
        moveMinDistance: {
            name: "moveMinDistance",
            label: "Distance",
            value: 0,
            topLabel: true,
            validationRequired: false,
            labelColor: "#397296",
            type : 'number'
        },
        moveMinPeriod: {
            name: "moveMinPeriod",
            label: "Angle Change",
            value: 0,
            topLabel: true,
            validationRequired: false,
            labelColor: "#397296",
            type : 'number'
        },
        moveMinRecords: {
            name: "moveMinRecords",
            label: "Speed Change",
            value: 0,
            topLabel: true,
            validationRequired: false,
            labelColor: "#397296",
            type : 'number'
        },
        moveMinSpeedDelta: {
            name: "moveMinSpeedDelta",
            label: "Saved Records",
            value: 1,
            topLabel: true,
            validationRequired: false,
            labelColor: "#397296",
            type : 'number'
        },
        moveSendPeriod: {
            name: "moveSendPeriod",
            label: "Send Period",
            value: 0,
            topLabel: true,
            validationRequired: false,
            labelColor: "#397296",
            type : 'number'
        },
    };

    const roamingFormFields = {

        deviceImei: {
            name: "deviceImei",
            label: "Device (IMEI) Number",
            value: { value: "", label: "" },
            options: [],
            topLabel: true,
            validationRequired: true,
            validPattern: "ALPHABET_with_numeric_label",
            isValid: true,
            errorMsg: "Please select device type",
            ref: useRef(),
            allowClear: true,
            labelColor: "#397296",
            // image: subscriptionType,
        },
        stopMinPeriod: {
            name: "stopMinPeriod",
            label: "Data Recording Timeout",
            value: 0,
            options: [],
            topLabel: true,
            validationRequired: false,
            //   ref: useRef(),
            // allowClear: true,
            labelColor: "#397296",
            // image: subscriptionType,
            type : 'number'
        },
        stopMinRecords: {
            name: "stopMinRecords",
            label: "Saved Records",
            value: 1,
            topLabel: true,
            validationRequired: false,
            labelColor: "#397296",
            type : 'number'
        },
        stopSendPeriod: {
            name: "stopSendPeriod",
            label: "Send Period",
            value: 0,
            topLabel: true,
            validationRequired: false,
            labelColor: "#397296",
            type : 'number'
        },
        moveMinAngle: {
            name: "moveMinAngle",
            label: "Timeout",
            value: 0,
            topLabel: true,
            validationRequired: false,
            labelColor: "#397296",
            type : 'number'
        },
        moveMinDistance: {
            name: "moveMinDistance",
            label: "Distance",
            value: 0,
            topLabel: true,
            validationRequired: false,
            labelColor: "#397296",
            type : 'number'
        },
        moveMinPeriod: {
            name: "moveMinPeriod",
            label: "Angle Change",
            value: 0,
            topLabel: true,
            validationRequired: false,
            labelColor: "#397296",
            type : 'number'
        },
        moveMinRecords: {
            name: "moveMinRecords",
            label: "Speed Change",
            value: 0,
            topLabel: true,
            validationRequired: false,
            labelColor: "#397296",
            type : 'number'
        },
        moveMinSpeedDelta: {
            name: "moveMinSpeedDelta",
            label: "Saved Records",
            value: 1,
            topLabel: true,
            validationRequired: false,
            labelColor: "#397296",
            type : 'number'
        },
        moveSendPeriod: {
            name: "moveSendPeriod",
            label: "Send Period",
            value: 0,
            topLabel: true,
            validationRequired: false,
            labelColor: "#397296",
            type : 'number'
        },
    };

    const unkonownFormFields = {

        deviceImei: {
            name: "deviceImei",
            label: "Device Imei Number",
            value: { value: "", label: "" },
            options: [],
            topLabel: true,
            validationRequired: true,
            validPattern: "ALPHABET_with_numeric_label",
            isValid: true,
            errorMsg: "Please select device type",
            ref: useRef(),
            allowClear: true,
            labelColor: "#397296",
            // image: subscriptionType,
        },
        stopMinPeriod: {
            name: "stopMinPeriod",
            label: "Data Recording Timeout",
            value: 0,
            options: [],
            topLabel: true,
            validationRequired: false,
            //   ref: useRef(),
            // allowClear: true,
            labelColor: "#397296",
            // image: subscriptionType,
            type : 'number'
        },
        stopMinRecords: {
            name: "stopMinRecords",
            label: "Saved Records",
            value: 1,
            topLabel: true,
            validationRequired: false,
            labelColor: "#397296",
            type : 'number'
        },
        stopSendPeriod: {
            name: "stopSendPeriod",
            label: "Send Period",
            value: 0,
            topLabel: true,
            validationRequired: false,
            labelColor: "#397296",
            type : 'number'
        },
        moveMinAngle: {
            name: "moveMinAngle",
            label: "Timeout",
            value: 0,
            topLabel: true,
            validationRequired: false,
            labelColor: "#397296",
            type : 'number'
        },
        moveMinDistance: {
            name: "moveMinDistance",
            label: "Distance",
            value: 0,
            topLabel: true,
            validationRequired: false,
            labelColor: "#397296",
            type : 'number'
        },
        moveMinPeriod: {
            name: "moveMinPeriod",
            label: "Angle Change",
            value: 0,
            topLabel: true,
            validationRequired: false,
            labelColor: "#397296",
            type : 'number'
        },
        moveMinRecords: {
            name: "moveMinRecords",
            label: "Speed Change",
            value: 0,
            topLabel: true,
            validationRequired: false,
            labelColor: "#397296",
            type : 'number'
        },
        moveMinSpeedDelta: {
            name: "moveMinSpeedDelta",
            label: "Saved Records",
            value: 1,
            topLabel: true,
            validationRequired: false,
            labelColor: "#397296",
            type : 'number'
        },
        moveSendPeriod: {
            name: "moveSendPeriod",
            label: "Send Period",
            value: 0,
            topLabel: true,
            validationRequired: false,
            labelColor: "#397296",
            type : 'number'
        },
    };
    const [fields, setFields] = useState(formFields);
    const [roamingFields, setRoamingFields] = useState(roamingFormFields);
    const [unkonownFields, setUnkonownFields] = useState(unkonownFormFields);
    const util = new Util()
    const [open, setOpen] = useState(props.open);
    const [alertData, setAlertData] = useState({
        open: false,
        severity: "",
        message: "",
    });
    const [roamingAlertData, setRoamingAlertData] = useState({
        open: false,
        severity: "",
        message: "",
    });
    const [unknownAlertData, setUnknownAlertData] = useState({
        open: false,
        severity: "",
        message: "",
    });
    const closeAlert = () => {
        let alert = _.cloneDeep(alertData);
        alert.open = false;
        alert.severity = "";
        alert.message = "";
        setAlertData(alert);
    };
    const closeRoamingAlert = () => {
        let alert = _.cloneDeep(roamingAlertData);
        alert.open = false;
        alert.severity = "";
        alert.message = "";
        setRoamingAlertData(alert);
    };
    const closeUnknownAlert = () => {
        let alert = _.cloneDeep(unknownAlertData);
        alert.open = false;
        alert.severity = "";
        alert.message = "";
        setUnknownAlertData(alert);
    };
    let classes = useStyles();
    let placeRefCircle = useRef();
    const [spinner, setSpinner] = useState(false);
    const fileInputRef = useRef(null);
    const validateFields = new ValidateFields();
    const Service = global.service
    useEffect(async () => {
        let newFields = _.cloneDeep(fields);
        let newRoamingFields = _.cloneDeep(roamingFields)
        let newUnknownFields = _.cloneDeep(unkonownFields)
        if (props.assignData) {
            newFields.deviceImei.value = { label: props?.assignData?.deviceId, value: props?.assignData?.deviceImei }
        }
        await Service.getNetworkSettingsData(props.assignData.deviceId)
        .then((res) => {
            setSpinner(false);
            if (res.status) {
                if(res.data.homeSettings[0].pending !== null){
                    newFields.stopMinPeriod.value = res.data.homeSettings[0].pending.on_stop.min_period
                    newFields.stopMinRecords.value = res.data.homeSettings[0].pending.on_stop.min_records
                    newFields.stopSendPeriod.value = res.data.homeSettings[0].pending.on_stop.send_period
                    newFields.moveMinAngle.value = res.data.homeSettings[0].pending.on_move.min_period
                    newFields.moveMinDistance.value = res.data.homeSettings[0].pending.on_move.min_distance
                    newFields.moveMinPeriod.value = res.data.homeSettings[0].pending.on_move.min_angle
                    newFields.moveMinRecords.value = res.data.homeSettings[0].pending.on_move.min_speed_delta
                    newFields.moveMinSpeedDelta.value = res.data.homeSettings[0].pending.on_move.min_records
                    newFields.moveSendPeriod.value = res.data.homeSettings[0].pending.on_move.send_period

                }
                if(res.data.roamingSettings[0].pending !== null){
                    newRoamingFields.stopMinPeriod.value = res.data.roamingSettings[0].pending.on_stop.min_period
                    newRoamingFields.stopMinRecords.value = res.data.roamingSettings[0].pending.on_stop.min_records
                    newRoamingFields.stopSendPeriod.value = res.data.roamingSettings[0].pending.on_stop.send_period
                    newRoamingFields.moveMinAngle.value = res.data.roamingSettings[0].pending.on_move.min_period
                    newRoamingFields.moveMinDistance.value = res.data.roamingSettings[0].pending.on_move.min_distance
                    newRoamingFields.moveMinPeriod.value = res.data.roamingSettings[0].pending.on_move.min_angle
                    newRoamingFields.moveMinRecords.value = res.data.roamingSettings[0].pending.on_move.min_speed_delta
                    newRoamingFields.moveMinSpeedDelta.value = res.data.roamingSettings[0].pending.on_move.min_records
                    newRoamingFields.moveSendPeriod.value = res.data.roamingSettings[0].pending.on_move.send_period

                }
                if(res.data.unknownSettings[0].pending !== null){
                    newUnknownFields.stopMinPeriod.value = res.data.unknownSettings[0].pending.on_stop.min_period
                    newUnknownFields.stopMinRecords.value = res.data.unknownSettings[0].pending.on_stop.min_records
                    newUnknownFields.stopSendPeriod.value = res.data.unknownSettings[0].pending.on_stop.send_period
                    newUnknownFields.moveMinAngle.value = res.data.unknownSettings[0].pending.on_move.min_period
                    newUnknownFields.moveMinDistance.value = res.data.unknownSettings[0].pending.on_move.min_distance
                    newUnknownFields.moveMinPeriod.value = res.data.unknownSettings[0].pending.on_move.min_angle
                    newUnknownFields.moveMinRecords.value = res.data.unknownSettings[0].pending.on_move.min_speed_delta
                    newUnknownFields.moveMinSpeedDelta.value = res.data.unknownSettings[0].pending.on_move.min_records
                    newUnknownFields.moveSendPeriod.value = res.data.unknownSettings[0].pending.on_move.send_period

                }
                if(res.data.homeSettings[0].current !== null){
                    newFields.stopMinPeriod.value = res.data.homeSettings[0].current.on_stop.min_period
                    newFields.stopMinRecords.value = res.data.homeSettings[0].current.on_stop.min_records
                    newFields.stopSendPeriod.value = res.data.homeSettings[0].current.on_stop.send_period
                    newFields.moveMinAngle.value = res.data.homeSettings[0].current.on_move.min_period
                    newFields.moveMinDistance.value = res.data.homeSettings[0].current.on_move.min_distance
                    newFields.moveMinPeriod.value = res.data.homeSettings[0].current.on_move.min_angle
                    newFields.moveMinRecords.value = res.data.homeSettings[0].current.on_move.min_speed_delta
                    newFields.moveMinSpeedDelta.value = res.data.homeSettings[0].current.on_move.min_records
                    newFields.moveSendPeriod.value = res.data.homeSettings[0].current.on_move.send_period

                }
                if(res.data.roamingSettings[0].current !== null){
                    newRoamingFields.stopMinPeriod.value = res.data.roamingSettings[0].current.on_stop.min_period
                    newRoamingFields.stopMinRecords.value = res.data.roamingSettings[0].current.on_stop.min_records
                    newRoamingFields.stopSendPeriod.value = res.data.roamingSettings[0].current.on_stop.send_period
                    newRoamingFields.moveMinAngle.value = res.data.roamingSettings[0].current.on_move.min_period
                    newRoamingFields.moveMinDistance.value = res.data.roamingSettings[0].current.on_move.min_distance
                    newRoamingFields.moveMinPeriod.value = res.data.roamingSettings[0].current.on_move.min_angle
                    newRoamingFields.moveMinRecords.value = res.data.roamingSettings[0].current.on_move.min_speed_delta
                    newRoamingFields.moveMinSpeedDelta.value = res.data.roamingSettings[0].current.on_move.min_records
                    newRoamingFields.moveSendPeriod.value = res.data.roamingSettings[0].current.on_move.send_period

                }
                if(res.data.unknownSettings[0].current !== null){
                    newUnknownFields.stopMinPeriod.value = res.data.unknownSettings[0].current.on_stop.min_period
                    newUnknownFields.stopMinRecords.value = res.data.unknownSettings[0].current.on_stop.min_records
                    newUnknownFields.stopSendPeriod.value = res.data.unknownSettings[0].current.on_stop.send_period
                    newUnknownFields.moveMinAngle.value = res.data.unknownSettings[0].current.on_move.min_period
                    newUnknownFields.moveMinDistance.value = res.data.unknownSettings[0].current.on_move.min_distance
                    newUnknownFields.moveMinPeriod.value = res.data.unknownSettings[0].current.on_move.min_angle
                    newUnknownFields.moveMinRecords.value = res.data.unknownSettings[0].current.on_move.min_speed_delta
                    newUnknownFields.moveMinSpeedDelta.value = res.data.unknownSettings[0].current.on_move.min_records
                    newUnknownFields.moveSendPeriod.value = res.data.unknownSettings[0].current.on_move.send_period

                }
            } 
        })
        .catch((e) => {
            console.log("Error......", e);
        });

        setFields(newFields)
        setRoamingFields(newRoamingFields)
        setUnkonownFields(newUnknownFields)

    }, [props?.assignData])
    const [expanded, setExpanded] = React.useState(false);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    const inputChangeHandler = (value, name) => {
        let newFields = _.cloneDeep(fields);
        if (newFields[name]["validationRequired"] && value.length > 0) {
            newFields[name]["isValid"] = true;
        }
        newFields[name]["value"] = value;
        setFields(newFields);
    };

    const roamingInputChangeHandler = (value, name) => {
        let newRoamingFields = _.cloneDeep(roamingFields);
        if (newRoamingFields[name]["validationRequired"] && value.length > 0) {
            newRoamingFields[name]["isValid"] = true;
        }
        newRoamingFields[name]["value"] = value;
        setRoamingFields(newRoamingFields);
    };
    const unknownInputChangeHandler = (value, name) => {
        let newUnknownFields = _.cloneDeep(unkonownFields);
        if (newUnknownFields[name]["validationRequired"] && value.length > 0) {
            newUnknownFields[name]["isValid"] = true;
        }
        newUnknownFields[name]["value"] = value;
        setUnkonownFields(newUnknownFields);
    };
    const autoCompleteChangeHandler = async (value, name) => {
        let newFields = _.cloneDeep(fields);
        if (newFields[name]["validationRequired"] && value.label.length > 0) {
            newFields[name]["isValid"] = true;
        }
        newFields[name]["value"] = value;
        setFields(newFields);
    };



    const forUpdatingHomeSettings = async (fields) => {
        let dataStatus = await validateFields.validateFieldsData(fields);
        if (dataStatus.status) {
            setSpinner(true)
            let object = {
                deviceId : Number(props.assignData.deviceId),
                moveMinAngle :  Number(fields.moveMinAngle.value),
                moveMinDistance : Number(fields.moveMinDistance.value),
                moveMinPeriod : Number(fields.moveMinPeriod.value),
                moveMinRecords : Number(fields.moveMinRecords.value),
                moveMinSpeedDelta : Number(fields.moveMinSpeedDelta.value),
                moveSendPeriod : Number(fields.moveSendPeriod.value),
                stopMinPeriod : Number(fields.stopMinPeriod.value),
                stopMinRecords : Number(fields.stopMinRecords.value),
                stopSendPeriod : Number(fields.stopSendPeriod.value)

            };
            await Service.updateHomeSettings(object)
            .then((res) => {
                setSpinner(false);
                if (res.status) {
                  setAlertData({
                    open: true,
                    severity: "success",
                    message: res.message,
                  });
                //   setTimeout(() => {
                //     closeAlert();
                //     handleClose();
                //     // window.location.reload();
                //   }, 3000);
                } else {
                  setAlertData({
                    open: true,
                    severity: "error",
                    message: res.message,
                  });
                }
              })
              .catch((e) => {
                console.log("Error......", e);
              });
        } else {
            let newFields = _.cloneDeep(fields);
            let erroredFields = Object.keys(dataStatus.data).filter(
                (key, i) => dataStatus.data[key].isValid === false
            );
            newFields = dataStatus.data;
            setFields(newFields);
        }
        // props.onClose();
    };
    const updateRoamingSettings = async (fields) => {
        // let dataStatus = await validateFields.validateFieldsData(fields);
        // if (dataStatus.status) {
            setSpinner(true)
            let object = {
                deviceId : Number(props.assignData.deviceId),
                moveMinAngle :  Number(fields.moveMinAngle.value),
                moveMinDistance : Number(fields.moveMinDistance.value),
                moveMinPeriod : Number(fields.moveMinPeriod.value),
                moveMinRecords : Number(fields.moveMinRecords.value),
                moveMinSpeedDelta : Number(fields.moveMinSpeedDelta.value),
                moveSendPeriod : Number(fields.moveSendPeriod.value),
                stopMinPeriod : Number(fields.stopMinPeriod.value),
                stopMinRecords : Number(fields.stopMinRecords.value),
                stopSendPeriod : Number(fields.stopSendPeriod.value)

            };
            await Service.updateRoamingSettings(object)
            .then((res) => {
                setSpinner(false);
                if (res.status) {
                  setRoamingAlertData({
                    open: true,
                    severity: "success",
                    message: res.message,
                  });
                //   setTimeout(() => {
                //     closeRoamingAlert();
                //     handleClose();
                //     // window.location.reload();
                //   }, 3000);
                } else {
                  setRoamingAlertData({
                    open: true,
                    severity: "error",
                    message: res.message,
                  });
                }
              })
              .catch((e) => {
                console.log("Error......", e);
              });
        // } else {
        //     let newFields = _.cloneDeep(fields);
        //     let erroredFields = Object.keys(dataStatus.data).filter(
        //         (key, i) => dataStatus.data[key].isValid === false
        //     );
        //     newFields = dataStatus.data;
        //     setFields(newFields);
        // }
        // props.onClose();
    };
    const updateUnknownSettings = async (fields) => {
        // let dataStatus = await validateFields.validateFieldsData(fields);
        // if (dataStatus.status) {
            setSpinner(true)
            let object = {
                deviceId : Number(props.assignData.deviceId),
                moveMinAngle :  Number(fields.moveMinAngle.value),
                moveMinDistance : Number(fields.moveMinDistance.value),
                moveMinPeriod : Number(fields.moveMinPeriod.value),
                moveMinRecords : Number(fields.moveMinRecords.value),
                moveMinSpeedDelta : Number(fields.moveMinSpeedDelta.value),
                moveSendPeriod : Number(fields.moveSendPeriod.value),
                stopMinPeriod : Number(fields.stopMinPeriod.value),
                stopMinRecords : Number(fields.stopMinRecords.value),
                stopSendPeriod : Number(fields.stopSendPeriod.value)

            };
            await Service.updateUnknownSettings(object)
            .then((res) => {
                setSpinner(false);
                if (res.status) {
                  setUnknownAlertData({
                    open: true,
                    severity: "success",
                    message: res.message,
                  });
                //   setTimeout(() => {
                //     closeUnknownAlert();
                //     handleClose();
                //     // window.location.reload();
                //   }, 3000);
                } else {
                  setUnknownAlertData({
                    open: true,
                    severity: "error",
                    message: res.message,
                  });
                }
              })
              .catch((e) => {
                console.log("Error......", e);
              });
        // } else {
        //     let newFields = _.cloneDeep(fields);
        //     let erroredFields = Object.keys(dataStatus.data).filter(
        //         (key, i) => dataStatus.data[key].isValid === false
        //     );
        //     newFields = dataStatus.data;
        //     setFields(newFields);
        // }
        // props.onClose();
    };
    const handleClose = () => {
        setOpen(false);
        props.onClose();
        let newField = _.cloneDeep(fields);
        setFields(newField);
    };

    return (
        <div >
            <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
                classes={{ paper: classes.dialogPaper }}
                maxWidth="md" // Set the maximum width, you can use 'xs', 'sm', 'md', 'lg', 'xl', or a specific value
                fullWidth={true}
            >

                <DialogTitle
                    className={classes.dialogTitle}
                    id="draggable-dialog-title"
                >
                    Commands & Settings
                </DialogTitle>
                <DialogContent dividers className={classes.divider}>
                    <DialogContentText>
                        <Grid container className={"details_container"}>
                            <Grid item xs={12} style={{}}>
                                <Fields.AntSelectableSearchField
                                    fieldData={fields.deviceImei}
                                    autoCompleteChangeHandler={autoCompleteChangeHandler}
                                    variant="outlined"
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        <div className="details_container">

                            <Accordion
                                // style={{ marginLeft: 15, marginRight: 15 }}
                                expanded={expanded === "panel1"}
                                onChange={handleChange("panel1")}
                            >
                                <AccordionSummary
                                    style={{ background: "#366E93", coursor: "pointer" }}
                                    expandIcon={
                                        <ExpandMoreIcon style={{ color: "white" }} />
                                    }
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography
                                        className="details_container_heading"
                                        style={{ color: "white" }}
                                    >
                                        {/* <LocalShippingIcon color={"white"} style={{alignItems:'center',paddingRight:5,fontSize: "30px",}}/> */}
                                        Home GSM Network Settings
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {/* <Grid
                                        container
                                        spacing={3}
                                        className="details_container_content"
                                    > */}
                                    <Typography>Vehicle On Stop</Typography>
                                    <Grid item xs={12} className={"custom_select"}>
                                        <Grid
                                            container
                                            spacing={3}
                                            className="details_container_content"
                                        >
                                            <Grid item xs={6}>
                                                <Fields.InputField
                                                    fieldData={fields.stopMinPeriod}
                                                    inputChangeHandler={inputChangeHandler}
                                                    variant="outlined"
                                                    InputProps={{
                                                        style: {
                                                            color: "gray",
                                                        },
                                                    }}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            seconds
                                                        </InputAdornment>
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Fields.InputField
                                                    fieldData={fields.stopMinRecords}
                                                    inputChangeHandler={inputChangeHandler}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Fields.InputField
                                                    fieldData={fields.stopSendPeriod}
                                                    inputChangeHandler={inputChangeHandler}
                                                    variant="outlined"
                                                    InputProps={{
                                                        style: {
                                                            color: "gray",
                                                        },
                                                    }}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            seconds
                                                        </InputAdornment>
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                        <Typography>Vehicle Moving</Typography>
                                        <Grid item xs={12} className={"custom_select"}>
                                            <Grid
                                                container
                                                spacing={3}
                                                className="details_container_content"
                                            >

                                                <Grid item xs={6}>
                                                    <Fields.InputField
                                                        fieldData={fields.moveMinAngle}
                                                        inputChangeHandler={inputChangeHandler}
                                                        variant="outlined"
                                                        InputProps={{
                                                            style: {
                                                                color: "gray",
                                                            },
                                                        }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                seconds
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Fields.InputField
                                                        fieldData={fields.moveMinDistance}
                                                        inputChangeHandler={inputChangeHandler}
                                                        variant="outlined"
                                                        InputProps={{
                                                            style: {
                                                                color: "gray",
                                                            },
                                                        }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                meters
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Fields.InputField
                                                        fieldData={fields.moveMinPeriod}
                                                        inputChangeHandler={inputChangeHandler}
                                                        variant="outlined"
                                                        InputProps={{
                                                            style: {
                                                                color: "gray",
                                                            },
                                                        }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                degrees
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Fields.InputField
                                                        fieldData={fields.moveMinRecords}
                                                        inputChangeHandler={inputChangeHandler}
                                                        variant="outlined"
                                                        InputProps={{
                                                            style: {
                                                                color: "gray",
                                                            },
                                                        }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                Km/h
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Fields.InputField
                                                        fieldData={fields.moveMinSpeedDelta}
                                                        inputChangeHandler={inputChangeHandler}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Fields.InputField
                                                        fieldData={fields.moveSendPeriod}
                                                        inputChangeHandler={inputChangeHandler}
                                                        variant="outlined"
                                                        InputProps={{
                                                            style: {
                                                                color: "gray",
                                                            },
                                                        }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                seconds
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </Grid>



                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {/* </Grid> */}
                                    <Grid
                                        container
                                        spacing={2}
                                        className="details_container_content"
                                        style={{ marginRight: 25 }}
                                    >
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={3}></Grid>

                                                <Grid item xs={3}>
                                                    <Button
                                                        variant="outlined"
                                                        onClick={handleClose}
                                                        className="cancel_button"
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Button
                                                        className="save_button"
                                                        onClick={() => forUpdatingHomeSettings(fields)}
                                                        startIcon={
                                                            spinner ? (
                                                                <CircularProgress size={20} color={"#fff"} />
                                                            ) : null
                                                        }
                                                    >
                                                        Save
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={3}></Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {alertData.open ? (
                                        <div className={classes.alertBox}>
                                            <AlertMessage
                                            severity={alertData.severity}
                                            message={alertData.message}
                                            closeAlert={closeAlert}
                                            />
                                        </div>
                                        ) : null}


                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className="details_container">

                            <Accordion
                                // style={{ marginLeft: 15, marginRight: 15 }}
                                expanded={expanded === "panel2"}
                                onChange={handleChange("panel2")}
                            >
                                <AccordionSummary
                                    style={{ background: "#366E93", coursor: "pointer" }}
                                    expandIcon={
                                        <ExpandMoreIcon style={{ color: "white" }} />
                                    }
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography
                                        className="details_container_heading"
                                        style={{ color: "white" }}
                                    >
                                        {/* <LocalShippingIcon color={"white"} style={{alignItems:'center',paddingRight:5,fontSize: "30px",}}/> */}
                                        Roaming GSM Network Settings
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {/* <Grid
            container
            spacing={3}
            className="details_container_content"
        > */}
                                    <Typography>Vehicle On Stop</Typography>
                                    <Grid item xs={12} className={"custom_select"}>
                                        <Grid
                                            container
                                            spacing={3}
                                            className="details_container_content"
                                        >
                                            <Grid item xs={6}>
                                                <Fields.InputField
                                                    fieldData={roamingFields.stopMinPeriod}
                                                    inputChangeHandler={roamingInputChangeHandler}
                                                    variant="outlined"
                                                    InputProps={{
                                                        style: {
                                                            color: "gray",
                                                        },
                                                    }}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            seconds
                                                        </InputAdornment>
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Fields.InputField
                                                    fieldData={roamingFields.stopMinRecords}
                                                    inputChangeHandler={roamingInputChangeHandler}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Fields.InputField
                                                    fieldData={roamingFields.stopSendPeriod}
                                                    inputChangeHandler={roamingInputChangeHandler}
                                                    variant="outlined"
                                                    InputProps={{
                                                        style: {
                                                            color: "gray",
                                                        },
                                                    }}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            seconds
                                                        </InputAdornment>
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                        <Typography>Vehicle Moving</Typography>
                                        <Grid item xs={12} className={"custom_select"}>
                                            <Grid
                                                container
                                                spacing={3}
                                                className="details_container_content"
                                            >

                                                <Grid item xs={6}>
                                                    <Fields.InputField
                                                        fieldData={roamingFields.moveMinAngle}
                                                        inputChangeHandler={roamingInputChangeHandler}
                                                        variant="outlined"
                                                        InputProps={{
                                                            style: {
                                                                color: "gray",
                                                            },
                                                        }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                seconds
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Fields.InputField
                                                        fieldData={roamingFields.moveMinDistance}
                                                        inputChangeHandler={roamingInputChangeHandler}
                                                        variant="outlined"
                                                        InputProps={{
                                                            style: {
                                                                color: "gray",
                                                            },
                                                        }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                meters
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Fields.InputField
                                                        fieldData={roamingFields.moveMinPeriod}
                                                        inputChangeHandler={roamingInputChangeHandler}
                                                        variant="outlined"
                                                        InputProps={{
                                                            style: {
                                                                color: "gray",
                                                            },
                                                        }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                degrees
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Fields.InputField
                                                        fieldData={roamingFields.moveMinRecords}
                                                        inputChangeHandler={roamingInputChangeHandler}
                                                        variant="outlined"
                                                        InputProps={{
                                                            style: {
                                                                color: "gray",
                                                            },
                                                        }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                Km/h
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Fields.InputField
                                                        fieldData={roamingFields.moveMinSpeedDelta}
                                                        inputChangeHandler={roamingInputChangeHandler}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Fields.InputField
                                                        fieldData={roamingFields.moveSendPeriod}
                                                        inputChangeHandler={roamingInputChangeHandler}
                                                        variant="outlined"
                                                        InputProps={{
                                                            style: {
                                                                color: "gray",
                                                            },
                                                        }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                seconds
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </Grid>



                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {/* </Grid> */}
                                    <Grid
                                        container
                                        spacing={2}
                                        className="details_container_content"
                                        style={{ marginRight: 25 }}
                                    >
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={3}></Grid>

                                                <Grid item xs={3}>
                                                    <Button
                                                        variant="outlined"
                                                        onClick={handleClose}
                                                        className="cancel_button"
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Button
                                                        className="save_button"
                                                        onClick={() => updateRoamingSettings(roamingFields)}
                                                        startIcon={
                                                            spinner ? (
                                                                <CircularProgress size={20} color={"#fff"} />
                                                            ) : null
                                                        }
                                                    >
                                                        Save
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={3}></Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {roamingAlertData.open ? (
                                        <div className={classes.alertBox}>
                                            <AlertMessage
                                            severity={roamingAlertData.severity}
                                            message={roamingAlertData.message}
                                            closeAlert={closeRoamingAlert}
                                            />
                                        </div>
                                        ) : null}

                                </AccordionDetails>
                            </Accordion>
                        </div>
                        <div className="details_container">

                            <Accordion
                                // style={{ marginLeft: 15, marginRight: 15 }}
                                expanded={expanded === "panel3"}
                                onChange={handleChange("panel3")}
                            >
                                <AccordionSummary
                                    style={{ background: "#366E93", coursor: "pointer" }}
                                    expandIcon={
                                        <ExpandMoreIcon style={{ color: "white" }} />
                                    }
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography
                                        className="details_container_heading"
                                        style={{ color: "white" }}
                                    >
                                        {/* <LocalShippingIcon color={"white"} style={{alignItems:'center',paddingRight:5,fontSize: "30px",}}/> */}
                                        Unknown GSM Network Settings
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {/* <Grid
            container
            spacing={3}
            className="details_container_content"
        > */}
                                    <Typography>Vehicle On Stop</Typography>
                                    <Grid item xs={12} className={"custom_select"}>
                                        <Grid
                                            container
                                            spacing={3}
                                            className="details_container_content"
                                        >
                                            <Grid item xs={6}>
                                                <Fields.InputField
                                                    fieldData={unkonownFields.stopMinPeriod}
                                                    inputChangeHandler={unknownInputChangeHandler}
                                                    variant="outlined"
                                                    InputProps={{
                                                        style: {
                                                            color: "gray",
                                                        },
                                                    }}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            seconds
                                                        </InputAdornment>
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Fields.InputField
                                                    fieldData={unkonownFields.stopMinRecords}
                                                    inputChangeHandler={unknownInputChangeHandler}
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Fields.InputField
                                                    fieldData={unkonownFields.stopSendPeriod}
                                                    inputChangeHandler={unknownInputChangeHandler}
                                                    variant="outlined"
                                                    InputProps={{
                                                        style: {
                                                            color: "gray",
                                                        },
                                                    }}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            seconds
                                                        </InputAdornment>
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                        <Typography>Vehicle Moving</Typography>
                                        <Grid item xs={12} className={"custom_select"}>
                                            <Grid
                                                container
                                                spacing={3}
                                                className="details_container_content"
                                            >

                                                <Grid item xs={6}>
                                                    <Fields.InputField
                                                        fieldData={unkonownFields.moveMinAngle}
                                                        inputChangeHandler={unknownInputChangeHandler}
                                                        variant="outlined"
                                                        InputProps={{
                                                            style: {
                                                                color: "gray",
                                                            },
                                                        }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                seconds
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Fields.InputField
                                                        fieldData={unkonownFields.moveMinDistance}
                                                        inputChangeHandler={unknownInputChangeHandler}
                                                        variant="outlined"
                                                        InputProps={{
                                                            style: {
                                                                color: "gray",
                                                            },
                                                        }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                meters
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Fields.InputField
                                                        fieldData={unkonownFields.moveMinPeriod}
                                                        inputChangeHandler={unknownInputChangeHandler}
                                                        variant="outlined"
                                                        InputProps={{
                                                            style: {
                                                                color: "gray",
                                                            },
                                                        }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                degrees
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Fields.InputField
                                                        fieldData={unkonownFields.moveMinRecords}
                                                        inputChangeHandler={unknownInputChangeHandler}
                                                        variant="outlined"
                                                        InputProps={{
                                                            style: {
                                                                color: "gray",
                                                            },
                                                        }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                Km/h
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Fields.InputField
                                                        fieldData={unkonownFields.moveMinSpeedDelta}
                                                        inputChangeHandler={unknownInputChangeHandler}
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Fields.InputField
                                                        fieldData={unkonownFields.moveSendPeriod}
                                                        inputChangeHandler={unknownInputChangeHandler}
                                                        variant="outlined"
                                                        InputProps={{
                                                            style: {
                                                                color: "gray",
                                                            },
                                                        }}
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                seconds
                                                            </InputAdornment>
                                                        }
                                                    />
                                                </Grid>



                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {/* </Grid> */}
                                    <Grid
                                        container
                                        spacing={2}
                                        className="details_container_content"
                                        style={{ marginRight: 25 }}
                                    >
                                        <Grid item xs={12}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={3}></Grid>

                                                <Grid item xs={3}>
                                                    <Button
                                                        variant="outlined"
                                                        onClick={handleClose}
                                                        className="cancel_button"
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Button
                                                        className="save_button"
                                                        onClick={() => updateUnknownSettings(unkonownFields)}
                                                        startIcon={
                                                            spinner ? (
                                                                <CircularProgress size={20} color={"#fff"} />
                                                            ) : null
                                                        }
                                                    >
                                                        Save
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={3}></Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    {unknownAlertData.open ? (
                                        <div className={classes.alertBox}>
                                            <AlertMessage
                                            severity={unknownAlertData.severity}
                                            message={unknownAlertData.message}
                                            closeAlert={closeUnknownAlert}
                                            />
                                        </div>
                                        ) : null}

                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={handleClose} color="primary">
                  Cancel
                </Button>
                <Button
                  onClick={() => forSubmitingUserData(fields)}
                  color="primary"
                >
                  Submit
                </Button> */}
                    <Grid
                        container
                        spacing={2}
                        className="details_container_content"
                        style={{ marginRight: 25 }}
                    >


                    </Grid>
                </DialogActions>
            </Dialog>
        </div>
    );
};
