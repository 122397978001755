import PilotPaymentsRequestBuilder from "../RequestBuilders/pilotPayments";
class pilotPayments {
  constructor(Service, path, Util) {
    this.httpService = Service;
    this.path = path;
    this.Util = Util;
    this.PilotPaymentsRequestBuilder = new PilotPaymentsRequestBuilder(Util);
  }

  getPaymentsMaster = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(
        this.path.PILOTPAYMENTS + "/paymentsMaster",
        data
      );
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getDriverLedger = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(
        this.path.PILOTPAYMENTS + "/driverLedger",
        data
      );
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  addDriverAdvance = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.PILOTPAYMENTS +
      "/addAdvance";
    let reqData = this.PilotPaymentsRequestBuilder.AddAdvance(data);
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, reqData)
        .then((res) => resolve(res))
        .catch((error) => reject(error));
    });
  };

  addDriverExpense = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.PILOTPAYMENTS +
      "/addExpense";
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => resolve(res))
        .catch((error) => reject(error));
    });
  };

  addDriverIncentive = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.PILOTPAYMENTS +
      "/addDriverIncentive";
    let reqData = this.PilotPaymentsRequestBuilder.AddIncentive(data);
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, reqData)
        .then((res) => resolve(res))
        .catch((error) => reject(error));
    });
  };

  getPilotTrips = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(
        this.path.PILOTPAYMENTS + "/getPilotTrips",
        data
      );
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => resolve(res))
        .catch((error) => reject(error));
    });
  };

  getPilotSettlements = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(
        this.path.PILOTPAYMENTS + "/settlements",
        data
      );
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => resolve(res))
        .catch((error) => reject(error));
    });
  };

  createSettlement = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.PILOTPAYMENTS +
      "/settlements";
    let reqData = this.PilotPaymentsRequestBuilder.CreateSettlement(data);
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, reqData)
        .then((res) => resolve(res))
        .catch((error) => reject(error));
    });
  };
}
export default pilotPayments;
