import TripsheetRequestBuilders from "../RequestBuilders/tripsheet";

class Tripsheet {
  constructor(Service, path, path2, Util) {
    this.httpService = Service;
    this.path = path;
    this.path2 = path2;
    this.Util = Util;
    this.tripsheetRequestBuilders = new TripsheetRequestBuilders(Util);
  }

  getTripSheets = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_2 +
      this.Util.queryParamsFromObj(this.path.GET_TRIPSHEETS, data);
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getTripsheetSummary = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_2 +
      this.Util.queryParamsFromObj(this.path.GET_TRIPSHEET_SUMMARY, data);
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  createTripSheet = (data) => {
    let reqData = this.tripsheetRequestBuilders.addTripsheet(data);
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_2 + this.path.CREATE_TRIPSHEET;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  updateTripSheet = (data) => {
    let reqData = this.tripsheetRequestBuilders.updateTripsheet(data);
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_2 + this.path.UPDATE_TRIPSHEET;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  closeTripSheet = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_2 + this.path.CLOSE_TRIPSHEET;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export default Tripsheet;
