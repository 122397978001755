class PettycashRequestBuilder {
    constructor(util) {
      this.util = util;
    }
  
    AddPayment = (reqObj) => {
      return {
        amount: reqObj.amount.value,
        ...(reqObj.transactionNumber.value
          ? { transactionNumber: reqObj.transactionNumber.value }
          : {}),
        paymentDate: reqObj.paymentDate.value,
        paymentType: reqObj.paymentType.value,
      };
    };

  }
  
  export default PettycashRequestBuilder;
  