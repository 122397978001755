import React, { useState, useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import SkipNext from '@material-ui/icons/SkipNext';
import SkipPrevious from '@material-ui/icons/SkipPrevious';
import Stop from '@material-ui/icons/Stop';
import PlayArrow from '@material-ui/icons/PlayArrow';
import Pause from '@material-ui/icons/Pause';
// import EventNoteOutlined from '@material-ui/icons/EventNoteOutlined';
import Slider from '@material-ui/core/Slider';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

const PrettoSlider = withStyles({
    root: {
        color: '#047de0',
        // height: 10,
        margin: 'initial'
    },
    thumb: {
        height: 20,
        width: 20,
        backgroundColor: '#00FF00',
        border: '2px solid #00FF00',
        marginLeft: 0,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
        margin: 0,
        marginTop: -8
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 8,
        borderRadius: 4,
        margin: 0
    },
    rail: {
        height: 8,
        borderRadius: 4,
        margin: 0
    },
})(Slider);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        width: '100%',
        margin: 'auto',
        backgroundColor: '#000',
        alignItems: 'center',
        bottom: 0,
        height: "40px !important"
    },
    icon: {
        width: 30,
        height: 30,
        margin: -10,
        color: '#FFF',
    },
    smallIcon: {
        width: 30,
        height: 30,
        margin: 2,
        color: '#FFF'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 60,
        backgroundColor: '#FFF'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

var stopThePlayer = false;
var pauseThePlayer = false;
var playSpeedValue = 1;
var playerIndex = 0;
var manualPlayerIndex = 0;
var playerIndexChangedManually = false;
var initializePlayer = true;

export default function PlayerPanel(props) {
    var marks = props.coordinates;
    const [playSpeed, setPlaySpeed] = React.useState(1);
    const [toPlay, setToPlay] = React.useState(true);
    const [sliderIndex, setSliderIndex] = useState(0);
    const [sliderValue, setSliderValue] = useState(0);
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const initializeFields = () => {
        stopThePlayer = false;
        pauseThePlayer = false;
        playSpeedValue = 1;
        playerIndex = 0;
        manualPlayerIndex = 0;
        playerIndexChangedManually = false;
        setSliderIndex(0);
        setPlaySpeed(1);
        setSliderValue(0);
        setToPlay(true);
        props.setPositionInMap({ position: props.coordinates[0].position, direction: props.coordinates[0].direction, timestamp: props.coordinates[0].timestamp });
    }
    useEffect(() => {
        initializePlayer = true;
        initializeFields();
    }, [props.key]);

    const handlePlayerToPlay = () => {
        if (initializePlayer) { initializePlayer = false; }
        let markerIndex = 0;
        if (pauseThePlayer) {
            // markerIndex = sliderIndex;
            markerIndex = playerIndex;
            pauseThePlayer = false;
        }
        setToPlay(false);
        CallMarkerUpdation(markerIndex);
    }
    const handlePlayerToPause = () => {
        setToPlay(true);
        pauseThePlayer = true;
        playerIndexChangedManually = true;
        manualPlayerIndex = playerIndex;
    }
    // recursive function stops updating the marker position
    // when user clicks on stop button or pause button or reaches the last index
    const CallMarkerUpdation = (index) => {
        if (playerIndexChangedManually) {
            index = manualPlayerIndex;
            playerIndexChangedManually = false;
        }
        if (pauseThePlayer) {
            setToPlay(true);
            pauseThePlayer = false;
            return;
        }
        if (stopThePlayer) {
            stopThePlayer = false;
            setToPlay(true);
            setSliderIndex(0);
            setSliderValue(0);
            playerIndex = 0;
            manualPlayerIndex = 0;
            props.setPositionInMap({ position: marks[0].position, direction: marks[0].direction, timestamp: marks[0].timestamp });
            return;
        }
        setTimeout(function () {
            if (initializePlayer && toPlay) {
                initializeFields();
                initializePlayer = false;
                return;
            }
            var speed = parseInt(playSpeedValue / 4);
            index = index + (speed > 1 ? speed : 1);
            if (index > marks.length - 1) {
                index = marks.length - 1;
            }
            if (pauseThePlayer) {
                pauseThePlayer = false;
                setToPlay(true);
                return;
            }
            if (playerIndexChangedManually) {
                index = manualPlayerIndex;
                playerIndexChangedManually = false;
            }
            updateMarkerPosition(index);
            if (index < marks.length - 1) {
                CallMarkerUpdation(index);
            }
        }, 1000);
    }

    const updateMarkerPosition = (index) => {
        if (!playerIndexChangedManually) {
            const element = marks[index];
            setSliderIndex(index);
            setSliderValue(element.value);
            playerIndex = index;
            props.setPositionInMap({ position: element.position, direction: element.direction, timestamp: element.timestamp });
            if (index == marks.length - 1) {
                setToPlay(true);
            }
        }
    }
    const handleSkipPrevious = () => {
        if (sliderIndex > 0) {
            let indexValue = sliderIndex - 1;
            setSliderIndex(indexValue);
            setSliderValue(indexValue);
            playerIndex = indexValue;
            manualPlayerIndex = indexValue;
            playerIndexChangedManually = true;
            props.setPositionInMap({ position: marks[indexValue].position, direction: marks[indexValue].direction, timestamp: marks[indexValue].timestamp });
        }
    }
    const handleSkipNext = () => {
        if (sliderIndex < marks.length - 1) {
            let indexValue = sliderIndex + 1;
            setSliderIndex(indexValue);
            setSliderValue(indexValue);
            playerIndex = indexValue;
            manualPlayerIndex = indexValue;
            playerIndexChangedManually = true;
            props.setPositionInMap({ position: marks[indexValue].position, direction: marks[indexValue].direction, timestamp: marks[indexValue].timestamp });
        }
    }
    const handlePlaySpeed = (event) => {
        setPlaySpeed(event.target.value);
        playSpeedValue = event.target.value;
        handleClose();
    };
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleStop = () => {
        setToPlay(true);
        setSliderIndex(0);
        setSliderValue(0);
        props.setPositionInMap({ position: marks[0].position, direction: marks[0].direction, timestamp: marks[0].timestamp });
        playerIndex = 0;
        manualPlayerIndex = 0;
        stopThePlayer = true;
        // setPlayerTimer(null);
        // setVehicleToDestination();
    }
    const valuetext = (value) => {
        return `${value}`;
    }
    const handleSlide = (event, value) => {
        setSliderValue(value);
        setSliderIndex(value);
        playerIndex = value;
        manualPlayerIndex = value;
        playerIndexChangedManually = true;
        props.setPositionInMap({ position: marks[value].position, direction: marks[value].direction, timestamp: marks[value].timestamp });
    }
    return (
        <div className={classes.root} key={props.key}>
            {/* <IconButton color="primary" aria-label="skip to next message" component="span">
                <EventNoteOutlined className={classes.icon} style={{ animationDirection: 'reverse' }} />
            </IconButton> */}
            <div>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} >
                    <strong style={{ fontSize: 20, color: '#FFFFFF' }}>X{playSpeed}</strong>
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handlePlaySpeed} value={100}>X100</MenuItem>
                    <MenuItem onClick={handlePlaySpeed} value={70}>X70</MenuItem>
                    <MenuItem onClick={handlePlaySpeed} value={50}>X50</MenuItem>
                    <MenuItem onClick={handlePlaySpeed} value={30}>X30</MenuItem>
                    <MenuItem onClick={handlePlaySpeed} value={10}>X10</MenuItem>
                    <MenuItem onClick={handlePlaySpeed} value={1}>X1</MenuItem>
                </Menu>
            </div>
            {
                toPlay
                    ?
                    <IconButton color="primary" aria-label="skip to next message" component="span" onClick={handlePlayerToPlay}>
                        <PlayArrow className={classes.icon} />
                    </IconButton>
                    :
                    <IconButton color="primary" aria-label="skip to next message" component="span" onClick={handlePlayerToPause}>
                        <Pause className={classes.icon} />
                    </IconButton>
            }
            <IconButton color="primary" aria-label="skip to next message" component="span" onClick={handleStop}>
                <Stop className={classes.icon} />
            </IconButton>
            <IconButton color="primary" aria-label="skip to next message" component="span" onClick={handleSkipPrevious}>
                <SkipPrevious className={classes.icon} />
            </IconButton>
            <PrettoSlider
                getAriaValueText={valuetext}
                value={sliderValue}
                aria-label="player"
                defaultValue={0}
                min={marks[0].value}
                max={marks[marks.length - 1].value}
                step={1}
                marks={marks}
                onChange={handleSlide}
            />
            <IconButton color="primary" aria-label="skip to next message" component="span" onClick={handleSkipNext}>
                <SkipNext className={classes.icon} />
            </IconButton>
        </div>
    );
}