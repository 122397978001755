import RouteRequestBuilders from "../RequestBuilders/routes";
class Routes {
  constructor(Service, path, Util) {
    this.httpService = Service;
    this.path = path;
    this.Util = Util;
    this.routeRequestBuilder = new RouteRequestBuilders(Util);
  }

  getRoutes = (data) => {
    // const url =
    //   process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
    //   this.Util.queryParamsFromObj(this.path.ROUTES, data);
    let filterURL = data?.contractId && data?.contractId ? process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +`/routes?clientId=${data.clientId}&contractId=${data.contractId}` :process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +`/routes?clientId=${data.clientId}&contractId=${data.contractId}`
    const url =filterURL
    
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getRoutesDetails = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(this.path.ROUTES, data);
    // const url =
    // process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +`/routes?clientId=${data.clientId}`
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getRoutesForCreateTrip = (data) => {
    let getData = data && data ? `/routes?onlyMeta=true&clientId=${data}` : `/routes?onlyMeta=true`
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +getData
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getAllRoutes = () => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +'/routes'
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getAllRoutesForVehicleRequest = () => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +'/routes?onlyMeta=true'
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getRoute = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.ROUTES +
      "/" +
      data.routeId;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getDeliveryOrders = (dataObj) => {
      let data = (dataObj.contractId !== undefined && dataObj.doType === undefined && dataObj.startDate === undefined && dataObj.endDate === undefined) ? `contractId=${dataObj.contractId}` : `contractId=${dataObj.contractId}&doType=${dataObj.doType}&startDate=${dataObj.startDate}&endDate=${dataObj.endDate}`
        const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/deliveryorders/active?${data}`;
    // active?contractId=116
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })  
        .catch((error) => {
          reject(error);
        });
    });
  };
  getDoList = (dataObj) => {
    let data = (dataObj.contractId !== undefined && dataObj.doType === undefined && dataObj.startDate === undefined && dataObj.endDate === undefined) ? `contractId=${dataObj.contractId}&type=All` : `contractId=${dataObj.contractId}&doType=${dataObj.doType}&startDate=${dataObj.startDate}&endDate=${dataObj.endDate}&type=All`
      const url =
    process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/deliveryorders/list?${data}`;
  // active?contractId=116
  return new Promise((resolve, reject) => {
    this.httpService
      .get(url)
      .then((res) => {
        resolve(res);
      })  
      .catch((error) => {
        reject(error);
      });
  });
};
  getAllDeliveryOrders = (data) => {
    let s = "";
    Object.keys(data).map((i) => {
      s += `${i}=${data[i]}&`;
    });
    let formateData = s.slice(0, -1);
     const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/deliveryorders/list?${formateData}`;
  return new Promise((resolve, reject) => {
    this.httpService
      .get(url)
      .then((res) => {
        resolve(res);
      })  
      .catch((error) => {
        reject(error);
      });
  });
};

  createRoute = (data) => {
    let reqData = this.routeRequestBuilder.CreateRoute(data);
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + this.path.ROUTES;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  updateRoute = (data, id) => {
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + this.path.ROUTES +`/updateroute/${id}`;
    let dataObj = this.routeRequestBuilders.CreateRoute(data);
    return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, dataObj)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  editRoute = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.ROUTES +
      `/editRoute`;
    let dataObj = this.routeRequestBuilder.UpdateRoute(data);
    return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, dataObj)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };


  sendForApproval = (data,tableData,dispatch_remarks) => {
    let reqData = this.routeRequestBuilder.createVendorRequest(data,tableData,dispatch_remarks);
    // reqData.reqEmails = data.reqEmails;
    reqData.dispatch_remarks = dispatch_remarks;
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/vendors/registerrequest`;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };


}

export default Routes;
