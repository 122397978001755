import GenerateRequestBuilders from "../RequestBuilders/invoices";
class Invoices {
  constructor(Service, path, Util) {
    this.httpService = Service;
    this.path = path;
    this.Util = Util;
    this.GenerateRequestBuilders = new GenerateRequestBuilders(Util);
  }
  getInvoices = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(
        this.path.INVOICES + "/getallinvoices",
        data
      );
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getInvoice = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(this.path.INVOICES + "/getInvoice", data);
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getInvoicependingTrips = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(
        this.path.INVOICES + "/getinvoicependingtrips",
        data
      );
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getPaymentPendingInvoices = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(
        this.path.INVOICES + "/getpaymentpendinginvoices",
        data
      );
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getInvoicedTrips = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(
        this.path.INVOICES + "/getinvoicedtrips",
        data
      );
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  createInvoice = (data, sameStates) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + this.path.INVOICES;
    let reqData = this.GenerateRequestBuilders.CreateInvoice(data, sameStates);
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getInvoiceValidation = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.INVOICES +
      "/validateTrips";
    let reqData = this.GenerateRequestBuilders.getInvoiceValidation(data);
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getInvoiceAddress = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.INVOICES +
      "/getDetailsForInvoiceTrips";
    let reqObj = this.GenerateRequestBuilders.getInvoiceAddress(data);
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, reqObj)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  uploadInvoicePdf = (file, data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(this.path.INVOICES + "/attachments", data);
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, file)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  updateToTaxInvoice = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(
        this.path.INVOICES + "/updatetoTaxInvoice",
        data
      );
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getPayments = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(this.path.INVOICES + "/getPayments", data);
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  updatePayment = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.INVOICES +
      "/updatePayment";
    let reqData = this.GenerateRequestBuilders.updatePayment(data);
    return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getPayment = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.INVOICES +
      `/getpaybleinvoices/${data.paymentId}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  updateBillingAmount = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.INVOICES +
      `/updatetripamount`;
    let reqData = this.GenerateRequestBuilders.updateBillingAmount(data);
    return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getBanks = () => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.INVOICES +
      `/getbanks`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}
export default Invoices;
