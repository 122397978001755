import React from "react";
import { TimePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import { FormLabel } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  timeField: { width: "100%" },
  helperText: { color: "red", marginRight: "0px", marginLeft: "0px" },
  mtop: { marginTop: "8px" },
  headLabel: {
    // textTransform: "uppercase",
    fontWeight: "normal",
    color: "#333333",
    fontSize: "12px",
    fontFamily: "Montserrat, SemiBold"
  },
}));

const TimeField = (props) => {
  const classes = useStyles();
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {props.fieldData.topLabel ? (
        <FormLabel className={classes.headLabel} required={props.fieldData.validationRequired}>
          {props.fieldData.label}
        </FormLabel>
      ) : null}
      <div className={props.fieldData.topLabel ? classes.mtop : null}>
        <div className={props.fieldData.topLabel ? "custom_field" : ""}>
          <TimePicker
            margin="normal"
            label={props.fieldData.topLabel ? null : props.fieldData.label}
            value={props.fieldData.value}
            onChange={(value) => {
              props.timeStampChangeHandler(value, props.fieldData.name);
            }}
            variant={props.variant}
            className={classes.timeField}
          />
        </div>
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default TimeField;
