import React from "react";
import { FormLabel } from "@material-ui/core";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { makeStyles } from "@material-ui/core";
import "../css/uploadInput.css";
const useStyles = makeStyles((theme) => ({
  headLabel: {
    // textTransform: "uppercase",
    fontWeight: "normal",
    color: "#333333",
    fontSize: "12px",
    fontFamily: "Montserrat, SemiBold"
  },
  file_label: {
    paddingTop: "6px",
    display: "flex",
    justifyContent: "space-between",
  },
  remove: {
    fontSize: "12px",
    color: "#333333",
    fontWeight: "normal",
    cursor: "pointer",
  },
}));
const UploadInput = (props, ref) => {
  const classes = useStyles();
  const fileChangeHandler = (e) => {
    props.uploadHandler(e);
  };
  const fileRemoveHandler = () => {
    props.fileRemoveHandler();
  };
  return (
    <div>
      <div className={classes.file_label}>
        <FormLabel className={classes.headLabel}>{props?.label}</FormLabel>
        {props?.lrFile?.length > 0 ? (
          <FormLabel className={classes.remove} onClick={fileRemoveHandler}>
            Remove
          </FormLabel>
        ) : null}
      </div>
      <div className="uploadFile">
        <span className="filename">{props.fileName}</span>
        <input
          type="file"
          className="inputfile form-control"
          name="file"
          onChange={fileChangeHandler}
          // accept="image/png, image/jpeg, image/jpg"
          accept="image/png, image/jpeg, image/jpg, application/pdf"
        ></input>
        <AttachFileIcon className="cam_icon" />
      </div>
    </div>
  );
};
export default UploadInput;
