class BankRequestBuilders {
    constructor(util) {
      this.util = util;
    }
  
    CreateBank = (reqObj) => {
      return {
        bankName: reqObj.bankName.value,
        accountNumber: reqObj.accountNumber.value,
        ifsc: reqObj.ifsc.value,
        branch: reqObj.branch.value,
        isActive: reqObj.isActive.value
      };
    };
  
    UpdateBank = (reqObj) => {
      return {
        bankName: reqObj.bankName.value,
        accountNumber: reqObj.accountNumber.value,
        ifsc: reqObj.ifsc.value,
        branch: reqObj.branch.value,
        isActive: reqObj.isActive.value
      };
    };
  }
  
  export default BankRequestBuilders;
  