import axios from "./Axios/index";

class HttpService {
  get = (url) => {
    return new Promise((resolve, reject) => {
      return axios
        .get(url)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  post = (url, data, config) => {
    return new Promise((resolve, reject) => {
      return axios
        .post(url, data, config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  delete = (url) => {
    return new Promise((resolve, reject) => {
      return axios
        .delete(url)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  patch = (url, data) => {
    return new Promise((resolve, reject) => {
      return axios
        .put(url, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export default HttpService;