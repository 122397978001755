class PilotPaymentsRequestBuilder {
  constructor(util) {
    this.util = util;
  }

  AddAdvance = (reqObj) => {
    return {
      amount: reqObj.amount.value,
      ...(reqObj.transactionNumber.value
        ? { transactionNumber: reqObj.transactionNumber.value }
        : {}),
      driverId: reqObj.driverId,
      tripId: reqObj.tripId,
      advancePayType: reqObj.advancePayType,
      paymentDate: reqObj.paymentDate.value,
      paidBy: reqObj.paidBy,
      ...(reqObj.comments.value ? { comments: reqObj.comments.value } : {}),
      paymentType: reqObj.paymentType.value,
    };
  };

  AddIncentive = (reqObj) => {
    return {
      amount: reqObj.amount.value,
      ...(reqObj.transactionNumber.value
        ? { transactionNumber: reqObj.transactionNumber.value }
        : {}),
      driverId: reqObj.driverId,
      tripId: reqObj.tripId,
      paymentDate: reqObj.paymentDate.value,
      ...(reqObj.comments.value ? { comments: reqObj.comments.value } : {}),
      paymentType: reqObj.paymentType.value,
    };
  };

  CreateSettlement = (reqObj) => {
    return {
      ...(reqObj.transactionNumber.value
        ? { transactionNumber: reqObj.transactionNumber.value }
        : {}),
      driverId: reqObj.driverId,
      driverName: reqObj.driverName,
      paidAmount: reqObj.paidAmount,
      trips: reqObj.trips,
      paymentType: reqObj.paymentType.value,
      ...(reqObj.remarks.value ? { remarks: reqObj.remarks.value } : {}),
      paidOnDate: reqObj.paidOnDate.value
    };
  };
}

export default PilotPaymentsRequestBuilder;
