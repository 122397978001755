module.exports = Object.freeze({
  ALERT_SEVERITY: {
    error: "error",
    warning: "warning",
    info: "info",
    success: "success",
  },
  PAGINATION: {
    current: 0,
    pageSize: 25,
  },

  CLIENT_MORE_OPTIONS: [
    // { label: "Bookings", value: "./clientBookings" },
    // { label: "Edit Client", value: "./updateClient" },
    { label: "View Client", value: "./clientDetails" },
    { label: "Contracts", value: "./clientContracts" },
    { label: "View Invoices", value: "./invoiceList" },
    { label: "View Receivables", value: "./viewReceivables" },
    // { label: "View Payment Recievables", value: "./updatePayment" },
  ],
  GENERATE_INVOICE_OPTIONS: [
    // { label: "Bookings", value: "./clientBookings" },
    { label: "Invoice Details", value: "./invoice" },
  ],
  CREATE_INVOICE_OPTIONS: [
    { label: "Update Billing Amount", value: "", type: "button" },
    { label: "Update Freight Amount", value: "", type: "button" },
    { label: "Update THC Number", value: "", type: "button" },
  ],
  CLIENT_STATUS: [
    { label: "Deactive", value: "0", color: "#F24B4B" },
    { label: "Active", value: "1", color: "#008000" },
  ],
  CONTRACT_MORE_OPTIONS: [
    { label: "Routes", value: "./contractRoutes" },
    { label: "View Details", value: "./contractDetails" },
    { label: "Edit Contract", value: "./editContract" },
    { label: "Delivery Orders", value: "./deliveryOrderList" },
  ],
  ALL_CONTRACT_MORE_OPTIONS: [
    { label: "View Details", value: "./contractDetails" },
  ],
  ALL_CONTRACT_LIST_MORE_OPTIONS: [
    { label: "View Details", value: "./contractsListdetails" },
  ],
  DELIVERY_ORDER_MORE_OPTIONS: [
    { label: "View Details", value: "./deliveryOrderDetails" },
    { label: "Edit Delivery Order", value: "./editDeliveryOrder" },
  ],
  DUES_LIST_OPTIONS:[
    {label:"Edit Trip Details",value: "",type:"button"},
    {label: "Add Advance", value:"/addAdvance",type:"button" },
    {label: "Add Indent", value:"./addIndent",type:"button" },
    {label:"Add Expense",value:"./addExpense",type:"button"},
    // { label: "Track", value: "", type: "button" },
    {label:"View/Download Tripsheet",value: "",type:"button"},
  ],
  DELIVERY_ORDER_LIST_MORE_OPTIONS: [
    { label: "View Details", value: "./DeliveryOrdersDetails" },
  ],
  DRIVER_MORE_OPTIONS: [{ label: "Payments", value: "./driverPayments" }],
  VENDOR_MORE_OPTIONS: [{ label: "Vendor amounts", value: "./vendorDetails" }],
  CLIENT_TYPE_OPTIONS: [
    { label: "Company", value: "Company" },
    { label: "Agent", value: "Agent" },
    { label: "Individual", value: "Individual" },
  ],
  DO_TYPES: [
    { label: "Trips", value: "Trips" },
    { label: "Quantity", value: "Quantity" },
    { label: "Both", value: "Both" },
  ],
  COMMODITY: [
    { label: "Rice", value: "Rice" },
    { label: "Wheat", value: "Wheat" },
  ],
  QTYINUNITS: [
    { label: "Tons", value: "Tons" },
    { label: "KGs", value: "KGs" },
  ],
  CUSTOMERT_REF_OPTIONS: [
    { label: "Contract No", value: "Contract" },
    { label: "PO Number", value: "PO" },
  ],
  VEHICLE_TYPES: [
    { label: "Own Vehicle", value: "OWN" },
    { label: "Market Vehicle", value: "MARKET" },
  ],
  TRIP_TYPES: [
    { label: "TBB", value: "TBB" },
    { label: "To-Pay", value: "TOPAY" },
    { label: "Return", value: "RETURN" },
  ],
  PAN: "pan",
  GST: "gst",
  FREIGHT_TYPES: [
    { label: "Freight", value: "Freight" },
    { label: "Ton", value: "Ton" },
  ],

  ROUTE_MORE_OPTIONS: [
    { label: "View details", value: "./routeDetails" },
    { label: "Edit Route", value: "./addRoute" },
  ],
  ALL_ROUTES_MORE_OPTIONS: [{ label: "View details", value: "./routeDetails" }],
  TRIP_MORE_OPTIONS: [
    { label: "View details", value: "./tripDetails"},
    { label: "Start Trip", value: "" ,type:"button"},
    { label: "End Trip", value: "",type:"button" },
    {label: "Add Advance", value:"/addAdvance" },
    {label:"Add Expense",value:"./addExpense"},
    {label: "Add Indent", value:"./addIndent" },
    {label:"Fuel ODO",value:"./fuelodosummary"},
    {label:"Toll Summary",value:"./tollsummary"},
    { label: "Live Track", value: "", type: "button"},
    { label: "Track", value: "", type: "button"},
    {label:"View/Download Tripsheet",value:"",type:"button"},
    {label:"LR Receipt",value: "",type:"button"}
  ],
  TRIP_VIEW_OPTIONS: [{ label: "View details", value: "", type: "button" }],
  TRIP_STATUS: [
    { label: "Pending", value: "0", color: "#F57302" },
    { label: "To be Started ", value: "2", color: "#F2AC29" },
    { label: "On Trip", value: "3", color: "#6AAED9" },
    { label: "Completed", value: "4", color: "#008300" },
    { label: "Empty Trip", value: "5", color: "#BBE8F2" },
    // { label: "Loading", value: "6", color: "#6ef9f5" },
    // { label: "Started", value: "7", color: "#e5d9f2" },
    // { label: "Arrived", value: "8", color: "#ffbfb7" },
    // { label: "Pilot Arriving", value: "9", color: "#f9dec9" },
    { label: "At Dropoff Location", value: "10", color: "#63BF94" },
    { label: "Cancelled", value: "11", color: "#F24B4B" },
  ],
  INDENT_STATUS: [
    { label: "Draft", value: "0", color: "#6AAED9" },
    { label: "Review", value: "1", color: "#f9dec9" },
    { label: "Approved", value: "2", color: "#ffbfb7" },
    { label: "Rejected", value: "3", color: "#F24B4B" },
    { label: "Completed", value: "4", color: "#008300" },
  ],
  USER_MORE_OPTIONS: [
    { label: "Service", value: "./updateClient" },
    { label: "Admin", value: "./clientDetails" },
    { label: "maintenance", value: "./clientContracts" },
  ],
  GROUPS_MORE_OPTIONS: [
    { label: "Edit", value: "./updateGroup" },
    { label: "Delete", value: "", type: "button" },
    { label: "View Group Members", value: "./groupView" },
  ],
  JOURNALS_MORE_OPTIONS: [
    { label: "Edit", value: "./updateJournal" },
    { label: "Delete", value: "", type: "button" },
  ],
  USER_ROLES: [
    { label: "Fleet Owner", value: "0" },
    { label: "Fleet Supervisor", value: "1" },
    { label: "Maintenance Supervisor", value: "2" },
    { label: "Accountant supervisor", value: "3" },
    { label: "Accountant", value: "4" },
    { label: "Fleet Head", value: "5" },
    { label: "Inventory Head", value: "6" },
    { label: "Vehicle Helper", value: "7" },
    { label: "Fleet Observer", value: "8" },
    { label: "Pilot", value: "9" },
    { label: "Emt", value: "10" },
    { label: "Traffic Supervisor", value: "13" },
  ],
  VENDORS_MORE_OPTIONS: [
    { label: "Edit", value: "./addService" },
    { label: "View Details", value: "./serviceDetails" },
    { label: "Contracts", value: "./servicecontractList" },
    { label: "Indents", value: "./serviceindentList" },
  ],
  GST_TYPES: [
    { label: "CGST", value: "CGST" },
    { label: "IGST", value: "IGST" },
    { label: "SGST", value: "SGST" },
  ],
  CUSTOM_CLIENT_DETAILS: [
    { label: "VAT", value: "vat", re: /^[A-Z0-9]{15}$/ },
    { label: "TAX", value: "tax", re: /^[A-Z0-9]{9}$/ },
    { label: "PAN", value: "pan", re: /^[A-Z0-9]{10}$/ },
    { label: "TAN", value: "tan", re: /^[A-Z0-9]{10}$/ },
    { label: "TIN", value: "tin", re: /^[A-Z0-9]{9}$/ },
    { label: "CIN", value: "cin", re: /^[A-Z0-9]{21}$/ },
  ],
  STATUSES: [
    { label: "Active", value: "1", color: "#008000" },
    { label: "Inactive", value: "0", color: "#F24B4B" },
  ],
  SERVICECONTRACT_MORE_OPTIONS: [
    { label: "View Details", value: "./servicecontractDetails" },
    { label: "Edit", value: "./addserviceContract" },
  ],
  SITE_TYPES: [
    { label: "My Site", value: "1" },
    { label: "Client Site", value: "2" },
    { label: "General Site", value: "3" },
    { label: "Loading", value: "4" },
    { label: "Unloading", value: "5" },
  ],
  FLEETVENDOR_MORE_OPTIONS: [
    { label: "View Details", value: "./fleetDetails" },
    { label: "Active", value: "", type: "button" },
    { label: "Contracts", value: "./fleetcontractList" },
    // { label: "Customer Contracts", value: "./fleetCustomercontractList" },
    { label: "Add Vehicles", value: "", type: "button" },
    { label: "Add Existing Vehicles", value: "", type: "button" }, 
    { label: "View Vehicles", value: "", type: "button" },
  ],
  FLEETVENDOR_STATUSES: [
    { label: "Active", value: "1", color: "#008000" },
    { label: "Inactive", value: "0", color: "#F24B4B" },
  ],
  VECHILE_REQUEST_STATUSES: [
    { label: "verified", value: "1", color: "#008000" },
    { label: "pending", value: "0", color: "#F24B4B" },
  ],
  ACTIONS: [
    { label: "Approve", value: "1", color: "#008000" },
    { label: "Reject", value: "0", color: "#F24B4B" },
  ],
  OWNER_STATUSES: [
    { label: "Own Vehicle", value: "OWN" },
    { label: "Market Vehicle", value: "MARKET" },
  ],
  COMPLIANCE_TYPES: [
    { label: "Rc", value: "1" },
    { label: "National Permit", value: "2" },
    { label: "State Permit", value: "3" },
    { label: "Pollution", value: "4" },
    { label: "Fitness", value: "5" },
    { label: "Road TAX", value: "6" },
    { label: "Insurance", value: "7" },
  ],
  DAMAGE_TYPES: [
    { label: "Physical", value: "1" },
    { label: "Wet", value: "2" },
    { label: "Contamination", value: "3" },
    { label: "Others", value: "4" },
  ],
  FLEETCONTRACT_MORE_OPTIONS: [
    { label: "Routes", value: "./addRoute" },
    { label: "View Details", value: "./fleetcontractDetails" },
    { label: "Edit Contracts", value: "./editfleetcontract" },
    { label: "Delivery Orders", value: "./fleetcontractdeliveryOrderList" },
  ],
  FLEETCUSTOMERCONTRACT_MORE_OPTIONS: [
    { label: "View Details", value: "./fleetCustomerContractDetails" },
    // { label: "Edit Contracts", value: "./editfleetcontract" },
  ],
  DRIVER_LIST_OPTIONS: [
    // { label: "Tripsheets", value: "./driverTripsheets" },
    { label: "Edit Pilot", value:"./EditPilot"},
    // { label: "View Details", value: "./fleetcontractDetails" },
    // { label: "Activated", value: "1", color: "#008000" },
    // { label: "Deactivated", value: "0", color: "#F24B4B" },
  ],
  BANK_LIST_OPTIONS: [{ label: "Edit Bank", value: "./addBank" }],
  INVOICE_FILTERS: [
    { label: "All Invoices", value: "ALL" },
    { label: "Proforma Invoices", value: "PROFORMA" },
    { label: "Tax Invoices", value: "TAX" },
    // { label: "To be Invoiced", value: "3" },
  ],
  DISCOUNT_FILTERS: [
    { label: "Percentage", value: "PERCENTAGE" },
    { label: "Value", value: "VALUE" },
  ],
  WAIVEOFF_FILTERS: [
    // { label: "Percentage", value: "0" },
    { label: "Value", value: "1" },
  ],
  SGST_FILTERS: [
    { label: "SGST 2.5%", value: 2.5 },
    { label: "SGST 5%", value: 5 },
    { label: "SGST 6%", value: 6 },
    { label: "SGST 9%", value: 9 },
    { label: "SGST 18%", value: 18 },
    { label: "SGST 28%", value: 28 },
  ],
  CGST_FILTERS: [
    { label: "CGST 2.5%", value: 2.5 },
    { label: "CGST 5%", value: 5 },
    { label: "SGST 6%", value: 6 },
    { label: "CGST 9%", value: 9 },
    { label: "CGST 18%", value: 18 },
    { label: "CGST 28%", value: 28 },
  ],
  IGST_FILTERS: [
    { label: "IGST 5%", value: 5 },
    { label: "IGST 2.5%", value: 2.5 },
    { label: "IGST 9%", value: 9 },
    { label: "IGST 12%", value: 12 },
    { label: "IGST 18%", value: 18 },
    { label: "IGST 28%", value: 28 },
  ],
  TDS_FILTERS: [
    { label: "0.01%", value: 0.01 },
    { label: "0.10%", value: 0.1 },
    { label: "0.25%", value: 0.25 },
    { label: "0.50%", value: 0.5 },
    { label: "0.75%", value: 0.75 },
    { label: "1%", value: 1 },
    { label: "1.25%", value: 1.25 },
    { label: "1.5%", value: 1.5 },
    { label: "1.75%", value: 1.75 },
    { label: "2%", value: 2 },
  ],
  INVOICE_TYPE: [
    { label: "Profoma", value: "PROFOMA" },
    { label: "Tax", value: "TAX" },
  ],
  INVOICE_TAXLIST_OPTIONS: [
    { label: "View Invoice", value: "./viewInvoice" },
    // { label: "Update Recievables", value: "./updatePayments" },
  ],
  INVOICE_PROFLIST_OPTIONS: [
    { label: "View Invoice", value: "./viewInvoice" },
    { label: "Generate Tax Invoice", value: "", type: "button" },
    // { label: "Update Recievables", value: "./updatePayments" },
  ],
  // PAYMENT_OPTIONS: [{ label: "Update Recievables", value: "./updatePayments" }],
  PAYMENT_TYPE: [
    { label: "Cash", value: "CASH" },
    { label: "Cheque", value: "CHEQUE" },
    { label: "Online", value: "ONLINE" },
  ],
  VOUCHER_PAYMENT_TYPE: [
    { label: "Cash", value: "CASH" },
    { label: "Cheque", value: "CHEQUE" },
    { label: "Digital", value: "DIGITAL" },
    {label:'Credit Slip', value:'Credit Slip'}
  ],
  TRANSACTION_TYPE: [
    { label: "Credit", value: "Credit" },
    { label: "Debit", value: "Debit" },
  ],
  PAY_TYPE: [
    { label: "Paid To", value: "PAID TO" },
    { label: "Received From", value: "RECEIVED FROM" },
    { label: "Others", value: "OTHERS" },
  ],
  PAY_TYPE_OPTIONS: [
    { label: "Service Vendor", value: "SERVICE VENDOR" },
    { label: "Pilot", value: "PILOT" },
    { label: "Petty Cash", value: "PETTY CASH" },
    { label: "Others", value: "OTHERS" },
  ],
  PAYMENT_TRIPS: [{ label: "View Invoiced Trips", value: "", type: "button" }],
  PAYMENT_RECEIVABLES: [{ label: "View Payment", value: "./viewPayment" }],
  VENDOR_PAYMENTS_MORE_OPTIONS: [
    { label: "View Payments", value: "./vendorPayments" },
    //  { label: "View Indents", value: "./vendorIndents" }
  ],
  Fleet_VENDOR_PAYMENTS_MORE_OPTIONS: [
    { label: "View Payments", value: "./fleetVendorPaymentsDetails",},
    // {label: "View & Add Advance", value:"/addAdvance" },
    // {label: "View & Add Indent", value:"./addIndent"},
    // {label:"View & Add Expense",value:"./addExpense"},
    //  { label: "View Indents", value: "./vendorIndents" }
  ],
  VENDOR_DUES_OPTIONS: [{ label: "View Indent", value: "" }],
  PILOT_PAYMENTS_MORE_OPTIONS: [
    { label: "View Ledger", value: "./pilotLedger" },
    { label: "Trip Payments", value: "./pilotTrips" },
    { label: "Pilot Settlements", value: "./pilotSettlements" },
  ],
  PILOT_TRIPS_MORE_OPTIONS: [
    { label: "Add Advance", value: "./addAdvance" },
    { label: "Add Expense", value: "./addExpense" },
  ],
  BANK_NAMES: [
    { label: "1", value: "SBI" },
    { label: "2", value: "Indusind Bank" },
    { label: "3", value: "HDFC" },
    { label: "4", value: "KOTAK" },
    { label: "5", value: "DENA" },
    { label: "6", value: "BOI" },
    { label: "7", value: "ICICI" },
  ],
  STATE_CODES: [
    { label: "Andhra Pradesh", value: "AD" },
    { label: "Arunachal Pradesh", value: "AR" },
    { label: "Assam", value: "AS" },
    { label: "Bihar", value: "BR" },
    { label: "Chattisgarh", value: "CG" },
    { label: "Delhi", value: "DL" },
    { label: "Goa", value: "GA" },
    { label: "Gujarat", value: "GJ" },
    { label: "Haryana", value: "HR" },
    { label: "Himachal Pradesh", value: "HP" },
    { label: "Jammu and Kashmir", value: "JK" },
    { label: "Jharkhand", value: "JH" },
    { label: "Karnataka", value: "KA" },
    { label: "Kerala", value: "KL" },
    { label: "Lakshadweep Islands", value: "LD" },
    { label: "Madhya Pradesh", value: "MP" },
    { label: "Maharashtra", value: "MH" },
    { label: "Manipur", value: "MN" },
    { label: "Meghalaya", value: "ML" },
    { label: "Mizoram", value: "MZ" },
    { label: "Nagaland", value: "NL" },
    { label: "Odisha", value: "OD" },
    { label: "Pondicherry", value: "PY" },
    { label: "Punjab", value: "PB" },
    { label: "Rajasthan", value: "RJ" },
    { label: "Sikkim", value: "SK" },
    { label: "Tamil Nadu", value: "TN" },
    { label: "Telangana", value: "TS" },
    { label: "Tripura", value: "TR" },
    { label: "Uttar Pradesh", value: "UP" },
    { label: "Uttarakhand", value: "UK" },
    { label: "West Bengal", value: "WB" },
    { label: "Andaman and Nicobar Islands", value: "AN" },
    { label: "Chandigarh", value: "CH" },
    { label: "Dadra & Nagar Haveli and Daman & Diu", value: "DNHDD" },
    { label: "Ladakh", value: "LA" },
    { label: "Other Territory", value: "OT" },
  ],
  PAIDTOTYPE: [
    { label: "Employee", value: "Employee" },
    { label: "Pilot", value: "Pilot" },
    { label: "Service Vendor", value: "Service Vendor" },
    { label: "Others", value: "Others" },
  ],
  MY_VEHICLE_OPTIONS: [
    { label: "Update Vehicle", value: "/update-vehicle"},
    // { label: "Vehicles Trip", value: "/vehicles-trip"},

  ],
  VEHICLE_STATUS: [
    { label: "Active", value: `1`},
    { label: "In Active", value: `0`},
  ],
});
