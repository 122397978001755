import React from "react";
import { FormHelperText, FormLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const useStyles = makeStyles((theme) => ({
  phoneField: { width: "100%" },
  helperText: { color: "red", marginRight: "0px", marginLeft: "0px" },
  mtop: { marginTop: "8px" },
  headLabel: {
    // textTransform: "uppercase",
    fontWeight: "normal",
    color: "#333333",
    fontSize: "12px",
    fontFamily: "Montserrat, SemiBold"
  },
}));

const PhoneNumberField = (props) => {
  const classes = useStyles();
  return (
    <div>
      {props.fieldData.topLabel ? (
        <FormLabel
          className={classes.headLabel}
          required={props.fieldData.validationRequired}
        >
          {props.index
            ? props.fieldData.label + " " + props.index
            : props.fieldData.label}
        </FormLabel>
      ) : null}
      <div className={props.fieldData.topLabel ? "custom_field" : ""}>
        <div className="phone_field">
          <PhoneInput
            country={props.defaultCountry.countryCode}
            value={props.fieldData.value}
            onChange={(value, country, e, formattedValue) => {
              props.phoneChangeHandler(country, value, props.fieldData.name);
            }}
            countryCodeEditable={false}
            className={`${classes.phoneField} ${props.fieldData.topLabel ? classes.mtop : ""
              }`}
            enableSearch={true}
            disabled = {props.fieldData.isDisabled ? props.fieldData.isDisabled : false}
          />
          {props.fieldData.isValid ? null : (
            <FormHelperText className={classes.helperText}>
              {props.fieldData.errorMsg}
            </FormHelperText>
          )}
        </div>
      </div>
    </div>
  );
};

export default PhoneNumberField;
