// import { response } from "express";
// import VehicleRequestBuilders from "../RequestBuilders/vehicles";

class Inventory {
  constructor(Service, path, Util) {
    this.httpService = Service;
    this.path = path;
    this.Util = Util;
  }
  getManuatures = ()=>{
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `vehicles/tireManufacturers`
    return new Promise((resolve,rej)=>{
      this.httpService.get(url).then((res)=>{
        resolve(res)
      }).catch((error)=>{
        rej(error)
      })
    })
  }
  getEquipmentCategories = (data) => {
    let url =
      data && data
        ? process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 +
          `inventory/equipments?parent_id=${data}`
        : process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `inventory/equipments`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getInventoryList = (data) => {
    const url =
    process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 +
      this.Util.queryParamsFromObj("driver/getInventory", data);
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getConsumables = (data) => {
    // const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1  + `driver/getInventory`;
    const url = data
      ? process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `driver/consumables?parentId=${data}`
      : process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "driver/consumables";
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getUnitTypes = () => {
    let url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "driver/getUnitTypes";
    // const url = "http://localhost:3080/driver/getUnitTypes"
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  insertInventoryDetails = (data) => {
    const url =process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "driver/addInventoryDetails";
    // const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `driver/addInventoryDetails`
    return new Promise((resolve, reject) => {
      return this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getAutoFillData = (id) => {
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `driver/inventoryData/${id}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  updateInventoryData = (data) => {
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `driver/updateInventoryDetails`;
    return new Promise((resolve, reject) => {
      return this.httpService
        .patch(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getPurchasesCount = () =>{
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "driver/purchasesCount"
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getInventoryTransactions = (data) =>{
    const url = data ? process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `inventory/transactions?status=${data}` :  process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "inventory/transactions";
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  insertCheckinDetails = (data) => {
    const url =process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "inventory/checkin";
    // const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `driver/addInventoryDetails`
    return new Promise((resolve, reject) => {
      return this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

}

export default Inventory;
