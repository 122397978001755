class Session {
    getItem = (key) => {
        return sessionStorage.getItem(key);
    }

    set = (key, value) => {
        sessionStorage.setItem(key, typeof(value)==='string'? value: JSON.stringify(value));
    }
    get = (key) => {
        return sessionStorage.getItem(key);
    }
    remove = (key) => {
        return sessionStorage.removeItem(key);
    }

    isLoginUser = () => {
        if(this.getItem("authId")) return true;
        else return false;
    }

    getUserToken = () => {
        if(this.getItem("authId")) return this.getItem("authId")
        else return ""
    }
}

export default Session;