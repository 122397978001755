import { Typography, Grid, CardContent } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
// import Header from "../../../../components/header";
import { Box, makeStyles, withStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Card from "@mui/material/Card";
// import Card from "@mui/material/Card";
// import "../../../../common.css";
// import CardContent from "@mui/material/CardContent";
// import * as CONFIG from "../../../../config/GlobalConstants";
// import CircularLoading from "../../../../components/loader/circularLoading";
// import Util from "../../../../utils";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import Dialog from "@mui/material/Dialog";
// import DialogTitle from "@mui/material/DialogTitle";
// import DialogContent from "@mui/material/DialogContent";
// import CloseIcon from "@mui/icons-material/Close";
// import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import {
  Step,
  Stepper,
  StepLabel,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Button,
} from "@material-ui/core";
import { Collapse } from "antd";
import CancelIcon from "@mui/icons-material/Cancel";
import moment from "moment";
import "../../common.css";
import Util from "../../utils";
import Header from "../../components/header";
// import { subscriptionImage,  } from "../../assets";
import {
  companyNameIcon,
  deviceImage,
  deviceImeiIcon,
  deviceInstallDate,
  deviceInstallationDateIcon,
  deviceSerialNumberIcon,
  deviceStatusIcon,
  deviceTypeIcon,
  serviceProviderIcon,
  simActivateDateIcon,
  simBasketNameIcon,
  simImage,
  simNumberIcon,
  subscriptionDateIcon,
  subscriptionExpiryDateIcon,
  subscriptionImage,
  subscriptionRateIcon,
  subscriptionStatus,
  subscriptionStatusIcon,
  subsctiptioTypeIcon,
  vehicleIdIcon,
  vehicleRegistrationNumberIcon,
  whitelistingNumberIcon,
} from "../../assets/index";
const useStyles = makeStyles((theme) => ({
  close_icon: {
    position: "absolute",
    top: "1px",
    right: "2px",
    cursor: "pointer",
    color: "red",
  },
  icon: {
    height: "30px",
    width: "30px",
    marginTop: "35px",
    color: "#1269a8",
  },
  table_column: {
    fontWeight: "bold",
    fontSize: "16px",
    padding: "8px 2px",
    lineHeight: "1rem",
  },
  table_data: {
    fontSize: "14px",
  },
  font_data: { fontWeight: 100, fontSize: "18px" },
  image: {
    margin: "7px",
    marginLeft: "50px",
    marginRight: "25px",
  },
}));
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#B7ECFF",
    color: theme.palette.common.black,
    // fontFamily: "montserrat"
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
      // fontFamily: "montserrat"
    },
  },
}))(TableRow);
var Service;
const { Panel } = Collapse;
const DeviceViewDetails = (props) => {
  let dataObject = props.location.state;
  console.log(
    "props.location.state.deliveryOrderId......",
    props.location.state
  );
  const history = useHistory();
  const [spinner, setSpinner] = useState(false);
  const [doData, setDOData] = useState(null);
  const [dialog, setDialog] = useState([]);
  const [loader, setLoader] = useState(false);
  Service = global.service;
  let util = new Util();
  const classes = useStyles();
  //   useEffect(() => {
  //     window.scrollTo({ top: 0 });
  //     if (props.location.state) {
  //       setLoader(true);
  //       Service.getDeliveryOrder({ id: props.location.state.deliveryOrderId })
  //         .then((res) => {
  //           setDOData(res);
  //           setLoader(false);
  //         })
  //         .catch(() => {
  //           setLoader(false);
  //         });
  //     } else {
  //       global.session.remove("BearerToken");
  //       global.session.remove("profile");
  //       global.session.remove("hideHeader");
  //       window.location = "/";
  //     }
  //   }, []);

  const fileViewHandler = (rec, i) => {
    const newOpen = [...dialog];
    newOpen[i] = true;
    setDialog(newOpen);
  };
  const handleClose = (rec, i) => {
    const newOpen = [...dialog];
    newOpen[i] = false;
    setDialog(newOpen);
  };
  return (
    <div>
      <Header />
      <div className="main_container">
        <div className="header_box">
          <Typography className="header_text">
            <ArrowBack className="arrow" onClick={() => history.goBack()} />
            View Details
          </Typography>
        </div>

        <div className="form_container">
          <div
            className={"details_container border_bottom"}
            style={{ backgroundColor: "#EFF7FF", borderRadius: "15px" }}
          >
            <Typography
              className="details_container_heading"
              style={{ color: "#397296", marginLeft: "15px" }}
            >
              SUBSCRIPTION DETAILS
            </Typography>
            <Grid
              container
              xs={12}
              spacing={3}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                xs={5}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Card
                  className="custom_card"
                  sx={{
                    border: "1px solid #397296",
                    height: "280px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "15px",
                  }}
                >
                  <img
                    src={subscriptionImage}
                    style={{ maxWidth: "100%", width: "auto" }}
                  />
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  className="custom_card"
                  sx={{ border: "1px solid #397296", borderRadius: "15px" }}
                >
                  <CardContent>
                    <div>
                      <Grid container xs={12}>
                        <Grid item xs={7}>
                          <Typography
                            gutterBottom
                            variant="body1"
                            className={classes.font_data}
                          >
                            <img
                              src={vehicleIdIcon}
                              className={classes.image}
                            />
                            Vehicle ID :
                          </Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <Typography
                            gutterBottom
                            variant="body1"
                            className={classes.font_data}
                            style={{ color: "#397296" }}
                          >
                            {dataObject.truckId}
                          </Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Typography
                            gutterBottom
                            variant="body1"
                            className={classes.font_data}
                          >
                            <img
                              src={vehicleRegistrationNumberIcon}
                              className={classes.image}
                            />
                            Vehicle Registration Number :
                          </Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <Typography
                            gutterBottom
                            variant="body1"
                            className={classes.font_data}
                            style={{ color: "#397296" }}
                          >
                            {dataObject.regiNum}
                          </Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Typography
                            gutterBottom
                            variant="body1"
                            className={classes.font_data}
                          >
                            <img
                              src={subsctiptioTypeIcon}
                              className={classes.image}
                            />
                            Subscription Type :
                          </Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <Typography
                            gutterBottom
                            variant="body1"
                            className={classes.font_data}
                            style={{ color: "#397296" }}
                          >
                            {dataObject.subscriptionType}
                          </Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Typography
                            gutterBottom
                            variant="body1"
                            className={classes.font_data}
                          >
                            <img
                              src={subscriptionStatusIcon}
                              className={classes.image}
                            />
                            Subscription Status :
                          </Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <Typography
                            gutterBottom
                            variant="body1"
                            className={classes.font_data}
                            style={{ color: "#397296" }}
                          >
                            {dataObject.subscriptionStatus != null && dataObject.subscriptionStatus == 1
                              ? "Active"
                              : dataObject.subscriptionStatus != null && "In Active"}
                          </Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Typography
                            gutterBottom
                            variant="body1"
                            className={classes.font_data}
                          >
                            <img
                              src={subscriptionDateIcon}
                              className={classes.image}
                            />
                            Subscription Date :
                          </Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <Typography
                            gutterBottom
                            variant="body1"
                            className={classes.font_data}
                            style={{ color: "#397296" }}
                          >
                            {dataObject.subscriptionDate}
                          </Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Typography
                            gutterBottom
                            variant="body1"
                            className={classes.font_data}
                          >
                            <img
                              src={subscriptionExpiryDateIcon}
                              className={classes.image}
                            />
                            Subscription Expiry Date :
                          </Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <Typography
                            gutterBottom
                            variant="body1"
                            className={classes.font_data}
                            style={{ color: "#F15F4C" }}
                          >
                            {dataObject.expiryDate}
                          </Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <Typography
                            gutterBottom
                            variant="body1"
                            className={classes.font_data}
                          >
                            <img
                              src={subscriptionRateIcon}
                              className={classes.image}
                            />
                            Subscription Price :
                          </Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <Typography
                            gutterBottom
                            variant="body1"
                            className={classes.font_data}
                            style={{ color: "#0D9D00" }}
                          >
                            {dataObject.subscriptionPrice}
                          </Typography>
                        </Grid>
                      </Grid>

                      {/* <Typography variant="h6" component="div">
                              satya
                            </Typography> */}
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
          <div
            className={"details_container border_bottom"}
            style={{
              backgroundColor: "#EFF7FF",
              marginTop: "20px",
              marginBottom: "20px",
              borderRadius: "15px",
            }}
          >
            <Typography
              className="details_container_heading"
              style={{ color: "#397296", marginLeft: "15px" }}
            >
              DEVICE DETAILS
            </Typography>
            <Grid
              container
              xs={12}
              spacing={3}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                xs={5}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Card
                  className="custom_card"
                  sx={{
                    border: "1px solid #397296",
                    height: "280px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "15px",
                  }}
                >
                  <img
                    src={deviceImage}
                    style={{ maxWidth: "100%", width: "auto" }}
                  />
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  className="custom_card"
                  sx={{ border: "1px solid #397296", borderRadius: "15px" }}
                >
                  <CardContent>
                    <Grid container xs={12}>
                      <Grid item xs={6}>
                        <Typography
                          gutterBottom
                          variant="body1"
                          className={classes.font_data}
                        >
                          <img
                            src={companyNameIcon}
                            className={classes.image}
                          />
                          Company Name :
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          gutterBottom
                          variant="body1"
                          className={classes.font_data}
                          style={{ color: "#397296" }}
                        >
                          {dataObject.companyName}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          gutterBottom
                          variant="body1"
                          className={classes.font_data}
                        >
                          <img
                            src={serviceProviderIcon}
                            className={classes.image}
                          />
                          Service Provider :
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          gutterBottom
                          variant="body1"
                          className={classes.font_data}
                          style={{ color: "#397296" }}
                        >
                          {dataObject.deviceProviderName}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          gutterBottom
                          variant="body1"
                          className={classes.font_data}
                        >
                          <img
                            src={deviceTypeIcon}
                            className={classes.image}
                          />
                          Device Type :
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          gutterBottom
                          variant="body1"
                          className={classes.font_data}
                          style={{ color: "#397296" }}
                        >
                          {dataObject.deviceType}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          gutterBottom
                          variant="body1"
                          className={classes.font_data}
                        >
                          <img
                            src={deviceImeiIcon}
                            className={classes.image}
                          />
                          Device (IMEI) :
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          gutterBottom
                          variant="body1"
                          className={classes.font_data}
                          style={{ color: "#397296" }}
                        >
                          {dataObject.deviceImei}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          gutterBottom
                          variant="body1"
                          className={classes.font_data}
                        >
                          <img
                            src={deviceSerialNumberIcon}
                            className={classes.image}
                          />
                          Device Serial Number :
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          gutterBottom
                          variant="body1"
                          className={classes.font_data}
                          style={{ color: "#397296" }}
                        >
                          {dataObject.deviceSerialNum}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          gutterBottom
                          variant="body1"
                          className={classes.font_data}
                        >
                          <img
                            src={deviceInstallationDateIcon}
                            className={classes.image}
                          />
                          Device Installation Date :
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          gutterBottom
                          variant="body1"
                          className={classes.font_data}
                          style={{ color: "#F15F4C" }}
                        >
                          {dataObject.installDate}
                        </Typography>
                      </Grid>

                      <Grid item xs={6}>
                        <Typography
                          gutterBottom
                          variant="body1"
                          className={classes.font_data}
                        >
                          <img
                            src={deviceStatusIcon}
                            className={classes.image}
                          />
                          Device Status :
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          gutterBottom
                          variant="body1"
                          className={classes.font_data}
                          style={{ color: "#0D9D00" }}
                        >
                          {dataObject.deviceStatus == 1
                            ? "Active"
                            : "In Active"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
          <div
            className={"details_container border_bottom"}
            style={{ backgroundColor: "#EFF7FF", borderRadius: "15px" }}
          >
            <Typography
              className="details_container_heading"
              style={{ color: "#397296", marginLeft: "15px" }}
            >
              SIM DETAILS
            </Typography>
            <Grid
              container
              xs={12}
              spacing={3}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                xs={5}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Card
                  className="custom_card"
                  sx={{
                    border: "1px solid #397296",
                    height: "230px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "15px",
                  }}
                >
                  <img
                    src={simImage}
                    
                    style={{ maxWidth: "43%",width: "auto" }}
                  />
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  className="custom_card"
                  sx={{ border: "1px solid #397296", borderRadius: "15px" }}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    height: "270px",
                  }}
                >
                  <CardContent>
                    <div>
                      <Grid container xs={12}>
                        <Grid item xs={6}>
                          <Typography
                            gutterBottom
                            variant="body1"
                            className={classes.font_data}
                          >
                            <img
                              src={simBasketNameIcon}
                              className={classes.image}
                            />
                            Sim Basket Name :
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            gutterBottom
                            variant="body1"
                            className={classes.font_data}
                            style={{ color: "#397296" }}
                          >
                            {dataObject.basketName}
                          </Typography>
                        </Grid>

                        <Grid item xs={6}>
                          <Typography
                            gutterBottom
                            variant="body1"
                            className={classes.font_data}
                          >
                            <img
                              src={simNumberIcon}
                              className={classes.image}
                            />
                            Sim Number :
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            gutterBottom
                            variant="body1"
                            className={classes.font_data}
                            style={{ color: "#397296" }}
                          >
                            {dataObject.simNum}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            gutterBottom
                            variant="body1"
                            className={classes.font_data}
                          >
                            <img
                              src={whitelistingNumberIcon}
                              className={classes.image}
                            />
                            Whitelisting Number :
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            gutterBottom
                            variant="body1"
                            className={classes.font_data}
                            style={{ color: "#397296" }}
                          >
                            {dataObject.whitelistNum}
                          </Typography>
                        </Grid>

                        <Grid item xs={6}>
                          <Typography
                            gutterBottom
                            variant="body1"
                            className={classes.font_data}
                          >
                            <img
                              src={simActivateDateIcon}
                              className={classes.image}
                            />
                            Sim Activate Date :
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            gutterBottom
                            variant="body1"
                            className={classes.font_data}
                            style={{ color: "#0D9D00" }}
                          >
                            {dataObject.simActvateDate}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DeviceViewDetails;
