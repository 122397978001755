import Axios from 'axios';
import CryptoJS from 'crypto-js';
const axios = Axios.create({
    // baseURL :'http://65.0.133.20:3080/',
    timeout : 180000
})

axios.interceptors.request.use(async (config) => {
    if(global.session.get('BearerToken')){
        const decryptData = (encryptedData, secretKey) => {
            try {
              const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
              const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
          
              // If decryption fails and empty data is returned
              if (!decryptedData) {
                throw new Error("Decryption failed");
              }
          
              return JSON.parse(decryptedData);
            } catch (error) {
              console.error("Error during decryption:", error);
              return null;
            }
          };
          // Function to retrieve encrypted data from sessionStorage and decrypt it
          const retrieveData = (key, secretKey) => {
            const encryptedData = sessionStorage.getItem(key);
            if (!encryptedData) return null; // Return null if no data found
            return decryptData(encryptedData, secretKey);
          };
          const storedBearerToken = retrieveData('BearerToken', "my_Bearer_Token");
        let token = storedBearerToken
        config.headers['Authorization'] = `Bearer ${token}`        
        return config;
    }
    return config;   
})
export default axios;