import SiteRequestBuilders from "../RequestBuilders/sites";

class Sites {
  constructor(Service, path, Util) {
    this.httpService = Service;
    this.path = path;
    this.Util = Util;
    this.siteRequestBuilder = new SiteRequestBuilders();
  }

  getSites = (data) => {
    let filterData = data?.siteTypeIds &&data?.siteTypeIds?.map(id => `siteTypeId=${id}`).join('&');
    let getUrlData = data?.siteTypeIds && data?.siteTypeIds? process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/sites/getSites?${filterData}`: data === undefined ? process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/sites/getSites` :process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
    this.Util.queryParamsFromObj(this.path.SITES + "/getSites", data);
    const url = getUrlData
    // const url =
    //   process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
    //   this.Util.queryParamsFromObj(this.path.SITES + "/getSites", filterData);
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getSite = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.SITES +
      `/getSite/${data.siteId}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  createSite = (data) => {
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + this.path.SITES;
    let reqData = this.siteRequestBuilder.CreateSite(data);
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  updateSite = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.SITES +
      `/updatesite/${data.siteId}`;
    let reqData = this.siteRequestBuilder.UpdateSite(data);
    return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  deleteSite = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.SITES +
      `/deletesite/${data.siteId}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .delete(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export default Sites;
