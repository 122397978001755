class Profile {
    constructor(Service, path, Util) {
      this.httpService = Service;
      this.path = path;
      this.Util = Util;
    }
  
    getOwnerDetails = () => {
      // console.log(data)
      const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `owner/getOwnerDetails`;
      return new Promise((resolve, reject) => {
        this.httpService
          .get(url)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    };
    updateProfileDetails = (data) => {
      const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "owner/updateProfileDetails"
      return new Promise((resolve, reject) => {
        this.httpService
          .post(url, data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    };
    updateOwnerProfilePic = (data) => {
      const url =process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "owner/updateOwnerProfilePic";
      return new Promise((resolve, reject) => {
        return this.httpService
          .post(url, data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    };
    benchmarkDetails = () => {
      // console.log(data)
      const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `owner/benchmarkDetails`;
      return new Promise((resolve, reject) => {
        this.httpService
          .get(url)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    };
    updateBenchmarkDetails = (data) => {
      const url =process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "owner/updateBenchmarkDetails";
      return new Promise((resolve, reject) => {
        return this.httpService
          .post(url, data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    };
  
    getInventoryCategoryDetails = (data) => {
      const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 +
        this.Util.queryParamsFromObj(
           "inventory/inventoriesList",
          data
        );
      return new Promise((resolve, reject) => {
        this.httpService
          .get(url)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    };
  
    getSelectionEquipment = () => {
      const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `inventory/parentEquipments`;
      return new Promise((resolve, reject) => {
        this.httpService
          .get(url)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    };
  
    addEquipmentDetailsData = (data) => {
      const url =process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "inventory/addEquipment";
      return new Promise((resolve, reject) => {
        return this.httpService
          .post(url, data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    };
  
    updateEquipmentDetailsData = (data) => {
      const url =process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "inventory/updateEquipment";
      return new Promise((resolve, reject) => {
        return this.httpService
          .patch(url, data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    };
  }
  
  export default Profile
  