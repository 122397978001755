class RouteRequestBuilders {
  constructor(util) {
    this.util = util;
  }
  CreateRoute = (reqObj) => {
    return {
      clientId: reqObj.clientId,
      contractId: reqObj.contractId,
      routePayload: {
        scheduleTime: reqObj.scheduleTime.value,
        routeName: reqObj.routeName.value,
        pickupName: reqObj.pickupName.value,
        ...(reqObj.pickupLocationGroups.value.length > 0
          ? { pickupLocationGroups: reqObj.pickupLocationGroups.value }
          : {}),
        pickupLocation: {
          latitude: reqObj.pickupLocation.lat,
          longitude: reqObj.pickupLocation.lng,
          address: reqObj.pickupLocation.value,
        },
        ...(reqObj.pickupSiteId.value.value
          ? { pickupSiteId: reqObj.pickupSiteId.value.value }
          : {}),
        ...(reqObj.pickHoldingTimeHours.value ||
        reqObj.pickHoldingTimeMinutes.value
          ? {
              pickupHoldingTime:
                reqObj.pickHoldingTimeHours.value &&
                reqObj.pickHoldingTimeMinutes.value
                  ? parseInt(reqObj.pickHoldingTimeHours.value) * 60 +
                    parseInt(reqObj.pickHoldingTimeMinutes.value)
                  : reqObj.pickHoldingTimeHours.value
                  ? parseInt(reqObj.pickHoldingTimeHours.value) * 60 + 0
                  : reqObj.pickHoldingTimeMinutes.value
                  ? 0 + parseInt(reqObj.pickHoldingTimeMinutes.value)
                  : 0,
            }
          : {}),
        dropoffLocation: {
          latitude: reqObj.dropoffLocation.lat,
          longitude: reqObj.dropoffLocation.lng,
          address: reqObj.dropoffLocation.value,
        },
        ...(reqObj.dropoffSiteId.value.value
          ? { dropoffSiteId: reqObj.dropoffSiteId.value.value }
          : {}),
        dropoffName: reqObj.dropoffName.value,
        ...(reqObj.dropoffLocationGroups.value.length > 0
          ? { dropoffLocationGroups: reqObj.dropoffLocationGroups.value }
          : {}),
        ...(reqObj.dropHoldingTimeHours.value ||
        reqObj.dropHoldingTimeMinutes.value
          ? {
              dropoffHoldingTime:
                reqObj.dropHoldingTimeHours.value &&
                reqObj.dropHoldingTimeMinutes.value
                  ? parseInt(reqObj.dropHoldingTimeHours.value) * 60 +
                    parseInt(reqObj.dropHoldingTimeMinutes.value)
                  : reqObj.dropHoldingTimeHours.value
                  ? parseInt(reqObj.dropHoldingTimeHours.value) * 60 + 0
                  : reqObj.dropHoldingTimeMinutes.value
                  ? 0 + parseInt(reqObj.dropHoldingTimeMinutes.value)
                  : 0,
            }
          : {}),
        ...(reqObj.totalTATHours.value || reqObj.totalTATMin.value
          ? {
              totalTAT:
                reqObj.totalTATHours.value && reqObj.totalTATMin.value
                  ? parseInt(reqObj.totalTATHours.value) * 60 +
                    parseInt(reqObj.totalTATMin.value)
                  : reqObj.totalTATHours.value
                  ? parseInt(reqObj.totalTATHours.value) * 60 + 0
                  : reqObj.totalTATMin.value
                  ? 0 + parseInt(reqObj.totalTATMin.value)
                  : 0,
            }
          : {}),
        ...(reqObj.polyLine ? { polyLine: reqObj.polyLine } : {}),
        onlyWaypointAlert: reqObj.onlyWaypointAlert.value,
        maximumExpenses: Number(reqObj.maximumExpenses.value),
        maximumFuel: Number(reqObj.maximumFuel.value),
        driverIncentive: Number(reqObj.driverIncentive.value),
        maximumAdBlue: Number(reqObj.maximumAdBlue.value),
        maximumDistance: Number(reqObj.maximumDistance.value),
        ...(reqObj.commodity.value
          ? { commodity: reqObj.commodity.value }
          : {}),
        freightType: "None",
        freightAmount: Number(parseFloat(reqObj.freightAmount.value).toFixed(2)),
        ownerUnits: reqObj.ownerUnits.value?reqObj.ownerUnits.value.value: null,
        marketUnits: reqObj.marketUnits.value?reqObj.marketUnits.value.value: null,
        maximumMarketVehicleFreightAmt: Number(parseFloat(
          reqObj.maximumMarketVehicleFreightAmt.value
        ).toFixed(2)),
        notes: reqObj.notes.value?reqObj.notes.value: null,
      },
      wayPoints: reqObj.wayPoints.map((wp) => {
        return {
          waypointName: wp.waypointName.value,
          transitTime:
            wp.transitHours.value && wp.transitMinutes.value
              ? parseInt(wp.transitHours.value) * 60 +
                parseInt(wp.transitMinutes.value)
              : wp.transitHours.value
              ? parseInt(wp.transitHours.value) * 60 + 0
              : wp.transitMinutes.value
              ? 0 + parseInt(wp.transitMinutes.value)
              : 0,
          holdingTime:
            wp.holdingHours.value && wp.holdingMinutes.value
              ? parseInt(wp.holdingHours.value) * 60 +
                parseInt(wp.holdingMinutes.value)
              : wp.holdingHours.value
              ? parseInt(wp.holdingHours.value) * 60 + 0
              : wp.holdingMinutes.value
              ? 0 + parseInt(wp.holdingMinutes.value)
              : 0,
          location: {
            latitude: wp.location.lat,
            longitude: wp.location.lng,
            address: wp.location.value,
          },
          ...(wp.siteId.value.value ? { siteId: wp.siteId.value.value } : {}),
          ...(wp.groups.value.length > 0 ? { groups: wp.groups.value } : {}),
          mobileNumbers: [
            wp.mobileNumber1.value ? `+91${wp.mobileNumber1.value}` : "",
            wp.mobileNumber2.value ? `+91${wp.mobileNumber2.value}` : "",
            wp.mobileNumber3.value ? `+91${wp.mobileNumber3.value}` : "",
          ]
            .filter((item) => item !== "")
            .toString(),
        };
      }),
    };
  };

  UpdateRoute = (reqObj) => {
    return {
      clientId: reqObj.clientId,
      contractId: reqObj.contractId,
      routeId: reqObj.routeId,
      routePayload: {
        scheduleTime: reqObj.scheduleTime.value,
        routeName: reqObj.routeName.value,
        pickupName: reqObj.pickupName.value,
        ...(reqObj.pickupLocationGroups.value.length > 0
          ? { pickupLocationGroups: reqObj.pickupLocationGroups.value }
          : {}),
        pickupLocation: {
          latitude: reqObj.pickupLocation.lat,
          longitude: reqObj.pickupLocation.lng,
          address: reqObj.pickupLocation.value,
        },
        ...(reqObj.pickupSiteId.value.value
          ? { pickupSiteId: reqObj.pickupSiteId.value.value }
          : {}),
        ...(reqObj.pickHoldingTimeHours.value ||
        reqObj.pickHoldingTimeMinutes.value
          ? {
              pickupHoldingTime:
                reqObj.pickHoldingTimeHours.value &&
                reqObj.pickHoldingTimeMinutes.value
                  ? parseInt(reqObj.pickHoldingTimeHours.value) * 60 +
                    parseInt(reqObj.pickHoldingTimeMinutes.value)
                  : reqObj.pickHoldingTimeHours.value
                  ? parseInt(reqObj.pickHoldingTimeHours.value) * 60 + 0
                  : reqObj.pickHoldingTimeMinutes.value
                  ? 0 + parseInt(reqObj.pickHoldingTimeMinutes.value)
                  : 0,
            }
          : {}),
        dropoffLocation: {
          latitude: reqObj.dropoffLocation.lat,
          longitude: reqObj.dropoffLocation.lng,
          address: reqObj.dropoffLocation.value,
        },
        ...(reqObj.dropoffSiteId.value.value
          ? { dropoffSiteId: reqObj.dropoffSiteId.value.value }
          : {}),
        dropoffName: reqObj.dropoffName.value,
        ...(reqObj.dropoffLocationGroups.value.length > 0
          ? { dropoffLocationGroups: reqObj.dropoffLocationGroups.value }
          : {}),
        ...(reqObj.dropHoldingTimeHours.value ||
        reqObj.dropHoldingTimeMinutes.value
          ? {
              dropoffHoldingTime:
                reqObj.dropHoldingTimeHours.value &&
                reqObj.dropHoldingTimeMinutes.value
                  ? parseInt(reqObj.dropHoldingTimeHours.value) * 60 +
                    parseInt(reqObj.dropHoldingTimeMinutes.value)
                  : reqObj.dropHoldingTimeHours.value
                  ? parseInt(reqObj.dropHoldingTimeHours.value) * 60 + 0
                  : reqObj.dropHoldingTimeMinutes.value
                  ? 0 + parseInt(reqObj.dropHoldingTimeMinutes.value)
                  : 0,
            }
          : {}),
        ...(reqObj.totalTATHours.value || reqObj.totalTATMin.value
          ? {
              totalTAT:
                reqObj.totalTATHours.value && reqObj.totalTATMin.value
                  ? parseInt(reqObj.totalTATHours.value) * 60 +
                    parseInt(reqObj.totalTATMin.value)
                  : reqObj.totalTATHours.value
                  ? parseInt(reqObj.totalTATHours.value) * 60 + 0
                  : reqObj.totalTATMin.value
                  ? 0 + parseInt(reqObj.totalTATMin.value)
                  : 0,
            }
          : {}),
        ...(reqObj.polyLine ? { polyLine: reqObj.polyLine } : {}),
        onlyWaypointAlert: reqObj.onlyWaypointAlert.value,
        maximumExpenses: Number(reqObj.maximumExpenses.value),
        maximumFuel: Number(reqObj.maximumFuel.value),
        driverIncentive: Number(reqObj.driverIncentive.value),
        maximumAdBlue: Number(reqObj.maximumAdBlue.value),
        maximumDistance: Number(reqObj.maximumDistance.value),
        ...(reqObj.commodity.value
          ? { commodity: reqObj.commodity.value }
          : {}),
        freightType: "None",
        freightAmount: Number(parseFloat(reqObj.freightAmount.value).toFixed(2)),
        ownerUnits: reqObj.ownerUnits.value ?reqObj.ownerUnits.value.value: null,
        marketUnits: reqObj.marketUnits.value?reqObj.marketUnits.value.value: null,
        maximumMarketVehicleFreightAmt: Number(parseFloat(
          reqObj.maximumMarketVehicleFreightAmt.value
        ).toFixed(2)),
        notes: reqObj.notes.value,
      },
      wayPoints: reqObj.wayPoints.map((wp) => {
        return {
          waypointName: wp.waypointName.value,
          transitTime:
            wp.transitHours.value && wp.transitMinutes.value
              ? parseInt(wp.transitHours.value) * 60 +
                parseInt(wp.transitMinutes.value)
              : wp.transitHours.value
              ? parseInt(wp.transitHours.value) * 60 + 0
              : wp.transitMinutes.value
              ? 0 + parseInt(wp.transitMinutes.value)
              : 0,
          holdingTime:
            wp.holdingHours.value && wp.holdingMinutes.value
              ? parseInt(wp.holdingHours.value) * 60 +
                parseInt(wp.holdingMinutes.value)
              : wp.holdingHours.value
              ? parseInt(wp.holdingHours.value) * 60 + 0
              : wp.holdingMinutes.value
              ? 0 + parseInt(wp.holdingMinutes.value)
              : 0,
          location: {
            latitude: wp.location.lat,
            longitude: wp.location.lng,
            address: wp.location.value,
          },
          ...(wp.siteId.value.value ? { siteId: wp.siteId.value.value } : {}),
          waypointId: wp.waypointName.id,
          sequencenumber: wp.waypointName.sequenceNumber,
          ...(wp.groups.value.length > 0 ? { groups: wp.groups.value } : {}),
          mobileNumbers: [
            wp.mobileNumber1.value ? `+91${wp.mobileNumber1.value}` : "",
            wp.mobileNumber2.value ? `+91${wp.mobileNumber2.value}` : "",
            wp.mobileNumber3.value ? `+91${wp.mobileNumber3.value}` : "",
          ]
            .filter((item) => item !== "")
            .toString(),
        };
      }),
    };
  };

  createVendorRequest = (reqObj, tableData) => {
    let resObj = {};
    for (let key in reqObj) {
      if (
        reqObj[key].name != "route" &&
        reqObj[key].name != "vehicleNumber" &&
        reqObj[key].name != "chassisNumber" &&
        reqObj[key].name != "type"
      ) {
        if (reqObj[key].name == "bankName") {
          if (reqObj[key].value && reqObj[key].value.value) {
            resObj[reqObj[key].name] = reqObj[key].value.value.toString();
          } else {
            resObj[reqObj[key].name] = null;
          }
        } else {
          resObj[reqObj[key].name] = reqObj[key].value;
        }
      }
      // if(reqObj[key].name == 'route') {
      //   resObj[reqObj[key].name] = reqObj[key].value.value;
      // } else {
      // resObj[reqObj[key].name] = reqObj[key].value;
      // }
    }
    let vehicles = [];

    for (let i = 0; i < tableData.length; i++) {
      let vehicleObj = {
        registrationNumber: tableData[i].Vehicle_Number.props.children,

        chassisNumber: tableData[i].chassis_Number.props.children,
        routeId: tableData[i].Route.props.value,
        truckTypeId: tableData[i].type.props.value,
      };

      vehicles.push(vehicleObj);
    }

    resObj.vehicles = vehicles;
    return resObj;
  };
}
export default RouteRequestBuilders;
