import React, { useState } from "react";
import { Select } from "antd";
import { FormLabel, FormHelperText } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const { Option } = Select;

const useStyles = makeStyles((theme) => ({
  helperText: { color: "red", marginRight: "0px", marginLeft: "0px" },
  headLabel: {
    fontWeight: "normal",
    color: "#333333",
    fontSize: "12px",
    fontFamily: "Montserrat, SemiBold",
  },
}));

const MultiSelectField = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleChange = (value, name) => {
    props.selectChangeHandler(value, name);
  };
  return (
    <div
      className="ant_custom_multi_select"
      id="testPosition"
      style={{ position: "relative" }}
    >
      <FormLabel
        className={classes.headLabel}
        required={props.fieldData.validationRequired}
      >
        {props.fieldData.label}
      </FormLabel>
      <Select
        mode={props.mode}
        value={props.fieldData.actualValue}
        getPopupContainer={() => document.getElementById("testPosition")}
        style={{ width: "100%", marginTop: "8px" }}
        onChange={(value) => {
          handleChange(value, props.fieldData.name);
        }}
        tokenSeparators={[","]}
        open={open}
        showArrow={true}
        onMouseEnter={() => {
          setOpen(true);
        }}
        onMouseLeave={() => {
          setOpen(false);
        }}
        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      >
        {props.options.length > 0
          ? props.options.map((gp) => <Option key={gp.id}>{gp.name}</Option>)
          : []}
      </Select>
      {props.fieldData.isValid ? null : (
        <FormHelperText className={classes.helperText}>
          {props.fieldData.errorMsg}
        </FormHelperText>
      )}
    </div>
  );
};

export default MultiSelectField;
