import AuthenticationRequestBuilders from "../RequestBuilders/autentication";

class Authentication {
  constructor(Service, path, ownerPath, Util) {
    this.httpService = Service;
    this.path = path;
    this.ownerPath = ownerPath;
    this.authenticationRequestBuilders = new AuthenticationRequestBuilders(Util)
  }

  userLogin = (data) => {
    let reqData = this.authenticationRequestBuilders.login(data);
    return new Promise((resolve, reject) => {
      const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + this.ownerPath.LOGIN;
      return this.httpService
        .post(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((errorObj) => {
          reject(errorObj);
        });
    });
  };

  getUserDetails = () => {
    return new Promise((resolve, reject) => {
      const url =
        process.env.REACT_APP_SUVEGA_API_ENDPOINT_2 +
        this.path.GET_USER_DETAILS;
      return this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((errorObj) => {
          reject(errorObj);
        });
    });
  };

  userLoginByToken = () => {
        return new Promise((resolve, reject) => {
			const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + this.ownerPath.LOGIN_BY_TOKEN;
			return this.httpService.post(url, {})
				.then((res) => {
					resolve(res);
				})
				.catch((errorObj) => {
					reject(errorObj);
				});
		    });
    };
}

export default Authentication;