export default (state = {}, action) => {
    switch (action.type) {
        case "FUEL_ODO_SUMMARY_DETAILS":
            return { ...state, ...action.payload}
        case "FUEL_ODO_INDENT_DETAILS":
            return {...state, ...action.payload}    
        default:
            return state;

    }
}