import BankRequestBuilders from "../../services/RequestBuilders/banks";

class OnDemandTrips {
  constructor(Service, path, Util) {
    this.httpService = Service;
    this.path = path;
    this.Util = Util;
    this.bankRequestBuilder = new BankRequestBuilders();
  }

  addTripData = (data) => {
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + "/routes/onDemandRouteTrips"
    // https://api-dev.suvegafleet.com
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  addOndemandTripsData = (data) => {
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + "/trips/addOnDemandtrip"
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  onDemandTripsFiltringData = (data) => {
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + "/routes/selectedTripsData"
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  addUploadFileData = (data) => {
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + "/dprOrders"
    // https://api-dev.suvegafleet.com
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  addUploadFile = (data) => {
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + "/dprOrders/uploadDprFile"
    // https://api-dev.suvegafleet.com
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}
export default OnDemandTrips