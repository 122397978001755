import React from "react";
import { TextField, Autocomplete } from "@mui/material";
import { makeStyles, FormHelperText, FormLabel } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  autoComplete: { width: "100%" },
  helperText: { color: "red", marginRight: "0px", marginLeft: "0px" },
  mtop: { marginTop: "8px" },
  headLabel: {
    fontWeight: "normal",
    color: "#333333",
    fontSize: "12px",
    fontFamily: "Montserrat, SemiBold"
  },
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
}));
export default function AutoCompleteField(props) {
  const classes = useStyles();
  return (
    <div>
      {props.fieldData.topLabel ? (
        <FormLabel
          className={classes.headLabel}
          required={props.fieldData.validationRequired}
        >
          {props.fieldData.label}
        </FormLabel>
      ) : null}
      <div className={props.fieldData.topLabel ? "custom_field" : ""}>
        <Autocomplete
          value={props.fieldData.value}
          disablePortal
          id="combo-box-demo"
          options={props.fieldData.options}
          className={`${classes.autoComplete} ${props.fieldData.topLabel ? classes.mtop : ""
            } ${props.className ? props.className : ""}`}
          renderInput={(params) => (
            <TextField
              {...params}
              label={props.fieldData.topLabel ? null : props.fieldData.label}
              required={props.fieldData.validationRequired}
              variant={props.variant}
              placeholder={props.fieldData.placeholder}
            />
          )}
          disabled={props.fieldData.readOnly === true ? true : false}
          onChange={(event, value) => {
            props.autoCompleteChangeHandler(value, props.fieldData.name);
          }}
          disabled={props.fieldData.readOnly === true ? true : false}
        />
        {props.fieldData.isValid ? null : (
          <FormHelperText className={classes.helperText}>
            {props.fieldData.errorMsg}
          </FormHelperText>
        )}
      </div>
    </div>
  );
}
