class CreateExpensesRequestBuilders {
  constructor(util) {
    this.util = util;
  }
  CreateExpenses = (reqObj) => {
    return {
      tripsheet_id: reqObj.tripsheet_id,
      booking_id: reqObj.booking_id,
      expense_type: reqObj.expenses_type.value,
      price: reqObj.price.value,
      unit: reqObj.unit.value,
      unit_value: reqObj.unit_value.value,
      location: reqObj.location.value,
      voucher_id: reqObj.voucher_id.value,
      attachment: reqObj.attachment.value,
    };
  };
  UpdateExpenses = (reqObj) => {
    return {
      tripsheet_id: reqObj.tripsheet_id,
      expense_id: reqObj.expense_id,
      expense_type: reqObj.expenses_type.value,
      price: reqObj.price.value,
      unit: reqObj.unit.value,
      unit_value: reqObj.unit_value.value,
      location: reqObj.location.value,
      voucher_id: reqObj.voucher_id.value,
      attachment: reqObj.attachment.value,
    };
  };
  addAdvance = (reqObj) => {
    return {
      tripsheet_id: reqObj.tripsheet_id,
      data: [
        {
          trip_id: reqObj.booking_id,
          advanced_paid_by_customer: parseInt(reqObj.advanced_paid_by_customer.value),
          advanced_paid_by_owner: parseInt(reqObj.advanced_paid_by_owner.value)
        },
      ],
    };
  };
}
export default CreateExpensesRequestBuilders;
