class AuthenticationRequestBuilders {
    constructor(util){
        this.util = util;
    }

    login = (reqObj) => {
        return {
            "email": reqObj.email.value,
            "password": reqObj.password.value,
            "gcm_id":reqObj.gcmToken
        }
    }
}

export default AuthenticationRequestBuilders;