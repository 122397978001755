import React, { useEffect, useState } from "react";
import Map from "./map";
import PlayerPanel from "./playerPanel";
import { Typography } from "antd";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import decodePolyline from 'decode-google-map-polyline';
// import LoadersPlayerPanel from "../loadersPlayerPanel";
function TrackingMap(props) {
  const [PolyLinePoints, setPolyLinePoints] = useState([]);
  const [positionFromPanel, getPositionFromPanel] = useState({
    position: [],
    direction: 0,
    timestamp: new Date(),
  });
  const [pcsPositions,getPcsPositions] = useState({
    position:[]
  })
  const [pcsPolyline,setPcsPolyline] = useState([])
  const [selectedVehicle, setSelectedVehicle] = useState(props.SelectedVehicle);
  const [ErrorMessage, setErrorMessage] = useState("Loading...");
  const [loading, setLoading] = useState(false);
  const [pcsData,setPcsData] = useState([])
  const setPositionInMap = (pos) => {
    getPositionFromPanel(pos);
    props.setVehilcePositionTimestampFunc(
      pos.timestamp
        ? moment(pos.timestamp * 1000).format("yyyy-MM-DD hh:mm A")
        : ""
    );
  };

  const getTrackingData = (trackingInput) => {
    let vehicleFlespiId = null;
    if (props.SelectedVehicle && props.SelectedVehicle.flespi_id) {
      vehicleFlespiId = props.SelectedVehicle.flespi_id;
    }
    if (vehicleFlespiId) {
      setLoading(true);
      props.getDistanceTravelled(null);
      props.getRunningHours(null);
      setPolyLinePoints([]);
      let promise1 = global.service
      .getTrackingMessages({
        flespiId: vehicleFlespiId,
        fromUnixTimestamp: Math.floor(
          new Date(props.fromDate).getTime() / 1000
        ),
        toUnixTimestamp: Math.floor(new Date(props.toDate).getTime() / 1000),
      })
      let promise2 = global.service.getLoadersRouteHistory({fromTimeStamp: Math.floor(
        new Date(props.fromDate).getTime() / 1000
      ),
      toTimeStamp: Math.floor(new Date(props.toDate).getTime() / 1000),vehicleIds:""})
      Promise.all([promise1,promise2]).then((response)=>{
        setLoading(false);
        if (response[0]) {
          if(response[0].status){
            var points = response[0].data.map((ce, index) => ({
              value: index,
              label: index,
              position: [ce["position.latitude"], ce["position.longitude"]],
              direction: ce["position.direction"],
              odometer: ce["vehicle.mileage"],
              timestamp: ce["timestamp"],
              speed: ce["position.speed"],
            }));
            getPositionFromPanel({
              position: points[0].position,
              direction: points[0].direction,
              timestamp: points[0].timestamp,
              odometer: points[0].odometer,
              speed: points[0].speed,
            });
            setPolyLinePoints(points);
            // setLoading(false);
            if (points.length == 0) {
              setErrorMessage("No Data Available");
            }
          }else{
            setErrorMessage(response[0].message)
          }

        } 
        if(response[1]){
          if(response[1].status){
            let filterPolylineData = []
            let filterPolyline = response[1]?.data.map((i)=>{
             let getData=  decodePolyline(i?.polyline)
             let data ={position:getData,vehicle:i.vehicle}
             filterPolylineData.push(data)
             return filterPolylineData
            })
            if(filterPolylineData.length && filterPolylineData.length>0){
              setPcsData(filterPolylineData)
              getPcsPositions({
                position:[filterPolylineData[0].position[0].lat,filterPolylineData[0].position[0].lng]
              })
              let latLngArray = []
              filterPolylineData.forEach(item => {
                item.position.forEach(pos => {
                  latLngArray.push([pos.lat, pos.lng]);
                });
              });
              setPcsPolyline(latLngArray)
              // let filterPositions = filterPolylineData.map((i)=>{
              //   console.log(i?.position[0])
              //   getPcsPositions({
              //     position:[i?.position[0].lat,i?.position[0].lng]
              //   })
              // })
            }
          }else{
            setErrorMessage(response[1].message)
          }
        }else {
          setLoading(false);
          setErrorMessage("No Data Available");
        }
        // const decodedPath = decode(encodedPolyline);
        // const coordinates = decodedPath.map(point => ({
        //   lat: point.lat,
        //   lng: point.lng
        // }));
      })
      // global.service
      //   .getTrackingMessages({
      //     flespiId: vehicleFlespiId,
      //     fromUnixTimestamp: Math.floor(
      //       new Date(props.fromDate).getTime() / 1000
      //     ),
      //     toUnixTimestamp: Math.floor(new Date(props.toDate).getTime() / 1000),
      //   })
      //   .then((response) => {

      //   })
        .catch((err) => {
          console.log(err);
          // this.setState({ successMsg: err.toString() });
          setLoading(false);
          setErrorMessage("Unable to fetch data from API");
        });
    }
  };
  useEffect(() => {
    getTrackingData(props);
  }, [props.SelectedVehicle]);
  return (
    <>
      {PolyLinePoints.length > 0 ? (
        <>
          <Map
            key={props.key}
            currentPosition={positionFromPanel}
            coordinates={PolyLinePoints}
            height={props.height}
            SelectedVehicle={props.SelectedVehicle}
            fromDate={props.fromDate}
            toDate={props.toDate}
            getDistanceTravelled={props.getDistanceTravelled}
            getRunningHours={props.getRunningHours}
            DriverBehaviorEventType={props.DriverBehaviorEventType}
            StopEventTypes={props.StopEventTypes}
            siteDetails={props?.siteDetails}
            pcsPositions={pcsPositions}
            pcsPolyline={pcsPolyline}
            pcsData={pcsData}
          />
          <PlayerPanel
            key={props.key}
            setPositionInMap={setPositionInMap}
            coordinates={PolyLinePoints}
          />
          {/* {pcsPolyline &&pcsPolyline.length>0 ? <LoadersPlayerPanel setPositionInMap={setPositionInMap} coordinates={pcsPolyline}/> : null} */}
        </>
      ) : loading ? (
        <CircularProgress
          disableShrink
          style={{ position: "fixed", top: "50%", left: "50%" }}
        />
      ) : (
        <div
          style={{
            position: "fixed",
            top: "50%",
            width: "100%",
            textAlign: "center",
            fontSize: 25,
            fontFamily: "sans-serif",
            color: "#366E93",
          }}
        >
          <Typography>{ErrorMessage} </Typography>
        </div>
      )}
    </>
  );
}

export default TrackingMap;
