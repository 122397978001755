import React, { useState } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Button } from "@material-ui/core";
import "../css/custom_columns.css";
import _ from "lodash";

const CustomColumns = (props) => {
  const [dropDownVisible, setDropDownVisible] = useState(false);
  const [columns, setColumns] = useState(props.columns);
  const columnCheckHandler = (i, e) => {
    let newCols = _.cloneDeep(columns);
    newCols[i]["checked"] = e.target.checked;
    setColumns(newCols);
  };
  return (
    <div
      id="list1"
      className={
        dropDownVisible ? "dropdown-check-list visible" : "dropdown-check-list"
      }
      tabIndex={100}
    >
      <span
        className="anchor"
        onClick={() => {
          setDropDownVisible(!dropDownVisible);
        }}
      >
        <SettingsIcon style={{ fontSize: "20px" }} />
        {dropDownVisible ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </span>
      <ul className="items">
        {columns
          .filter((col) => col.label.length > 0)
          .map((col, i) => (
            <li key={col.label}>
              <input
                type="checkbox"
                checked={col.checked}
                onChange={(e) => {
                  columnCheckHandler(i, e);
                }}
              />
              <span>{col.label}</span>
            </li>
          ))}
        <hr style={{ margin: "4px 0px" }} />
        <li>
          <Button
            variant="contained"
            disableElevation={true}
            style={{
              padding: "2px 15px",
              backgroundColor: "#D3710F",
              color: "#FFF",
            }}
            onClick={() => {
              props.checkApplyHandler(columns);
            }}
          >
            Apply
          </Button>
        </li>
      </ul>
    </div>
  );
};

export default CustomColumns;
