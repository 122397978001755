import React, { useState, useEffect } from "react";
import * as L from "leaflet";
import { Marker, Popup, useMapEvents, Tooltip } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import SetDivIcon from "./icon";

export default function LocationMarker(props) {
  const coordinates = props.coordinates.map((x) => x.position);
  const [position, setPosition] = useState(props.currentPosition.position);
  const [currentPostion, setCurrentPosition] = useState(props.currentPosition);
  const [lastPosition, setLastPosition] = useState(
    props.currentPosition.position
  );
  const [direction, setDirection] = useState(props.currentPosition.direction);
  const [vehiclePathLayer, setVehiclePathLayer] = useState(null);

  if (position != props.currentPosition.position) {
    setPosition(props.currentPosition.position);
    setCurrentPosition(props.currentPosition);
    setDirection(props.currentPosition.direction);
    if(currentPostion.position === props.currentPosition.position){
      setCurrentPosition(coordinates)
    }
  }

  const [isPopupOpen, setPopupOpen] = React.useState(false);
  const map = useMapEvents({});
  // map.setMaxBounds(map.getBounds());
  if (vehiclePathLayer) {
    map.removeLayer(vehiclePathLayer);
  }
  const pathPolyLine = L.polyline(coordinates);
  if (!pathPolyLine) {
    setVehiclePathLayer(pathPolyLine);
  }
  if (pathPolyLine) {
    clearMap(map);
    pathPolyLine.addTo(map);
  }
  // if(props && coordinates === )
  useEffect(() => {
    map.flyTo(position);
  }, []);
  const handlePopupClose = () => {
    // Set the state to close the popup
    setPopupOpen(false);

    // Add #close to the URL
    window.history.pushState(null, null, "#close");
  };
  return position === null ? null : (
    <div>
      <Marker
        key={props.key}
        position={position}
        icon={L.divIcon(SetDivIcon(`rotate(${direction || 0}deg)`))}
        style={{ background: "transperent",}}
        keepAtCenter={true}
      >
        <Tooltip>
          <div style={{ padding: 10 }}>
            <p
              style={{
                display: "flex",
                justifyContent: "center",
                fontWeight: "bold",
              }}
            >
              {props.vehicleDetails.vehicle}
            </p>
            <p style={{ fontWeight: "bold" }}>
              Latitude : {position[0]},Longitude : {position[1]}
            </p>
          </div>
        </Tooltip>
        {/* <Popup onClose={handlePopupClose} closeButton={false} autoClose={false} closeOnClick={false} isOpen={isPopupOpen}>You are here</Popup> */}
      </Marker>
    </div>
  );
}

function clearMap(m) {
  for (let i in m._layers) {
    if (m._layers[i]._path != undefined) {
      try {
        m.removeLayer(m._layers[i]);
      } catch (e) {
        console.log("problem with " + e + m._layers[i]);
      }
    }
  }
}
