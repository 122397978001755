class GroupRequestBuilders {
  constructor(util) {
    this.util = util;
  }
  createGroup = (reqObj) => {
    return {
      groupName: reqObj.groupName.value,
      telegramId: reqObj.telegramId.value,
      externalMobileNumbers: [
        reqObj.externalMobileNumber1.value ? `+91${reqObj.externalMobileNumber1.value}` : "", 
        reqObj.externalMobileNumber2.value ? `+91${reqObj.externalMobileNumber2.value}`: ""
      ].filter((item) => item !== "").toString(),
      ...(reqObj.externalEmails.value ? { externalEmails: reqObj.externalEmails.value } : {}),
      ...(reqObj.groupUsers.length > 0 ? {groupUsers : reqObj.groupUsers.map((user) => {
        let id = user.id.toString();
        let role = user.roleId.toString();
        return { 
          userId: id,
          roleId: role
        }
      })} : {}),
    }
  };
}
export default GroupRequestBuilders;
