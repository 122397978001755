import React, { useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FormLabel, makeStyles } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  headLabel: {
    // textTransform: "uppercase",
    fontWeight: "normal",
    color: "#333333",
    fontSize: "12px",
    fontFamily: "Montserrat, SemiBold",
    marginBottom: "10px"
  },
}));

const DateRangeSelection = (props) => {
  const classes = useStyles();
  const maxDays = 15;
  const [ranges, setRanges] = useState({
    ...(props.fieldData.allowClear ? {'None': []} : {}),
    Yesterday:[
      moment().subtract(1, "days").startOf("day"), 
      moment().subtract(1, "days").endOf("day")
    ],
    Today: [moment(), moment()],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    // "Last 15 Days": [moment().subtract(14, "days"), moment()],
  });
  const [dates, setDates] = useState(
    props.fieldData.value.length > 0
      ? {
          startDate: props.fieldData.value[0],
          endDate: props.fieldData.value[1],
        }
      : {startDate: moment(),
        endDate: moment(),}
  );
  return (
    <>
      {props.fieldData.topLabel ? (
        <FormLabel
          className={classes.headLabel}
          required={props.fieldData.validationRequired}
        >
          {props.fieldData.label}
        </FormLabel>
      ) : null}
      <DateRangePicker
        initialSettings={{
          ranges: ranges,
          maxSpan: {
            days: maxDays,  // Restrict maximum days
          },
          // showCustomRangeLabel: false,
          // startDate: dates.startDate,   // Set default start date
          // endDate: dates.endDate,  
        }}
        onApply={(e, picker) => {
          if (picker.chosenLabel === "Custom Range") {
            setDates({ startDate: picker.startDate, endDate: picker.endDate });
            props.dateRangeChangeHandler(
              { startDate: picker.startDate, endDate: picker.endDate },
              props.fieldData.name
            );
          } else {
            setDates({
              startDate: ranges[picker.chosenLabel][0],
              endDate: ranges[picker.chosenLabel][1],
            });
            props.dateRangeChangeHandler(
              {
                startDate: ranges[picker.chosenLabel][0],
                endDate: ranges[picker.chosenLabel][1],
              },
              props.fieldData.name
            );
          }
        }}
      >
        <div
          style={{
            background: "#FFF",
            cursor: "pointer",
            padding: "8px 10px",
            border: "1px solid #91b0c4",
            borderRadius:4
          }}
        >
          <CalendarMonthIcon />
          {dates.startDate &&
          dates.endDate &&
          props.fieldData.value.length > 0 ? (
            <span
              style={{ verticalAlign: "middle", padding: "0px 0px 0px 4px" }}
            >
              {dates.startDate.format("MMMM D, YYYY")} -{" "}
              {dates.endDate.format("MMMM D, YYYY")}
            </span>
          ) : (
            <span
              style={{ verticalAlign: "middle", padding: "0px 0px 0px 4px" }}
            >
              Select Date
            </span>
          )}
          <ArrowDropDownIcon />
        </div>
      </DateRangePicker>
    </>
  );
};

export default DateRangeSelection;
