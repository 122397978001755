import React from "react";
import { Select } from "antd";
import { makeStyles, FormHelperText, FormLabel } from "@material-ui/core";
// import axios from "../../services/Axios";
// import axios from "axios";
const { Option } = Select;

const useStyles = makeStyles((theme) => ({
  autoComplete: {
    width: "100%",
  },
  helperText: { color: "red", marginRight: "0px", marginLeft: "0px" },
  mtop: { marginTop: "8px" },
  headLabel: {
    fontWeight: "normal",
    color: "#333333",
    fontSize: "12px",
    fontFamily: "Montserrat, SemiBold",
  },
}));

const SelectableSearchField = (props) => {
  const classes = useStyles();
  const changeHandler = (value, name) => {
    if (value || Number(value) === 0) {
      let val = props.fieldData.options.filter((opt) => opt.value === value);
      props.autoCompleteChangeHandler(val[0], name);
    } else {
      props.autoCompleteChangeHandler({ label: "", value: "" }, name);
    }
  };
  return (
    <div>
      {props?.fieldData?.topLabel ? (
        <FormLabel
          className={classes.headLabel}
          required={props.fieldData.validationRequired}
          style={{color:props.fieldData?.labelColor}}
        >
          {props.fieldData?.image ? <img src = {props.fieldData.image} style={{height:'17px',width:'17px',marginRight:'5px'}}/> : null}
          {props.fieldData.label}
        </FormLabel>
      ) : null}
      <Select
        showSearch
        placeholder={props?.fieldData?.placeholder}
        onChange={(value) => {
          changeHandler(value, props?.fieldData?.name);
        }}
        onFocus={() => {
          if (props?.autoCompleteFocusHandler) {
            props?.autoCompleteFocusHandler(
              props?.fieldData?.value,
              props?.fieldData?.name
            );
          }
        }}
        allowClear={props?.fieldData?.allowClear}
        value={props?.fieldData?.value?.value || undefined}
        className={classes.autoComplete}
        disabled={props.disabled}
        style={{ marginTop: props?.fieldData?.topLabel ? "8px" : ""}}
        loading={props?.loading}
        filterOption={(input, option) => {
          if (option?.children) {
            return (
              option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
          }
        }}
      >
        {props?.fieldData?.options?.map((option, i) => {
          return (
            <Option value={option?.value} key={i}>
              {option?.label}
            </Option>
          );
        })}
      </Select>
      {props?.fieldData?.isValid ? null : (
        <FormHelperText className={classes.helperText}>
          {props?.fieldData?.errorMsg}
        </FormHelperText>
      )}
    </div>
  );
};

export default SelectableSearchField;
