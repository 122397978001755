import React from 'react';
import { Select } from 'antd';

// function onBlur() {
//     console.log('blur');
// }

// function onFocus() {
//     console.log('focus');
// }

function onSearch(val) {
}
export default function AntdSelect(props) {

    function onChange(value) {
        props.onChange(value);
    }
    return (
        <Select
            showSearch
            style={{ width: 200, padding: 5 }}
            placeholder={props.placeholder}
            optionFilterProp="children"
            onChange={onChange}
            // value={props.defaultValue}
            // onFocus={onFocus}
            // onBlur={onBlur}
            value={props.value}
            onSearch={onSearch}
            filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
        >
            {
                props.items.map(x => {
                    return <Select.Option value={x}>{x}</Select.Option>
                })
            }
        </Select>
    );
}