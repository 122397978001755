import React, { useState, useEffect, useRef } from "react";
import Header from "../../components/header";
import "../../common.css";
import * as Fields from "../../sharedComponents";
import { Typography, Button, Grid } from "@material-ui/core";
import { makeStyles, CircularProgress } from "@material-ui/core";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
// import * as Fields from "../../sharedComponents";
// import { useHistory } from "react-router-dom";
import CircularLoading from "../../components/loader/circularLoading";
import _ from "lodash";
import * as Components from "../../sharedComponents";
import AlertMessage from "../../components/alertmessage/AlertMessage";
import * as CONFIG from "../../config/GlobalConstants";
import EditIcon from "@mui/icons-material/Edit";
// import moment from "moment";
// import AddCategories from "./AddCategory";
// import EditCategories from "./EdditCategory";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import ValidateFields from "../../validations/validateFields";
function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}
const useStyles = makeStyles((theme) => ({
  addindent_btn: {
    textTransform: "none",
    backgroundColor: "#649B42",
    "&:hover": {
      backgroundColor: "#649B42",
    },
    padding: "8px 15px",
    marginRight: "12px",
  },
  update_btn: {
    textTransform: "none",
    backgroundColor: "#649B42",
    "&:hover": {
      backgroundColor: "#649B42",
    },
    marginRight: "15px",
  },
  cancel_btn: {
    textTransform: "none",
    backgroundColor: "#E15656",
    "&:hover": {
      backgroundColor: "#E15656",
    },
  },
  export_button: {
    padding: "8px 15px",
    background: "#366E93",
    textTransform: "none",
    color: "#FFF",
    "&:hover": {
      background: "#366E93",
    },
  },
  select: {
    width: "180px",
    marginRight: "16px",
  },
  header_box: {
    display: "flex",
    background: "#FFF",
    padding: "10px 24px 20px 24px",
  },
  alertBox: {
    padding: "10px 15px 0px 15px",
  },
  text: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#366E93",
    marginTop: "25px",
    marginRight: "15px",
    alignSelf: "center",
  },
  table_data: {
    fontSize: "14px",
  },
}));
var Service;
const InventoryCategory = () => {
  const columns = [
    { id: "s_no", disablePadding: true, label: "S.NO", checked: true },
    {
      id: "parentEquipment",
      disablePadding: true,
      label: "PARENT EQUIPMENT",
      checked: true,
    },
    {
      id: "name",
      disablePadding: true,
      label: "NAME",
      checked: true,
    },
    {
      id: "inventoryBenchmark",
      label: "INVENTORY BENCHMARK",
      disablePadding: true,
      checked: true,
    },
    {
      id: "vehicleBenchmark",
      label: "VEHICLE BENCHMARK",
      disablePadding: true,
      checked: true,
    },
    {
      id: "actions",
      disablePadding: true,
      label: "ACTIONS",
      checked: true,
    },
  ];

  const formFields = {
    equipment: {
      name: "equipment",
      label: "Equipment",
      value: { label: "", value: "" },
      options: [],
      topLabel: true,
      // validationRequired: true,
      // maxLength: 30,
      validPattern: "SPECIAL_CHARS_DESC",
      isValid: true,
      // errorMsg: "Please enter your name",
      ref: useRef(),
    },
    name: {
      name: "name",
      label: "Name",
      value: "",
      topLabel: true,
      validationRequired: true,
      validPattern: "SPECIAL_CHARS_DESC",
      isValid: true,
      errorMsg: "Please enter equipment name",
      ref: useRef(),
    },
    inventoryBenchmark: {
      name: "inventoryBenchmark",
      label: "Inventory Benchmark",
      value: "",
      topLabel: true,
      validationRequired: true,
      validPattern: "NUMBER",
      isValid: true,
      errorMsg: "Please enter inventory benchmark",
      type: "number",
    },
    vehicleBenchmark: {
      name: "vehicleBenchmark",
      label: "Vehicle Benchmark",
      value: "",
      topLabel: true,
      validationRequired: true,
      validPattern: "NUMBER",
      isValid: true,
      errorMsg: "Please enter vehicle benchmark",
      ref: useRef(),
      type: "number",
    },
  };

  const addedFormFields = {
    addEquipment: {
      name: "addEquipment",
      label: "Equipment",
      value: { label: "", value: "" },
      options: [],
      topLabel: true,
      // validationRequired: true,
      // maxLength: 30,
      validPattern: "SPECIAL_CHARS_DESC",
      isValid: true,
      // errorMsg: "Please enter your name",
      ref: useRef(),
    },
    addName: {
      name: "addName",
      label: "Name",
      value: "",
      topLabel: true,
      validationRequired: true,
      validPattern: "SPECIAL_CHARS_DESC",
      isValid: true,
      errorMsg: "Please enter equipment name",
      ref: useRef(),
    },
    addInventoryBenchmark: {
      name: "addInventoryBenchmark",
      label: "Inventory Benchmark",
      value: "",
      topLabel: true,
      validationRequired: true,
      validPattern: "SPECIAL_CHARS_DESC",
      isValid: true,
      errorMsg: "Please enter inventory benchmark",
      ref: useRef(),
      type: "number",
    },
    addVehicleBenchmark: {
      name: "addVehicleBenchmark",
      label: "Vehicle Benchmark",
      value: "",
      topLabel: true,
      validationRequired: true,
      validPattern: "SPECIAL_CHARS_DESC",
      isValid: true,
      errorMsg: "Please enter vehicle benchmark",
      ref: useRef(),
      type: "number",
    },
  };

  const [addedFormData, setAddedFormData] = useState(addedFormFields);
  const [formData, setFormData] = useState(formFields);
  const [selectedObj, setSelectedObj] = useState({});
  let validateFields = new ValidateFields();
  const classes = useStyles();
  Service = global.service;
  const [headRows, setHeadRows] = useState(columns);
  const [cols, setCols] = useState(columns);
  const [pagination, setPagination] = useState({
    current: CONFIG.PAGINATION.current,
    pageSize: CONFIG.PAGINATION.pageSize,
  });
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  useEffect(() => {
    window.scrollTo({ top: 0 });
    let dataObj = {
      skip: pagination.current * pagination.pageSize,
      limit: pagination.pageSize,
    };
    renderIndents(dataObj, cols);
    // setPagination({ ...pagination, pageSize: rowsPerPage })
    Service.getSelectionEquipment().then((res) => {
      let addedNewField = _.cloneDeep(addedFormData);
      let newField = _.cloneDeep(formData);
      addedNewField["addEquipment"]["options"] = res.data.map((item) => {
        return { label: item.name, value: item.id };
      });
      newField["equipment"]["options"] = res.data.map((item) => {
        return { label: item.name, value: item.id };
      });
      setFormData(newField);
      setAddedFormData(addedNewField);
    });
  }, []);
  const [tableData, setTableData] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [total, setTotal] = useState(0);
  const [indentIsOpen, setIndentIsOpen] = useState(false);
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    message: "",
  });

  const [editData, setEditData] = useState();
  const [addOpen, setAddOpen] = useState(false);
  const forOpeningTheAddEquipmentDialogBox = () => {
    Service.getSelectionEquipment().then((res) => {
      let addedNewField = _.cloneDeep(addedFormData);
      addedNewField["addEquipment"]["options"] = res.data.map((item) => {
        return { label: item.name, value: item.id };
      });
      setAddedFormData(addedNewField);
    });
    setAddOpen(true);
  };
  const updatehandleClose = () => {
    setEditOpen(false);
  };
  const addhandleClose = () => {
    setAddedFormData(_.cloneDeep(addedFormFields));
    setAddOpen(false);
  };
  const decimalValidation = (value) => {
    const regex = new RegExp("^[0-9]+(.[0-9]{1,3})?$");
    return regex.test(value);
  };
  const otherThenalphaNumericValidation = (value) => {
    const regex = new RegExp("^[a-zA-Z0-9 ]*$");
    return regex.test(value);
  };
  const inputChangeHandler = (value, name) => {
    if (
      decimalValidation(value) === true ||
      value === "" ||
      otherThenalphaNumericValidation(value) === true
    ) {
      if (
        name == "addEquipment" ||
        name == "addName" ||
        name == "addInventoryBenchmark" ||
        name == "addVehicleBenchmark"
      ) {
        let newFields = _.cloneDeep(addedFormData);
        newFields[name]["isValid"] = true;
        if (value.length === 0 && newFields[name]["validationRequired"]) {
          newFields[name]["isValid"] = false;
        }
        newFields[name]["value"] = value;
        setAddedFormData(newFields);
      } else {
        let newFields = _.cloneDeep(formData);
        newFields[name]["isValid"] = true;
        if (value.length === 0 && newFields[name]["validationRequired"]) {
          newFields[name]["isValid"] = false;
        }
        newFields[name]["value"] = value;
        setFormData(newFields);
      }
    }
  };
  const forShowingPreviousData = async (selectedObj) => {
    let newField = _.cloneDeep(formData);
    await Service.getSelectionEquipment().then((res) => {
      newField["equipment"]["options"] = res.data.map((item) => {
        return { label: item.name, value: item.id };
      });
    });
    newField["equipment"]["value"] = {
      label: selectedObj.parentName,
      value: selectedObj.parentId,
    };
    newField["name"]["value"] = selectedObj.name;
    newField["inventoryBenchmark"]["value"] = selectedObj.inventory_benchmark;
    newField["vehicleBenchmark"]["value"] = selectedObj.truck_benchmark;
    setFormData(newField);
  };
  const autoCompleteChangeHandler = (value, name) => {
    if (name == "addEquipment") {
      let newFields = _.cloneDeep(addedFormData);
      newFields[name]["value"] = { label: value?.label, value: value?.value };
      newFields[name]["isValid"] = true;
      setAddedFormData(newFields);
    } else if (value && value) {
      let newFields = _.cloneDeep({ ...formData });
      newFields[name]["value"] = value;
      newFields[name]["isValid"] = true;
      setFormData(newFields);
    }
  };
  const renderIndents = (dataObj, cols) => {
    setSpinner(true);
    Service.getInventoryCategoryDetails(dataObj)
      .then((res) => {
        let checkedCols = cols.filter((col) => col.checked === true);
        setHeadRows(checkedCols);
        let data = res.data.map((item, i) => {
          return {
            ...(checkedCols.find((data) => data.id === "s_no")
              ? {
                  s_no: (
                    <div className={classes.table_data}>
                      {i + dataObj.skip + 1}
                    </div>
                  ),
                }
              : {}),
            ...(checkedCols.find((data) => data.id === "parentEquipment")
              ? {
                  parentEquipment: (
                    <div>
                      <div className={classes.table_data}>
                        {item.parentName ? item.parentName : "--"}
                      </div>
                    </div>
                  ),
                }
              : {}),
            ...(checkedCols.find((data) => data.id === "name")
              ? {
                  name: (
                    <div className={classes.table_data}>
                      {item.name ? item.name : "--"}
                    </div>
                  ),
                }
              : {}),
            ...(checkedCols.find((data) => data.id === "inventoryBenchmark")
              ? {
                  inventoryBenchmark: (
                    <div className={classes.table_data}>
                      {item.inventory_benchmark
                        ? item.inventory_benchmark
                        : "--"}
                    </div>
                  ),
                }
              : {}),
            ...(checkedCols.find((data) => data.id === "vehicleBenchmark")
              ? {
                  vehicleBenchmark: (
                    <div className={classes.table_data}>
                      {item.truck_benchmark ? item.truck_benchmark : "--"}
                    </div>
                  ),
                }
              : {}),
            ...(checkedCols.find((data) => data.id === "actions")
              ? {
                  actions: (
                    <div>
                      <EditIcon
                        style={{
                          //   marginLeft: "5px",
                          color: "#1269A8",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          forShowingPreviousData(item);
                          setEditOpen(true);
                          setSelectedObj(item);
                        }}
                      />
                    </div>
                  ),
                }
              : {}),
          };
        });
        setTotal(res.totalCount);
        setTableData(data);
        setSpinner(false);
      })
      .catch((error) => {
        setSpinner(false);
        setAlertData({
          open: true,
          severity: CONFIG.ALERT_SEVERITY.error,
          message:
            error.response && error.response.data
              ? error.response.data.message
              : "Something went wrong!",
        });
      });
  };
  const forUpdatingEquipment = (formData, selectedObj) => {
    let dataStatus = validateFields.validateFieldsData(formData);
    let updateEquipmentObj = {
      id: selectedObj.id,
      equipment: formData.equipment.value?.value,
      name: formData.name.value,
      inventoryBenchmark: formData.inventoryBenchmark.value,
      vehicleBenchmark: formData.vehicleBenchmark.value,
    };
    if (dataStatus.status) {
      setSpinner(true);
      Service.updateEquipmentDetailsData(updateEquipmentObj).then((res) => {
        if (res.status) {
          setAlertData({
            open: true,
            severity: "success",
            message: res.message,
          });
          setTimeout(() => {
            addhandleClose();
            closeAlert();
            window.location.reload();
            setSpinner(false);
          }, 2000);
        } else {
          setAlertData({
            open: true,
            severity: "error",
            message: res.message,
          });
          setSpinner(false);
        }
      });
    } else {
      let newFields = _.cloneDeep(addedFormData);
      let erroredFields = Object.keys(dataStatus.data).filter(
        (key, i) => dataStatus.data[key].isValid === false
      );
      setAddedFormData(newFields);
    }
  };

  const pageChangeHandler = (page) => {
    let dataObj;
    dataObj = {
      skip: page * pagination.pageSize,
      limit: pagination.pageSize,
    };
    renderIndents(dataObj, cols);
    setPagination({ ...pagination, current: page });
  };
  const rowsPerPageChangeHandler = (rowsPerPage) => {
    let dataObj = {
      skip: pagination.current * rowsPerPage,
      limit: rowsPerPage,
    };

    renderIndents(dataObj, cols);
    setPagination({ ...pagination, pageSize: rowsPerPage });
  };
  const closeAlert = () => {
    let alert = _.cloneDeep(alertData);
    alert.open = false;
    alert.severity = "";
    alert.message = "";
    setAlertData(alert);
  };

  const forAddingEquipmentData = (addedFormData) => {
    let dataStatus = validateFields.validateFieldsData(addedFormData);
    let addedEquipmentObj = {
      equipment: addedFormData.addEquipment.value?.value,
      name: addedFormData.addName.value,
      inventoryBenchmark: addedFormData.addInventoryBenchmark.value,
      vehicleBenchmark: addedFormData.addVehicleBenchmark.value,
    };
    if (dataStatus.status) {
      setSpinner(true);
      Service.addEquipmentDetailsData(addedEquipmentObj).then((res) => {
        if (res.status) {
          setAlertData({
            open: true,
            severity: "success",
            message: res.message,
          });
          setTimeout(() => {
            addhandleClose();
            closeAlert();
            window.location.reload();
            setSpinner(false);
          }, 2000);
        } else {
          setAlertData({
            open: true,
            severity: "error",
            message: res.message,
          });
          setSpinner(false);
        }
      });
    } else {
      let newFields = _.cloneDeep(addedFormData);
      let erroredFields = Object.keys(dataStatus.data).filter(
        (key, i) => dataStatus.data[key].isValid === false
      );
      setAddedFormData(newFields);
    }
  };

  const columnCheckHandler = (columns) => {
    setCols(columns);
    renderIndents(
      {
        skip: pagination.current * pagination.pageSize,
        limit: pagination.pageSize,
      },
      columns
    );
  };
  return (
    <div>
      <Header />
      <div className="main_container">
        <div className="header_box">
          <Typography className="header_text">Equipments</Typography>
          <div className="header_buttons" style={{ padding: "0px 15px" }}>
            <Button
              variant="contained"
              className={classes.addindent_btn}
              color="primary"
              startIcon={<AddCircleRoundedIcon />}
              onClick={() => forOpeningTheAddEquipmentDialogBox()}
              //   disableElevation={true}
            >
              Add Equipment
            </Button>
          </div>
        </div>
        <div>
          {alertData.open ? (
            <div className={classes.alertBox}>
              <AlertMessage
                severity={alertData.severity}
                message={alertData.message}
                closeAlert={closeAlert}
              />
            </div>
          ) : null}
          <div>
            {spinner ? (
              <CircularLoading />
            ) : (
              <Components.DataTable
                headRows={headRows}
                cols={cols}
                tableData={tableData}
                pagination={pagination}
                total={total}
                pageChangeHandler={pageChangeHandler}
                columnCheckHandler={columnCheckHandler}
                rowsPerPageChangeHandler={rowsPerPageChangeHandler}
              />
            )}
          </div>
        </div>
      </div>
      {/* <AddCategories open={open} onClose={() => setOpen(false)} /> */}
      {/* <EditCategories
        open={editOpen}
        onClose={() => setEditOpen(false)}
        editData={editData}
      /> */}
      <Dialog
        open={editOpen}
        onClose={updatehandleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        //   maxWidth="md" // Set the maximum width, you can use 'xs', 'sm', 'md', 'lg', 'xl', or a specific value
        //   fullWidth={true}
      >
        {alertData.open ? (
          <div className={classes.alertBox}>
            <AlertMessage
              severity={alertData.severity}
              message={alertData.message}
              closeAlert={closeAlert}
            />
          </div>
        ) : null}
        <DialogTitle
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
          textAlign="center"
        >
          Update Equipment
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12}>
                <Grid item xs={12} spacing={2}>
                  <Fields.AntSelectableSearchField
                    fieldData={formData.equipment}
                    autoCompleteChangeHandler={autoCompleteChangeHandler}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Fields.InputField
                    fieldData={formData.name}
                    inputChangeHandler={inputChangeHandler}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid container xs={12} spacing={2}></Grid>
            </Grid>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={6}>
                <Fields.InputField
                  fieldData={formData.inventoryBenchmark}
                  inputChangeHandler={inputChangeHandler}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <Fields.InputField
                  fieldData={formData.vehicleBenchmark}
                  inputChangeHandler={inputChangeHandler}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={updatehandleClose} color="primary"
          style={{ 
            textTransform: "none", 
            backgroundColor: "#9d9d9d", // custom background color
            color: "white" // custom text color
          }}>
            Cancel
          </Button>
          <Button
            onClick={() => forUpdatingEquipment(formData, selectedObj)}
            color="primary"
            style={{ 
              textTransform: "none", 
              backgroundColor: "#366e93", // custom background color
              color: "white" // custom text color
            }}
            startIcon={spinner ? <CircularProgress size={20} color={"#fff"}/> : null}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={addOpen}
        onClose={addhandleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        //   maxWidth="md" // Set the maximum width, you can use 'xs', 'sm', 'md', 'lg', 'xl', or a specific value
        //   fullWidth={true}
      >
        {alertData.open ? (
          <div className={classes.alertBox}>
            <AlertMessage
              severity={alertData.severity}
              message={alertData.message}
              closeAlert={closeAlert}
            />
          </div>
        ) : null}
        <DialogTitle
          style={{ cursor: "move" }}
          id="draggable-dialog-title"
          textAlign="center"
        >
          Add Equipment
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12}>
                <Grid item xs={12} spacing={2}>
                  <Fields.AntSelectableSearchField
                    fieldData={addedFormData.addEquipment}
                    autoCompleteChangeHandler={autoCompleteChangeHandler}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Fields.InputField
                    fieldData={addedFormData.addName}
                    inputChangeHandler={inputChangeHandler}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Grid container xs={12} spacing={2}></Grid>
            </Grid>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={6}>
                <Fields.InputField
                  fieldData={addedFormData.addInventoryBenchmark}
                  inputChangeHandler={inputChangeHandler}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={6}>
                <Fields.InputField
                  fieldData={addedFormData.addVehicleBenchmark}
                  inputChangeHandler={inputChangeHandler}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={addhandleClose} color="primary" style={{ 
              textTransform: "none", 
              backgroundColor: "#9d9d9d", // custom background color
              color: "white" // custom text color
            }}>
            Cancel
          </Button>
          <Button
            onClick={() => forAddingEquipmentData(addedFormData)}
            color="primary"
            style={{ 
              textTransform: "none", 
              backgroundColor: "#366e93", // custom background color
              color: "white" // custom text color
            }}
            startIcon={spinner ? <CircularProgress size={20} color={"#fff"}/> : null}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default InventoryCategory;
