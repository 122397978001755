class IndentRequestBuilders {
  constructor(util) {
    this.util = util;
  }
  CreateIndent = (reqObj, indents) => {
    return {
      truckId:
        reqObj?.truckId && reqObj?.truckId?.value
          ? reqObj?.truckId?.value?.value
          : reqObj?.marketTruckId?.value ? reqObj?.marketTruckId?.value?.value : indents?.vendorTrucks?.value && indents?.vendorTrucks?.value.value,
      vendorId: Number(reqObj.vendorId.value),
      ...(reqObj.contractId.value
        ? { contractId: Number(reqObj.contractId.value) }
        : {}),
      ...(reqObj.tripId.value ? { tripId: parseInt(reqObj.tripId.value) } : indents?.vendorTripId?.value ?{tripId: parseInt(indents?.vendorTripId?.value)}: {}),
      ...(reqObj.vendorMobile.value
        ? { vendorMobile: reqObj.vendorMobile.value }
        : {}),
      indentAlias: reqObj.indentAlias.value,
      ...(reqObj.odometer.value === ""
        ? {}
        : { odometer: Number(reqObj.odometer.value) }),
      indentDate: this.util.formatDate(reqObj.indentDate.value),
      requestedUserId: reqObj.requestedUserId.value,
      requestedUserRoleId: reqObj.requestedUserRoleId.value,
      ...(reqObj.comments.value ? { comments: reqObj.comments.value } : {}),
      ...(reqObj.fleetVendorName && reqObj.fleetVendorName.value ? {fvCompanyId:Number(reqObj.fleetVendorName.value.value)}:{}),
      items:  indents.length && indents.length >0 ?
      indents.map((ind) => {
        return {
          indentTypeId: ind.indentTypeId.value.value,
          quantity: parseFloat(ind.quantity.value),
          unitQuantity: parseFloat(ind.unitQuantity.value),
          total: ind.total.value,
          ...(ind.indentRemarks.value
            ? { indentRemarks: ind.indentRemarks.value }
            : {}),
        }
      }) : [{
        indentTypeId: indents.indentTypeId.value.value,
        quantity: parseFloat(indents.quantity.value),
        unitQuantity: parseFloat(indents.unitQuantity.value),
        total: indents.total.value,
        ...(indents.indentRemarks.value
          ? { indentRemarks: indents.indentRemarks.value }
          : {}),
      }]
    };
  };

  UpdateIndent = (reqObj, indents) => {
    return {
      indentId: reqObj.indentId,
      items: indents.map((ind) => {
        return {
          indentId: ind.id.value,
          indentTypeId: ind.indentTypeId.value.value,
          quantity: parseFloat(ind.quantity.value),
          unitQuantity: parseFloat(ind.unitQuantity.value),
          total: ind.total.value,
          actualAmount : ind.actualAmount.value!="" ? ind.actualAmount.value : 0,
          ...(ind.indentRemarks.value
            ? { indentRemarks: ind.indentRemarks.value }
            : { indentRemarks: "" }),
        };
      }),
    };
  };
}
export default IndentRequestBuilders;
