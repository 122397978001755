import Util from "../util";
class VehicleRequestBuilders {
  constructor(util) {
    this.util = util;
  }

  AddVehicles = (reqObj) => {
    let util = new Util()
    return {
      vehicles: reqObj.vehicles.map((veh) => {
        if(veh.gateNum.value != ""){
          return {
            ownerId: veh.FleetVendor ? veh.FleetVendor.value?.value : reqObj.ownerId,
            registrationNumber: veh.regiNo.value,
            chassisNumber: veh.chasisNo.value,
            truckType:veh.VehicleType.value?.value,
            gateNum:veh.gateNum.value,
            rent: veh.rent.value != "" ? veh.rent.value : 0 ,
            dieselMileage : veh.dieselMileage.value != "" ? veh.dieselMileage.value : 0,
            dieselConsumption : veh.dieselConsumption.value != "" ? veh.dieselConsumption.value : 0, 
            hireDate : veh.hireDate.value != "" ? util.formatDate(veh.hireDate.value) : ""
          };
        }
        return {
          ownerId: veh.FleetVendor ? veh.FleetVendor.value?.value : reqObj.ownerId,
          registrationNumber: veh.regiNo.value,
          chassisNumber: veh.chasisNo.value,
          truckType:veh.VehicleType.value?.value,
          rent:veh.rent.value != "" ? veh.rent.value : 0 ,
          dieselMileage : veh.dieselMileage.value != "" ? veh.dieselMileage.value : 0,
          dieselConsumption : veh.dieselConsumption.value != "" ? veh.dieselConsumption.value : 0,
          hireDate : veh.hireDate.value != "" ? util.formatDate(veh.hireDate.value) : ""
        };
      }),
    };
  };
  AddVehicle = (reqObj) => {
    // let geOownerId = reqObj?.vehicles?.
    return {
      vehicles: reqObj.vehicles.map((veh) => {
        if(veh.gateNum.value != ""){
          return {
            "registrationNumber": veh.regiNo.value,
            "chassisNumber": veh.chasisNo.value,
            "truckType": veh.VehicleType.value.value,
            "gateNum" : veh.gateNum.value,
            "dieselMileage" : veh.dieselMileage.value != "" ? veh.dieselMileage.value : 0,
            "dieselConsumption" : veh.dieselConsumption.value != "" ? veh.dieselConsumption.value : 0 

          };
        }
        return {
          "registrationNumber": veh.regiNo.value,
          "chassisNumber": veh.chasisNo.value,
          "truckType": veh.VehicleType.value.value,
          "dieselMileage" : veh.dieselMileage.value != "" ? veh.dieselMileage.value : 0,
          "dieselConsumption" : veh.dieselConsumption.value != "" ? veh.dieselConsumption.value : 0
        };
      }),
    };
  };
}

export default VehicleRequestBuilders;
