class Registration {
    constructor(Service, path, Util) {
      this.httpService = Service;
      this.path = path;
      this.Util = Util;
    }
    insertRegistrationData = (data) => {
      const url =process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "owner/registerOwner";
     

      return new Promise((resolve, reject) => {
        return this.httpService
          .post(url, data)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    };
    forgotPassword = (data) => {
        const url =process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "owner/forgotPassword";
        return new Promise((resolve, reject) => {
          return this.httpService
            .post(url, data)
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        });
      };

      resetNewPassword = (data) => {
        const url =process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "owner/resetNewPassword";
        return new Promise((resolve, reject) => {
          return this.httpService
            .post(url, data)
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        });
      };
      uploadProfilePic = (data) => {
        const url =process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "owner/uploadProfilePic";
        return new Promise((resolve, reject) => {
          return this.httpService
            .post(url, data)
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        });
      };
      forgotResendCode = (data) => {
        const url =process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "owner/resendForgotVCode";
        return new Promise((resolve, reject) => {
          return this.httpService
            .post(url, data)
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        });
      };
      

      insertCompanyInformation = (data) => {
        const url =process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "owner/insertCompanyInfo";
        return new Promise((resolve, reject) => {
          return this.httpService
            .post(url, data)
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        });
      };

      smsVerifyCode = (data) => {
        const url =process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "owner/smsVerifyCode";
        return new Promise((resolve, reject) => {
          return this.httpService
            .post(url, data)
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        });
      };

    //   validateOtp = (data) => {
    //     const url =process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "owner/validateotp";
    //     return new Promise((resolve, reject) => {
    //       return this.httpService
    //         .post(url, data)
    //         .then((res) => {
    //           resolve(res);
    //         })
    //         .catch((error) => {
    //           reject(error);
    //         });
    //     });
    //   };

      resendVCode = (data) => {
        const url =process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "owner/resendVCode";
        return new Promise((resolve, reject) => {
          return this.httpService
            .post(url, data)
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        });
      };
      businessIdUpload = (data) => {
        const url =process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "owner/businessIdUpload";
        return new Promise((resolve, reject) => {
          return this.httpService
            .post(url, data)
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        });
      };

      getCompanyDetails = ()=>{
        const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `owner/allCompanyList`
        return new Promise((resolve,rej)=>{
          this.httpService.get(url).then((res)=>{
            resolve(res)
          }).catch((error)=>{
            rej(error)
          })
        })
      }

      customerValidateOtp = (data) => {
        const url =process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "owner/validateotp";
        return new Promise((resolve, reject) => {
          return this.httpService
            .post(url, data)
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        });
      };

      customerRegistration = (data) => {
        const url =process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "owner/register";
        return new Promise((resolve, reject) => {
          return this.httpService
            .post(url, data)
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        });
      };

      customerImagesUpload = (data) => {
        const url =process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "owner/customerimages";
        return new Promise((resolve, reject) => {
          return this.httpService
            .post(url, data)
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        });
      };
      getAreaofServices = ()=>{
        const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `owner/getServiceAreas`
        return new Promise((resolve,rej)=>{
          this.httpService.get(url).then((res)=>{
            resolve(res)
          }).catch((error)=>{
            rej(error)
          })
        })
      }

      serviceProviderRegistration = (data) => {
        const url =process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + "owner/serviceProvider/register";
        return new Promise((resolve, reject) => {
          return this.httpService
            .post(url, data)
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        });
      };
  }
  
  export default Registration;