module.exports = Object.freeze({
  GET_TRACKING_MESSAGES: "tracking/get-messages",
  GET_OVER_SPEEDING_EVENTS: "tracking/get-overspeeding-events",
  GET_CRASH_EVENTS: "tracking/get-crash-events",
  GET_HARSH_ACCELERATION_EVENTS: "tracking/get-harsh-acceleration-events",
  GET_HARSH_BRAKING_EVENTS: "tracking/get-harsh-braking-events",
  GET_HARSH_CORNERING_EVENTS: "tracking/get-harsh-cornering-events",
  GET_RUNNING_HOURS: "tracking/get-running-hours",
  GET_TRAVELED_DISTANCE: "tracking/get-traveled-distance",
  GET_REFUEL_HISTORY: "tracking/get-fuel-refill",
  GET_SCHEDULE_STOP_EVENTS: "stops/scheduled",
  GET_NON_SCHEDULE_STOP_EVENTS: "stops/unscheduled",
});
