class ClientRequestBuilders {
  constructor(util) {
    this.util = util;
  }
  CreateClient = (reqObj, addAddress) => {
    let details = reqObj.customDetails
      .filter((item) => item.detailType.value.value)
      .map((item) => {
        return { [item.detailType.value.value]: item.detailValue.value };
      });
    return {
      clientAliasCode: reqObj.clientAliasCode.value,
      contactFirstName: reqObj.contactFirstName.value,
      contactLastName: reqObj.contactLastName.value,
      companyName: reqObj.companyName.value,
      countryCode: reqObj.countryCode,
      contactMobile: reqObj.contactMobile,
      paymentTermsInDays: parseInt(reqObj.paymentTermsInDays.value),
      contactEmailAddress: reqObj.contactEmailAddress.value,
      ...(reqObj.gst.value ? { gst: reqObj.gst.value } : {}),
      ...(reqObj.gstType.value ? { gstType: reqObj.gstType.value } : {}),
      clientType: reqObj.clientType.value,
      ...(reqObj.openingBalance.value
        ? { openingBalance: parseFloat(reqObj.openingBalance.value) }
        : {}),
      notes: reqObj.notes.value,
      status: reqObj.status.value,
      customDetails: Object.assign({}, ...details),
      ...(addAddress
        ? {
          clientAddress: {
            addressLine1: reqObj.addressLine1.value,
            addressLine2: reqObj.addressLine2.value,
            addressLine3: reqObj.addressLine3.value,
            city: reqObj.city.value,
            state: reqObj.state.value,
            country: reqObj.country.value,
            postalCode: reqObj.postalCode.value,
            faxNo: reqObj.faxNo.value,
          },
        }
        : {}),
    };
  };
  UpdateClient = (reqObj, addAddress) => {
    let details = reqObj.customDetails
      .filter((item) => item.detailType.value.value)
      .map((item) => {
        return { [item.detailType.value.value]: item.detailValue.value };
      });
    return {
      contactFirstName: reqObj.contactFirstName.value,
      contactLastName: reqObj.contactLastName.value,
      contactMobile: reqObj.contactMobile,
      countryCode: reqObj.countryCode,
      contactEmailAddress: reqObj.contactEmailAddress.value,
      paymentTermsInDays: parseInt(reqObj.paymentTermsInDays.value),
      status: reqObj.status.value,
      gst: reqObj.gst.value,
      gstType: reqObj.gstType.value,
      clientType: reqObj.clientType.value,
      ...(reqObj.openingBalance.value
        ? { openingBalance: parseFloat(reqObj.openingBalance.value) }
        : { openingBalance: 0 }),
      notes: reqObj.notes.value,
      customDetails: Object.assign({}, ...details),
      ...(addAddress
        ? {
          clientAddress: {
            addressLine1: reqObj.addressLine1.value,
            addressLine2: reqObj.addressLine2.value,
            addressLine3: reqObj.addressLine3.value,
            city: reqObj.city.value,
            state: reqObj.state.value,
            country: reqObj.country.value,
            postalCode: reqObj.postalCode.value,
            faxNo: reqObj.faxNo.value,
          },
        }
        : {}),
    };
  };

  GetAllClients = (reqObj) => {
    return {
      // companyId : reqObj.clientId.value.companyId,
      // clientId: reqObj.clientId.value.value,
      // fromTimestamp: new Date(reqObj.dateRange.value[0]).getTime(),
      // toTimestamp: new Date(reqObj.dateRange.value[1]).getTime()
    };
  };
}
export default ClientRequestBuilders;
