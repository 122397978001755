// import React, { useState, useEffect } from "react";
// import { FormHelperText, FormLabel } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core";
// import Geocode from "react-geocode";
// import Autocomplete from "react-google-autocomplete";
// Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
// Geocode.enableDebug();

// const useStyles = makeStyles((theme) => ({
//   textField: { width: "100%" },
//   helperText: { color: "red", marginRight: "0px", marginLeft: "0px" },
//   mtop: { marginTop: "8px" },
//   headLabel: {
//     fontWeight: "normal",
//     color: "#333333",
//     fontSize: "14px",
//   },
// }));

// const PlaceSearch = (props) => {
//   const classes = useStyles();
//   const [address, setAddress] = useState();
//   useEffect(() => {
//     setAddress(props.fieldData.value)
//   },[props.fieldData.value])
//   return (
//     <div>
//       {props.fieldData.topLabel ? (
//         <FormLabel
//           className={classes.headLabel}
//           required={props.fieldData.validationRequired}
//         >
//           {props.index
//             ? props.fieldData.label + " " + props.index
//             : props.fieldData.label}
//         </FormLabel>
//       ) : null}
//       <div className={props.fieldData.topLabel ? "custom_field" : ""}>
//         <Autocomplete
//           style={{
//             width: "100%",
//             height: "35px",
//             paddingLeft: "16px",
//             marginTop: props.fieldData.topLabel ? "8px" : "0px",
//             borderRadius: "4px",
//             border: "1px solid #91b0c4",
//           }}
//           placeholder={props.fieldData.placeholder}
//           onChange={(e) => {
//             if(e.target.value.length === 0){
//               props.emptyLocHandler(e.target.value, props.fieldData.name);
//             }
//             setAddress(e.target.value);
//           }}
//           value={address}
//           onPlaceSelected={(place) => {
//             setAddress(place.formatted_address);
//             props.setLocationHandler(place, props.fieldData.name, props.index);
//           }}
//           types={["geocode", "establishment"]}
//         />
//         {props.fieldData.isValid ? null : (
//           <FormHelperText className={classes.helperText}>
//             {props.fieldData.errorMsg}
//           </FormHelperText>
//         )}
//       </div>
//     </div>
//   );
// };

// export default PlaceSearch;
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import { FormHelperText, FormLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  textField: { width: "100%" },
  helperText: { color: "red", marginRight: "0px", marginLeft: "0px" },
  mtop: { marginTop: "8px" },
  headLabel: {
    fontWeight: "normal",
    color: "#333333",
    fontSize: "12px",
    fontFamily: "Montserrat, SemiBold"
  },
}));

const PlaceSearch = (props, ref) => {
  const [value, setValue] = useState(props.fieldData.value);
  useEffect(() => {
    if (props.wayPointFields && props.wayPointFields.length) {
      setValue(props.fieldData.completeValue);
    }
  }, [props.wayPointFields && props.wayPointFields.length]);
  const classes = useStyles();
  const selectHandler = (val) => {
    setValue(val);
    if (val) {
      geocodeByAddress(val.value.description)
        .then((res) => {
          getLatLng(res[0]).then(({ lat, lng }) => {
            props.setLocationHandler(
              props.fieldData.name,
              val.label,
              lat,
              lng,
              val
            );
          });
        })
        .catch(() => { });
    }
  };
  const clearValue = () => {
    setValue(null);
  };
  const setPlace = (value) => {
    setValue(value);
  };
  useImperativeHandle(ref, () => {
    return { clearValue: clearValue, value: value, setPlace: setPlace };
  });
  return (
    <div ref={ref}>
      {props.fieldData.topLabel ? (
        <FormLabel
          className={classes.headLabel}
          required={props.fieldData.validationRequired}
        >
          {props.index
            ? props.fieldData.label + " " + props.index
            : props.fieldData.label}
        </FormLabel>
      ) : null}
      <div style={{ marginTop: props.fieldData.topLabel ? "8px" : "0px" }}>
        <GooglePlacesAutocomplete
          selectProps={{ value: value, onChange: (val) => { selectHandler(val) } }}
        />
      </div>
      {props.fieldData.isValid ? null : (
        <FormHelperText className={classes.helperText}>
          {props.fieldData.errorMsg}
        </FormHelperText>
      )}
    </div>
  );
};

export default forwardRef(PlaceSearch);
