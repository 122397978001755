import React from "react";
import { TextField, FormHelperText, FormLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  textField: { width: "100%" },
  helperText: { color: "red", marginRight: "0px", marginLeft: "0px" },
  mtop: { marginTop: "8px" },
  headLabel: {
    // textTransform: "uppercase",
    fontWeight: "normal",
    color: "#333333",
    fontSize: "12px",
    fontFamily: "Montserrat, SemiBold",
  },
}));

export default function InputField(props) {
  const classes = useStyles();
  return (
    <div>
      {props?.fieldData?.topLabel && props?.fieldData?.label ? (
        <FormLabel
          className={classes.headLabel}
          required={props?.fieldData?.validationRequired}
          style={{color:props?.fieldData?.labelColor}}
        >
           {props.fieldData?.image ? <img src = {props.fieldData.image} style={{height:'17px',width:'17px',marginRight:'5px'}}/> : null}
          {props?.index
            ? props?.fieldData?.label + " " + props?.index
            : props?.fieldData?.label}
        </FormLabel>
      ) : null}
      <div className={props?.fieldData?.topLabel ? "custom_field" : ""}>
        <TextField style={{backgroundColor:'#FFFFFF'}}
          variant={props?.variant}
          label={
            props?.fieldData?.topLabel
              ? null
              : props?.index
                ? props?.fieldData?.label + " " + props?.index
                : props?.fieldData?.label
          }
          placeholder={props?.fieldData?.placeholder}
          className={`${classes.textField} ${props?.fieldData?.topLabel ? classes.mtop : ""
            }`}
          onWheel={(e) => {
            e.target.blur();
          }}
          disabled={props?.disabled}
          onChange={(e) => {
            if (
              props?.fieldData?.type === "number" &&
              e.target.value &&
              (props?.fieldData?.max || props?.fieldData?.max === 0) &&
              (props?.fieldData?.min ||
                props?.fieldData?.min === 0)
            ) {
              if (
                parseFloat(e.target.value) <= parseFloat(props?.fieldData?.max) &&
                parseFloat(e.target.value) >= parseFloat(props?.fieldData?.min)
              ) {
                props?.inputChangeHandler(e.target.value, props?.fieldData?.name);
              }
            } else if (
              props?.fieldData?.type === "number" &&
              e.target.value &&
              (props?.fieldData?.min || props?.fieldData?.min === 0)
            ) {
              if (
                parseFloat(e.target.value) >= parseFloat(props?.fieldData?.min)
              ) {
                props?.inputChangeHandler(e.target.value, props?.fieldData?.name);
              }
            } else if (
              props?.fieldData?.type === "number" &&
              e.target.value &&
              props?.fieldData?.onlyInt
            ) {
              if (Number.isInteger(Number(e.target.value))) {
                props.inputChangeHandler(e.target.value, props?.fieldData?.name);
              }
            } else {
              props?.inputChangeHandler(e.target.value, props?.fieldData?.name);
            }
          }}
          onBlur={(e) => {
            if (props?.onInputBlurHandler) {
              props?.onInputBlurHandler(e.target.value, props?.fieldData?.name);
            }
          }}
          onKeyPress={(e)=>props.onKeyPressHandler(e)}
          required={props?.fieldData?.validationRequired}
          value={props?.fieldData?.value}
          type={props?.fieldData?.type}
          inputProps={{
            min: props?.fieldData?.min,
            max: props?.fieldData?.max,
            maxLength: props?.fieldData?.maxLength,
            step: props?.fieldData?.step,
          }}
          InputProps={{
            endAdornment: props?.endAdornment,
            startAdornment: props?.startAdornment,
            readOnly: props?.fieldData?.readOnly,
          }}
          InputLabelProps={{
            shrink: props?.fieldData?.shrink
          }}
        />
        {props.fieldData.isValid ? null : (
          <FormHelperText className={classes.helperText}>
            {props?.fieldData?.errorMsg}
          </FormHelperText>
        )}
      </div>
    </div>
  );
}
