import ClientRequestBuilders from "../RequestBuilders/clients";

class Clients {
  constructor(Service, path, Util) {
    this.httpService = Service;
    this.path = path;
    this.Util = Util;
    this.clientRequestBuilder = new ClientRequestBuilders(Util);
  }

  getClients = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(this.path.CLIENTS, data);
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  getAllMetaClients = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(this.path.CLIENTS, data);
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getClient = (data) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.CLIENTS +
      "/" +
      data.clientId;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  createClient = (data, addAddress) => {
    let reqData = this.clientRequestBuilder.CreateClient(data, addAddress);
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + this.path.CLIENTS;
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  updateClient = (data, clientData, addAddress) => {
    let reqData = this.clientRequestBuilder.UpdateClient(data, addAddress);
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.Util.queryParamsFromObj(
        this.path.CLIENTS + "/updateClient",
        clientData
      );
    return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getClientDocuments = (clientData) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.CLIENTS +
      `/${clientData.id}` +
      "/attachments";
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  uploadClientDocument = (file, clientData, config) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.CLIENTS +
      "/" +
      clientData.id +
      "/attachments";
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, file, config)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  deleteClientDocument = (file) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.CLIENTS +
      "/" +
      file.clientId +
      "/attachments/" +
      file.id;
    return new Promise((resolve, reject) => {
      this.httpService
        .delete(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getAllClients = (dataObj) => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +
      this.path.CLIENTS +
      "/allclients";
    let reqData = this.clientRequestBuilder.GetAllClients(dataObj);
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url, reqData)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}

export default Clients;
