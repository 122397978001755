import _ from "lodash";
class Util {
  formatDate = (date) => {
    var d = new Date(date);
    if (isNaN(d.getMonth())) {
      d = new Date();
    }
    var month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  slashFormatDate = (date) => {
    var d = new Date(date);
    if (isNaN(d.getMonth())) {
      d = new Date();
    }
    var month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("/");
  };

  formatDateTime = (date) => {
    var d = new Date(date);
    if (isNaN(d.getMonth())) {
      d = new Date();
    }
    var month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    var hours = d.getHours(),
        minutes = d.getMinutes() < 10 ? '0'+d.getMinutes() : d.getMinutes(),
        seconds = d.getSeconds() < 10 ? '0'+d.getSeconds() : d.getSeconds();
    return [[year, month, day].join("-"), [hours, minutes, seconds].join(":")].join(" ");
  }

  getLngLatString = (data) => {
    let lnglat = [];
    lnglat.push(`${data.pickup.lng},${data.pickup.lat}`);
    let wplnglat = data.wayPointFields.map((wp) => `${wp.location.lng},${wp.location.lat}`);
    lnglat = [...lnglat, ...wplnglat];
    lnglat.push(`${data.dropoff.lng},${data.dropoff.lat}`);
    return lnglat.join(";");
  }

  validatePanGst = (fields) => {
    const dataFields = _.cloneDeep(fields);
    let valid = true;
    for (var key in dataFields) {
      if (dataFields.hasOwnProperty(key)) {
        var val = dataFields[key];
        if (val.name === "pan" && val.value !== "") {
          let re = /^[A-Z0-9]{10}$/;
          if (re.test(val.value)) {
            valid = true;
            dataFields[val.name]["isValid"] = true;
          } else {
            valid = false;
            dataFields[val.name]["isValid"] = false;
          }
        }
        if (val.name === "gst" && val.value !== "") {
          let re = /^[A-Z0-9]{15}$/;
          if (re.test(val.value)) {
            valid = true;
            dataFields[val.name]["isValid"] = true;
          } else {
            valid = false;
            dataFields[val.name]["isValid"] = false;
          }
        }
      }
    }
    if (valid) {
      return { status: valid, data: dataFields };
    } else {
      return { status: valid, data: dataFields };
    }
  };

  myFleetMapStyles = () => {
    return [
      {
        featureType: "water",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#d3d3d3",
          },
        ],
      },
      {
        featureType: "transit",
        stylers: [
          {
            color: "#808080",
          },
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.stroke",
        stylers: [
          {
            visibility: "on",
          },
          {
            color: "#b3b3b3",
          },
        ],
      },
      {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#ffffff",
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "geometry.fill",
        stylers: [
          {
            visibility: "on",
          },
          {
            color: "#ffffff",
          },
          {
            weight: 1.8,
          },
        ],
      },
      {
        featureType: "road.local",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#d7d7d7",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "geometry.fill",
        stylers: [
          {
            visibility: "on",
          },
          {
            color: "#ebebeb",
          },
        ],
      },
      {
        featureType: "administrative",
        elementType: "geometry",
        stylers: [
          {
            color: "#a7a7a7",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#ffffff",
          },
        ],
      },
      {
        featureType: "landscape",
        elementType: "geometry.fill",
        stylers: [
          {
            visibility: "on",
          },
          {
            color: "#efefef",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "labels.text.fill",
        stylers: [
          {
            color: "#696969",
          },
        ],
      },
      {
        featureType: "administrative",
        elementType: "labels.text.fill",
        stylers: [
          {
            visibility: "on",
          },
          {
            color: "#737373",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "labels",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "road.arterial",
        elementType: "geometry.stroke",
        stylers: [
          {
            color: "#d6d6d6",
          },
        ],
      },
      {
        featureType: "road",
        elementType: "labels.icon",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
      {
        featureType: "poi",
        elementType: "geometry.fill",
        stylers: [
          {
            color: "#dadada",
          },
        ],
      },
      {
        featureType: "poi.business",
        elementType: "labels",
        stylers: [
          {
            visibility: "off",
          },
        ],
      },
    ]
  };
}

export default Util;
