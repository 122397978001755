
class Owner {
    constructor(Service, ownerPath) {
        this.httpService = Service;
        this.ownerPath = ownerPath;
    }

    getFleetOwnerImage = (data) => {
        return new Promise((resolve, reject) => {
            const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + this.ownerPath.OWNER_PROFILE_IMAGE;
            return this.httpService.post(url, data)
                .then((res) => {
                    resolve(res);
                })
                .catch((errorObj) => {
                    reject(errorObj);
                });
        });
    };

    getFleetOwnerProfile = () => {
        return new Promise((resolve, reject) => {
            const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + this.ownerPath.OWNER_GET_PROFILE;
            return this.httpService.post(url)
                .then((res) => {
                    resolve(res);
                })
                .catch((errorObj) => {
                    reject(errorObj);
                });
        });
    };
    //
    activeDeactiveTruck = (data) => {
        return new Promise((resolve, reject) => {
            const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `owner/activeDeactiveTruck`;
            return this.httpService.post(url,data)
                .then((res) => {
                    resolve(res);
                })
                .catch((errorObj) => {
                    reject(errorObj);
                });
        });
    };

    storesDistenceData = (data) =>{
        const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `trips/storesDistence/${data}`
        return new Promise((resolve, reject) => {
          this.httpService
            .get(url)
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
    logout = (data) => {
        return new Promise((resolve, reject) => {
            const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_1 + `owner/logout`;
            return this.httpService.post(url,data)
                .then((res) => {
                    resolve(res);
                })
                .catch((errorObj) => {
                    reject(errorObj);
                });
        });
    };
}

export default Owner;