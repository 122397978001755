import React from "react";
import { TextField, FormLabel, FormHelperText } from "@material-ui/core";
import DateTimePicker from "@mui/lab/DateTimePicker";
import { makeStyles } from "@material-ui/core";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";

const useStyles = makeStyles((theme) => ({
  dateTimePickerField: { width: "100%", marginTop: "8px" },
  helperText: { color: "red", marginRight: "0px", marginLeft: "0px" },
  mtop: {
    marginTop: "8px",
    border: "0.5px solid #91b0c4",
    borderRadius: "4px",
  },
  headLabel: {
    fontWeight: "normal",
    color: "#333333",
    fontSize: "12px",
    fontFamily: "Montserrat, SemiBold"
  },
}));

const DateTimePickerField = (props) => {
  const classes = useStyles();
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {props.fieldData.topLabel ? (
          <FormLabel
            className={classes.headLabel}
            required={props.fieldData.validationRequired}
          >
            {props.fieldData.label}
          </FormLabel>
        ) : null}
        <div className="date_picker_field">
          <DateTimePicker
            label={props.fieldData.topLabel ? null : props.fieldData.label}
            value={props.fieldData.value}
            minDateTime={props.fieldData.minDate}
            maxDateTime={props.fieldData.maxDate}
            disablePast={true}
            onChange={(value) => {
              props.timeStampChangeHandler(value, props.fieldData.name);
            }}
            InputProps={{
              disableUnderline: true,
            }}
            clearable={true}
            renderInput={(params) => (
              <TextField
                {...params}
                variant={props.variant}
                className={
                  props.fieldData.topLabel
                    ? `${classes.mtop} ${classes.dateTimePickerField}`
                    : null
                }
              />
            )}
          />
          {props.fieldData.isValid ? null : (
            <FormHelperText className={classes.helperText}>
              {props.fieldData.errorMsg}
            </FormHelperText>
          )}
        </div>
      </LocalizationProvider>
    </div>
  );
};

export default DateTimePickerField;
